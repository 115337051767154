// @ts-nocheck
/* eslint-disable no-nested-ternary */
import { Button, ModalV2, Picto, SelectStatus, Text } from "@zolteam/react-ras-library";
import { format, getYear } from "date-fns";
import localeFr from "date-fns/locale/fr";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { cloneElement, forwardRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import ModalContentManualCommandFilled from "../../../views/Command/Profiles/Modals/ModalContentManualCommandFilled";
import ModalContentOrderNotProvided from "../../../views/Command/Profiles/Modals/ModalContentOrderNotProvided";

// Atoms
import CommandSideMenuItem from "../../atoms/CommandSideMenuItem";
import IdBox from "../../atoms/IdBox";
import ProfileNav from "../../atoms/ProfileNav";

// Hooks
import useEnabledFeatures from "../../../hooks/useEnabledFeatures";
import useOrderData from "../../../hooks/useOrderData";
import useOrderOffer from "../../../hooks/useOrderOffer";

// Utils
import formatPhoneNumber from "../../../utils/formatPhoneNumber";

import { getOrderProfiles, patchOrderStatus } from "../../../services/commandService";

// Constants
import { CREATE_MODE, ORDER_CONTENT_BACKGROUND, ORDER_STATUS, PROFILES_STATUS } from "../../../constants";

// Style
import cn from "../../../utils/cn";
import style from "./CommandSideMenu.module.css";

// Store
import { useToastContext } from "../../../store/ToastContext";

const CommandSideMenu = forwardRef(
	({ agency, client, qualification, contact, routes, backgroundType, children: views }, scrollRef) => {
		const { t } = useTranslation();
		const { orderId } = useParams();
		const { refetchOrderData, orderData, agencyFeaturesData } = useOrderData(orderId);
		const queryClient = useQueryClient();
		const [, toastDispatch] = useToastContext();
		const { pathname } = useLocation();
		const [modalContentManualCommandFilled, setModalContentManualCommandFilled] = useState(false);
		const [orderNotProvidedModalStatus, setOrderNotProvidedModalStatus] = useState(false);
		const { data: order } = orderData || {};
		const { period, missionTime, clientLocationSidebar } = useOrderOffer(order);

		const {
			enabledFeatures: { hasOrderProfilesAccess, hasMatchingAccess },
		} = useEnabledFeatures();

		const isNewCommand = orderId === CREATE_MODE;

		const commandStatusOption = [
			{
				label: t("commands.statusType.IN_PROGRESS"),
				value: ORDER_STATUS.IN_PROGRESS,
			},
			{
				label: t("commands.statusType.IS_FILLED"),
				value: ORDER_STATUS.IS_FILLED,
			},
			{
				label: t("commands.statusType.NOT_PROVIDED"),
				value: ORDER_STATUS.NOT_PROVIDED,
			},
		];

		const { data: orderProfilesData } = useQuery(["getOrderProfiles", orderId], () => getOrderProfiles(orderId), {
			enabled: !!order,
		});

		const primaryPhone = useMemo(() => contact?.phone ?? contact?.phone2, [contact?.phone, contact?.phone2]);

		const filteredCommandStatusOption = useMemo(() => {
			if (!orderProfilesData?.data) return [];

			const hasValidatedStatus = orderProfilesData.data.some(
				(profile) => profile.status === PROFILES_STATUS.VALIDATED
			);
			const inProgressStatus = order?.status === ORDER_STATUS.IN_PROGRESS;
			const notProvidedStatus = order?.status === ORDER_STATUS.NOT_PROVIDED;

			if (hasValidatedStatus && inProgressStatus) {
				return [
					{
						label: t("commands.statusType.IS_FILLED"),
						value: ORDER_STATUS.IS_FILLED,
					},
					{
						label: t("commands.statusType.NOT_PROVIDED"),
						value: ORDER_STATUS.NOT_PROVIDED,
					},
				];
			}

			if (!hasValidatedStatus && inProgressStatus) {
				return [
					{
						label: t("commands.statusType.NOT_PROVIDED"),
						value: ORDER_STATUS.NOT_PROVIDED,
					},
				];
			}

			if (notProvidedStatus) {
				return [];
			}

			return commandStatusOption.filter(({ value }) => value !== order?.status);
		}, [orderData?.data?.status, orderProfilesData, orderProfilesData?.data]);

		const orderStatusValue = useMemo(() => {
			if (order?.status) {
				return commandStatusOption.find(({ value }) => value === order?.status);
			}
			return { label: "", value: "" };
		}, [order?.status]);

		const handleSelectStatusVariant = (newStatus) => {
			switch (newStatus) {
				case ORDER_STATUS.IN_PROGRESS:
					return "default";
				case ORDER_STATUS.IS_FILLED:
					return "success";
				case ORDER_STATUS.NOT_PROVIDED:
					return "neutral";
				default:
					return "default";
			}
		};

		const getSuccessMessage = (orderStatus) => {
			if (orderStatus === "IS_FILLED") {
				return t("commands.profiles.modalsContent.commandFilled.succesFilledOrderMessage");
			}
			if (orderStatus === "IN_PROGRESS") {
				return t("commands.profiles.modalsContent.commandFilled.succesInProgressdOrderMessage");
			}
			if (orderStatus === "NOT_PROVIDED") {
				return t("commands.profiles.modalsContent.commandFilled.succesNotFilledOrderMessage");
			}
			return null;
		};

		const patchOrderStatusMutation = useMutation(
			async (newStatus) => patchOrderStatus(orderId, newStatus.value, newStatus.statusReasonId || null),
			{
				onMutate: async () => {
					setModalContentManualCommandFilled(false);
					await queryClient.cancelQueries({ queryKey: ["getOrder", orderId] });
					const previousState = queryClient.getQueryData(["getOrder", orderId]);

					return { previousState };
				},
				onSuccess: (response) => {
					toastDispatch({
						type: "SUCCESS",
						payload: {
							icon: "checkCircle",
							msg: getSuccessMessage(response?.data?.status),
						},
					});
				},
				onError: (error, _, context) => {
					const { message } = error.response.data;
					queryClient.setQueryData(["getOrder", orderId], context.previousState);
					toastDispatch({
						type: "ERROR",
						payload: {
							icon: "alertCircle",
							msg: t(`commands.apiCommandErrors.${message}`)
								? t(`commands.apiCommandErrors.${message}`)
								: message,
							params: {
								autoClose: true,
								closeOnClick: true,
							},
						},
					});
				},
				onSettled: () => {
					refetchOrderData();
					queryClient.invalidateQueries({ queryKey: ["getOrder", orderId] });
				},
			}
		);

		const handleOrderStatusChange = (newOrderStatus) => {
			if (newOrderStatus.value === ORDER_STATUS.IS_FILLED) {
				setModalContentManualCommandFilled(true);
			} else if (newOrderStatus.value === ORDER_STATUS.NOT_PROVIDED) {
				setOrderNotProvidedModalStatus(true);
			} else {
				patchOrderStatusMutation.mutate(newOrderStatus);
			}
		};

		const handleConfirmModalClick = async () => {
			patchOrderStatusMutation.mutate({
				label: t("commands.statusType.IS_FILLED"),
				value: ORDER_STATUS.IS_FILLED,
			});
		};

		const handleConfirmOrderNotProvidedClick = (orderNotProvidedSelected) => {
			setOrderNotProvidedModalStatus(false);
			patchOrderStatusMutation.mutate({
				label: t("commands.statusType.NOT_PROVIDED"),
				value: ORDER_STATUS.NOT_PROVIDED,
				statusReasonId: orderNotProvidedSelected.id,
			});
		};

		const links = useMemo(() => {
			if (!orderId) return [];
			return routes
				.filter((route) => {
					switch (route.routeName) {
						case "profils": {
							return hasOrderProfilesAccess && agencyFeaturesData?.hasAccessToProfileManagement;
						}
						case "matching": {
							return hasMatchingAccess;
						}
						default: {
							return true;
						}
					}
				})
				.map((route) => ({
					...route,
					path: route.path.replace(":orderId", orderId),
				}));
		}, [
			routes,
			orderId,
			hasOrderProfilesAccess,
			hasMatchingAccess,
			agencyFeaturesData?.hasAccessToProfileManagement,
		]);

		const periods = useMemo(() => {
			if (!period?.startDate && !period?.endDate) return "";

			const startDate = new Date(period.startDate);
			const endDate = new Date(period.endDate);

			const isYearSame = getYear(startDate) === getYear(endDate);
			return `${format(startDate, `dd MMM ${isYearSame ? "" : "yyyy"}`, {
				locale: localeFr,
			})} - ${format(endDate, "dd MMM yyyy", {
				locale: localeFr,
			})}`;
		}, [period?.startDate, period?.endDate]);

		const renderPeriod = () => {
			const { startHour, endHour, startHour2, endHour2 } = missionTime ?? {};
			const hasFirstSlotValid = !!startHour && !!endHour;
			const hasLastSlotValid = !!startHour2 && !!endHour2;
			const hasExactlyOneEmptyStringValue = (obj) => {
				const values = Object.values(obj);
				const emptyStringCount = values.filter((value) => value === "").length;
				return emptyStringCount === 1;
			};

			if (hasExactlyOneEmptyStringValue(missionTime)) {
				return "";
			}

			if (hasFirstSlotValid && hasLastSlotValid) {
				return (
					<>
						<Text tag="div" size="paragraph01" fontWeight="light" color="white" className={style.ptxs}>
							{t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
								startHour: missionTime?.startHour,
								endHour: missionTime?.endHour,
							})}
						</Text>
						<Text tag="div" size="paragraph01" fontWeight="light" color="white" className={style.ptxs}>
							{t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
								startHour: missionTime?.startHour2,
								endHour: missionTime?.endHour2,
							})}
						</Text>
					</>
				);
			}
			if (hasFirstSlotValid && !hasLastSlotValid) {
				return t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
					startHour,
					endHour,
				});
			}
			if (!hasFirstSlotValid && hasLastSlotValid) {
				return t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
					startHour: startHour2,
					endHour: endHour2,
				});
			}

			return "";
		};

		return (
			<>
				<div className={cn([style.sidemenu, style.sidemenuDesktop])} data-testid="formSideMenu">
					{!isNewCommand && (
						<div className={style.info}>
							<div className={style.selectStatus}>
								<SelectStatus
									options={filteredCommandStatusOption || []}
									variant={handleSelectStatusVariant(orderStatusValue.value)}
									value={orderStatusValue}
									onChange={handleOrderStatusChange}
									noOptionsMessage={() => null}
								/>
							</div>
							{order?.tempoId ? <IdBox>{order.tempoId}</IdBox> : null}
							<Text tag="div" size="heading02" color="white" fontWeight="bold" className={style.ptm}>
								{contact?.orderStatus || ""}
							</Text>
							{agency?.name ? (
								<Text
									tag="div"
									size="paragraph01"
									color="white"
									fontWeight="light"
									className={style.pts}
								>
									{agency.name || ""}
								</Text>
							) : (
								<Skeleton
									className={style.skeletonMenuItem}
									baseColor="var(--color-neutral-700)"
									highlightColor="var(--color-neutral-600)"
									height={30}
								/>
							)}
							<hr className={style.menuSeparator} />
							{client?.name ? (
								<Text
									tag="div"
									size="heading03"
									fontWeight="normal"
									color="white"
									className={style.ptxs}
								>
									{client.name || ""}
								</Text>
							) : (
								<Skeleton
									className={style.skeletonMenuItem}
									baseColor="var(--color-neutral-700)"
									highlightColor="var(--color-neutral-600)"
									height={50}
								/>
							)}
							<div className={style.contactBlock}>
								{contact?.name ? (
									<Text
										tag="div"
										size="paragraph01"
										color="white"
										fontWeight="light"
										className={style.pts}
									>
										{contact.name || ""}
									</Text>
								) : contact?.name === null ? null : (
									<Skeleton
										className={style.skeletonMenuItem}
										baseColor="var(--color-neutral-700)"
										highlightColor="var(--color-neutral-600)"
										height={30}
										width={120}
									/>
								)}
							</div>
							{primaryPhone || contact?.email ? (
								<div className={style.contact}>
									<div className={style.contactPhone}>
										<div className={style.phone1}>
											{primaryPhone && (
												<a href={`tel:${primaryPhone}`} target="__blank">
													<Button
														tabIndex="-1"
														size="s"
														color="white"
														outline
														type="tel"
														className={style.contactButton}
													>
														{formatPhoneNumber(`+33${primaryPhone?.slice(1)}` || "")}
													</Button>
												</a>
											)}
											{contact?.email && (
												<button
													className={style.mailButton}
													onClick={(e) => {
														window.location = `mailto:${contact?.email}`;
														e.preventDefault();
													}}
													type="button"
													aria-label="mail"
												>
													<Picto icon="mail" className={style.mailIcon} />
												</button>
											)}
										</div>
										{contact?.phone2 && contact?.phone && (
											<a href={`tel:${contact?.phone}`} target="__blank">
												<Button
													tabIndex="-1"
													size="s"
													color="white"
													outline
													type="tel"
													className={style.contactButton}
												>
													{formatPhoneNumber(`+33${contact?.phone2?.slice(1)}` || "")}
												</Button>
											</a>
										)}
									</div>
								</div>
							) : contact?.phone === null || contact?.email === null ? null : (
								<div className={style.skeletonContact}>
									<Skeleton
										className={style.skeletonMenuItem}
										baseColor="var(--color-neutral-700)"
										highlightColor="var(--color-neutral-600)"
										height={30}
									/>
								</div>
							)}
							<hr className={style.menuSeparator} />
							{qualification?.name ? (
								<Text
									tag="div"
									size="heading03"
									fontWeight="bold"
									color="white"
									className={cn([style.ptxs, style.qualification])}
								>
									{qualification.name}
								</Text>
							) : (
								<Skeleton
									className={style.skeletonMenuItem}
									baseColor="var(--color-neutral-700)"
									highlightColor="var(--color-neutral-600)"
									height={30}
									width={100}
								/>
							)}
							{clientLocationSidebar ? (
								<Text
									tag="div"
									size="paragraph01"
									fontWeight="light"
									color="white"
									className={style.ptm}
								>
									{clientLocationSidebar}
								</Text>
							) : clientLocationSidebar === null ? (
								<Skeleton
									className={cn([style.skeletonMenuItem, style.skeletonSpacing])}
									baseColor="var(--color-neutral-700)"
									highlightColor="var(--color-neutral-600)"
									height={30}
								/>
							) : null}
							{period?.startDate && period?.endDate ? (
								<Text
									tag="div"
									size="paragraph01"
									fontWeight="light"
									color="white"
									className={style.pts}
								>
									{periods}
								</Text>
							) : (
								<Skeleton
									className={cn([style.skeletonMenuItem, style.skeletonSpacing])}
									baseColor="var(--color-neutral-700)"
									highlightColor="var(--color-neutral-600)"
									height={30}
									width={100}
									count={2}
								/>
							)}
							<div className={style.pts}>{renderPeriod()}</div>
						</div>
					)}

					<div className={style.menu}>
						{links.map((route) => (
							<CommandSideMenuItem key={route.label} path={route.path}>
								{route.label}
							</CommandSideMenuItem>
						))}
					</div>
				</div>
				<div className={style.sidemenuMobile}>
					<div className={style.info}>
						{!isEmpty(order) ? (
							<>
								{order?.tempoId && (
									<div className={style.idBox}>
										<IdBox>{order.tempoId}</IdBox>
									</div>
								)}
								<SelectStatus
									options={filteredCommandStatusOption || []}
									variant={handleSelectStatusVariant(orderStatusValue.value)}
									value={orderStatusValue}
									onChange={handleOrderStatusChange}
									noOptionsMessage={() => null}
									size="small"
									isSearchable={false}
									className={style.selectStatusSmall}
								/>
								<Text
									tag="div"
									size="paragraph01"
									color="black"
									fontWeight="normal"
									className={style.infoText}
								>
									{`${client?.name || t("global.loading")} - ${order.qualificationName}`}
								</Text>
							</>
						) : null}
					</div>
					<div className={style.menu}>
						{links.map((route) => (
							<ProfileNav key={route.label} path={route.path} isActive={pathname === route.path}>
								{route.label}
							</ProfileNav>
						))}
					</div>
				</div>
				<div
					className={cn([style.container, style.containerMobile, style[`containerTheme${backgroundType}`]])}
					ref={scrollRef}
				>
					{cloneElement(views, {
						scrollRef,
					})}
				</div>
				<ModalV2
					isDisplayed={modalContentManualCommandFilled}
					onClose={() => setModalContentManualCommandFilled(false)}
					size="s"
				>
					<ModalContentManualCommandFilled
						onConfirmClick={() => handleConfirmModalClick()}
						onCancelClick={() => {
							setModalContentManualCommandFilled(false);
						}}
					/>
				</ModalV2>
				<ModalV2
					isDisplayed={orderNotProvidedModalStatus}
					onClose={() => setOrderNotProvidedModalStatus(false)}
					size="s"
					className={style.modal}
				>
					<ModalContentOrderNotProvided
						onCancelClick={() => setOrderNotProvidedModalStatus(false)}
						onAddClick={(orderNotProvidedSelected) => {
							handleConfirmOrderNotProvidedClick(orderNotProvidedSelected);
						}}
						currentOrder={orderData?.data}
					/>
				</ModalV2>
			</>
		);
	}
);

CommandSideMenu.propTypes = {
	qualification: PropTypes.shape(),
	agency: PropTypes.shape(),
	client: PropTypes.shape(),
	contact: PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string,
		name: PropTypes.string,
		function: PropTypes.string,
		phone: PropTypes.string,
		phone2: PropTypes.string,
		email: PropTypes.string,
		orderStatus: PropTypes.string,
	}),
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string,
			label: PropTypes.string,
			component: PropTypes.node,
		})
	),
	backgroundType: PropTypes.oneOf(Object.values(ORDER_CONTENT_BACKGROUND)),
	children: PropTypes.node,
};

CommandSideMenu.defaultProps = {
	agency: {},
	client: {},
	contact: {
		id: 0,
		title: "",
		name: "",
		function: "",
		phone: "",
		phone2: "",
		email: "",
		orderStatus: "",
	},
	routes: {
		path: "",
		label: "",
		component: null,
	},
	children: null,
	backgroundType: ORDER_CONTENT_BACKGROUND.WHITE,
	qualification: {},
};

export default CommandSideMenu;
