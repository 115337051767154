// @ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ModalV2 } from "@zolteam/react-ras-library";

// Style
import style from "./WithTopBarre.module.css";
import cn from "../../../utils/cn";

const WithTopBarre = ({ children, leftContent, rightContent, quickEntryContent, title, containerClassname }) => {
	// Will add hook to use the global search
	const [isQuickEntryOpen, setIsQuickEntryOpen] = useState(false);

	return (
		<div className={style.withTopBarreWrapper}>
			{leftContent || rightContent ? (
				<header className={style.topBarre}>
					{leftContent && <div className={style.leftContent}>{leftContent || null}</div>}
					<div className={style.rightContent}>{rightContent || null}</div>
				</header>
			) : null}
			{title ? <div className={style.pageTitle}>{title}</div> : null}
			<main className={cn([style.withTopBarreContent, containerClassname])}>{children}</main>

			<ModalV2 isDisplayed={isQuickEntryOpen} onClose={() => setIsQuickEntryOpen(false)} size="m">
				{quickEntryContent}
			</ModalV2>
		</div>
	);
};

WithTopBarre.propTypes = {
	children: PropTypes.node.isRequired,
	quickEntryContent: PropTypes.node.isRequired,
	leftContent: PropTypes.node,
	rightContent: PropTypes.node,
	title: PropTypes.node,
	containerClassname: PropTypes.string,
};

WithTopBarre.defaultProps = {
	leftContent: null,
	rightContent: null,
	title: null,
	containerClassname: "",
};

export default WithTopBarre;
