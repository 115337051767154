// @ts-nocheck
/* eslint-disable max-len */
import { Checkbox, InfoMessage, Picto, SelectAsync, Tag, Title, ToggleSwitch } from "@zolteam/react-ras-library";
import { Form, Formik, useFormikContext } from "formik";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import DateRangeInput from "../../../../../../components/atoms/DateRangeInput/DateRangeInput";
import DateRangePicker from "../../../../../../components/molecules/DateRangePicker";

// Hooks
import useOrderData from "../../../../../../hooks/useOrderData";
import useBusinessInformation from "./useBusinessInformation";

// Style
import cn from "../../../../../../utils/cn";
import style from "./BusinessInformation.module.css";

// Utils
import { getAtomFromDate } from "../../../../../../utils/formatDate";

// Services
import Slider from "../../../../../../components/atoms/Slider";
import {
	getAvailableFilters,
	getCommonDocuments,
	getCommonQualifications,
	getOrdersEquipments,
	getOutOfBusinessReasons,
	getVehiculesList,
} from "../../../../../../services/commandService";

// Utils
import { CURRENTAVAILABLEFILTERS, ORDER_EQUIPMENTS } from "../../../../../../constants";

const BusinessInformation = ({
	onRadiusChange,
	onStatusChange,
	onQualificationSelect,
	onQualificationDelete,
	onCommonDocumentSelect,
	onCommonDocumentDelete,
	onVehiclesChange,
	onOutOfBusinessReasons,
	onEquipmentSelect,
	onEquipmentDelete,
	onAvailabilityElementChange,
	onPeriodChange,
}) => {
	const { t } = useTranslation();
	const { orderId } = useParams();
	const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext();
	const {
		distance,
		statuses,
		equipments,
		qualifications,
		commonDocuments,
		isMedicalCheckupValid,
		isAdequateForClient,
		hasAlreadyWorkedForClient,
		vehicles,
		isOutOfBusiness,
		outOfBusinessReasonsIds,
		period,
		isTempWorkerAvailabilitiesFilterEnabled,
		isTempWorkerTimeOffFilterEnabled,
		isTempWorkerOrderFilterEnabled,
	} = values;

	const [documentInputValue, setDocumentInputValue] = useState("");
	const [qualificationInputValue, setQualificationInputValue] = useState("");
	const [equipmentInputValue, setEquipmentInputValue] = useState("");

	const { startDate, endDate } = period;

	const DATE_FORMAT_STRING = "DD/MM/yyyy";
	const [focusedInputName, setFocusedInputName] = useState("");

	const { orderData } = useOrderData(orderId);
	const { isLocationBeingCalculated, locationCouldNotBeCalculated } = useBusinessInformation(orderData);

	// API Calls
	let timeoutId;
	// API Calls

	const { data: outOfBusinessReasonsList, isLoading: isoutOfBusinessReasonsListLoading } = useQuery(
		"getOutOfBusinessReasons",
		getOutOfBusinessReasons
	);

	const { data: vehiclesList, isLoading: isVehiclesListLoading } = useQuery("getVehiculesList", getVehiculesList);

	const formatResult = (result) => result.data?.items?.sort((a, b) => a.name.localeCompare(b.name));

	const { data: ordersEquipmentsData, isFetched: ordersEquipmentsFetched } = useQuery(
		"ordersEquipments",
		getOrdersEquipments,
		{
			staleTime: Infinity,
			placeholderData: { data: [] },
		}
	);

	const { data: availableFilters } = useQuery(["availableFilters"], () => getAvailableFilters());

	const orderEquipmentOptions = useMemo(() => {
		if (!ordersEquipmentsData) return [];
		return ordersEquipmentsData.data.map(({ id, name }) => ({
			id,
			name: ORDER_EQUIPMENTS[name],
		}));
	}, [ordersEquipmentsData]);

	const qualficationOptions = async (inputValue) => {
		clearTimeout(timeoutId);

		if (inputValue.length < 3) return null;

		return new Promise((resolve) => {
			timeoutId = setTimeout(async () => {
				if (inputValue.length < 3) {
					resolve([]);
				} else {
					try {
						const res = await getCommonQualifications({
							search: inputValue,
							limit: 200,
						});
						resolve(formatResult(res));
					} catch (error) {
						resolve([]);
					}
				}
			}, 200);
		});
	};

	const commonDocumentsOptions = async (inputValue) => {
		clearTimeout(timeoutId);

		if (inputValue.length < 3) return null;

		return new Promise((resolve) => {
			timeoutId = setTimeout(async () => {
				if (inputValue.length < 3) {
					resolve([]);
				} else {
					try {
						const res = await getCommonDocuments({
							search: inputValue,
							limit: 200,
						});
						resolve(formatResult(res));
					} catch (error) {
						resolve([]);
					}
				}
			}, 200);
		});
	};

	const renderSliderLabel = (value) => {
		if (value === 10) return "Moins de 10 km";
		if (value === 100 || value === null) return "100 km et plus";
		return `${value} km`;
	};

	const handleStatusCheckboxChange = (statusType, checked) => {
		const newStatuses = checked ? [...statuses, statusType] : statuses.filter((status) => status !== statusType);
		onStatusChange("statuses", newStatuses);
	};

	const handleVehiclesCheckboxChange = (statusType, checked) => {
		const newStatuses = checked ? [...vehicles, statusType] : vehicles.filter((status) => status !== statusType);
		onVehiclesChange("vehicles", newStatuses);
	};

	const handleOutOfBusinessReasonsCheckboxChange = (statusType, checked) => {
		const newStatuses = checked
			? [...outOfBusinessReasonsIds, statusType]
			: outOfBusinessReasonsIds.filter((status) => status !== statusType);
		onStatusChange("outOfBusinessReasonsIds", newStatuses);
	};

	const handleDateRangeUpdate = (start, end) => {
		const newPeriod = {
			startDate: start ? getAtomFromDate(start) : null,
			endDate: end ? getAtomFromDate(end) : null,
		};

		if (startDate) {
			setFieldTouched("startDate", true);
		}
		if (endDate) {
			setFieldTouched("endDate", true);
		}

		onPeriodChange("period", newPeriod);
	};

	const handleClearPeriodDate = (periodSlot, event) => {
		event.stopPropagation();
		const newValues = {
			...values.period,
			[periodSlot]: null,
		};

		setFieldTouched(periodSlot, true);
		onPeriodChange("period", newValues);
	};

	const renderSelectedQualifications = ({ name, id }) => (
		<div className={style.addedItem} key={id}>
			<Tag key={id} color="primaryLight" size="s" showCloseButton onClose={() => onQualificationDelete(id)}>
				<p className={style.itemSelected}>{`${name}`}</p>
			</Tag>
		</div>
	);

	const renderSelectedCommonDocuments = ({ name, id }) => (
		<div className={style.addedItem} key={id}>
			<Tag
				key={id}
				color="primaryLight"
				size="s"
				className={style.tag}
				showCloseButton
				onClose={() => onCommonDocumentDelete(id)}
			>
				<p className={style.documentSelected}>{`${name}`}</p>
			</Tag>
		</div>
	);

	const renderSelectedEquipments = (id) => {
		const qualificationName = orderEquipmentOptions.find((option) => option.id === id)?.name;
		return (
			<div className={style.addedItem} key={id}>
				<Tag key={id} color="primaryLight" size="s" showCloseButton onClose={() => onEquipmentDelete(id)}>
					<p className={style.itemSelected}>{`${qualificationName}`}</p>
				</Tag>
			</div>
		);
	};

	const renderOptionLabel = (option, isSelected) => (
		<div className={style.optionsLabel}>
			<div className={style.optionCheckbox}>
				<Checkbox
					className={style.checkbox}
					onChange={(e) => {
						e.stopPropagation();
						// e.preventDefault();
					}}
					theme="primary"
					id={option.unifiedTempWorkerUniqueId}
					value={isSelected}
				/>
			</div>
			<p className={cn([style.optionLabel, isSelected ? style.active : ""])}>{`${option.name}`}</p>
		</div>
	);

	const showClientHistory =
		availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.ADEQUATE_FOR_CLIENT) ||
		availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.ALREADY_WORKED_FOR_CLIENT);

	const showClientAdequate = availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.ADEQUATE_FOR_CLIENT);
	const showClientWorked = availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.ALREADY_WORKED_FOR_CLIENT);

	return (
		<>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.profiles.modalsContent.matchingFilters.title")}
			</Title>
			{availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.GEO_DISTANCE) && (
				<>
					<div className={style.infoMessage}>
						{locationCouldNotBeCalculated && (
							<InfoMessage color="warning" withIcon>
								{t("commands.profiles.modalsContent.matchingFilters.locationCouldNotBeCalculated")}
							</InfoMessage>
						)}
					</div>
					<div className={style.infoMessage}>
						{isLocationBeingCalculated && (
							<InfoMessage color="warning">
								<div className={style.infoMessageContent}>
									<Picto icon="refresh" className={style.refreshPicto} />
									{t("commands.profiles.modalsContent.matchingFilters.geolocationInProgress")}
								</div>
							</InfoMessage>
						)}
					</div>
				</>
			)}
			<div className={style.section}>
				<h3 className={style.sectionTitle}>{t("commands.profiles.modalsContent.matchingFilters.subTitle")}</h3>

				{/* QUALIFICATIONS */}
				{availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.QUALIFICATION) && (
					<div className={style.subSection}>
						<div className={style.subtitle}>
							{t("commands.profiles.modalsContent.matchingFilters.sectionTitleQualification")}
						</div>
						<div className={style.addedItemBox}>
							{qualifications?.map((qualification) => renderSelectedQualifications(qualification)) || []}
						</div>
						<div className={style.searchInput}>
							<Formik>
								{() => (
									<Form className={style.addProfileForm}>
										<SelectAsync
											className={cn([
												style.inputs,
												errors?.qualifications ? "errorSelect" : null,
											])}
											isSearchable
											searchIcon
											promiseOptions={qualficationOptions}
											onChange={(item) => {
												onQualificationSelect(item);
											}}
											inputPersistOnOptionSelect
											name="qualifications"
											label={t("commands.profiles.modalsContent.matchingFilters.selectLabel")}
											getOptionLabel={(option) =>
												renderOptionLabel(
													option,
													qualifications?.find((opt) => opt.name === option.name) != null
												)
											}
											getOptionValue={(option) => option.unifiedTempWorkerUniqueId}
											value=""
											handleInputChange={(value, { action, prevInputValue }) => {
												if (action === "set-value") {
													setQualificationInputValue(prevInputValue);
													return prevInputValue;
												}
												if (action !== "menu-close") setQualificationInputValue(value);
												return value;
											}}
											inputValue={qualificationInputValue}
											noOptionsMessage={() => null}
											loadingMessage={() =>
												t(
													"commands.profiles.modalsContent.matchingFilters.qualifications.loadingMessage"
												)
											}
										/>
										{errors.qualifications && (
											<InfoMessage color="error" withIcon>
												{errors.qualifications}
											</InfoMessage>
										)}
									</Form>
								)}
							</Formik>
						</div>
					</div>
				)}

				{/* DOCUMENTS */}
				{availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.DOCUMENTS) && (
					<div className={style.subSection}>
						<div className={style.subtitle}>
							{t("commands.profiles.modalsContent.matchingFilters.sectionDocuments.title")}
						</div>
						{availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.MEDICAL_CHECKUP) && (
							<div className={style.switch}>
								<ToggleSwitch
									id="isMedicalCheckupValid"
									name="isMedicalCheckupValid"
									onChange={() => setFieldValue("isMedicalCheckupValid", !isMedicalCheckupValid)}
									toggled={isMedicalCheckupValid}
								>
									<label htmlFor="isMedicalCheckupValid" className={style["toggle-label"]}>
										{t(
											"commands.profiles.modalsContent.matchingFilters.sectionDocuments.medicalVisit"
										)}
									</label>
								</ToggleSwitch>
							</div>
						)}
						<div className={style.addedItemBox}>
							{commonDocuments?.map((commonDocument) => renderSelectedCommonDocuments(commonDocument)) ||
								[]}
						</div>
						<div className={style.searchInput}>
							<Formik>
								{() => (
									<Form className={style.addProfileForm}>
										<SelectAsync
											className={cn([style.inputs])}
											isSearchable
											searchIcon
											inputPersistOnOptionSelect
											promiseOptions={commonDocumentsOptions}
											onChange={(item) => {
												onCommonDocumentSelect(item);
											}}
											name="commonDocuments"
											label={t(
												"commands.profiles.modalsContent.matchingFilters.documents.selectCommonDocumentLabel"
											)}
											getOptionLabel={(option) =>
												renderOptionLabel(
													option,
													commonDocuments?.find((opt) => opt.name === option.name) != null
												)
											}
											getOptionValue={(option) => option.id}
											value=""
											handleInputChange={(value, { action, prevInputValue }) => {
												if (action === "set-value") {
													setDocumentInputValue(prevInputValue);
													return prevInputValue;
												}
												if (action !== "menu-close") setDocumentInputValue(value);
												return value;
											}}
											inputValue={documentInputValue}
											noOptionsMessage={() => null}
											loadingMessage={() =>
												t(
													"commands.profiles.modalsContent.matchingFilters.documents.loadingMessage"
												)
											}
										/>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				)}

				{/* EQUIPMENTS */}
				{availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.EQUIPMENTS) && (
					<div>
						{ordersEquipmentsFetched ? (
							<div className={style.searchInput}>
								<div className={style.subSection}>
									<div className={style.subtitle}>
										{t("commands.profiles.modalsContent.matchingFilters.sectionEquipments.title")}
									</div>
									<div className={style.addedItemBox}>
										{equipments.map((qualificationId) =>
											renderSelectedEquipments(qualificationId)
										) || []}
									</div>
									<Formik>
										{() => (
											<Form className={style.addProfileForm}>
												<SelectAsync
													className={cn([style.inputs])}
													defaultOptions={orderEquipmentOptions}
													promiseOptions={async (input) => {
														if (input.length < 3) return orderEquipmentOptions;
														return orderEquipmentOptions.filter((option) =>
															option.name.toLowerCase().includes(input.toLowerCase())
														);
													}}
													onChange={(item) => {
														onEquipmentSelect(item);
													}}
													inputPersistOnOptionSelect
													name="profile"
													label={t(
														"commands.profiles.modalsContent.matchingFilters.sectionEquipments.selectLabel"
													)}
													getOptionLabel={(option) =>
														renderOptionLabel(
															option,
															equipments?.find((id) => option.id === id) != null
														)
													}
													getOptionValue={(option) => option.id}
													value=""
													handleInputChange={(value, { action, prevInputValue }) => {
														if (action === "set-value") {
															setEquipmentInputValue(prevInputValue);
															return prevInputValue;
														}
														if (action !== "menu-close") setEquipmentInputValue(value);
														return value;
													}}
													inputValue={equipmentInputValue}
													noOptionsMessage={() => null}
													loadingMessage={() => null}
												/>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						) : (
							<div>Chargement des équipements...</div>
						)}
					</div>
				)}
			</div>
			{/* STATUS */}
			{availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.STATUS) && (
				<div className={style.section}>
					<h3 className={style.sectionTitle}>
						{t("commands.profiles.modalsContent.matchingFilters.sectionTitleStatus")}
					</h3>
					<div className={cn([style.statusSection, style.checkbox, style.statusBox])}>
						<Checkbox
							id="candidate"
							value={statuses.includes("candidate")}
							onChange={() => handleStatusCheckboxChange("candidate", !statuses.includes("candidate"))}
							theme="primary"
							label={t("commands.profiles.modalsContent.matchingFilters.candidate")}
							name="statuses"
						/>
						<Checkbox
							id="worker"
							value={statuses.includes("worker")}
							onChange={() => handleStatusCheckboxChange("worker", !statuses.includes("worker"))}
							theme="primary"
							label={t("commands.profiles.modalsContent.matchingFilters.worker")}
							name="statuses"
						/>
					</div>
				</div>
			)}

			{/* OUT OF BUSINESS */}
			{!isoutOfBusinessReasonsListLoading &&
				availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.OUT_OF_BUSINESS) && (
					<div className={style.section}>
						<div className={cn([style.switch, style.toggleSwitchBox])}>
							<ToggleSwitch
								id="isOutOfBusiness"
								name="isOutOfBusiness"
								toggled={isOutOfBusiness}
								onChange={() => {
									onOutOfBusinessReasons("isOutOfBusiness", !isOutOfBusiness);
								}}
							/>
							<label htmlFor="isOutOfBusiness" className={style["toggle-label"]}>
								{t("commands.profiles.modalsContent.matchingFilters.outProfiles")}
							</label>
						</div>
						{isOutOfBusiness && (
							<div className={cn([style.checkbox, style.statusBox, style.outOfBusinessReasons])}>
								{outOfBusinessReasonsList?.data?.map((reason) => (
									<div className={style.outOfBusinessCheckBox}>
										<Checkbox
											id={reason.keyName}
											value={outOfBusinessReasonsIds.includes(reason.id)}
											onChange={() =>
												handleOutOfBusinessReasonsCheckboxChange(
													reason.id,
													!outOfBusinessReasonsIds.includes(reason.id)
												)
											}
											theme="primary"
											label={t(`outOfBusinessReasons.${reason.keyName}`)}
											name="outOfBusinessReasonsIds"
										/>
									</div>
								))}
							</div>
						)}
					</div>
				)}
			{/* PREVIOUS EXPERIENCES, Change condition if more filters are added in this section */}
			{showClientHistory && (
				<div className={style.section}>
					<h3 className={style.sectionTitle}>
						{t("commands.profiles.modalsContent.matchingFilters.sectionPreviousExperiences.title")}
					</h3>

					{/* CLIENT HISTORY */}
					<p className={style.subtitle}>
						{t(
							"commands.profiles.modalsContent.matchingFilters.sectionPreviousExperiences.clientHistory.title"
						)}
					</p>
					<div className={cn([style.checkbox])}>
						{showClientAdequate && (
							<Checkbox
								id="isAdequateForClientCheckbox"
								value={isAdequateForClient}
								onChange={() => setFieldValue("isAdequateForClient", !isAdequateForClient)}
								theme="primary"
								label={t(
									"commands.profiles.modalsContent.matchingFilters.sectionPreviousExperiences.clientHistory.isAdequateForClient"
								)}
								name="isAdequateForClient"
							/>
						)}
						{showClientWorked && (
							<Checkbox
								id="hasAlreadyWorkedForClientCheckbox"
								value={hasAlreadyWorkedForClient}
								onChange={() => setFieldValue("hasAlreadyWorkedForClient", !hasAlreadyWorkedForClient)}
								theme="primary"
								label={t(
									"commands.profiles.modalsContent.matchingFilters.sectionPreviousExperiences.clientHistory.hasAlreadyWorkedForClient"
								)}
								name="isAdequateForClient"
							/>
						)}
					</div>
				</div>
			)}

			{/* AVAILABILITY */}
			<div className={style.section}>
				<h3 className={style.sectionTitle}>
					{t("commands.profiles.modalsContent.matchingFilters.sectionAvailability.title")}
				</h3>
				<p className={style.subtitle}>
					{t("commands.profiles.modalsContent.matchingFilters.sectionAvailability.availability")}
				</p>
				{availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.UNAVAILABILITY_TIME_OFF_PERIODS) && (
					<div className={style.dateRange}>
						<div className={style.dateRangeWrapper}>
							<DateRangeInput
								label={t("commands.start")}
								disabled={false}
								value={startDate ? moment(startDate).format(DATE_FORMAT_STRING) : ""}
								onClick={() => {
									setFieldTouched("startDate", true);
									setFocusedInputName("startDate");
								}}
								onClear={(e) => handleClearPeriodDate("startDate", e)}
								className={style.fieldStartDate}
								isActive={focusedInputName === "startDate"}
								error={
									(touched?.startDate && errors?.period?.startDate
										? t("commands.error.mandatoryStartDateField")
										: null) ||
									(typeof errors.period === "string"
										? t("commands.error.mandatoryEndDateField")
										: null)
								}
							/>
							<DateRangeInput
								label={t("commands.end")}
								disabled={false}
								value={endDate ? moment(endDate).format(DATE_FORMAT_STRING) : ""}
								onClick={() => {
									setFieldTouched("endDate", true);
									setFocusedInputName("endDate");
								}}
								onClear={(e) => handleClearPeriodDate("endDate", e)}
								className={cn([style.dateRangeField, style.fieldEndDate])}
								isActive={focusedInputName === "endDate"}
								error={
									touched?.endDate && errors?.period?.endDate
										? t("commands.error.mandatoryEndDateField")
										: null ||
											(typeof errors.period === "string"
												? t("commands.error.mandatoryEndDateField")
												: null)
								}
							/>

							<DateRangePicker
								className={style.datePickerWrapper}
								dateFrom={moment(startDate || new Date())
									.startOf("day")
									.add(12, "hours")}
								dateTo={endDate ? moment(endDate) : null}
								focusedInput={focusedInputName.replace("period.", "")}
								setFocusedInput={(reactDatesInputName) =>
									setFocusedInputName(reactDatesInputName ? `period.${reactDatesInputName}` : "")
								}
								onDatesChange={handleDateRangeUpdate}
								keepOpenOnDateSelect
								isVisible={focusedInputName !== ""}
								isDayBlocked={() => false}
								setIsVisible={() => setFocusedInputName("")}
							/>
						</div>
						{typeof errors.period === "string" ? (
							<InfoMessage color="error" withIcon>
								{errors.period}
							</InfoMessage>
						) : null}
					</div>
				)}

				<p className={style.subtitle}>
					{t("commands.profiles.modalsContent.matchingFilters.sectionAvailability.availabilityReasons")}
				</p>
				<div className={cn([style.checkbox, style.outOfBusinessReasons])}>
					<div className={style.outOfBusinessCheckBox}>
						<Checkbox
							id="isTempWorkerAvailabilitiesFilterEnabled"
							value={isTempWorkerAvailabilitiesFilterEnabled}
							onChange={() =>
								onAvailabilityElementChange(
									"isTempWorkerAvailabilitiesFilterEnabled",
									!isTempWorkerAvailabilitiesFilterEnabled
								)
							}
							theme="primary"
							label={t(
								"commands.profiles.modalsContent.matchingFilters.sectionAvailability.availabilityPlanning"
							)}
							name="availability"
						/>
					</div>
					{availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.UNAVAILABILITY_UNIFIED_TEMP_WORKER) && (
						<div className={style.outOfBusinessCheckBox}>
							<Checkbox
								id="isTempWorkerOrderFilterEnabled"
								value={isTempWorkerOrderFilterEnabled}
								onChange={() =>
									onAvailabilityElementChange(
										"isTempWorkerOrderFilterEnabled",
										!isTempWorkerOrderFilterEnabled
									)
								}
								theme="primary"
								label={t(
									"commands.profiles.modalsContent.matchingFilters.sectionAvailability.tempWorkerOrder"
								)}
								name="availability"
							/>
						</div>
					)}
					{availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.UNAVAILABILITY_ORDER_PERIODS) && (
						<div className={style.outOfBusinessCheckBox}>
							<Checkbox
								id="isTempWorkerTimeOffFilterEnabled"
								value={isTempWorkerTimeOffFilterEnabled}
								onChange={() =>
									onAvailabilityElementChange(
										"isTempWorkerTimeOffFilterEnabled",
										!isTempWorkerTimeOffFilterEnabled
									)
								}
								theme="primary"
								label={t(
									"commands.profiles.modalsContent.matchingFilters.sectionAvailability.absences"
								)}
								name="availability"
							/>
						</div>
					)}
				</div>
			</div>

			{/* GEO */}
			{!isVehiclesListLoading && availableFilters?.data?.includes(CURRENTAVAILABLEFILTERS.GEO_DISTANCE) && (
				<div className={style.section}>
					<h3 className={style.sectionTitle}>
						{t("commands.profiles.modalsContent.matchingFilters.sectionTitleProfilPreferences")}
					</h3>
					<div
						className={
							locationCouldNotBeCalculated || isLocationBeingCalculated ? style.disabledFieldArea : null
						}
					>
						<p className={style.specialSubtitle}>
							{t("commands.profiles.modalsContent.matchingFilters.missionRadius")}
						</p>
						<Slider
							name="distance"
							onChange={onRadiusChange}
							value={distance}
							min={10}
							max={100}
							minLabel={10}
							maxLabel={100}
							minLabelDetails="km"
							maxLabelDetails="km et plus"
							type="distance"
							renderSliderLabel={renderSliderLabel}
							className={
								distance === 10 || distance === 100 || distance === null
									? "tooltipEdge"
									: "tooltipDefault"
							}
						/>
					</div>
				</div>
			)}

			{/* VEHICLES */}
			{!isVehiclesListLoading && (
				<div className={cn([style.section, style.lastSection])}>
					<p className={style.subSectionTitle}>
						{t("commands.profiles.modalsContent.matchingFilters.sectionTitleVehicles")}
					</p>
					<div className={cn([style.checkbox, style.statusBox, style.outOfBusinessReasons])}>
						{vehiclesList?.data?.map((reason) => (
							<div className={style.outOfBusinessCheckBox} key={reason.id}>
								<Checkbox
									id={reason.name}
									value={vehicles.includes(reason.id)}
									onChange={() =>
										handleVehiclesCheckboxChange(reason.id, !vehicles.includes(reason.id))
									}
									theme="primary"
									label={reason.name}
									name="vehicles"
								/>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

BusinessInformation.propTypes = {
	onRadiusChange: PropTypes.func.isRequired,
	onStatusChange: PropTypes.func.isRequired,
	onQualificationSelect: PropTypes.func.isRequired,
	onQualificationDelete: PropTypes.func.isRequired,
	onCommonDocumentSelect: PropTypes.func.isRequired,
	onCommonDocumentDelete: PropTypes.func.isRequired,
	onVehiclesChange: PropTypes.func.isRequired,
	onOutOfBusinessReasons: PropTypes.func.isRequired,
	onEquipmentSelect: PropTypes.func.isRequired,
	onEquipmentDelete: PropTypes.func.isRequired,
	onAvailabilityElementChange: PropTypes.func.isRequired,
	onPeriodChange: PropTypes.func.isRequired,
};

export default BusinessInformation;
