// @ts-nocheck
import React from "react";
import { InfoMessage } from "@zolteam/react-ras-library";

// Store
import { useAppContext } from "../../../store/AppContext";

// Style
import style from "./OrderMessage.module.css";

const OrderMessage = () => {
	const [{ orderMessage }] = useAppContext();

	if (!orderMessage.displayed) {
		return null;
	}

	return (
		<div className={style.message}>
			<InfoMessage color={orderMessage.color} withIcon={orderMessage.withIcon}>
				{orderMessage.content}
			</InfoMessage>
		</div>
	);
};

export default OrderMessage;
