// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

// Style
import style from "./TitleCount.module.css";

const TitleCount = ({ children }) => <div className={style.container}>{children}</div>;

TitleCount.propTypes = {
	children: PropTypes.node.isRequired,
};

export default TitleCount;
