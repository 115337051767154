// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

import style from "./ImgUser.module.css";

const ImgUser = ({ link, alt }) => <img src={link} alt={alt} className={style.image} />;

ImgUser.propTypes = {
	link: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
};

export default ImgUser;
