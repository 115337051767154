// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Picto } from "@zolteam/react-ras-library";

// Style
import style from "./OptionQualificationTempo.module.css";

const OptionQualificationTempo = ({
	id = "",
	name = "",
	level = null,
	coefficient = null,
	hoursCoefficient = null,
	tempoId = null,
}) => (
	<div className={style.optionContainer} data-testid={`option-${id}-${name}`} key={id}>
		<Picto icon="isSynchronizable" className={style.picto} />
		<div className={style.textContainer}>
			<span className={style.optionName}>{`${name ?? ""}`}</span>
			<span className={style.optionInfos}>
				{`${tempoId ?? "/"} - ${level || "/"} - ${hoursCoefficient ?? "/"} - ${coefficient ?? "/"}`}
			</span>
		</div>
	</div>
);

OptionQualificationTempo.propTypes = {
	id: PropTypes.number,
	name: PropTypes.string,
	level: PropTypes.string,
	coefficient: PropTypes.string,
	hoursCoefficient: PropTypes.string,
	tempoId: PropTypes.string,
};

export default OptionQualificationTempo;
