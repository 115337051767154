// @ts-nocheck
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

// Atoms
import PopoverListMaxWidth from "../../atoms/PopoverListMaxWidth/PopoverListMaxWidth";

// Style
import style from "./ListMaxWidth.module.css";

// Utils
import getTextWidth from "../../../utils/getTextWidth";

const PADDING = 10;
const HIDDEN_CHIP_COUNT_WIDTH = 46;

const ListMaxWidth = ({
	array,
	component,
	keyListElem,
	maxWidth = "300",
	margeError = 0,
	maxWidthMarge = 0,
	addMaxWidthMarge = false,
	oneItem = false,
}) => {
	const calculateListWidth = useCallback(() => {
		let totalWidth = 0;
		return array.map((elem) => {
			const width = getTextWidth(elem.name, "10.3px DM sans", margeError);
			totalWidth += width;

			const max = oneItem
				? getTextWidth(array[0].name, "10.3px DM sans", margeError)
				: maxWidth - 2 * PADDING - HIDDEN_CHIP_COUNT_WIDTH - (addMaxWidthMarge ? maxWidthMarge + PADDING : 0);

			return {
				...elem,
				width,
				toDisplay: totalWidth <= max,
			};
		});
	}, [array, maxWidth, margeError, maxWidthMarge, oneItem, addMaxWidthMarge]);

	const [list, setList] = useState(calculateListWidth());

	useEffect(() => {
		setList(calculateListWidth());
	}, [array]);

	return (
		<div className={style.container}>
			{list.map((item, index) =>
				item.toDisplay
					? React.createElement(component, {
							key: item[keyListElem],
							data: item,
							index,
						})
					: null
			)}
			{list.filter((item) => !item.toDisplay).length > 0 ? (
				<PopoverListMaxWidth
					content={
						<ul className={style.listPopover}>
							{list
								.filter((item) => !item.toDisplay)
								.map((item) => (
									<li key={item.id} className={style.itemPopover}>
										{item.name}
									</li>
								))}
						</ul>
					}
				>
					<span>{`+ ${list.filter((item) => !item.toDisplay).length}`}</span>
				</PopoverListMaxWidth>
			) : null}
		</div>
	);
};

ListMaxWidth.propTypes = {
	array: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
	component: PropTypes.func.isRequired,
	keyListElem: PropTypes.string.isRequired,
	maxWidth: PropTypes.string,
	margeError: PropTypes.number,
	maxWidthMarge: PropTypes.number,
	addMaxWidthMarge: PropTypes.bool,
	oneItem: PropTypes.bool,
};

export default ListMaxWidth;
