// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

// Style
import style from "./TableFilters.module.css";

const TableFilters = ({ rightContent = null, leftContent = null }) => (
	<div className={style.container}>
		<div className={style.left}>{leftContent}</div>
		<div className={style.right}>{rightContent}</div>
	</div>
);

TableFilters.propTypes = {
	rightContent: PropTypes.node,
	leftContent: PropTypes.node,
};

export default TableFilters;
