// @ts-nocheck
import PropTypes from "prop-types";

// Style
import style from "./NavigationTab.module.css";

const NavigationTabs = ({ tabs, onTabChange, activeTab }) => (
	<div className={style.tabsContainer}>
		<ul className={style.tabs}>
			{tabs.map((tab) => (
				<li key={tab.id}>
					<button
						onClick={() => onTabChange(tab.id)}
						type="button"
						onKeyDown={() => onTabChange(tab.id)}
						className={tab.id === activeTab ? style.tabActive : style.tab}
					>
						{tab.content}
					</button>
				</li>
			))}
		</ul>
	</div>
);

NavigationTabs.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			content: PropTypes.node.isRequired,
			id: PropTypes.number.isRequired,
		})
	).isRequired,
	onTabChange: PropTypes.func.isRequired,
	activeTab: PropTypes.number.isRequired,
};

export default NavigationTabs;
