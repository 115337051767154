import React from "react";
import { ReactSVG } from "react-svg";

interface ISVGProps {
	className?: string;
	src: string;
	style?: React.CSSProperties;
}

const SVG: React.FC<ISVGProps> = (props) => (
	<ReactSVG
		src={props.src}
		type="image/svg+xml"
		aria-label="svg"
		beforeInjection={(svg) => {
			let classes = props.className ? props.className.split(" ") : [];
			classes = classes.filter(Boolean);
			svg.classList.add(...classes);

			let styles = props.style;
			if (styles) {
				Object.keys(styles).forEach((key) => {
					(svg.style as any)[key as keyof React.CSSProperties] = styles
						? styles[key as keyof React.CSSProperties]
						: "";
				});
			}
		}}
		wrapper="span"
	/>
);

export default SVG;
