// @ts-nocheck
import PropTypes from "prop-types";

// Style
import style from "./PopoverMenuItem.module.css";

const PopoverMenuItem = ({ click = () => {}, children }) => {
	return (
		<button type="button" className={style.button} onClick={click}>
			{children}
		</button>
	);
};
PopoverMenuItem.propTypes = {
	click: PropTypes.func,
	children: PropTypes.node.isRequired,
};

export default PopoverMenuItem;
