// @ts-nocheck
import { Text } from "@zolteam/react-ras-library";
import { format } from "date-fns";
import localeFr from "date-fns/locale/fr";
import { t } from "i18next";
import OutOfBusinessTag from "src/components/atoms/OutOfBusinessTag";
import Tag from "src/components/atoms/Tag";
import VipTag from "src/components/atoms/VipTag";
import MyRasPicto from "src/components/atoms/myRasPicto/MyRasPicto";
import ColumnListing from "../../../components/molecules/ColumnListing";
import TableInfo from "../../../components/molecules/TableInfo";
import ListMaxWidth from "../../../components/organisms/ListMaxWidth";
import { userStatusCandidate, userStatusWaiting, userStatusWorker } from "../../../enum/enumStatusUser";
import style from "./UsersListing.module.css";

const displayStatusLabel = (temporaryWorkerStatus) => {
	const statusLabels = {
		[userStatusCandidate]: t("usersListing.temporaryWorkerStatusLabel.candidate"),
		[userStatusWaiting]: t("usersListing.temporaryWorkerStatusLabel.waiting"),
		[userStatusWorker]: t("usersListing.temporaryWorkerStatusLabel.worker"),
	};

	return statusLabels[temporaryWorkerStatus] || "";
};

const UsersListColumns = () => {
	return [
		<ColumnListing
			key="temporaryWorkerStatus"
			id="temporaryWorkerStatus"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.temporaryWorkerStatus")}
				</Text>
			}
			field={(elem) => ({ temporaryWorkerStatus: elem.temporaryWorkerStatus })}
			component={({ data: { temporaryWorkerStatus } }) => (
				<Text tag="span" size="paragraph02">
					{displayStatusLabel(temporaryWorkerStatus)}
				</Text>
			)}
			isSortable
		/>,
		<ColumnListing
			key="agencyName"
			id="agency.name"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.agencyName")}
				</Text>
			}
			field={(elem) => ({ agencyName: elem.agency.name })}
			component={({ data: { agencyName } }) => (
				<Text tag="span" size="paragraph02">
					{agencyName || ""}
				</Text>
			)}
			isSortable
		/>,
		<ColumnListing
			key="uniqueId"
			id="uniqueId"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.uniqId")}
				</Text>
			}
			field={(elem) => ({ uniqueId: elem.uniqueId, unifiedTempWorker: elem.unifiedTempWorker })}
			component={({ data: { uniqueId, unifiedTempWorker } }) => (
				<Text
					tag="span"
					size="paragraph02"
					className={"flex flex-col gap-0 items-start " + (unifiedTempWorker?.hasMyRas && "translate-y-1")}
				>
					{uniqueId || ""}
					{unifiedTempWorker?.hasMyRas && <MyRasPicto className="w-12 h-5 mt-0 -translate-y-1" />}
				</Text>
			)}
			isSortable
		/>,
		<ColumnListing
			key="tempoId"
			id="tempoId"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.tempoId")}
				</Text>
			}
			field={(elem) => ({ tempoId: elem.tempoId })}
			component={({ data: { tempoId } }) => (
				<Text tag="div" size="paragraph02">
					{tempoId || ""}
				</Text>
			)}
			isSortable
		/>,
		<ColumnListing
			key="name"
			id="name"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.fullName")}
				</Text>
			}
			field={(elem) => ({
				name: elem.name,
				isVip: elem.isVip,
				isCdiInterim: elem.isCdiInterim,
				isOutOfBusiness: elem.isOutOfBusiness,
				outOfBusinessReason: elem.outOfBusinessReason,
				outCommentInterviewDate: elem.outCommentInterviewDate,
			})}
			component={({
				data: { name, isVip, isCdiInterim, isOutOfBusiness, outOfBusinessReason, outCommentInterviewDate },
			}) => (
				<Text tag="div" size="paragraph02" className={"flex flex-row gap-3 items-center whitespace-nowrap"}>
					{name}
					{isCdiInterim && (
						<Tag color={"primary"} size={"s"}>
							CDII
						</Tag>
					)}
					{isVip && <VipTag className={style.profilAttribute} />}
					{isOutOfBusiness === true && (
						<OutOfBusinessTag
							outOfBusinessReason={outOfBusinessReason}
							outCommentInterviewDate={outCommentInterviewDate}
						/>
					)}
				</Text>
			)}
			isSortable
		/>,
		<ColumnListing
			key="phoneNumber"
			id="phoneNumber"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.phoneNumber")}
				</Text>
			}
			field={(elem) => ({ phoneNumber: elem.phoneNumber })}
			component={({ data: { phoneNumber } }) =>
				phoneNumber ? (
					<Text tag="span" size="paragraph02" color="primary">
						{phoneNumber}
					</Text>
				) : (
					<Text tag="span" size="paragraph02">
						{t("usersListing.notSpecified")}
					</Text>
				)
			}
		/>,
		<ColumnListing
			key="qualifications"
			id="qualifications"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.qualification")}
				</Text>
			}
			field={(elem) => ({
				mainQualification: elem.mainQualification,
				otherQualifications: elem.otherQualifications,
			})}
			component={({ data: { otherQualifications, mainQualification } }) => {
				const qualifications = [...(otherQualifications || [])]?.sort((a, b) =>
					a?.name?.toLocaleLowerCase() < b?.name?.toLocaleLowerCase() ? -1 : 1
				);

				qualifications.unshift(
					mainQualification || {
						name: "-",
						id: -1,
					}
				);

				return (
					<ListMaxWidth
						array={qualifications}
						keyListElem="id"
						component={({ data: mainQualification, index }) => {
							return (
								<Text tag="span" size="paragraph02" className="whitespace-nowrap">
									{`${mainQualification?.name}`}
								</Text>
							);
						}}
						margeError={5}
						oneItem
					/>
				);
			}}
		/>,
		<ColumnListing
			key="zipCode"
			id="zipCode"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.zipCode")}
				</Text>
			}
			field={(elem) => ({ zipCode: elem.zipCode })}
			component={({ data: { zipCode } }) => (
				<Text tag="span" size="paragraph02">
					{zipCode || ""}
				</Text>
			)}
		/>,
		<ColumnListing
			key="city"
			id="city"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.city")}
				</Text>
			}
			field={(elem) => ({ city: elem.city })}
			component={({ data: { city } }) => (
				<Text tag="span" size="paragraph02">
					{city || ""}
				</Text>
			)}
		/>,
		<ColumnListing
			key="cv"
			id="cv"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.cv")}
				</Text>
			}
			field={(elem) => ({ cv: elem.cv })}
			component={({ data: { cv } }) => (
				<Text tag="span" size="paragraph02">
					{cv || ""}
				</Text>
			)}
		/>,
		<ColumnListing
			key="createdAt"
			id="createdAt"
			name={
				<Text tag="span" size="paragraph02">
					{t("usersListing.createdAt")}
				</Text>
			}
			field={(elem) => ({ createdAt: elem.createdAt })}
			component={({ data: { createdAt } }) => (
				<div>
					<TableInfo
						info={
							createdAt
								? format(new Date(createdAt), "dd MMM yyyy", {
										locale: localeFr,
									})
								: ""
						}
					/>
					<div className={style.infoIndice}>
						{createdAt
							? format(new Date(createdAt), "HH:mm", {
									locale: localeFr,
								})
							: ""}
					</div>
				</div>
			)}
			isSortable
		/>,
	];
};

export default UsersListColumns;
