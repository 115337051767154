// @ts-nocheck
import matchingFormDefaultValues from "./matchingFormDefaultValues";

export const singleMatchingFilters = ["agency", "distance"];

export const booleanMatchingFilters = [
	"isMedicalCheckupValid",
	"isOutOfBusiness",
	"isTempWorkerAvailabilitiesFilterEnabled",
	"isTempWorkerTimeOffFilterEnabled",
	"isTempWorkerOrderFilterEnabled",
	"isAdequateForClient",
	"hasAlreadyWorkedForClient",
];

export const arrayMatchingFilters = [
	"qualifications",
	"commonDocuments",
	"statuses",
	"vehicles",
	"outOfBusinessReasonsIds",
	"equipments",
];

export const objectMatchingFilters = ["period"];

export const allMatchingFilters = [...singleMatchingFilters, ...booleanMatchingFilters, ...arrayMatchingFilters];

export const defaultFilters = {
	agency: null,
	...matchingFormDefaultValues,
};
