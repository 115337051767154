// @ts-nocheck
import UsersListing from "src/views/Users/UsersListing/UsersListing";
import i18n from "../i18n";

// Views
import ActivityLogs from "../views/ActivityLogs";
import advancePayments from "../views/AdvancePayments";
import Command from "../views/Command";
import Commands from "../views/Commands";
import ExpiredDocuments from "../views/ExpiredDocuments";
import Home from "../views/Home";
import Notifications from "../views/Notifications";
import Profile from "../views/Profile";
import PurgeRGPD from "../views/PurgeRGPD";
import SuspiciousWrapper from "../views/Suspicious";
import SuspiciousSheet from "../views/SuspiciousSheet/SuspiciousSheet";

const routes = [
	{
		label: i18n.t("global.homePageTitle"),
		path: "/",
		component: Home,
		exact: true,
	},
	{
		label: i18n.t("clientsMyTemp.notifications.title"),
		path: "/notifications",
		component: Notifications,
		exact: true,
		permissions: ["user"],
	},
	{
		label: i18n.t("commands.pageTitle"),
		path: "/commandes",
		component: Commands,
		exact: true,
	},
	{
		label: i18n.t("profile.pageTitle"),
		path: "/profile/:profileId",
		component: Profile,
		exact: false,
	},
	{
		label: i18n.t("commands.subpageTitle"),
		path: "/commandes/:orderId/:commandStep",
		component: Command,
		exact: false,
	},
	{
		label: i18n.t("expiredDocuments.title"),
		path: "/expired-documents",
		component: ExpiredDocuments,
		exact: true,
		permissions: ["beta.user.v2"],
	},
	{
		label: i18n.t("suspicious.pageTitle"),
		path: "/suspicions",
		component: SuspiciousWrapper,
		exact: true,
		permissions: ["digital.division"],
	},
	{
		label: i18n.t("suspiciousSheet.pageTitle"),
		path: "/suspicions/:id",
		component: SuspiciousSheet,
		exact: false,
		permissions: ["user"],
	},
	{
		label: i18n.t("activityLogs.pageTitle"),
		path: "/logs-activite/:userId",
		component: ActivityLogs,
		exact: true,
		permissions: ["admin"],
	},
	{
		label: i18n.t("profiles.advancePayments.pageTitle"),
		path: "/advance-payments",
		component: advancePayments,
		exact: true,
		permissions: ["beta.advanced.payments"],
	},
	{
		label: i18n.t("global.purgeRGPD"),
		path: "/purge-rgpd",
		component: PurgeRGPD,
		exact: true,
		permissions: ["rgpd"],
	},
	{
		label: i18n.t("usersListing.pageTitle"),
		path: "/users",
		component: UsersListing,
		exact: true,
		permissions: ["digital.division"],
	},
];

export default routes;
