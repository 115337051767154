// @ts-nocheck
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Picto } from "@zolteam/react-ras-library";

// Style
import style from "./FileUpload.module.css";
import cn from "../../../utils/cn";

export const FileUpload = ({
	handleDeleteClick,
	handleFileDrop,
	files = [],
	handleFileChange,
	accept = "*",
	info = null,
	disabled = false,
	isSameFile = false,
	fileURL = "",
}) => {
	const { t } = useTranslation();
	const [dragIsOver, setDragIsOver] = useState(false);

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragIsOver(true);
		} else if (e.type === "dragleave") {
			setDragIsOver(false);
		}
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setDragIsOver(false);
		handleFileDrop(e.dataTransfer.files);
	};

	const handleFileInputChange = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.target.files && e.target.files[0]) {
			handleFileChange(e.target.files);
		}
	};

	const handleFileClick = (file) => {
		if (isSameFile) {
			window.open(fileURL, "_blank");
		}
		const fileUrl = URL.createObjectURL(file);
		window.open(fileUrl, "_blank");
	};

	return files.length === 0 ? (
		<div
			className={cn([dragIsOver ? style.wrapperDragOver : null, style.wrapper])}
			onDragOver={handleDrag}
			onDragEnter={handleDrag}
			onDragLeave={handleDrag}
			onDrop={handleDrop}
		>
			<div className={dragIsOver ? style.hidde : style.content}>
				<input type="file" id="upload" accept={accept} hidden onChange={handleFileInputChange} />
				<label
					className={disabled ? style.uploadButton : cn([style.uploadButton, style.disabled])}
					htmlFor="upload"
				>
					{t("missionFollowUp.creationForm.uploadInputText")}
				</label>
				{info || null}
			</div>
		</div>
	) : (
		<div>
			{files.map((file) => (
				<div
					className={disabled ? style.filesList : cn([style.filesList, style.filesListDisabled])}
					key={file.lastModified}
				>
					<span
						onClick={() => handleDeleteClick(file.name)}
						onKeyDown={() => handleDeleteClick(file.name)}
						role="button"
						tabIndex="0"
						aria-label="delete file"
					>
						<Picto
							icon="trash"
							className={disabled ? style.trashPicto : cn([style.trashPictoDisabled, style.trashPicto])}
						/>
					</span>
					<span
						onClick={() => handleFileClick(file)}
						tabIndex="0"
						onKeyDown={() => handleFileClick(file)}
						role="button"
					>
						{file.name}
					</span>
				</div>
			))}
		</div>
	);
};

FileUpload.propTypes = {
	handleFileDrop: PropTypes.func.isRequired,
	handleDeleteClick: PropTypes.func.isRequired,
	handleFileChange: PropTypes.func.isRequired,
	isSameFile: PropTypes.bool,
	fileURL: PropTypes.string,
	files: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
		})
	),
	accept: PropTypes.string,
	info: PropTypes.node,
	disabled: PropTypes.bool,
};

export default FileUpload;
