// @ts-nocheck
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackLink, PaginationV2, Picto, Text, Title } from "@zolteam/react-ras-library";

// Services
import activityLogs from "../../services/activityLogs";

// Templates
import WithSideMenu from "../../components/templates/WithSideMenu";
import WithTopBarre from "../../components/templates/WithTopBarre";

// Molecules
import PaginationSearch from "../../components/molecules/PaginationSearch/PaginationSearch";
import LogTreeStructure from "../../components/molecules/LogTreeStructure";

// Style
import style from "./ActivityLogs.module.css";

// Utils
import { formatDate } from "../../utils/formatDate";

// Constants
import { COMMON_FIELDS, COMMON_FIELDS_TEMPLATES, FAMILY_SITUATIONS, SOURCE_AUTOMATIC_FUSION } from "../../constants";

const ActivityLogs = () => {
	const { userId } = useParams();
	const { t } = useTranslation();

	const initSearch = {
		limit: 10,
		page: 1,
		orderBy: "DESC",
	};
	const [search, setSearch] = useState(initSearch);
	const [totalPages, setTotalPages] = useState(0);

	const handleSearch = (sort) => {
		setSearch((prev) => {
			const searchRequest = { ...prev, ...sort };
			return searchRequest;
		});
	};

	// Queries
	const postActivityLogsSearch = useQuery(
		["postActivityLogsSearch", search],
		() => activityLogs.postActivityLogsSearch(userId, search),
		{
			onSuccess: (data) => {
				setTotalPages(data?.data?.total);
			},
		}
	);

	const displayChannelPreferences = (channelPrefs) => {
		let template = "";
		if (channelPrefs) {
			template = Object.keys(channelPrefs)
				.reduce((acc, key) => {
					if (channelPrefs[key]) {
						acc.push(t(`activityLogs.${key}`));
					}
					return acc;
				}, [])
				.map((item, index) => (index === 0 ? `${item}` : `, ${item}`));
		}
		return template;
	};

	const displayValue = (attribut, template, value) => {
		switch (template) {
			case COMMON_FIELDS_TEMPLATES.BOOLEAN: {
				return (
					<Text tag="span" size="paragraph02">
						{`${value}` === "true" && t("activityLogs.true")}
						{`${value}` === "false" && t("activityLogs.false")}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.DATE: {
				return (
					<Text tag="span" size="paragraph02">
						{formatDate(value, "dd/MM/yyyy")}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.NAME: {
				return (
					<Text tag="span" size="paragraph02">
						{value?.name || ""}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.CHANNEL_PREF: {
				return (
					<Text tag="span" size="paragraph02">
						{displayChannelPreferences(value)}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.AVAILABILITY: {
				return (
					<Text tag="span" size="paragraph02">
						{value?.length > 0 ? t("activityLogs.availabilityValues") : ""}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.MAIN_LOCATION: {
				return value ? (
					<Text tag="span" size="paragraph02">
						{value.postalCode
							? `${value?.postalCode}, ${value?.name}, [${value?.inseeCode}]`
							: `${value?.name} [${value?.departmentInseeCode}]`}
					</Text>
				) : null;
			}
			case COMMON_FIELDS_TEMPLATES.GEO_LOCATIONS: {
				return (
					<Text tag="span" size="paragraph02">
						{value.length
							? value?.map(({ location }, index) => (
									<div key={index}>{`${location?.name} ${location?.postalCode}`}</div>
								))
							: ""}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.DOCUMENTS: {
				return (
					<Text tag="span" size="paragraph02">
						{value.length &&
							value?.map(({ entityId, status }, index) => (
								<div key={index}>{`${entityId} ${t(`activityLogs.${status}`)}`}</div>
							))}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.QUALIFICATIONS: {
				return (
					<Text tag="span" size="paragraph02">
						{value.length
							? value?.map(({ entityId }, index) => <div key={index}>{`${entityId}`}</div>)
							: ""}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.COMPETENCIES: {
				return (
					<Text tag="span" size="paragraph02">
						{value.length && value?.map(({ entityId }, index) => <div key={index}>{`${entityId}`}</div>)}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.JOB_TYPES: {
				return (
					<Text tag="span" size="paragraph02">
						{value.length
							? value?.map(({ jobType: { name } }, index) => <div key={index}>{`${name}`}</div>)
							: ""}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.ARRAY_NAME: {
				return (
					<Text tag="span" size="paragraph02">
						{value.length && value?.map(({ name }, index) => <div key={index}>{`${name}`}</div>)}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.ARRAY_NAME_TRANS: {
				return (
					<Text tag="span" size="paragraph02">
						{value.length &&
							value?.map(({ name }, index) => (
								<div key={index}>{`${t(`activityLogs.${attribut}.${name}`)}`}</div>
							))}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.TIME_OFF_REQUEST: {
				return (
					<Text tag="span" size="paragraph02">
						{value.length &&
							value?.map(({ startDate, finishDate }, index) => (
								<div key={index}>
									{`
                  ${formatDate(startDate, `dd/MM/yyyy ${t("activityLogs.at")} HH:mm`)}
                   - 
                  ${formatDate(finishDate, `dd/MM/yyyy ${t("activityLogs.at")} HH:mm`)}`}
								</div>
							))}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.LOCATIONS: {
				// Never face an example of that data
				return (
					<Text tag="span" size="paragraph02">
						{value}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.FAMILY_SITUATIONS: {
				return (
					<Text tag="span" size="paragraph02">
						{value ? t(`global.familySituation.${FAMILY_SITUATIONS[value]}`) : ""}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.SEX: {
				return (
					<Text tag="span" size="paragraph02">
						{value ? t(`activityLogs.${value}`) : ""}
					</Text>
				);
			}
			case COMMON_FIELDS_TEMPLATES.META: {
				return (
					value.length &&
					value?.map((item, index) => (
						<Text key={index} tag="span" size="paragraph02">
							<div>{item || ""}</div>
						</Text>
					))
				);
			}
			default: {
				return (
					<Text tag="span" size="paragraph02">
						{value || ""}
					</Text>
				);
			}
		}
	};

	return (
		<WithSideMenu>
			<WithTopBarre
				quickEntryContent={<div>test</div>}
				title={
					<Title tag="h1" size="heading01" lineHeight="s">
						{t("activityLogs.title")}
					</Title>
				}
				leftContent={
					<a href={`${process.env.REACT_APP_BASE_OLD_URL}/#/fr/contacts/${userId}`}>
						<BackLink onClick={() => {}}>
							<Text fontWeight="normal" tag="span">
								{t("activityLogs.backLink")}
							</Text>
						</BackLink>
					</a>
				}
				containerClassname="container-list-paginate"
			>
				<div className="white-bg container-radius overflow-auto" style={{ gridRow: "1" }}>
					{postActivityLogsSearch?.isSuccess ? (
						<>
							{postActivityLogsSearch?.data?.data?.items.length ? (
								postActivityLogsSearch?.data?.data?.items?.map(
									// eslint-disable-next-line no-unused-vars
									({ source, author, agency, changes, createdAt: { date } }, index) => (
										<div key={index} className={style.logsContainer}>
											<Title className={style.title} tag="h6" size="paragraph01">
												{`${t("activityLogs.the")} 
                        ${formatDate(date, `dd/MM/yyyy ${t("activityLogs.at")} HH:mm`)}`}
												{source === SOURCE_AUTOMATIC_FUSION ? (
													<span className="ml-m">{`${t("activityLogs.automaticFusion")}`}</span>
												) : (
													<span className="ml-m">
														{`${author || ""} ${author && agency ? "-" : ""} ${agency || ""}`}
													</span>
												)}
											</Title>
											<div className={style.logsHeader}>
												<div className={style.logAttr}>
													<Text tag="div" size="paragraph03" color="grey">
														{t("activityLogs.treeStructure")}
													</Text>
												</div>
												<div className={style.logValue}>
													<Text tag="div" size="paragraph03" color="grey">
														{t("activityLogs.oldValue")}
													</Text>
												</div>
												<div className={style.logValue}>
													<Text tag="div" size="paragraph03" color="grey">
														{t("activityLogs.newValue")}
													</Text>
												</div>
											</div>
											{changes.map(({ attribute, oldValue, newValue }) => (
												<div key={attribute} className={style.logsTable}>
													<div className={style.logsTableWrapper}>
														<div className={style.logAttr}>
															<LogTreeStructure>
																{COMMON_FIELDS[attribute]?.tab ? (
																	<Text tag="span" size="paragraph02">
																		{t(
																			`activityLogs.commonFields.tab.${COMMON_FIELDS[attribute].tab}`
																		)}
																	</Text>
																) : null}
																{COMMON_FIELDS[attribute]?.block ? (
																	<Text tag="span" size="paragraph02">
																		{t(
																			`activityLogs.commonFields.block.${COMMON_FIELDS[attribute].block}`
																		)}
																	</Text>
																) : null}
																{t(`activityLogs.commonFields.wording.${attribute}`) ? (
																	<Text tag="span" size="paragraph02">
																		{t(
																			`activityLogs.commonFields.wording.${attribute}`
																		)}
																	</Text>
																) : null}
															</LogTreeStructure>
														</div>
														<div className={style.logValue}>
															{displayValue(
																attribute,
																COMMON_FIELDS[attribute].template,
																oldValue
															)}
														</div>
														<div className={style.logValue}>
															{displayValue(
																attribute,
																COMMON_FIELDS[attribute].template,
																newValue
															)}
														</div>
													</div>
												</div>
											))}
										</div>
									)
								)
							) : (
								<Text className="ml-l" tag="p" size="paragraph03" color="grey">
									{t("suspiciousSheet.noTempWorkerSuggestions")}
								</Text>
							)}
						</>
					) : null}
					{postActivityLogsSearch?.isLoading ? (
						<Text className="ml-l" tag="p" size="paragraph03" color="grey">
							{t("global.loading")}
						</Text>
					) : null}
				</div>
				{totalPages / search.limit > 1 ? (
					<div className={style.footer}>
						<PaginationV2
							previousLabel={<Picto icon="chevronLeft" />}
							nextLabel={<Picto icon="chevronRight" />}
							breakLine={
								<PaginationSearch
									onInputChange={handleSearch}
									max={Math.round(totalPages / search.limit)}
								/>
							}
							currentPage={search.page}
							totalCount={totalPages}
							pageSize={search.limit}
							onPageChange={(activePage) => handleSearch({ page: activePage })}
						/>
					</div>
				) : null}
			</WithTopBarre>
		</WithSideMenu>
	);
};

export default ActivityLogs;
