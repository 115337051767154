// @ts-nocheck
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { keyBy } from "lodash";

// Store
import useAppContext from "../../../store/useAppContext";

// Service
import user from "../../../services/user";

const UserLoadingScreen = ({ loading, children }) => {
	const [, appDispatch] = useAppContext();

	const getUserMe = useQuery("getUserMe", () => user.getUserMe(), {
		onSuccess: ({ data: { groups } }) => {
			const roles = keyBy(groups, "name");
			appDispatch({
				type: "SET_USER",
				payload: { roles },
			});
		},
		onError: () => {
			appDispatch({
				type: "CLEAR_USER",
			});
		},
	});

	return getUserMe?.isSuccess ? children : loading;
};

UserLoadingScreen.propTypes = {
	loading: PropTypes.node.isRequired,
	children: PropTypes.node.isRequired,
};

export default UserLoadingScreen;
