// @ts-nocheck
import React from "react";
import "moment/locale/fr";
import moment from "moment";
import PropTypes from "prop-types";
// Components
import { Picto } from "@zolteam/react-ras-library";
import { DayPickerRangeController } from "react-dates";
// Utils
import cn from "../../../utils/cn";
// Style
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import style from "./DateRangePicker.module.css";

const DateRangePicker = ({
	dateFrom = null,
	dateTo = null,
	focusedInput,
	setFocusedInput,
	onDatesChange,
	className = "",
	isVisible = true,
	isDayBlocked = undefined,
	setIsVisible = undefined,
	keepOpenOnDateSelect,
}) => {
	/**
	 * Capitalize month text
	 * @param {*} month month text
	 * @returns {string} capitalized month text
	 */
	const formatMonthText = (month) => {
		let text = moment(month).locale("fr").format("MMMM Y");
		text = text.charAt(0).toUpperCase() + text.slice(1);

		return text;
	};
	/**
	 * Manage when user click outside calendar
	 * @param
	 * @returns
	 */
	const handleOnClickOutside = () => {
		setIsVisible("");
		setFocusedInput(null);
	};

	return isVisible ? (
		<div data-testid="period-date-range-picker" className={className}>
			<DayPickerRangeController
				keepOpenOnDateSelect={keepOpenOnDateSelect}
				numberOfMonths={2}
				startDate={dateFrom}
				endDate={dateTo}
				onFocusChange={setFocusedInput}
				onDatesChange={({ startDate, endDate }) => onDatesChange(startDate, endDate)}
				onOutsideClick={handleOnClickOutside}
				focusedInput={focusedInput}
				initialVisibleMonth={() => dateFrom}
				isDayBlocked={isDayBlocked}
				monthFormat="MMMM Y"
				minimumNights={0}
				renderMonthElement={({ month }) => formatMonthText(month)}
				renderKeyboardShortcutsButton={() => null}
				renderDayContents={(dayMoment) => <div className={style.dayWrapper}>{dayMoment.format("D")}</div>}
				navPrev={
					<div className={cn([style.chevronWrapper, style.chevronLeftWrapper])}>
						<Picto icon="chevronLeft" className={style.picto} />
					</div>
				}
				navNext={
					<div className={cn([style.chevronWrapper, style.chevronRightWrapper])}>
						<Picto icon="chevronRight" className={style.picto} />
					</div>
				}
			/>
		</div>
	) : null;
};

DateRangePicker.propTypes = {
	focusedInput: PropTypes.string.isRequired,
	setFocusedInput: PropTypes.func.isRequired,
	onDatesChange: PropTypes.func.isRequired,
	dateFrom: PropTypes.objectOf(moment),
	dateTo: PropTypes.objectOf(moment),
	className: PropTypes.string,
	keepOpenOnDateSelect: PropTypes.bool,
	isVisible: PropTypes.bool,
	isDayBlocked: PropTypes.func,
	setIsVisible: PropTypes.func,
};

export default DateRangePicker;
