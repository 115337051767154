import { Picto, Tag } from "@zolteam/react-ras-library";
import PropTypes from "prop-types";
import style from "./VipTag.module.css";

const VipTag = ({ className = "" }) => (
	<Tag className={`${style.vipTag} ${className}`} color="primaryLight">
		<Picto
			icon="star"
			className={style.starPicto}
			style={{
				height: "10px",
				width: "10px",
				marginRight: 0,
			}}
		/>
	</Tag>
);

VipTag.propTypes = {
	className: PropTypes.string,
};

export default VipTag;
