/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import { Checkbox, Picto, StatusBox } from "@zolteam/react-ras-library";
import i18n from "i18next";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Store
import TableListingContext from "../../../store/TableListingContext";

// Components
import WideLoader from "../../molecules/WideLoader/WideLoader";

import { Popover, Text } from "@zolteam/react-ras-library";

// Style
import PopoverMenuItem from "src/components/atoms/PopoverMenuItem";
import ColumnListing from "src/components/molecules/ColumnListing";
import cn from "../../../utils/cn";
import styles from "./TableListing.module.css";

const TableListing = ({
	children: columns,
	loading,
	error,
	data,
	initialColumnSort,
	handleSearch,
	footer,
	canBeChecked,
	onDoubleClick,
	noDataText,
	showCustomMessage,
	customMessage,
	disabledRow,
	noBorder,
	fullHeight,
	styleInactiveRows,
	getTableRowHref,
	selectedItems,
	toggleSelected,
	selectAll,
	selectedValuesStatus,
	loadingMessage,
	hasCustomFooter,
	actions,
	genericEmptyCellsMessage,
}) => {
	const { t } = useTranslation();
	const [columnSort, setColumnSort] = useState(initialColumnSort);

	const handleClick = (e) => {
		if (!e.ctrlKey && !e.metaKey) {
			e.preventDefault();
		}
	};

	const getMessage = () => {
		if (loading) {
			return null;
		}

		switch (error) {
			case true:
				return t("global.error");
			default:
				return showCustomMessage ? customMessage : noDataText;
		}
	};

	const onPressSort = (field) => {
		// If we click on same column, we retrieve the currentSort order
		// Else, we use the default sort order which is DESC, but as we call handleSearch
		// With the new sort order we want, we just say that the current order is ASC
		// So the invert is sent to handleSearch
		let newOrderBy = "";
		let orderBy = "";

		// exception createdAt field
		if (field === "createdAt") {
			newOrderBy = columnSort.orderBy === "DESC" ? "ASC" : "DESC";
			orderBy = field === columnSort.field ? newOrderBy : "DESC";
		} else {
			newOrderBy = columnSort.orderBy === "ASC" ? "DESC" : "ASC";
			orderBy = field === columnSort.field ? newOrderBy : "ASC";
		}

		handleSearch({
			sorting: field,
			orderBy,
		});
		setColumnSort({
			field,
			orderBy,
		});
	};

	const renderRows = () =>
		data.map((elem, rowIndex) => {
			const isRowDisabled = disabledRow(elem);
			const isChecked = selectedItems?.includes(elem.id);

			return (
				<tr
					onDoubleClick={() => (isRowDisabled ? null : onDoubleClick(elem))}
					key={`row-${elem.id}`}
					data-testid={`row-${elem.id}`}
					className={cn([
						styles.row,
						isRowDisabled ? styles.rowDisabled : null,
						styleInactiveRows(elem) ? styles.greyRow : null,
					])}
				>
					{canBeChecked ? (
						<td onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
							<a>
								<div
									className={cn([
										styles.cellWrapper,
										styles.cellCheckbox,
										selectedItems?.find((val) => val === elem.id) != null &&
											styles.cellWrapperSelected,
									])}
								>
									<Checkbox
										value={isChecked}
										onChange={() => toggleSelected(elem)}
										id={`childSelect-${elem.id}`}
										theme="primary"
									/>
								</div>
							</a>
						</td>
					) : null}
					{columns.map((column) => {
						const renderColumn = () => (
							<div
								className={cn([
									styles.cellWrapper,
									isChecked && styles.cellWrapperSelected,
									column.props.cellClassName,
								])}
							>
								<TableListingContext.Provider
									value={{
										data: data[rowIndex],
										toggleSelected,
										selectedItems,
									}}
								>
									<ColumnListing
										field={column.props.field}
										component={column.props.component}
										genericEmptyCellsMessage={genericEmptyCellsMessage}
									/>
								</TableListingContext.Provider>
							</div>
						);

						return (
							<td
								key={`cell-${column.props.id}-${elem.id}`}
								data-testid={`cell-${column.props.id}`}
								className={cn([
									column.props.tdClassName,
									column.props.hideOnDesktop && styles.hideOnDesktop,
								])}
							>
								{!column.props.containsLink ? (
									<a href={getTableRowHref(elem)} onClick={handleClick}>
										{renderColumn()}
									</a>
								) : (
									renderColumn()
								)}
							</td>
						);
					})}
					{actions && (
						<td onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
							<div
								className={cn([
									styles.cellWrapper,
									styles.cellActions,
									selectedItems?.find((val) => val === elem.id) != null && styles.cellWrapperSelected,
								])}
							>
								<Popover
									minWidth
									clickInside
									placement="left-start"
									animation="perspective"
									offset={[-20, 10]}
									content={
										<>
											{actions?.map((action) => (
												<PopoverMenuItem
													key={action.label}
													label={action.label}
													click={() => action.action()}
												>
													<Text
														tag="div"
														size="paragraph01"
														data-testid={`edit-command-${1}`}
													>
														{action.label}
													</Text>
												</PopoverMenuItem>
											))}
										</>
									}
								>
									<button type="button" onClick={(e) => e.preventDefault()} aria-label="more">
										<Picto
											icon="more"
											style={{
												width: "18px",
												transform: "rotate(90deg)",
												color: "var(--color-neutral-500)",
											}}
										/>
									</button>
								</Popover>
							</div>
						</td>
					)}
				</tr>
			);
		});

	const getTableListingContent = () =>
		!loading && data?.length > 0 && !showCustomMessage ? (
			renderRows()
		) : (
			<tr>
				<td colSpan={columns?.length} key="cell-message" data-testid="cell-message">
					{getMessage()}
				</td>
			</tr>
		);

	const needCenterMessage = loading || data?.length === 0 || (data?.length > 0 && showCustomMessage);

	return (
		<>
			<div className={cn([styles.tableContainer, fullHeight ? styles.tableFullHeight : null])}>
				<table
					cellPadding={noBorder ? 0 : null}
					cellSpacing={noBorder ? 0 : null}
					className={cn([
						styles.table,
						noBorder ? styles.noBorder : null,
						needCenterMessage ? styles.tableModeMessage : null,
					])}
				>
					<thead className={styles.head}>
						<tr>
							{canBeChecked ? (
								<th className={styles.checkboxColumn}>
									<StatusBox
										value={selectedValuesStatus(data)}
										onClick={() => selectAll(data)}
										name="selectAll"
										theme="primary"
									/>
								</th>
							) : null}
							{columns.map((column) => (
								<th
									key={`column-${column.props.id}`}
									data-testid={`column-${column.props.id}`}
									className={cn([
										column.props.id === columnSort.field ? styles.selected : null,
										column.props.thClassName,
										column.props.hideOnDesktop && styles.hideOnDesktop,
									])}
									onClick={() => column.props.isSortable && onPressSort(column.props.id)}
									onKeyDown={() => column.props.isSortable && onPressSort(column.props.id)}
								>
									<div
										className={cn([
											styles.columnTitle,
											column.props.isSortable &&
												columnSort.field === column.props.id &&
												styles.columnActive,
											column.props.isSortable && styles.columnInteractive,
										])}
									>
										{column.props.name}
										{column.props.isSortable &&
											(columnSort.orderBy === "ASC" && columnSort.field === column.props.id ? (
												<Picto
													icon="chevronFilter"
													className={styles.chevronFilter}
													style={{
														transform: "rotate(180deg) translate(-4px, -0.075rem)",
													}}
												/>
											) : (
												<Picto icon="chevronFilter" className={styles.chevronFilter} />
											))}
									</div>
								</th>
							))}
							{actions && <th className={styles.checkboxColumn}></th>}
						</tr>
					</thead>
					<tbody className={styles.body}>{getTableListingContent()}</tbody>
				</table>
				{loading && <WideLoader message={loadingMessage} />}
			</div>
			{!loading && (
				<div className={hasCustomFooter ? cn([styles.footer, styles.selectedFooter]) : cn([styles.footer])}>
					{footer}
				</div>
			)}
		</>
	);
};

TableListing.propTypes = {
	children: PropTypes.arrayOf(
		PropTypes.shape({
			props: PropTypes.shape({
				name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
				component: PropTypes.func.isRequired,
				field: PropTypes.func.isRequired,
			}),
		})
	).isRequired,
	loading: PropTypes.bool.isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})),
	handleSearch: PropTypes.func.isRequired,
	initialColumnSort: PropTypes.shape({
		field: PropTypes.string.isRequired,
		orderBy: PropTypes.oneOf(["DESC", "ASC"]).isRequired,
	}).isRequired,
	footer: PropTypes.node,
	canBeChecked: PropTypes.bool,
	onDoubleClick: PropTypes.func,
	noDataText: PropTypes.string,
	error: PropTypes.bool,
	showCustomMessage: PropTypes.bool,
	customMessage: PropTypes.string,
	disabledRow: PropTypes.func,
	styleInactiveRows: PropTypes.func,
	noBorder: PropTypes.bool,
	fullHeight: PropTypes.bool,
	getTableRowHref: PropTypes.func,
	selectedItems: PropTypes.arrayOf(PropTypes.string),
	toggleSelected: PropTypes.func,
	selectAll: PropTypes.func,
	selectedValuesStatus: PropTypes.func,
	loadingMessage: PropTypes.string,
	hasCustomFooter: PropTypes.bool,
	genericEmptyCellsMessage: PropTypes.node,
};

TableListing.defaultProps = {
	data: [],
	footer: null,
	canBeChecked: true,
	onDoubleClick: () => {},
	noDataText: i18n.t("global.noDataText"),
	error: false,
	showCustomMessage: false,
	customMessage: "",
	disabledRow: () => false,
	styleInactiveRows: () => false,
	noBorder: false,
	fullHeight: false,
	getTableRowHref: () => "#",
	selectedItems: [],
	toggleSelected: () => {},
	selectAll: () => {},
	selectedValuesStatus: () => {},
	loadingMessage: i18n.t("global.loading"),
	hasCustomFooter: false,
	genericEmptyCellsMessage: undefined,
};

export default TableListing;
