// @ts-nocheck
import { Button, Title } from "@zolteam/react-ras-library";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
// Style
import style from "./Modals.module.css";

const ModalContentTempoConflict = ({ onConfirmClick, onCancelClick }) => {
	const { t } = useTranslation();

	return (
		<div className={style.wrapper}>
			<Title tag="h1" size="heading01" lineHeight="l" className={style.title}>
				{t("commands.profiles.modalsContent.tempoConflict.title")}
			</Title>
			<p className={style.tempoConflictMessage}>
				<Trans i18nKey="commands.profiles.modalsContent.tempoConflict.infoMessage" />
			</p>
			<hr className={style.separator} />
			<div className={style.buttonsBlock}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.profiles.modalsContent.notifyUsers.cancelAction")}
				</Button>
				<Button className={style.validationButtonRight} type="button" color="primary" onClick={onConfirmClick}>
					{t("commands.profiles.modalsContent.notifyUsers.confirmAction")}
				</Button>
			</div>
		</div>
	);
};

ModalContentTempoConflict.propTypes = {
	onConfirmClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
};

export default ModalContentTempoConflict;
