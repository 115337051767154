// @ts-nocheck
import React from "react";

// Style
import cn from "../../../utils/cn";
import styles from "./CountBox.module.css";

interface ICountBoxProps {
	number: string | number;
	alert?: boolean;
	theme?: "primary" | "darkGrey" | "grey" | "black" | "dark";
}

const CountBox: React.FC<ICountBoxProps> = ({ number, alert = false, theme = "primary" }) => (
	<div className={cn([styles.container, styles[theme], `countbox-${theme}`])}>
		<span className={styles.number}>{number}</span>
		{alert ? <span className={styles.alert} /> : null}
	</div>
);

export default CountBox;
