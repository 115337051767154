// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import ReactSlider from "react-slider";

// Utils
import cn from "../../../utils/cn";

import styles from "./Slider.module.css";

const Slider = ({
	min = 0,
	max = 100,
	onChange,
	step = 10,
	value,
	maxLabel = "",
	minLabel = "",
	minLabelDetails = "",
	maxLabelDetails = "",
	renderSliderLabel,
	className = "",
}) => (
	<div className={styles.wrapper}>
		<ReactSlider
			className="default-slider"
			min={min}
			max={max}
			step={step}
			value={value === null ? 100 : value}
			thumbClassName="default-slider-thumb"
			trackClassName="default-slider-track"
			thumbActiveClassName="default-slider-thumb-active"
			onChange={onChange}
			renderThumb={(props) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<div {...props}>
					<div className={cn([styles.tooltip, `${styles[className]}`])}>
						{renderSliderLabel(value, maxLabel)}
					</div>
				</div>
			)}
		/>
		<span className={cn([styles.start, styles.label])}>{`${minLabel} ${minLabelDetails}`}</span>
		<span className={cn([styles.end, styles.label])}>{`${maxLabel} ${maxLabelDetails}`}</span>
	</div>
);
Slider.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	step: PropTypes.number,
	value: PropTypes.number.isRequired,
	maxLabel: PropTypes.number,
	minLabel: PropTypes.number,
	minLabelDetails: PropTypes.string,
	maxLabelDetails: PropTypes.string,
	renderSliderLabel: PropTypes.func.isRequired,
	className: PropTypes.string,
};

export default Slider;
