// @ts-nocheck
import PropTypes from "prop-types";
import React, { useContext } from "react";

// Store
import TableListingContext from "../../../store/TableListingContext";

const ColumnListing = ({ field, component, genericEmptyCellsMessage }) => {
	const { data } = useContext(TableListingContext);
	const cellData = field(data);
	//Determines if the cell has data to display generic message
	const hasData = Object.values(cellData).some((value) => {
		if (Array.isArray(value)) {
			// Vérifiez si le tableau est vide
			return value.length > 0;
		}
		return value != null && value !== "" && value !== undefined;
	});

	//TODO: Add a generic message to display when the cell is empty, check all table listings
	return (
		<>
			{hasData || !genericEmptyCellsMessage
				? React.createElement(component, {
						data: field(data),
					})
				: genericEmptyCellsMessage}
		</>
	);
};

ColumnListing.propTypes = {
	field: PropTypes.func.isRequired,
	component: PropTypes.func.isRequired,
	genericEmptyCellsMessage: PropTypes.node,
};

export default ColumnListing;
