// @ts-nocheck
import React, { useMemo, useEffect } from "react";
// import PropTypes from 'prop-types';
import { Route, Switch, NavLink, useParams, useHistory, useLocation } from "react-router-dom";

// Nested Profile Mission Views
// TODO: Enable the order tab once ready
// import ProfileMissionsOrders from './ProfileMissionsOrders';
import ProfileMissionsFollowUp from "./ProfileMissionsFollowUp";
import ProfileMissionsClientRelationships from "./ProfileMissionsClientRelationships";
import ProfileMissionsContracts from "./ProfileMissionsContracts";

// Utils
import cn from "../../../utils/cn";
// Constants
// import {} from '../../constants';

import style from "./ProfileMissions.module.css";

const ProfileMissions = () => {
	const { profileId } = useParams();
	const { replace } = useHistory();
	const { pathname } = useLocation();

	// TODO: Remove default redirection to follow up view
	useEffect(() => {
		if (pathname === `/profile/${profileId}/missions`) {
			replace(`/profile/${profileId}/missions/follow-up`);
		}
	}, [profileId, pathname]);

	const routes = useMemo(
		() => [
			// TODO: Enable the order tab once ready
			// {
			//   path: '/profile/:profileId/missions/orders',
			//   routeName: 'profile-missions-orders',
			//   label: 'Commandes',
			//   component: <ProfileMissionsOrders />,
			//   enabled: false,
			// },
			{
				path: "/profile/:profileId/missions/follow-up",
				routeName: "profile-missions-follow-up",
				label: "Suivis de mission",
				component: <ProfileMissionsFollowUp />,
				enabled: true,
			},
			{
				path: "/profile/:profileId/missions/client-relationship",
				routeName: "profile-missions-client-relationship",
				label: "Relations clients",
				component: <ProfileMissionsClientRelationships />,
				enabled: true,
			},
			{
				path: "/profile/:profileId/missions/contracts",
				routeName: "profile-missions-contracts",
				label: "Contrats",
				component: <ProfileMissionsContracts />,
				enabled: true,
			},
		],
		[]
	);

	return (
		<div className={style.profileMissions}>
			<div className={style.menu}>
				{routes.map((route) => (
					<NavLink
						key={route.path}
						className={cn([style.menuItem, !route.enabled ? style.routeDisabled : null])}
						to={route.path.replace(":profileId", profileId)}
						activeClassName={style.selected}
					>
						<button type="button" key={route.path} className={style.menuItemBtn}>
							{route.label}
						</button>
					</NavLink>
				))}
			</div>
			<div className={style.content}>
				<Switch>
					{routes.map((route) => (
						<Route key={route.path} exact path={`${route.path}`}>
							{route.component}
						</Route>
					))}
				</Switch>
			</div>
		</div>
	);
};

ProfileMissions.propTypes = {};

export default ProfileMissions;
