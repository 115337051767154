// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

// Style
import style from "./OptionClient.module.css";
import cn from "../../../utils/cn";

const OptionClient = ({ tempoId = "", name = "", city = "" }) => (
	<div className={cn([style.optionContainer, "ellipsis"])} data-testid={`option-${tempoId}-${name}-${city}`}>
		<span className={style.optionTempoId}>{`${tempoId} -`}</span>
		<span className={style.optionName}>{`${name}`}</span>
		{city ? <span className={style.optionCity}>{`- ${city}`}</span> : null}
	</div>
);

OptionClient.propTypes = {
	tempoId: PropTypes.string,
	name: PropTypes.string,
	city: PropTypes.string,
};

export default OptionClient;
