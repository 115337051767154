// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import moment from "moment";
import "moment/locale/fr";
import PropTypes from "prop-types";
import { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import DateRangeInput from "../../atoms/DateRangeInput/DateRangeInput";
import DateSinglePicker from "../DateSinglePicker/DateSinglePicker";

const DateSinglePickerField = ({
	id,
	label,
	name,
	value,
	error,
	info,
	onDateChange,
	onClear,
	onClickOnInput,
	containerClassname,
	inputClassName,
	keepOpenOnDateSelect,
	isDayBlocked,
	disabled,
	numberOfMonths,
}) => {
	const [focusedInputName, setFocusedInputName] = useState("");

	return (
		<div className={containerClassname}>
			<DateRangeInput
				label={label}
				value={value ? moment(value).format("DD/MM/yyyy") : ""}
				onClick={() => {
					setFocusedInputName(name);
					if (onClickOnInput) onClickOnInput();
				}}
				onClear={(event) => {
					event.stopPropagation();
					if (onClear) onClear(event);
				}}
				className={inputClassName}
				isActive={focusedInputName === name}
				error={error}
				info={info}
				disabled={disabled}
			/>
			<DateSinglePicker
				id={id}
				dateString={value || ""}
				focusedInput={focusedInputName.replace("period.", "")}
				setFocusedInput={(inputName) => {
					setFocusedInputName(inputName ? `period.${inputName}` : "");
				}}
				onDateChange={onDateChange}
				isVisible={focusedInputName !== ""}
				setIsVisible={() => setFocusedInputName("")}
				keepOpenOnDateSelect={keepOpenOnDateSelect}
				isDayBlocked={isDayBlocked}
				numberOfMonths={numberOfMonths}
				className="absolute z-50"
			/>
		</div>
	);
};

DateSinglePickerField.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
	error: PropTypes.string,
	info: PropTypes.string,
	onDateChange: PropTypes.func.isRequired,
	onClear: PropTypes.func,
	onClickOnInput: PropTypes.func,
	containerClassname: PropTypes.string,
	inputClassName: PropTypes.string,
	keepOpenOnDateSelect: PropTypes.bool,
	isDayBlocked: PropTypes.func,
	disabled: PropTypes.bool,
	numberOfMonths: PropTypes.number,
};

DateSinglePickerField.defaultProps = {
	value: "",
	error: "",
	containerClassname: "",
	info: "",
	keepOpenOnDateSelect: false,
	onClear: undefined,
	onClickOnInput: undefined,
	inputClassName: "",
	isDayBlocked: undefined,
	disabled: false,
	numberOfMonths: 2,
};

export default DateSinglePickerField;
