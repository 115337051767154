// @ts-nocheck
import React, { useMemo, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, InputSearch, Title, ModalV2 } from "@zolteam/react-ras-library";
import { useTranslation } from "react-i18next";
import { DragDropContext } from "react-beautiful-dnd";
import { useParams, useHistory } from "react-router-dom";
import { deburr } from "lodash";
import { useQuery, useMutation } from "react-query";

// Components
import DropColumn from "./Kanban/DropColumn";
import DropColumnHead from "./Kanban/DropColumnHead";
import DragCard from "./Kanban/DragCard";
import Tag from "../../../components/atoms/Tag";
import OrderProfilesActiveUsers from "../../../components/molecules/OrderProfilesActiveUsers";
import ModalContentAddProfile from "./Modals/ModalContentAddProfile";
import ModalContentConfirmProfil from "./Modals/ModalContentConfirmProfil";
import ModalContentRecuseProfil from "./Modals/ModalContentRecuseProfil";
import ModalContentCommandFilled from "./Modals/ModalContentCommandFilled";
import ModalContentSharingOffers from "./Modals/ModalContentSharingOffers";
import ModalContentMissionOrder from "./Modals/ModalContentMissionOrder";
import ModalContentSelectProfil from "./Modals/ModalContentSelectProfil";
import ModalContentProfileCardDetails from "./Modals/ModalContentProfileCardDetails/ModalContentProfileCardDetails";
import WideLoader from "../../../components/molecules/WideLoader";

// Hooks
import useOrderData from "../../../hooks/useOrderData";
import useEnabledFeatures from "../../../hooks/useEnabledFeatures";
import useProfileSocket from "../../../hooks/useProfileSocket";

// Store
import { OrderProfilesFormProvider, useOrderProfilesContext } from "../../../store/OrderProfilesContext";
import { useToastContext } from "../../../store/ToastContext";

// Services
import {
	getOrderProfiles,
	postAddProfilesToOrder,
	putStatusProfileUpdate,
	postOrderShare,
	postOrderMission,
} from "../../../services/commandService";

// Style
import style from "./Profiles.module.css";
import cn from "../../../utils/cn";

// Misc
import { PROFILES_STATUS } from "../../../constants";

const DROP_COLUMNS_CONFIG = [
	{
		labelKey: "commands.profiles.columns.selectedTitle",
		columnStatus: PROFILES_STATUS.SELECTED,
	},
	{
		labelKey: "commands.profiles.columns.contactedTitle",
		columnStatus: PROFILES_STATUS.CONTACTED,
	},
	{
		labelKey: "commands.profiles.columns.acceptedTitle",
		columnStatus: PROFILES_STATUS.OFFER_ACCEPTED,
	},
	{
		labelKey: "commands.profiles.columns.declinedTitle",
		columnStatus: PROFILES_STATUS.OFFER_DECLINED,
	},
	{
		labelKey: "commands.profiles.columns.suggestedTitle",
		columnStatus: PROFILES_STATUS.PROPOSED,
	},
	{
		labelKey: "commands.profiles.columns.confirmedTitle",
		columnStatus: PROFILES_STATUS.VALIDATED,
	},
	{
		labelKey: "commands.profiles.columns.refusedTitle",
		columnStatus: PROFILES_STATUS.RECUSED,
	},
];

const DEFAULT_ADDMODAL_STATE = { isDisplayed: false, selected: "" };
const DEFAULT_COLUMN_MODAL_STATE = { isDisplayed: false, dragEndEvent: null };

const Profiles = ({ agency, client }) => {
	const { t } = useTranslation();
	const [, toastDispatch] = useToastContext();
	const { orderId } = useParams();
	const history = useHistory();

	const { userList } = useProfileSocket();

	const [, dispatchOrderProfiles] = useOrderProfilesContext();

	// TODO: remove this when the feature is ready
	const {
		enabledFeatures: { hasOrderProfilesAccess, hasMissionListAccess },
		query: { isLoading },
	} = useEnabledFeatures();

	// TODO: Remove when ready
	useEffect(() => {
		if (!isLoading && !hasMissionListAccess) {
			history.push("/commandes");
		}
	}, [isLoading, hasMissionListAccess]);

	const initialKanbanState = {
		[PROFILES_STATUS.SELECTED]: [],
		[PROFILES_STATUS.CONTACTED]: [],
		[PROFILES_STATUS.OFFER_ACCEPTED]: [],
		[PROFILES_STATUS.OFFER_DECLINED]: [],
		[PROFILES_STATUS.PROPOSED]: [],
		[PROFILES_STATUS.VALIDATED]: [],
		[PROFILES_STATUS.RECUSED]: [],
	};

	// States
	const [profileKanbanByStatus, setProfileKanbanByStatus] = useState(initialKanbanState);
	const [searchString, setSearchString] = useState("");
	const [targetedStatus, setTargetedStatus] = useState([]);
	const [firstTargetedProfile, setFirstTargetedProfile] = useState(null);
	const boardElementRef = useRef(null);

	// States modals
	const [addModalStatus, setAddModalStatus] = useState(DEFAULT_ADDMODAL_STATE);

	const [selectModalStatus, setSelectModalStatus] = useState(DEFAULT_COLUMN_MODAL_STATE);
	const [sharingOffersModalStatus, setSharingOffersModalStatus] = useState(DEFAULT_ADDMODAL_STATE);
	const [orderMissionModalStatus, setOrderMissionModalStatus] = useState(DEFAULT_ADDMODAL_STATE);
	const [confirmProfileStatus, setConfirmProfileStatus] = useState(DEFAULT_COLUMN_MODAL_STATE);
	const [recuseProfileStatus, setRecuseProfileStatus] = useState(DEFAULT_COLUMN_MODAL_STATE);
	const [commandFilledStatus, setCommandFilledStatus] = useState(DEFAULT_COLUMN_MODAL_STATE);
	const [profileIdSelected, setProfileIdSelected] = useState(null);

	const handleProfiles = (profiles) => {
		if (!profiles) return;
		const profilesByStatus = profiles.reduce((acc, profile) => {
			const { status } = profile;
			if (status && acc[status]) {
				acc[status].push(profile);
			}
			return acc;
		}, initialKanbanState);
		setProfileKanbanByStatus(profilesByStatus);
	};

	const handleError = (message) => {
		toastDispatch({
			type: "ERROR",
			payload: {
				icon: "alertCircle",
				msg: message,
			},
		});
	};

	// Queries

	const { orderData, agencyFeaturesData, refetchOrderData } = useOrderData(orderId);
	const isOrderNotProvided = orderData?.data?.status === "NOT_PROVIDED";

	const { refetch: refetchOrderProfiles, data: orderProfilesData } = useQuery(
		["getOrderProfiles", orderId],
		() => getOrderProfiles(orderId),
		{
			enabled: !!orderId,
			onSuccess: ({ data }) => handleProfiles(data),
			onError: () => handleError(t("global.apiErrors.undifined")),
		}
	);

	const shouldDisplayLoader = useMemo(() => {
		if (orderProfilesData === undefined) return true;
		if (orderProfilesData?.data?.length === 0) return false;
		return Object.values(profileKanbanByStatus).every((column) => column.length === 0);
	}, [orderProfilesData, profileKanbanByStatus]);

	const statusProfileUpdateMutation = useMutation(
		({ orderProfileId, status, notifyUser, version }) =>
			putStatusProfileUpdate(orderId, {
				orderProfileId,
				status,
				notifyUser,
				version,
			}),
		{
			onSuccess: () => {
				// TODO: potentially update the loading background state
				refetchOrderProfiles();
			},
			onError: (e) => {
				const { response } = e;
				switch (response?.data?.message || "") {
					case "ERR_CO_ORDER_PROFILE_STATUS_CONFLICT": {
						handleError(t("commands.apiCommandErrors.ERR_CO_ORDER_PROFILE_STATUS_CONFLICT"));
						break;
					}
					default: {
						handleError(t("commands.error.undefined"));
						break;
					}
				}
				refetchOrderProfiles();
			},
		}
	);

	const addProfilToOrderMutation = useMutation(
		({ selectedStatus, selectedProfilesId }) => postAddProfilesToOrder(orderId, selectedProfilesId, selectedStatus),
		{
			onSuccess: () => {
				// TODO: potentially update the loading background state
				refetchOrderData();
				refetchOrderProfiles();
			},
			onError: () => {},
		}
	);

	const findOrderProfilById = (profileId) => {
		const list = orderProfilesData?.data;
		return list?.find(({ id }) => id.toString() === profileId) || {};
	};

	const handleTagColorChange = useMemo(() => {
		if (orderData?.data?.status === "IS_FILLED") {
			return "success";
		}
		if (orderData?.data?.status === "IN_PROGRESS") {
			return "primary";
		}
		if (orderData?.data?.status === "NOT_PROVIDED") {
			return "grey";
		}
		return null;
	}, [orderData?.data?.status]);

	const addShareOrderMutation = useMutation(
		({ showClientName, showCityAndPostalCode }) =>
			postOrderShare(orderId, targetedStatus, showClientName, showCityAndPostalCode),
		{
			onSuccess: (response) => {
				refetchOrderProfiles();
				if (response?.data?.inErrorOrderProfileIds && response?.data?.inErrorOrderProfileIds.length !== 0) {
					const profileId = response.data.inErrorOrderProfileIds[0];
					const { firstName, lastName } = findOrderProfilById(profileId.toString());
					const inErrorOrderProfileIdsLength = response.data.inErrorOrderProfileIds.length || 0;
					if (inErrorOrderProfileIdsLength === 1) {
						toastDispatch({
							type: "WARN",
							payload: {
								icon: "info",
								msg: t("commands.profiles.offerShareError", {
									firstName,
									lastName,
								}),
							},
						});
					}
					if (inErrorOrderProfileIdsLength > 1) {
						toastDispatch({
							type: "WARN",
							payload: {
								icon: "info",
								msg: t("commands.profiles.offerShareErrors"),
							},
						});
					}
				}
			},
		}
	);

	const addOrderMissionShareMutation = useMutation(
		(additionalInformations) => postOrderMission(orderId, targetedStatus, additionalInformations),
		{
			onSuccess: (response) => {
				refetchOrderProfiles();
				if (response?.data?.inErrorOrderProfileIds && response?.data?.inErrorOrderProfileIds.length !== 0) {
					const profileId = response.data.inErrorOrderProfileIds[0];
					const { firstName, lastName } = findOrderProfilById(profileId.toString());
					const inErrorOrderProfileIdsLength = response.data.inErrorOrderProfileIds.length || 0;
					if (inErrorOrderProfileIdsLength === 1) {
						toastDispatch({
							type: "WARN",
							payload: {
								icon: "info",
								msg: t("commands.profiles.missionOrderShareError", {
									firstName,
									lastName,
								}),
							},
						});
					}
					if (inErrorOrderProfileIdsLength > 1) {
						toastDispatch({
							type: "WARN",
							payload: {
								icon: "info",
								msg: t("commands.profiles.missionOrderShareErrors"),
							},
						});
					}
				}
			},
		}
	);

	const workerRequestedData = useMemo(
		() => orderData?.data?.qualification?.workerRequestedCount || null,
		[orderData]
	);

	const manageDropItem = (dragEndEvent) =>
		setProfileKanbanByStatus((prevKanban) => {
			// If source and destination are the same do nothing to avoid duplicates
			if (dragEndEvent.source.droppableId === dragEndEvent.destination.droppableId) {
				return prevKanban;
			}

			// Get the source list and destination list and the profile that has been dragged
			const sourceList = prevKanban[dragEndEvent.source.droppableId];
			const destinationList = prevKanban[dragEndEvent.destination.droppableId];

			const profileDragged = sourceList.find((profile) => profile.id.toString() === dragEndEvent.draggableId);

			// Remove the profile from the source list and add it to the destination list
			const clearedSourceList = sourceList.filter(
				(profile) => profile.id.toString() !== profileDragged.id.toString()
			);
			const newDestinationList = destinationList.concat([profileDragged]);

			// Return the new kanban list with the modification to update the state
			const newKanban = {
				...prevKanban,
				[dragEndEvent.source.droppableId]: clearedSourceList,
				[dragEndEvent.destination.droppableId]: newDestinationList,
			};
			return newKanban;
		});

	const shouldShowConfirmModal = (droppedColumnStatus, willColumnBeFull) => {
		const isDroppedToValidated = droppedColumnStatus === PROFILES_STATUS.VALIDATED;
		const isOrderFilled = orderData?.data?.status === "IS_FILLED";

		return isDroppedToValidated && (!willColumnBeFull || (willColumnBeFull && isOrderFilled) || isOrderNotProvided);
	};

	const shouldShowFilledStatusModal = (droppedColumnStatus, willColumnBeFull, dataOrder) => {
		const isDroppedToValidated = droppedColumnStatus === PROFILES_STATUS.VALIDATED;
		const isOrderFilled = dataOrder?.data?.status === "IS_FILLED";

		return isDroppedToValidated && willColumnBeFull && !isOrderFilled && !isOrderNotProvided;
	};

	const shouldShowSelectStatusModal = (droppedColumnStatus, dragEndEvent) => {
		const { hasBeenShared } = findOrderProfilById(dragEndEvent.draggableId);

		return (
			droppedColumnStatus === PROFILES_STATUS.SELECTED &&
			((dragEndEvent.source.droppableId === PROFILES_STATUS.CONTACTED && hasBeenShared) ||
				dragEndEvent.source.droppableId === PROFILES_STATUS.OFFER_ACCEPTED ||
				dragEndEvent.source.droppableId === PROFILES_STATUS.PROPOSED ||
				dragEndEvent.source.droppableId === PROFILES_STATUS.VALIDATED)
		);
	};

	const shouldPreventToMoveToContacted = (dragEndEvent) => {
		const isDroppedFromRestrictedSource =
			dragEndEvent.source.droppableId === PROFILES_STATUS.VALIDATED ||
			dragEndEvent.source.droppableId === PROFILES_STATUS.RECUSED ||
			dragEndEvent.source.droppableId === PROFILES_STATUS.PROPOSED ||
			dragEndEvent.source.droppableId === PROFILES_STATUS.OFFER_DECLINED ||
			dragEndEvent.source.droppableId === PROFILES_STATUS.OFFER_ACCEPTED;

		const isDroppedToContacted = dragEndEvent.destination.droppableId === PROFILES_STATUS.CONTACTED;

		return isDroppedFromRestrictedSource && isDroppedToContacted;
	};

	const shouldPreventToMoveFromValidated = (dragEndEvent) => {
		const isDroppedToRestrictedSource = dragEndEvent.destination.droppableId !== PROFILES_STATUS.RECUSED;
		const isDroppedFromValidated = dragEndEvent.source.droppableId === PROFILES_STATUS.VALIDATED;

		return isDroppedToRestrictedSource && isDroppedFromValidated;
	};

	const handleDragEnd = (dragEndEvent) => {
		if (dragEndEvent.destination === null) {
			return null;
		}
		if (dragEndEvent.source.droppableId === dragEndEvent.destination.droppableId) {
			return null;
		}
		if (shouldPreventToMoveFromValidated(dragEndEvent)) {
			toastDispatch({
				type: "ERROR",
				payload: {
					icon: "alertCircle",
					msg: t("commands.profiles.preventToMooveFromValidatedErrorMessage"),
				},
			});
			return null;
		}

		if (shouldPreventToMoveToContacted(dragEndEvent)) {
			toastDispatch({
				type: "ERROR",
				payload: {
					icon: "alertCircle",
					msg: t("commands.profiles.preventToMooveToContactedErrorMessage"),
				},
			});

			return null;
		}

		if (dragEndEvent.source.droppableId === dragEndEvent.destination.droppableId) {
			return null;
		}
		if (!dragEndEvent?.destination?.droppableId) return null;

		const droppedColumnStatus = dragEndEvent.destination.droppableId;
		const willColumnBeFull = profileKanbanByStatus[PROFILES_STATUS.VALIDATED].length === workerRequestedData - 1;

		if (shouldShowConfirmModal(droppedColumnStatus, willColumnBeFull)) {
			return setConfirmProfileStatus({ isDisplayed: true, dragEndEvent });
		}

		if (shouldShowFilledStatusModal(droppedColumnStatus, willColumnBeFull, orderData)) {
			return setCommandFilledStatus({ isDisplayed: true, dragEndEvent });
		}

		if (droppedColumnStatus === PROFILES_STATUS.RECUSED) {
			return setRecuseProfileStatus({ isDisplayed: true, dragEndEvent });
		}

		if (shouldShowSelectStatusModal(droppedColumnStatus, dragEndEvent)) {
			return setSelectModalStatus({ isDisplayed: true, dragEndEvent });
		}

		const { draggableId: orderProfileId } = dragEndEvent;
		const { version } = findOrderProfilById(orderProfileId);

		statusProfileUpdateMutation.mutate({
			orderProfileId: Number(orderProfileId),
			status: droppedColumnStatus,
			version,
		});

		return manageDropItem(dragEndEvent);
	};

	const handleOnSearchChange = (value) => {
		setSearchString(value);
	};

	const handleAddProfileClick = (columnStatus = "") =>
		setAddModalStatus({ selected: columnStatus, isDisplayed: true });

	const handleSharingOffersFromProfilClick = (profile, columnStatus = "") => {
		setTargetedStatus([profile.id]);
		setFirstTargetedProfile(profile);
		setSharingOffersModalStatus({ selected: columnStatus, isDisplayed: true });
	};

	const handleSharingOffersFromColumnClick = (columnStatus) => {
		const targetedProfiles = profileKanbanByStatus[columnStatus].filter(({ hasBeenShared }) => !hasBeenShared);

		setTargetedStatus(targetedProfiles.map(({ id }) => id));
		setSharingOffersModalStatus({ selected: columnStatus, isDisplayed: true });
	};

	const handleSendMissionOrderFromColumnClick = (columnStatus) => {
		const targetedProfiles = profileKanbanByStatus[columnStatus];

		setTargetedStatus(targetedProfiles.map(({ id }) => id));
		setOrderMissionModalStatus({ selected: columnStatus, isDisplayed: true });
	};

	const handleSendMissionOrderFromProfilClick = (profile, columnStatus = "") => {
		setTargetedStatus([profile.id]);
		setFirstTargetedProfile(profile);
		setOrderMissionModalStatus({ selected: columnStatus, isDisplayed: true });
	};

	const handleConfirmProfilClick = () => {
		// TODO: add api call to update the profile status, optimistically update the state

		const { draggableId: orderProfileId } = confirmProfileStatus.dragEndEvent;
		const { version } = findOrderProfilById(orderProfileId);

		statusProfileUpdateMutation.mutate({
			orderProfileId: Number(orderProfileId),
			status: PROFILES_STATUS.VALIDATED,
			notifyUser: true,
			version,
		});
		manageDropItem(confirmProfileStatus.dragEndEvent);
		setConfirmProfileStatus(DEFAULT_COLUMN_MODAL_STATE);
		refetchOrderData();
	};

	const validateOrderMutation = async (selectedProfiles, selectedStatus) => {
		const selectedProfilesId = selectedProfiles.map(({ id }) => id);
		const willOrderBeFull =
			profileKanbanByStatus[PROFILES_STATUS.VALIDATED].length + selectedProfiles.length >= workerRequestedData;

		setAddModalStatus(DEFAULT_ADDMODAL_STATE);

		await addProfilToOrderMutation.mutateAsync({
			selectedStatus,
			selectedProfilesId,
		});

		if (
			orderData?.data?.status !== "IS_FILLED" &&
			orderData?.data?.status !== "NOT_PROVIDED" &&
			willOrderBeFull &&
			selectedStatus === "VALIDATED"
		) {
			toastDispatch({
				type: "SUCCESS",
				payload: {
					icon: "checkCircle",
					msg: t("commands.profiles.modalsContent.commandFilled.succesFilledOrderMessage"),
				},
			});
		}
	};

	const handleConfirmCommandFilledClick = async () => {
		const orderProfileId = commandFilledStatus?.dragEndEvent?.draggableId || null;
		const { selectedProfiles } = commandFilledStatus;
		const { selectedStatus } = commandFilledStatus;
		const { version } = findOrderProfilById(orderProfileId);

		if (!orderProfileId) {
			setCommandFilledStatus(DEFAULT_COLUMN_MODAL_STATE);
			await validateOrderMutation(selectedProfiles, selectedStatus);
			refetchOrderData();
			return;
		}

		setCommandFilledStatus(DEFAULT_COLUMN_MODAL_STATE);
		await statusProfileUpdateMutation.mutateAsync({
			orderProfileId: Number(orderProfileId),
			status: PROFILES_STATUS.VALIDATED,
			notifyUser: true,
			version,
		});

		manageDropItem(commandFilledStatus.dragEndEvent);

		await refetchOrderData();
		if (orderData?.data?.status === "IN_PROGRESS") {
			toastDispatch({
				type: "SUCCESS",
				payload: {
					icon: "checkCircle",
					msg: t("commands.profiles.modalsContent.commandFilled.succesFilledOrderMessage"),
				},
			});
		}
	};

	const handleConfirmRecuseProfilClick = ({ shouldSendNotification }) => {
		const { draggableId: orderProfileId } = recuseProfileStatus.dragEndEvent;
		const { version } = findOrderProfilById(orderProfileId);
		statusProfileUpdateMutation.mutate({
			orderProfileId: Number(orderProfileId),
			status: PROFILES_STATUS.RECUSED,
			notifyUser: shouldSendNotification,
			version,
		});
		manageDropItem(recuseProfileStatus.dragEndEvent);
		setRecuseProfileStatus(DEFAULT_COLUMN_MODAL_STATE);
	};

	const handleConfirmSharingOfferClick = async (showClientName, showCityAndPostalCode) => {
		addShareOrderMutation.mutate({
			showClientName,
			showCityAndPostalCode,
		});
		setTargetedStatus([]);
		setSharingOffersModalStatus(DEFAULT_COLUMN_MODAL_STATE);
	};

	const handleConfirmsMissionOrderClick = (additionalInformations) => {
		addOrderMissionShareMutation.mutate(additionalInformations);
		setTargetedStatus([]);
		setOrderMissionModalStatus(DEFAULT_COLUMN_MODAL_STATE);
	};

	const handleSelectProfilClick = () => {
		const { draggableId: orderProfileId } = selectModalStatus.dragEndEvent;
		const { version } = findOrderProfilById(orderProfileId);
		statusProfileUpdateMutation.mutate({
			orderProfileId: Number(orderProfileId),
			status: PROFILES_STATUS.SELECTED,
			version,
		});
		manageDropItem(selectModalStatus.dragEndEvent);
		setSelectModalStatus(DEFAULT_COLUMN_MODAL_STATE);
	};

	const handleAddProfileOrderClick = async (selectedStatus, selectedProfiles) => {
		const willOrderBeFull =
			profileKanbanByStatus[PROFILES_STATUS.VALIDATED].length + selectedProfiles.length >= workerRequestedData;

		if (willOrderBeFull && selectedStatus === "VALIDATED" && orderData?.data?.status === "IN_PROGRESS") {
			setAddModalStatus(DEFAULT_ADDMODAL_STATE);
			setCommandFilledStatus({
				isDisplayed: true,
				selectedStatus,
				selectedProfiles,
			});
		} else {
			validateOrderMutation(selectedProfiles, selectedStatus);
		}
	};

	const filterSearchOnProfilesName = (profile) => {
		const cleanedProfileFirstName = deburr(profile.firstName?.toLowerCase() || "").replace(/\s/g, "");
		const cleanedProfileLasttName = deburr(profile.lastName?.toLowerCase() || "").replace(/\s/g, "");
		const fullName = `${cleanedProfileFirstName}${cleanedProfileLasttName}`;

		const cleanedSearchString = deburr(searchString.toLowerCase()).replace(/\s/g, "");

		return (
			fullName.includes(cleanedSearchString) ||
			cleanedProfileFirstName.includes(cleanedSearchString) ||
			cleanedProfileLasttName.includes(cleanedSearchString)
		);
	};

	const getCardActionsList = useMemo(
		() => [
			{
				type: "shareAction",
				label: t("commands.profiles.cardActions.shareAction"),
				onClick: (profil) => handleSharingOffersFromProfilClick(profil),
			},
			{
				type: "shareMissionOrder",
				label: t("commands.profiles.cardActions.sendMissionOrder"),
				onClick: (profil) => handleSendMissionOrderFromProfilClick(profil),
			},
			{
				type: "goToFi",
				label: t("commands.profiles.cardActions.goToFi"),
				onClick: (profil) => {
					window.open(`${process.env.REACT_APP_BASE_OLD_URL}/#/fr/contacts/${profil.userId}`);
				},
			},
		],
		[]
	);

	const getColumnActionsList = useMemo(
		() => [
			{
				type: "shareAction",
				label: t("commands.profiles.cardActions.shareAction"),
				onClick: (columnStatus) => handleSharingOffersFromColumnClick(columnStatus),
			},
			{
				type: "shareMissionOrder",
				label: t("commands.profiles.cardActions.sendMissionOrder"),
				onClick: (columnStatus) => handleSendMissionOrderFromColumnClick(columnStatus),
			},
		],
		[profileKanbanByStatus]
	);

	const filteredProfilesKanbanByStatus = (columnConfig) =>
		profileKanbanByStatus[columnConfig.columnStatus].filter(filterSearchOnProfilesName);

	// TODO: remove this when the feature is ready
	if (!hasOrderProfilesAccess || !agencyFeaturesData?.hasAccessToProfileManagement) {
		return (
			<div className={style.wrapper}>
				<p>Chargement...</p>
			</div>
		);
	}

	return (
		<div className={style.wrapper}>
			{shouldDisplayLoader && <WideLoader message={t("commands.profiles.loadingMessage")} />}
			<div className={style.head}>
				<div className={style.leftPart}>
					<Title tag="h1" size="heading01" lineHeight="s">
						{t("commands.profiles.title")}
					</Title>
					<Tag className={style.headTag} size="l" radius="l" color={handleTagColorChange}>
						{`${profileKanbanByStatus[PROFILES_STATUS.VALIDATED].length}/${workerRequestedData}`}
					</Tag>
				</div>
				<div className={style.rightPart}>
					{/* <ToggleSwitch
            id="missionStartReminder"
            name="missionStartReminder"
            onChange={() => {}}
          >
            <span className={style.toggleLabel}>
              {t('commands.profiles.missionStartReminderLabel')}
            </span>
          </ToggleSwitch> */}
					<OrderProfilesActiveUsers userList={userList} />
					<Button
						disabled={isOrderNotProvided}
						className={style.addButton}
						type="button"
						color={isOrderNotProvided ? "grey" : "primary"}
						onClick={() => handleAddProfileClick()}
					>
						{t("commands.profiles.addProfileAction")}
					</Button>
					<InputSearch
						className={style.search}
						value={searchString}
						onClick={() => null}
						type="text"
						name="searchString"
						placeholder={t("commands.profiles.searchPlaceholder")}
						removeValue={handleOnSearchChange}
					/>
				</div>
			</div>

			<div
				className={isOrderNotProvided ? cn([style.dragDropContext, style.notProvided]) : style.dragDropContext}
				ref={boardElementRef}
			>
				<DragDropContext onDragEnd={handleDragEnd}>
					{DROP_COLUMNS_CONFIG.map((columnConfig) => (
						<DropColumn
							key={columnConfig.columnStatus}
							droppableId={columnConfig.columnStatus}
							headComponent={
								<DropColumnHead
									columnStatus={columnConfig.columnStatus}
									orderStatus={orderData?.data?.status}
									label={t(columnConfig.labelKey)}
									profilesByStatus={profileKanbanByStatus[columnConfig.columnStatus]}
									onAddClick={() => handleAddProfileClick(columnConfig.columnStatus)}
									availableActions={getColumnActionsList}
								/>
							}
						>
							{filteredProfilesKanbanByStatus(columnConfig).map((profile, index) => (
								<DragCard
									key={`card-${profile?.id}`}
									className={style.card}
									profile={profile}
									index={index}
									availableActions={getCardActionsList}
									handleDoubleClick={() =>
										setProfileIdSelected({
											unifiedTempWorkerId: profile?.unifiedTempWorkerId,
											orderProfileId: profile?.id,
										})
									}
								/>
							))}
						</DropColumn>
					))}
				</DragDropContext>
			</div>

			{/* Modals */}
			<ModalV2
				isDisplayed={selectModalStatus.isDisplayed}
				onClose={() => setSelectModalStatus(DEFAULT_COLUMN_MODAL_STATE)}
				size="s"
				className={style.modal}
			>
				<ModalContentSelectProfil
					onCancelClick={() => setSelectModalStatus(DEFAULT_COLUMN_MODAL_STATE)}
					onConfirmClick={handleSelectProfilClick}
				/>
			</ModalV2>
			<ModalV2
				isDisplayed={addModalStatus.isDisplayed}
				onClose={() => setAddModalStatus(DEFAULT_ADDMODAL_STATE)}
				size="s"
				className={style.modal}
			>
				<ModalContentAddProfile
					onAddClick={({ selectedStatus, selectedProfiles }) => {
						handleAddProfileOrderClick(selectedStatus, selectedProfiles);
					}}
					onCancelClick={() => setAddModalStatus(DEFAULT_ADDMODAL_STATE)}
					defaultSelectedStatus={addModalStatus.selected}
				/>
			</ModalV2>
			<ModalV2
				isDisplayed={confirmProfileStatus.isDisplayed}
				onClose={() => setConfirmProfileStatus(DEFAULT_COLUMN_MODAL_STATE)}
				size="s"
			>
				<ModalContentConfirmProfil
					onCancelClick={() => setConfirmProfileStatus(DEFAULT_COLUMN_MODAL_STATE)}
					onConfirmClick={handleConfirmProfilClick}
				/>
			</ModalV2>
			<ModalV2
				isDisplayed={recuseProfileStatus.isDisplayed}
				onClose={() => setRecuseProfileStatus(DEFAULT_COLUMN_MODAL_STATE)}
				size="s"
			>
				<ModalContentRecuseProfil
					onCancelClick={() => setRecuseProfileStatus(DEFAULT_COLUMN_MODAL_STATE)}
					onConfirmClick={handleConfirmRecuseProfilClick}
				/>
			</ModalV2>
			<ModalV2
				isDisplayed={commandFilledStatus.isDisplayed}
				onClose={() => setCommandFilledStatus(DEFAULT_COLUMN_MODAL_STATE)}
				size="s"
			>
				<ModalContentCommandFilled
					onCancelClick={() => setCommandFilledStatus(DEFAULT_COLUMN_MODAL_STATE)}
					onConfirmClick={handleConfirmCommandFilledClick}
				/>
			</ModalV2>
			<ModalV2
				isDisplayed={sharingOffersModalStatus.isDisplayed}
				onClose={() => {
					setSharingOffersModalStatus(DEFAULT_ADDMODAL_STATE);
					setFirstTargetedProfile(null);
					setTargetedStatus(null);
				}}
				size="m"
			>
				<ModalContentSharingOffers
					profile={firstTargetedProfile}
					onCancelClick={() => {
						setSharingOffersModalStatus(DEFAULT_ADDMODAL_STATE);
						setFirstTargetedProfile(null);
						setTargetedStatus(null);
					}}
					onAddClick={({ showClientName, showCityAndPostalCode }) => {
						handleConfirmSharingOfferClick(showClientName, showCityAndPostalCode);
					}}
					currentOrder={{
						...orderData?.data,
						agency,
						client,
					}}
					context="sharingOffer"
				/>
			</ModalV2>
			<ModalV2
				isDisplayed={orderMissionModalStatus.isDisplayed}
				onClose={() => {
					setOrderMissionModalStatus(DEFAULT_ADDMODAL_STATE);
					setFirstTargetedProfile(null);
					setTargetedStatus(null);
				}}
				size="m"
			>
				<ModalContentMissionOrder
					currentOrder={{
						...orderData?.data,
						agency,
						client,
					}}
					isColumnAction={!!firstTargetedProfile}
					onCancelClick={() => {
						setOrderMissionModalStatus(DEFAULT_ADDMODAL_STATE);
						setFirstTargetedProfile(null);
						setTargetedStatus(null);
					}}
					onAddClick={(additionalInformations) => {
						handleConfirmsMissionOrderClick(additionalInformations);
					}}
				/>
			</ModalV2>
			<ModalV2
				isDisplayed={!!profileIdSelected}
				onClose={() => {
					setProfileIdSelected(null);
					dispatchOrderProfiles({ type: "RESET_ORDER_PROFILES" });
				}}
				size="l"
				backgroundColor="grey"
			>
				<ModalContentProfileCardDetails profile={profileIdSelected} />
			</ModalV2>
			{shouldDisplayLoader && <WideLoader message={t("commands.profiles.loadingMessage")} />}
		</div>
	);
};

Profiles.propTypes = {
	agency: PropTypes.shape(),
	client: PropTypes.shape(),
};

Profiles.defaultProps = {
	agency: null,
	client: null,
};

const ProfilesContext = ({ agency, client }) => (
	<OrderProfilesFormProvider>
		<Profiles agency={agency} client={client} />
	</OrderProfilesFormProvider>
);

export default ProfilesContext;

ProfilesContext.propTypes = {
	agency: PropTypes.shape(),
	client: PropTypes.shape(),
};

ProfilesContext.defaultProps = {
	agency: null,
	client: null,
};
