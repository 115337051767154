// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";

import styles from "./DropColumn.module.css";

const getListStyle = (isDraggingOver, draggingFromThisWith) => {
	const getBackground = () => {
		if (isDraggingOver) return "var(--color-primary-100)";
		if (draggingFromThisWith) return "var(--color-primary-50)";
		return "var(--color-neutral-100)";
	};

	return {
		background: getBackground(),
		border: isDraggingOver ? "2px dashed var(--color-primary-500)" : "none",
		borderRadius: isDraggingOver ? "10px 10px 0px 0px" : "0",
		padding: "0.625rem 0.625rem 0.625rem 0.625rem",
		transition: "background 0.3s ease-in-out",
		flexGrow: 1,
		overflow: "auto",
	};
};

const DropColumn = ({ droppableId, children = null, headComponent = null }) => (
	// essayer avec une div qui wrapp tout, le header en dehors du droppable et la colonne en overflow
	<div
		style={{
			minWidth: "13.25rem",
			flex: "1",
			height: "100%",
			display: "flex",
			flexFlow: "column nowrap",
		}}
	>
		{headComponent}
		<Droppable droppableId={droppableId} type="COLUMN" direction="vertical">
			{(provided, snapshot) => (
				<div
					{...provided.droppableProps}
					ref={provided.innerRef}
					style={getListStyle(snapshot.isDraggingOver, snapshot.draggingFromThisWith)}
					className={styles.columnContent}
				>
					{children}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	</div>
);

DropColumn.propTypes = {
	droppableId: PropTypes.string.isRequired,
	children: PropTypes.node,
	headComponent: PropTypes.node,
	containerHeight: PropTypes.number,
};

export default DropColumn;
