// @ts-nocheck
import { Tooltip } from "@zolteam/react-ras-library";
import { useTranslation } from "react-i18next";
import Tag from "../Tag";
import localeFr from "date-fns/locale/fr";
import { format } from "date-fns";

const outOfBusinessNonIso: string = "OUT_BUSINESS_NON_ISO";
const OutOfBusinessTag = ({
	outOfBusinessReason,
	outCommentInterviewDate,
}: {
	outOfBusinessReason?: string;
	outCommentInterviewDate?: string;
}): JSX.Element => {
	const { t } = useTranslation();
	const tag = (
		<Tag color={outOfBusinessReason === outOfBusinessNonIso ? "error" : "grey"} size={"s"}>
			{t("expiredDocuments.headers.outOfBusiness")}
		</Tag>
	);
	const translatedReason = t(`outOfBusinessReasons.${outOfBusinessReason}`);
	const tooltipContent =
		translatedReason +
		(outCommentInterviewDate
			? ` le ${format(new Date(outCommentInterviewDate), "dd MMM yyyy", {
					locale: localeFr,
				})}`
			: "");
	return outOfBusinessReason ? (
		<Tooltip content={<div>{t(tooltipContent)} </div>}>
			<div className="flex items-center">{tag}</div>
		</Tooltip>
	) : (
		tag
	);
};

export default OutOfBusinessTag;
