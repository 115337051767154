// @ts-nocheck
import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Components
import { Tag, PictoAction, Popover, Picto } from "@zolteam/react-ras-library";

// Constants
import { PROFILES_STATUS, ORDER_STATUS } from "../../../../constants";

// Style
import style from "./DropColumnHead.module.css";

const DropColumnHead = ({
	label,
	profilesByStatus = [],
	onAddClick = undefined,
	availableActions = [],
	droppableSnapshot = null,
	columnStatus = null,
	orderStatus,
}) => {
	const shouldDisplayContextualMenu = () => {
		if (!orderStatus || !profilesByStatus) {
			return false;
		}
		if (profilesByStatus.length === 0) {
			return false;
		}
		if (orderStatus === ORDER_STATUS.NOT_PROVIDED) {
			return false;
		}
		if (orderStatus === ORDER_STATUS.IS_FILLED && columnStatus !== PROFILES_STATUS.VALIDATED) {
			return false;
		}
		if (
			(columnStatus === PROFILES_STATUS.SELECTED || columnStatus === PROFILES_STATUS.CONTACTED) &&
			profilesByStatus.some(({ hasBeenShared }) => !hasBeenShared)
		) {
			return true;
		}
		return columnStatus === PROFILES_STATUS.VALIDATED;
	};

	const actions = useMemo(() => {
		let allowedActions = availableActions;

		if (columnStatus === PROFILES_STATUS.SELECTED || columnStatus === PROFILES_STATUS.CONTACTED) {
			allowedActions = allowedActions.filter((action) => action.type !== "shareMissionOrder");
		}
		if (columnStatus === PROFILES_STATUS.VALIDATED) {
			allowedActions = allowedActions.filter((action) => action.type !== "shareAction");
		}
		return allowedActions;
	}, [profilesByStatus]);

	return (
		<div
			className={style.head}
			style={{
				backgroundColor: droppableSnapshot?.isDraggingOver
					? "var(--color-primary-100)"
					: "var(--color-neutral-100)",
			}}
		>
			<div>
				<span className={style.label}>{label}</span>
				{profilesByStatus.length > 0 ? (
					<Tag className={style.tag} color="primary">
						{profilesByStatus.length}
					</Tag>
				) : null}
			</div>
			<div>
				{onAddClick ? (
					<div className={style.actions}>
						{columnStatus !== PROFILES_STATUS.RECUSED && (
							<PictoAction
								className={style.add}
								picto="plus"
								pictoSize={{ width: "1.625rem", height: "1.625rem" }}
								onClick={onAddClick}
							/>
						)}
						<Popover
							className={style.popover}
							placement="top"
							clickInside
							content={
								<ul className={style.actionsList}>
									{actions.map((action) => (
										<li key={action.label} className={style.actionsListItem}>
											<button
												className={style.actionsListItemButton}
												type="button"
												onClick={() => {
													action.onClick(columnStatus);
												}}
											>
												{action.label}
											</button>
										</li>
									))}
								</ul>
							}
						>
							{shouldDisplayContextualMenu() && <Picto icon="more" className={style.more} />}
						</Popover>
					</div>
				) : null}
			</div>
		</div>
	);
};

DropColumnHead.propTypes = {
	label: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	droppableSnapshot: PropTypes.object,
	profilesByStatus: PropTypes.arrayOf(PropTypes.shape()),
	onAddClick: PropTypes.func,
	availableActions: PropTypes.arrayOf(PropTypes.shape()),
	columnStatus: PropTypes.string,
	orderStatus: PropTypes.string.isRequired,
};
export default DropColumnHead;
