// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@zolteam/react-ras-library";

// Style
import style from "./PopoverListMaxWidth.module.css";

const PopoverListMaxWidth = ({ click = () => {}, content, children }) => (
	<Tooltip arrow={false} animation={false} placement="bottom" content={content}>
		<button type="button" className={style.button} onClick={click}>
			{children}
		</button>
	</Tooltip>
);

PopoverListMaxWidth.propTypes = {
	click: PropTypes.func,
	children: PropTypes.node.isRequired,
	content: PropTypes.node.isRequired,
};

export default PopoverListMaxWidth;
