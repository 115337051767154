// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import { SelectV2 } from "@zolteam/react-ras-library";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Services
import { searchGouvAddress } from "../../../services/gouvAddressService";

// Utils
import cn from "../../../utils/cn";
import { createGouvAddressOptions } from "../../../utils/gouvAddresses";

// Style
import style from "./GouvAddressSelect.module.css";

const DropdownIndicator = ({ isLoading }) => {
	if (isLoading)
		return (
			<img className={style.loaderIcon} src="/circle-loader.png" width="75" height="auto" alt="circle-loader" />
		);
	return null;
};

DropdownIndicator.propTypes = {
	isLoading: PropTypes.bool.isRequired,
};

export const GouvAddressSelect = ({ name, onAddressSelect, className, addressValue }) => {
	const MASKED_OPTION = "MASKED_OPTION";

	const { t } = useTranslation();

	const getDefaultAutoCompleteOptions = () => {
		if (addressValue?.address && addressValue?.postalCode && addressValue?.city) {
			return [
				{
					label: `${addressValue.address}, ${addressValue.postalCode} ${addressValue.city}, France`,
					value: MASKED_OPTION,
				},
			];
		}
		return [];
	};

	const [autoCompleteOptions, setAutoCompleteOptions] = useState(getDefaultAutoCompleteOptions());
	const [searchLoading, setSearchLoading] = useState(false);
	const [userTyping, setUserTyping] = useState(false);

	useEffect(() => {
		const { address, postalCode, city } = addressValue;
		if (!address || !postalCode || !city) {
			return () => {};
		}
		const formattedAddress = `${address}, ${postalCode} ${city}, France`;
		const newOptions = autoCompleteOptions.slice(0);
		const index = autoCompleteOptions.findIndex((option) => option.value === MASKED_OPTION);

		if (index !== -1) {
			newOptions[index].label = formattedAddress;
		} else {
			newOptions.push({ label: formattedAddress, value: MASKED_OPTION });
		}

		setAutoCompleteOptions(newOptions);
		return () => {};
	}, [addressValue]);

	const handleSelectAddress = (selectedOption) => {
		if (!selectedOption) {
			onAddressSelect({
				address: null,
				postalCode: null,
				city: null,
				latitude: null,
				longitude: null,
				country: "France",
			});
		} else onAddressSelect(selectedOption.value);
		setAutoCompleteOptions([]);
	};

	let timeoutId;
	const SEARCH_LIMIT = 4;
	const MINIMUM_SEARCH_LENGTH = 3;
	const handleInputChange = async (value) => {
		clearTimeout(timeoutId);
		return new Promise((resolve) => {
			timeoutId = setTimeout(async () => {
				if (value?.length >= MINIMUM_SEARCH_LENGTH) {
					try {
						setSearchLoading(true);
						setUserTyping(false);
						const autoCompRes = await searchGouvAddress(value, SEARCH_LIMIT);
						const result = createGouvAddressOptions(autoCompRes.data.features);

						setSearchLoading(false);
						setAutoCompleteOptions(result);
						resolve(result);
					} catch (error) {
						resolve([]);
					}
				} else {
					resolve([]);
				}
			}, 1000);
		});
	};

	// Using SelectV2, too much hassle with SelectAsync 🤷‍♂️
	return (
		<SelectV2
			isSearchable
			isClearable
			className={cn([className, style.gouvAddress])}
			options={autoCompleteOptions}
			placeholder={t("commands.locationForm.addressLine1Placeholder")}
			onChange={handleSelectAddress}
			name={name}
			onMenuOpen={() => {
				setAutoCompleteOptions(getDefaultAutoCompleteOptions());
			}}
			onInputChange={(value) => {
				if (value?.length >= MINIMUM_SEARCH_LENGTH) setUserTyping(true);
				handleInputChange(value);
			}}
			noOptionsMessage={({ inputValue }) => {
				if (searchLoading) {
					return t("components.gouvAddressSelect.loading");
				}
				if (inputValue.length < MINIMUM_SEARCH_LENGTH) {
					return t("components.gouvAddressSelect.minimumSearchLength", { length: MINIMUM_SEARCH_LENGTH });
				}
				if (userTyping) {
					return t("components.gouvAddressSelect.loading");
				}
				return t("components.gouvAddressSelect.noResults");
			}}
			filterOption={(option) => option.value !== MASKED_OPTION}
			value={addressValue?.address ? MASKED_OPTION : null}
			label={t("commands.locationForm.addressLine1Placeholder")}
			components={{
				DropdownIndicator: () => <DropdownIndicator isLoading={searchLoading || userTyping} />,
			}}
		/>
	);
};

GouvAddressSelect.propTypes = {
	name: PropTypes.string.isRequired,
	onAddressSelect: PropTypes.func.isRequired,
	addressValue: PropTypes.shape({
		address: PropTypes.string,
		postalCode: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
	}).isRequired,
	className: PropTypes.string,
};

GouvAddressSelect.defaultProps = {
	className: null,
};

export default GouvAddressSelect;
