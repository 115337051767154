// @ts-nocheck
import client from "./client";

/**
 * @description Get myTemp notifications
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const postNotificationsSearch = (params) => client.post("/api-client-v2/mytemp-notifications/search", params);

/**
 * @description Get myTemp notifications count by status
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const postUnreadNotificationsCount = (params) => client.post("/api-client-v2/mytemp-notifications/count", params);

/**
 * @description Update a myTemp notification status
 * @param {String} myTempNotificationId
 * @param {String} status
 * @returns {Promise<AxiosResponse<any>>}
 */
const patchMyTempNotificationStatus = ({ notificationId, newStatus }) => {
	client.patch(`/api-client-v2/mytemp-notifications/${notificationId}`, {
		status: newStatus,
	});
};

const endpoints = { patchMyTempNotificationStatus, postNotificationsSearch, postUnreadNotificationsCount };

export default endpoints;
