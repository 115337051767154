// @ts-nocheck
/* eslint-disable react/jsx-no-useless-fragment */
import { Button, SelectAsync, SelectV2 } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

// Style
import cn from "../../../../../utils/cn";
import style from "./CommandTypeFormBlock.module.css";

// Constants & misc
import {
	COMMAND_TYPE_FORMATION,
	COMMAND_TYPE_INTERIM,
	COMMAND_TYPE_INVESTMENT,
	COMMAND_TYPE_NEW_ORDER,
	COMMAND_TYPE_REPLACEMENT,
	CREATE_MODE,
	EDIT_MODE,
	ORDER_NATURE,
} from "../../../../../constants";

import {
	commandAppealCaseFormDefaultValues,
	commandClientFormDefaultValues,
	commandInterlocutorFormDefaultValues,
	commandQualificationFormDefaultValues,
	commandWorkAddressFormDefaultValues,
} from "../commandFormDefaultValues";

// Services
import { getAgencies, getCommercialCodes } from "../../../../../services/commandService";

const CommandTypeFormBlock = ({ isOrderFetched }) => {
	// Hooks
	const { values, setValues, setFieldValue } = useFormikContext();
	const { type, agencyOption, commercialCodeOption } = values;
	const { orderId } = useParams();
	const isNewCommand = orderId === CREATE_MODE;
	const commandFormMode = isNewCommand ? CREATE_MODE : EDIT_MODE;
	const { t } = useTranslation();

	const orderNatureOptions = [
		{
			label: t("commands.newOrder"),
			value: COMMAND_TYPE_NEW_ORDER,
			types: [COMMAND_TYPE_INTERIM, COMMAND_TYPE_INVESTMENT],
		},
		{
			label: t("commands.replacement"),
			value: COMMAND_TYPE_REPLACEMENT,
			types: [COMMAND_TYPE_INVESTMENT, COMMAND_TYPE_INTERIM],
		},
		{
			label: t("commands.formation"),
			value: COMMAND_TYPE_FORMATION,
			types: [COMMAND_TYPE_INTERIM],
		},
	];

	/**
	 * @param {string} newType
	 */
	const handleCommandType = (selectedType) => {
		setFieldValue("type", selectedType);
	};

	const handleSelectAgency = (option) => {
		// When new agency is selected should reset associated fields
		setValues({
			...values,
			agencyOption: option || null,
			agencyId: option?.id || null,
			commercialCode: null,
			commercialCodeOption: null,
			// Reset form values that depends on agencyId
			...commandClientFormDefaultValues,
			...commandInterlocutorFormDefaultValues,
			...commandAppealCaseFormDefaultValues,
			...commandQualificationFormDefaultValues,
			...commandWorkAddressFormDefaultValues,
		});
	};
	// API Call
	const agenciesOptionsAsync = (inputValue) =>
		getAgencies(inputValue.length >= 3 ? inputValue : "").then((res) => {
			if (res?.data?.length === 1 && commandFormMode !== EDIT_MODE) {
				handleSelectAgency(res.data[0]);
			}
			if (commandFormMode === EDIT_MODE) {
				const commandAgency = res?.data?.find((agency) => agency.id === values.agencyId);
				if (commandAgency) setFieldValue("agencyOption", commandAgency);
			}

			return res.data;
		});

	const { data: commercialCodes, isFetching: commercialCodesFetching } = useQuery(
		["agency-commercial-codes", values.agencyId],
		async () => {
			const data = await getCommercialCodes(values.agencyId, "");
			return data;
		},
		{
			enabled: !!values.agencyId && agencyOption?.isGeneric,
			placeholderData: [],
		}
	);

	const commercialCodeOptions = useMemo(() => {
		if (!commercialCodes?.data) return [];

		return commercialCodes.data?.map((code) => ({
			label: code.designation,
			value: code.id,
		}));
	}, [commercialCodes]);

	useEffect(() => {
		if (commandFormMode === EDIT_MODE && agencyOption?.isGeneric && commercialCodeOptions?.length > 0) {
			const foundOption = commercialCodeOptions.find((option) => option.value === values.commercialCode);
			setFieldValue("commercialCodeOption", foundOption?.value);
		}
	}, [agencyOption, commandFormMode, commercialCodeOptions]);

	const isOrderInterim = type === COMMAND_TYPE_INTERIM;
	// const isOrderInvestment = type === COMMAND_TYPE_INVESTMENT;

	if (!isOrderFetched && !isNewCommand) {
		return (
			<div className={style.skeleton}>
				<div>
					<Skeleton width={140} height={45} borderRadius={25} />
					<Skeleton width={200} height={45} borderRadius={25} />
				</div>
				<div>
					<Skeleton width={300} height={50} borderRadius={25} />
					<Skeleton width={150} height={50} borderRadius={25} />
				</div>
			</div>
		);
	}

	const setQualificationNoteValueFromNature = ({ value }) => {
		setFieldValue("nature", value);

		if (value !== ORDER_NATURE.REPLACEMENT) return;

		if (values?.qualificationNote?.includes("Remplacement de")) return;

		if (!values?.qualificationNote) {
			setFieldValue("qualificationNote", `Remplacement de \n`);
			return;
		}

		setFieldValue("qualificationNote", `${values.qualificationNote}\nRemplacement de \n`);
	};

	return (
		<>
			<div className={style.topBlock}>
				<div className={style.typeButtons}>
					<div className={style.commandType}>
						<Button
							data-testid={`button_${COMMAND_TYPE_INTERIM}`}
							type="button"
							color={isOrderInterim ? "primary" : "white"}
							onClick={() => handleCommandType(COMMAND_TYPE_INTERIM)}
							className={isOrderInterim ? style.interimBtnActive : style.interimBtn}
						>
							{t("commands.interim")}
						</Button>
						{/* <Button
              data-testid={`button_${COMMAND_TYPE_INVESTMENT}`}
              type="button"
              color={isOrderInvestment ? 'primary' : 'white'}
              onClick={() => handleCommandType(COMMAND_TYPE_INVESTMENT)}
              className={isOrderInvestment ? style.investmentBtnActive : style.investmentBtn}
              disabled
            >
              {t('commands.investment')}
          </Button> */}
					</div>
				</div>
				<div className={style.commandNatureSelect}>
					<SelectV2
						options={orderNatureOptions.filter(({ types }) => types.includes(type))}
						onChange={setQualificationNoteValueFromNature}
						name="nature"
						isClearable={false}
						value={values.nature || null}
						defaultValue={orderNatureOptions[0]}
						noOptionsMessage={() => t("global.noDataText")}
						errorMessage={t("profile.followUp.form.agencyId.error")}
						size="small"
						className={cn([
							commandFormMode === EDIT_MODE ? style.disabled : "",
							style.select,
							style.codeSelect,
						])}
					/>
				</div>
			</div>
			<div className={style.bottomBlock}>
				<div className={style.bottomBlockSelect}>
					{commandFormMode === EDIT_MODE && !agencyOption && (
						<div className={style.select}>
							<Skeleton width={350} height={50} borderRadius={25} />
						</div>
					)}
					<SelectAsync
						key={values.updatedAt}
						isClearable={commandFormMode !== EDIT_MODE}
						className={cn([
							commandFormMode === EDIT_MODE ? style.disabled : "",
							commandFormMode === EDIT_MODE && !agencyOption ? style.hidden : "",
							style.select,
						])}
						cacheOptions
						defaultOptions
						promiseOptions={agenciesOptionsAsync}
						onChange={handleSelectAgency}
						name="agencyOption"
						label={t("commands.agencyField")}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option.id}
						value={agencyOption}
						disabled={commandFormMode === EDIT_MODE}
						noOptionsMessage={() => t("commands.selectNoAgenciesFound")}
						loadingMessage={() => t("commands.selectLoadingAgencies")}
					/>
				</div>

				{/* Commercial Code, displays only if agency selected is generic */}
				<div className={style.bottomBlockSelect}>
					{agencyOption && agencyOption?.isGeneric && (
						<>
							{commercialCodesFetching ? (
								<div className={cn([style.select, style.codeSelect])}>
									<Skeleton width={240} height={50} borderRadius={25} />
								</div>
							) : (
								<SelectV2
									options={commercialCodeOptions}
									onChange={(selected) => {
										setValues({
											...values,
											commercialCode: selected?.value || null,
											commercialCodeOption: selected || null,
										});
									}}
									name="agencyId"
									label={t("commands.commercialCode")}
									placeholder={t("commands.commercialCode")}
									value={commercialCodeOption}
									isClearable={false}
									disabled={commandFormMode === EDIT_MODE}
									noOptionsMessage={() => t("global.noDataText")}
									errorMessage={t("profile.followUp.form.agencyId.error")}
									className={cn([
										commandFormMode === EDIT_MODE ? style.disabled : "",
										style.select,
										style.codeSelect,
									])}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

CommandTypeFormBlock.propTypes = {
	isOrderFetched: PropTypes.bool.isRequired,
};

export default CommandTypeFormBlock;
