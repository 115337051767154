// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Text } from "@zolteam/react-ras-library";
import { useTranslation } from "react-i18next";

// Style
import style from "./SuspiciousSheetFooter.module.css";

const SuspiciousSheetFooter = ({ agencies, children }) => {
	const { t } = useTranslation();
	return (
		<div className={style.footer}>
			<div className={style.container}>
				{agencies.length > 0 ? (
					<div className={style.agencies}>
						<Text tag="span" size="paragraph02">
							{t("suspiciousSheet.relevantAgenciesModifications", {
								count: agencies.length,
							})}
						</Text>
						<div className={style.list}>
							{agencies.map(({ id, name }, index) => (
								<Text key={id} tag="span" size="paragraph02" fontWeight="bold" className="mr-xs">
									{index !== agencies.length - 1 ? `${name},` : `${name}`}
								</Text>
							))}
						</div>
					</div>
				) : null}
			</div>
			<div className={style.submit}>{children}</div>
		</div>
	);
};

SuspiciousSheetFooter.propTypes = {
	agencies: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
		})
	).isRequired,
	children: PropTypes.node.isRequired,
};

export default SuspiciousSheetFooter;
