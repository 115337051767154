// @ts-nocheck
import { Select } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import { capitalize, upperFirst } from "lodash";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

// Components
import GouvAddressSelect from "../../../../../components/atoms/GouvAddressSelect";
import Field from "../../../../../components/molecules/Field";

// Style
import cn from "../../../../../utils/cn";
import style from "./CommandStartFormBlock.module.css";

const FullAddressFormTemplate = ({
	addressName,
	addressComplementName,
	postalCodeName,
	cityName,
	countryName,
	isFormDisabled,
	countriesList,
}) => {
	// Hooks
	const { t } = useTranslation();
	const { values, setFieldValue, setValues, handleChange, handleBlur, errors } = useFormikContext();
	const { workAddress } = values;

	const addressValue = useMemo(
		() => ({
			address: workAddress[addressName.split(".")[1]],
			postalCode: workAddress[postalCodeName.split(".")[1]],
			city: workAddress[cityName.split(".")[1]],
		}),
		[addressName, postalCodeName, cityName, workAddress]
	);

	const defaultAddress = useMemo(
		() => ({
			[addressName.split(".")[1]]: null,
			[postalCodeName.split(".")[1]]: null,
			[cityName.split(".")[1]]: null,
		}),
		[addressName, postalCodeName, cityName, workAddress]
	);

	const selectName = countryName.split(".")[1];
	const handleOnCountryChange = (selectedOption) =>
		// Bit odd logic here but to avoid both countries selects having the same option
		// we need to split the name to get the correct attributes in the form values.
		// As this component is not build to be used outside this part of the form, it should be good
		setValues({
			...values,
			workAddress: {
				...values?.workAddress,
				...defaultAddress,
				[selectName]: capitalize(selectedOption?.name) || null,
			},
			[`workAddress${upperFirst(selectName)}Option`]: selectedOption,
		});
	const capitalizeOnBlur = (e) => {
		if (e.target.value) setFieldValue(e.target.name, upperFirst(e.target.value));
		handleBlur(e);
	};

	const COUNTRY_FRANCE = "France";
	const IS_COUNTRY_FRANCE = useMemo(() => workAddress[selectName] === COUNTRY_FRANCE, [workAddress]);
	const showAutoCompleteLayout = useMemo(() => IS_COUNTRY_FRANCE, [IS_COUNTRY_FRANCE]);

	const handleAutoCompleteAddressSelected = (selectedOption) => {
		const meetingAddress = {
			meetingAddress: selectedOption.address,
			meetingPostalCode: selectedOption.postalCode,
			meetingCity: selectedOption.city,
			meetingCountry: selectedOption.country,
		};
		setValues({
			...values,
			workAddress: {
				...values.workAddress,
				...meetingAddress,
			},
		});
	};

	const renderAddessComplement = useMemo(
		() => (
			<Field
				disabled={isFormDisabled}
				type="text"
				name={addressComplementName}
				id={addressComplementName}
				label={t("commands.locationForm.addressLine2Placeholder")}
				className={style.fieldInput}
				classNameContainer={
					showAutoCompleteLayout
						? style.fieldInputAutoCompleteAddressComplement
						: style.fieldInputFreeAddressComplement
				}
				onChange={handleChange(addressComplementName)}
				onBlur={capitalizeOnBlur}
				customErrorDisplay
			/>
		),
		[style, t, IS_COUNTRY_FRANCE, handleChange, capitalizeOnBlur, isFormDisabled, showAutoCompleteLayout]
	);

	const renderCountrySelect = useMemo(
		() => (
			<Select
				isSearchable
				disabled={isFormDisabled}
				className={cn([
					style.select,
					style.capitalize,
					style.fieldCountry,
					IS_COUNTRY_FRANCE ? style.autoCompleteCountry : style.freeEditCountry,
					isFormDisabled && style.disabledSelect,
				])}
				options={countriesList || []}
				onChange={handleOnCountryChange}
				showIndicator={!isFormDisabled}
				onBlur={handleBlur("workAddress.country")}
				name={countryName}
				label={t("commands.locationForm.countrySelectPlaceholder")}
				getOptionLabel={(option) => option.name}
				getOptionValue={(option) => option.id}
				value={values[`workAddress${upperFirst(countryName.split(".")[1])}Option`]}
				noOptionsMessage={() => t("commands.locationForm.countrySelectNoOptions")}
				loadingMessage={() => t("commands.locationForm.countrySelectLoading")}
			/>
		),
		[
			style,
			t,
			countriesList,
			values,
			handleOnCountryChange,
			handleBlur,
			isFormDisabled,
			IS_COUNTRY_FRANCE,
			countryName,
		]
	);

	const renderAutoCompleteLayout = useMemo(
		() => (
			<>
				<GouvAddressSelect
					name="workAddressAutoComplete"
					onAddressSelect={handleAutoCompleteAddressSelected}
					className={style.fieldAutoCompleteInputAddress}
					addressValue={addressValue}
				/>
				{renderAddessComplement}
				{renderCountrySelect}
			</>
		),
		[renderAddessComplement, renderCountrySelect, style, t, handleChange, capitalizeOnBlur, isFormDisabled]
	);

	const renderForeignAddressLayout = useMemo(
		() => (
			<>
				<Field
					disabled={isFormDisabled}
					type="text"
					name={addressName}
					id={addressName}
					label={t("commands.locationForm.addressLine1Placeholder")}
					className={style.fieldInput}
					classNameContainer={style.fieldFreeInputAddress}
					onChange={handleChange(addressName)}
					onBlur={capitalizeOnBlur}
					customErrorDisplay
				/>
				{renderAddessComplement}
				<Field
					disabled={isFormDisabled}
					type="text"
					name={postalCodeName}
					id={postalCodeName}
					label={t("commands.locationForm.zipCodePlaceholder")}
					className={cn([style.fieldInput])}
					classNameContainer={cn([
						style.fieldZip,
						style.fieldInputpostalCode,
						errors?.workAddress?.meetingPostalCode ? style.fieldZipError : "",
					])}
					onChange={handleChange(postalCodeName)}
					onBlur={handleBlur(postalCodeName)}
					customErrorDisplay
				/>
				<Field
					disabled={isFormDisabled}
					type="text"
					name={cityName}
					id={cityName}
					label={t("commands.locationForm.cityPlaceholder")}
					className={cn([style.capitalize, style.fieldInput])}
					classNameContainer={cn([
						style.fieldInputCity,
						errors?.workAddress?.meetingPostalCode ? style.fieldZipError : "",
					])}
					onChange={handleChange(cityName)}
					onBlur={capitalizeOnBlur}
					customErrorDisplay
				/>
				{renderCountrySelect}
			</>
		),
		[
			style,
			errors,
			isFormDisabled,
			values,
			t,
			handleChange,
			handleBlur,
			capitalizeOnBlur,
			handleOnCountryChange,
			renderAddessComplement,
			renderCountrySelect,
		]
	);

	return (
		<div className={style.addressGrid}>
			{showAutoCompleteLayout ? renderAutoCompleteLayout : renderForeignAddressLayout}
		</div>
	);
};

FullAddressFormTemplate.propTypes = {
	addressName: PropTypes.string.isRequired,
	addressComplementName: PropTypes.string.isRequired,
	postalCodeName: PropTypes.string.isRequired,
	cityName: PropTypes.string.isRequired,
	countryName: PropTypes.string.isRequired,
	isFormDisabled: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	countriesList: PropTypes.array,
};

FullAddressFormTemplate.defaultProps = {
	isFormDisabled: false,
	countriesList: [],
};

export default FullAddressFormTemplate;
