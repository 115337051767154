// @ts-nocheck
import { Picto } from "@zolteam/react-ras-library";
import React from "react";
import style from "./CheckPicto.module.css";

interface ICheckPictoProps {
	customClass?: string;
}

const CheckPicto: React.FC<ICheckPictoProps> = ({ customClass = "" }) => (
	<div className={`${style.pictoContainer} ${customClass}`}>
		<Picto icon="validate" className={style.picto} />
	</div>
);

export default CheckPicto;
