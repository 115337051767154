// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Text } from "@zolteam/react-ras-library";

// Style
import style from "./TableInfo.module.css";

const TableInfo = ({ info, subInfo = "" }) => (
	<div className={style.container}>
		<Text tag="div" fontWeight="light" lineHeight="m" size="paragraph02">
			{info}
		</Text>
		{subInfo ? (
			<Text tag="div" color="grey" fontWeight="light" lineHeight="m" size="paragraph03">
				{subInfo}
			</Text>
		) : null}
	</div>
);

TableInfo.propTypes = {
	info: PropTypes.string.isRequired,
	subInfo: PropTypes.string,
};

export default TableInfo;
