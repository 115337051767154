// @ts-nocheck
import React from "react";

// Templates
import WithSideMenu from "../components/templates/WithSideMenu";
import WithTopBarre from "../components/templates/WithTopBarre";

const Home = () => (
	<WithSideMenu>
		<WithTopBarre quickEntryContent={<div>test</div>}>
			<div>Bienvenue sur myDaily !</div>
		</WithTopBarre>
	</WithSideMenu>
);

export default Home;
