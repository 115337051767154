// @ts-nocheck
import { Button, Title } from "@zolteam/react-ras-library";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cn from "../../../../../utils/cn";

// style
import style from "./ModalContentCreateUser.module.css";

const ModalContentcreateUserModal = ({ onConfirmClick, onCancelClick, agencyName }) => {
	// Hooks
	const { t } = useTranslation();

	return (
		<div className={style.content}>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("matching.createUserModal.title")}
			</Title>
			<div className={style.textContent}>
				<p className={style.message}>{t("matching.createUserModal.infoMessage", { agencyName })}</p>
			</div>
			<div className={style.textContent}>
				<p className={style.message}>{t("matching.createUserModal.confirmMessage")}</p>
			</div>
			<hr className={style.separator} />
			<div className={cn([style.buttonsBlock])}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("matching.createUserModal.cancelButtonMessage")}
				</Button>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={() => onConfirmClick()}
				>
					{t("matching.createUserModal.validationButtonMessage")}
				</Button>
			</div>
		</div>
	);
};

ModalContentcreateUserModal.propTypes = {
	agencyName: PropTypes.string,
	onConfirmClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
};

ModalContentcreateUserModal.defaultProps = {
	agencyName: "",
};

export default ModalContentcreateUserModal;
