// @ts-nocheck
/* eslint-disable max-len */
import client from "./client";

const PREFIX = "/api-client-v2/mission-follow-up";

export const searchMissionFollowUp = (unifiedTempWorkerId, data) =>
	client.post(`/api-client-v2/unified-temp-worker/${unifiedTempWorkerId}/mission-follow-up/search`, data);

/**
 * @description Get list of agencies for a given temp worker
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMissionFollowUpWorkerContracts = (workerId, clientId) =>
	client.get(`${PREFIX}/unified-temp-worker/${workerId}/client/${clientId}/contracts`);

/**
 * @description Get list of agencies for a given temp worker
 * @returns {Promise<AxiosResponse<any>>}
 */

export const getMissionFollowUpClientQualifications = (clientId, search) =>
	client.get(`${PREFIX}/client/${clientId}/qualifications`, {
		params: { search },
	});

export const getMissionFollowUpIncidentTypes = () => client.get(`${PREFIX}/incident-types`);

export const registerMissionFollowUp = async (unifiedTempWorkerId, data) => {
	const formData = new FormData();

	Object.entries(data).forEach(([key, value]) => {
		if (value === null || value === undefined) return;
		if (key === "attachment" && value?.name) {
			formData.append(key, value, value.name);
		} else {
			// Append other form data fields
			formData.append(key, value);
		}
	});

	const response = await client.post(
		`/api-client-v2/unified-temp-worker/${unifiedTempWorkerId}/mission-follow-up`,
		formData,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);

	return response;
};

/**
 * @description Update an mission follow up
 * @param {String} unifiedTempWorkerId
 * @param {String} missionFollowUpId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMissionFollowUp = (unifiedTempWorkerId, missionFollowUpId) =>
	client.get(`/api-client-v2/unified-temp-worker/${unifiedTempWorkerId}/mission-follow-up/${missionFollowUpId}`);

/**
 * @description Get unifiedTempWorker informations
 * @param {String} unifiedTempWorkerId
 * @param {String} missionFollowUpId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUnifiedTempWorker = (unifiedTempWorkerId) =>
	client.get(`/api-client-v2/unified-temp-worker/${unifiedTempWorkerId}`);

/**
 * @description Get client relation for an unifiedTempWorker unifiedTempWorker
 * @param {String} unifiedTempWorkerId
 * @param {String} clientId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getClientRelation = (unifiedTempWorkerId, clientId) =>
	client.get(`/api-client-v2/unified-temp-worker/${unifiedTempWorkerId}/client/${clientId}/client-relation`);

export const putMissionFollowUp = async (unifiedTempWorkerId, missionFollowUpId, missionFollowUpDetails) => {
	const formData = new FormData();

	Object.entries(missionFollowUpDetails).forEach(([key, value]) => {
		if (value === null || value === undefined) return;
		if (key === "attachment" && value?.name) {
			formData.append(key, value, value.name);
		} else {
			// Append other form data fields
			formData.append(key, value);
		}
	});

	// we are using "post" instead of "put" because Symfony does not support sending form data in "put" requests.
	const response = await client.post(
		`/api-client-v2/unified-temp-worker/${unifiedTempWorkerId}/mission-follow-up/${missionFollowUpId}`,
		formData,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);

	return response;
};
