// @ts-nocheck
import React, { useContext, useReducer } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";

// Molecules
import Toast from "../components/molecules/Toast";

// Style
import "react-toastify/dist/ReactToastify.css";

// Reducers
const toastReducer = (state, { type, payload: { icon, msg, params } }) => {
	switch (type) {
		case "SUCCESS": {
			toast.success(<Toast icon={icon} msg={msg} />, params);
			break;
		}
		case "ERROR": {
			toast.error(<Toast icon={icon} msg={msg} />, params);
			break;
		}
		case "INFO": {
			toast.info(<Toast icon={icon} msg={msg} />, params);
			break;
		}
		case "WARN": {
			toast.warn(<Toast icon={icon} msg={msg} />, params);
			break;
		}
		default: {
			throw new Error(`Unhandled action type: ${type}`);
		}
	}
};

const ToastStateContext = React.createContext(undefined);
const ToastDispatchContext = React.createContext(undefined);

const ToastProvider = ({ children }) => {
	const [state, dispatch] = useReducer(toastReducer, null);

	return (
		<ToastStateContext.Provider value={state}>
			<ToastDispatchContext.Provider value={dispatch}>
				{children}
				<ToastContainer hideProgressBar pauseOnHover={false} closeOnClick limit={3} />
			</ToastDispatchContext.Provider>
		</ToastStateContext.Provider>
	);
};

ToastProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

const useToastContext = () => [useContext(ToastStateContext), useContext(ToastDispatchContext)];

export { ToastProvider, ToastStateContext, ToastDispatchContext, useToastContext };
