// @ts-nocheck
import * as Yup from "yup";
import i18n from "../../../../../../i18n";

export default Yup.object().shape({
	followUpType: Yup.string().oneOf(["assessment", "incident"]),
	agencyId: Yup.number(),
	clientId: Yup.number().required(i18n.t("profile.followUp.form.client.error")),
	contractId: Yup.number().nullable(true),
	startDate: Yup.date().nullable().required(i18n.t("commands.error.mandatoryStartDateField")),
	endDate: Yup.date().nullable().required(i18n.t("commands.error.mandatoryEndDateField")),
	clientQualificationId: Yup.string().nullable(true),
	note: Yup.number().min(0).max(4).nullable(true),
	reporting: Yup.string("").max(1000, "").nullable(true),
	adequacy: Yup.string().nullable(true),
	author: Yup.string().max(17, i18n.t("profile.followUp.form.author.lengthError")).nullable(true),
	attachment: Yup.mixed().nullable(true),
	incidentTypeId: Yup.number().when("followUpType", {
		is: (val) => val === "incident",
		then: Yup.number().required(i18n.t("profile.followUp.form.incidentTypeId.error")),
		otherwise: Yup.number().nullable(true),
	}),
	incidentDate: Yup.string()
		.nullable(true)
		.when("followUpType", {
			is: (val) => val === "incident",
			then: Yup.string().required(i18n.t("profile.followUp.form.incidentDate.error")),
			otherwise: Yup.string().nullable(true),
		}),
});
