// @ts-nocheck
import { Select, Textarea } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ClientQualification, ClientInfo } from "../../../../../services/types/commandServiceTypes";

// Services
import { getClientQualifications, getClients } from "../../../../../services/commandService";

// Components
import OptionClient from "../../../../../components/atoms/OptionClient";
import OptionQualificationTempo from "../../../../../components/atoms/OptionQualificationTempo";
import TooltipMessage from "../../../../../components/atoms/TooltipMessage";

// Hooks
import { useConfigurationContext } from "../../../../../store/ConfigurationContext";

// Style
import cn from "../../../../../utils/cn";
import style from "./CommandClientFormBlock.module.css";

// Constants
import { CREATE_MODE, EDIT_MODE, COMMAND_TYPE_INTERIM } from "../../../../../constants";

// Misc
import {
	commandInterlocutorFormDefaultValues,
	commandQualificationFormDefaultValues,
	commandWorkAddressFormDefaultValues,
} from "../commandFormDefaultValues";

const CommandClientFormBlock = () => {
	// Hooks
	const { values, setValues, setFieldValue, handleBlur, errors, touched } = useFormikContext();
	const { agencyId, clientId, clientOption, qualification, type } = values;
	const [, dispatchConfiguration] = useConfigurationContext();
	const { t } = useTranslation();
	const { orderId } = useParams<{ orderId: string }>();
	const isNewCommand = orderId === CREATE_MODE;
	const commandFormMode = isNewCommand ? CREATE_MODE : EDIT_MODE;
	const customClientWorkingHour = clientOption?.customWorkingHour || null;

	// API Call

	const { data: clients, isLoading: isClientLoading } = useQuery(
		["getClients", agencyId],
		() => getClients(agencyId, ""),
		{
			onSuccess: (data: ClientInfo[]) => {
				if (commandFormMode === EDIT_MODE) {
					const selectedClient: ClientInfo = data?.find((client: ClientInfo) => client.id === clientId);
					setFieldValue("clientOption", selectedClient);
				}
			},
			enabled: !!agencyId,
			select: ({ data }) => data,
		}
	);

	const { data: clientQualifications } = useQuery(
		["getClientQualifications", agencyId, clientId],
		() => getClientQualifications(agencyId, clientId, ""),
		{
			select: ({ data }) =>
				data.map((qualification: ClientQualification) => ({
					...qualification,
					id: qualification.clientQualificationId,
				})),
			enabled: !!(agencyId && clientId),
		}
	);

	const defaultNumberWorkersOptions = Array.from({ length: 500 }, (_, i) => ({
		value: i + 1,
		label: `${i + 1}`,
	}));

	const qualificationOptionsTemplate = (option: ClientQualification) => (
		<OptionQualificationTempo
			key={option.clientQualificationId}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...option}
			id={option?.clientQualificationId?.toString()}
		/>
	);

	const handleCustomWorkingHour = (selectedOption: ClientQualification) => {
		if (selectedOption?.specificTime) {
			return selectedOption?.specificTime;
		}
		if (customClientWorkingHour) {
			return customClientWorkingHour;
		}
		return null;
	};

	const handleSelectQualification = (selectedOption: ClientQualification) => {
		const missionDescription = isNewCommand ? selectedOption?.missionDescription : values.missionDescription;
		const particularWorkTime = isNewCommand ? handleCustomWorkingHour(selectedOption) : values.particularWorkTime;
		setValues({
			...values,
			qualificationOption: selectedOption || null,
			qualification: {
				...values.qualification,
				id: selectedOption?.clientQualificationId || "",
				name: selectedOption?.name || null,
				level: selectedOption?.level || null,
				deletedAt: null,
			},
			missionDescription,
			workingTime: {
				...values.workingTime,
				particularWorkTime,
			},
		});
	};

	const handleSelectClient = (selectedClient: ClientInfo) => {
		const periodNotWorking = isNewCommand ? selectedClient?.notWorkedPeriod : values.periodNotWorking;

		dispatchConfiguration({
			type: "SET_IS_CLIENT_EDITED",
			payload: true,
		});
		setValues({
			...values,
			clientOption: selectedClient || null,
			clientId: selectedClient?.id || null,
			// Reset form fields depending on clientId
			...commandInterlocutorFormDefaultValues,
			...commandQualificationFormDefaultValues,
			...commandWorkAddressFormDefaultValues,
			workingTime: {
				...values.workingTime,
				periodNotWorking,
			},
		});
	};

	const handleSelectQualificationNumberWorker = (selectedNumber: { value: number; label: string }) => {
		setFieldValue("qualification.workerRequestedCount", selectedNumber.value);
	};

	if ((!isNewCommand && isClientLoading) || (!isNewCommand && !agencyId)) {
		return (
			<div className={style.skeleton}>
				<Skeleton height={50} width={400} borderRadius={25} />
				<div className={style.skeletonQualificationBox}>
					<Skeleton height={50} width={400} borderRadius={25} />
					<Skeleton height={50} width={90} borderRadius={25} />
				</div>
				<Skeleton height={250} borderRadius={25} />
			</div>
		);
	}

	return (
		<div className={style.content}>
			<div className={style.selectBox}>
				{
					<div className={style.clientBox}>
						{!isClientLoading ? (
							<Select
								isClearable
								options={clients || []}
								onChange={handleSelectClient}
								name="clientOption.id"
								label={t("commands.client")}
								getOptionLabel={(option: ClientInfo) => option.name}
								getOptionValue={(option: ClientInfo) => option.id}
								formatOptionLabel={(option: ClientInfo) => (
									<OptionClient
										key={option.tempoId}
										tempoId={option.tempoId}
										name={option.name}
										city={option.city}
									/>
								)}
								value={clientOption}
								noOptionsMessage={() => t("commands.selectNoClientsFound")}
								onBlur={handleBlur("clientOption.id")}
							/>
						) : (
							<Skeleton height={50} width={400} borderRadius={25} />
						)}
					</div>
				}
				<div className={style.qualificationBox}>
					{clientId && (
						<>
							<div className={style.qualificationSelect}>
								<Select
									key={values.clientId}
									name="qualification.id"
									label={`${t("commands.qualification")} ${type === COMMAND_TYPE_INTERIM ? "*" : ""}`}
									getOptionLabel={(option: ClientQualification) => option.name}
									getOptionValue={(option: ClientQualification) => option.clientQualificationId}
									formatOptionLabel={(option: ClientQualification) =>
										qualificationOptionsTemplate(option)
									}
									options={clientQualifications}
									value={
										qualification?.deletedAt
											? qualification
											: clientQualifications?.find(
													(option: ClientQualification) =>
														option.clientQualificationId === qualification?.id
												)
									}
									noOptionsMessage={() => t("commands.selectNoQualificationFound")}
									loadingMessage={() => t("commands.selectLoadingQualification")}
									isClearable
									className={cn([style.select, qualification?.deletedAt ? "warn warnSelect" : ""])}
									onChange={handleSelectQualification}
									onBlur={handleBlur("qualification.id")}
								/>
								{errors?.qualification?.id && !!touched.qualification && (
									<TooltipMessage className={style.centerTooltip} color="error">
										{errors?.qualification?.id}
									</TooltipMessage>
								)}
								{qualification?.deletedAt && (
									<TooltipMessage className={style.centerTooltip} color="warning">
										{t("commands.deletedQualificationMessage")}
									</TooltipMessage>
								)}
							</div>
							<Select
								isSearchable
								className={style.selectNumber}
								options={defaultNumberWorkersOptions}
								onChange={handleSelectQualificationNumberWorker}
								name="qualification.workerRequestedCount"
								value={defaultNumberWorkersOptions[(qualification?.workerRequestedCount ?? 0) - 1]}
							/>
						</>
					)}
				</div>
			</div>
			{values.qualification?.id && (
				<Textarea
					className={style.textarea}
					maxLength={800}
					name="missionDescription"
					label={t("commands.notes")}
					shouldDisplayErrorMessage={false}
				/>
			)}
		</div>
	);
};

export default CommandClientFormBlock;
