// @ts-nocheck
import client from "./client";

/**
 * @description Get advance payments
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const postUsersListingSearch = (params) => client.post("/api-client-v2/users/search", params);

const endpoints = { postUsersListingSearch };
export default endpoints;
