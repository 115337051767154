import { PaginationV2, Picto, SelectPopover, Text, Title } from "@zolteam/react-ras-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useTitle } from "react-use";
import { InputSearch } from "src/components/molecules/InputSearch/InputSearch";
import useSelectable from "src/hooks/useSelectable";
import { TAction, TFilters } from "src/types";
import PaginationSearch from "../../../components/molecules/PaginationSearch";
import TableListing from "../../../components/organisms/TableListing";
import TableFilters from "../../../components/templates/TableFilters";
import WithSideMenu from "../../../components/templates/WithSideMenu";
import WithSideTableFiltersPanel from "../../../components/templates/WithSideTableFiltersPanel";
import WithTopBarre from "../../../components/templates/WithTopBarre";
import { DEFAULT_USERS_LISTING_OPTIONS, PAGINATION_OFFSET_OPTIONS } from "../../../constants";
import usersListingService from "../../../services/usersListingService";
import { definePageOffset } from "../../../utils/paginationUtils";
import style from "./UsersListing.module.css";
import UsersListColumns from "./UsersListingColumns";

const UsersListing = () => {
	// Hooks
	const { t } = useTranslation();
	useTitle(t("usersListing.pageTitle"));
	const { selectedItems, toggleSelected, selectAll, selectedValuesStatus } = useSelectable({});

	// states
	const [filters, setFilters] = useState<TFilters>(DEFAULT_USERS_LISTING_OPTIONS);

	const postUsersListingSearchQuery = useQuery({
		queryKey: ["postUsersListingSearch", filters],
		queryFn: () =>
			usersListingService.postUsersListingSearch({
				...filters,
			}),
	});

	const {
		isError: isErrorUsersListingSearch,
		isLoading: isLoadingUsersListingSearch,
		data: users,
	} = postUsersListingSearchQuery;

	const getPageMaxNumber = () => Math.round((users?.data?.total ?? 0) / filters.limit);

	const getStartPagination = filters.limit * filters.page - filters.limit + 1;

	const getEndPagination =
		filters.limit * filters.page <= users?.data?.total ? filters.limit * filters.page : users?.data?.total;

	const handleUpdateFilters = (params: TFilters) => {
		setFilters((prev) => ({
			...prev,
			...params,
		}));
	};

	const actions: TAction[] = [];

	return (
		<WithSideMenu>
			<WithSideTableFiltersPanel>
				<WithTopBarre
					quickEntryContent={<div>test</div>}
					title={
						<Title tag="h1" size="heading01" lineHeight="s">
							{t("usersListing.pageTitle")}
						</Title>
					}
					containerClassname="container"
				>
					<TableFilters
						rightContent={
							<div className={style.filterField}>
								<div className={style.searchTextFilterWidth}>
									<InputSearch
										label={t("global.filters.searchText")}
										onChange={(value) => {
											handleUpdateFilters({
												page: 1,
												query: {
													...(value && { searchText: value }),
												},
											});
										}}
										minLength={0}
										debounced
										name="search"
										value={filters?.query?.searchText || ""}
										className="min-w-[300px] w-full sm:w-fit "
									/>
								</div>
							</div>
						}
					/>
					<TableListing
						loading={isLoadingUsersListingSearch}
						error={isErrorUsersListingSearch}
						data={users?.data?.items}
						handleSearch={handleUpdateFilters}
						initialColumnSort={{
							field: filters?.sorting,
							orderBy: filters?.orderBy,
						}}
						canBeChecked={true}
						selectedItems={selectedItems}
						toggleSelected={toggleSelected}
						selectAll={selectAll}
						actions={actions}
						selectedValuesStatus={selectedValuesStatus}
						showCustomMessage={false}
						genericEmptyCellsMessage={<div className="text-neutral-300">-</div>}
						footer={
							users?.data?.total ? (
								<>
									<PaginationV2
										previousLabel={<Picto icon="chevronLeft" />}
										nextLabel={<Picto icon="chevronRight" />}
										breakLine={
											<PaginationSearch
												onInputChange={handleUpdateFilters}
												max={getPageMaxNumber()}
											/>
										}
										currentPage={filters.page}
										totalCount={users?.data.total}
										pageSize={filters.limit}
										siblingCount={2}
										onPageChange={(activePage: number) => handleUpdateFilters({ page: activePage })}
									/>
									<Text tag="span" size="paragraph02" color="grey">
										{t("global.pagination.resultsPagination", {
											resultStart: getStartPagination,
											resultEnd: getEndPagination,
											resultTotal: users?.data?.total,
										})}
									</Text>
									<SelectPopover
										name="limit"
										options={PAGINATION_OFFSET_OPTIONS}
										onChange={(value: number) =>
											handleUpdateFilters({
												limit: value,
												page: definePageOffset(value, filters.limit, filters.page),
											})
										}
										value={
											filters.limit
												? PAGINATION_OFFSET_OPTIONS.find(
														(option) => option.value === filters.limit
													)
												: null
										}
									/>
								</>
							) : null
						}
					>
						{UsersListColumns()}
					</TableListing>
				</WithTopBarre>
			</WithSideTableFiltersPanel>
		</WithSideMenu>
	);
};

export default UsersListing;
