// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

// Style
import style from "./OptionAppealCase.module.css";

const OptionAppealCase = ({ tempoId = "", designation = "" }) => (
	<div className={`${style.optionContainer}`} data-testid={`option-${tempoId}-${designation}`}>
		<span className={style.optionTempoId}>{`${tempoId}`}</span>
		<span className={style.optionName}>{`${designation}`}</span>
	</div>
);

OptionAppealCase.propTypes = {
	tempoId: PropTypes.string,
	designation: PropTypes.string,
};

export default OptionAppealCase;
