// @ts-nocheck
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { useLocation, useHistory } from "react-router-dom";
import _ from "lodash";
import matchingFormDefaultValues from "../views/Command/Matching/matchingFormDefaultValues";
import {
	booleanMatchingFilters,
	singleMatchingFilters,
	arrayMatchingFilters,
	objectMatchingFilters,
} from "../views/Command/Matching/matchingFiltersEnum";

// Utils
import getLocalFilters from "../utils/matching/getLocalFilters";

// eslint-disable-next-line import/no-cycle
import { useMatchingContext } from "../store/MatchingContext";

export const getValueForFilter = (filterType, value) => {
	switch (filterType) {
		case "singleMatchingFilters": {
			if (typeof value !== "string") throw new Error("Incorrect value type");
			return value;
		}
		case "booleanMatchingFilters": {
			return value === "true";
		}
		case "arrayMatchingFilters": {
			if (!Array.isArray(value)) throw new Error("Incorrect value type");
			if (value.length === 0) return [];
			return value.map((element) => {
				try {
					return JSON.parse(element);
				} catch (error) {
					if (element === "true" || element === "false") {
						return element === "true";
					}
					return element;
				}
			});
		}
		case "objectMatchingFilters": {
			return JSON.parse(value);
		}
		default: {
			throw new Error("Unknown filter type");
		}
	}
};

const useFilterState = (orderId) => {
	const localStorageKey = `MatchingFilters_${orderId}`;

	const location = useLocation();
	const history = useHistory();

	const [, dispatch] = useMatchingContext();

	let searchParams = new URLSearchParams(location.search);

	const getParamsFromUrl = () => {
		const filtersFromURL = {};

		singleMatchingFilters.forEach((elem) => {
			const paramValue = searchParams.get(elem);
			if (paramValue !== null) filtersFromURL[elem] = getValueForFilter("singleMatchingFilters", paramValue);
		});

		booleanMatchingFilters.forEach((elem) => {
			const paramValue = searchParams.get(elem);
			if (paramValue !== null) filtersFromURL[elem] = getValueForFilter("booleanMatchingFilters", paramValue);
		});

		arrayMatchingFilters.forEach((elem) => {
			const paramValues = searchParams.getAll(`${elem}[]`);
			if (paramValues.length > 0) filtersFromURL[elem] = getValueForFilter("arrayMatchingFilters", paramValues);
		});

		objectMatchingFilters.forEach((elem) => {
			const paramValues = searchParams.getAll(`${elem}{}`);
			if (paramValues.length > 0) filtersFromURL[elem] = getValueForFilter("objectMatchingFilters", paramValues);
		});

		return filtersFromURL;
	};

	const setFiltersInURL = (newFilters) => {
		searchParams = new URLSearchParams();
		Object.keys(newFilters).forEach((key) => {
			const value = newFilters[key];

			// Specific case for agency
			if (key === "agency" && value === null) {
				return;
			}
			if (Array.isArray(value) && value.length > 0) {
				value.map((element) => {
					const valueToSet = typeof element === "object" ? JSON.stringify(element) : element;
					searchParams.append(`${key}[]`, valueToSet);
					return null;
				});
			}
			if (typeof value === "object" && !Array.isArray(value) && value !== null) {
				const objectValue = JSON.stringify(value);
				searchParams.set(`${key}{}`, objectValue);
			}
			if (typeof value === "boolean") {
				searchParams.set(key, value ? "true" : "false");
			}
			if (typeof value === "number" || typeof value === "string" || value === null) {
				searchParams.set(key, value);
			}
		});

		history.push({ search: searchParams.toString() });
	};

	const updateLocalStorage = (newFilters) => {
		localStorage.setItem(localStorageKey, JSON.stringify(newFilters));
	};

	const mergeObjects = (queryParams, localStorageParams) => {
		// Create a new object to store the merged values
		const mergedObject = {};

		// Iterate through the keys in queryParams
		// eslint-disable-next-line guard-for-in, no-restricted-syntax
		for (const key in queryParams) {
			const queryValue = queryParams[key];

			// Check the type of the value
			if (
				(typeof queryValue === "boolean" && queryValue) ||
				(typeof queryValue === "string" && queryValue.length > 0) ||
				(Array.isArray(queryValue) && queryValue.length > 0)
			) {
				// If the condition is met, use the value from queryParams
				mergedObject[key] = queryValue;
				// eslint-disable-next-line no-prototype-builtins
			} else if (localStorageParams.hasOwnProperty(key)) {
				// If the key is not set in queryParams, use the value from localStorageParams
				mergedObject[key] = localStorageParams[key];
			}
		}

		// Check for keys in localStorageParams that are not in queryParams
		for (const key in localStorageParams) {
			if (!queryParams.hasOwnProperty(key)) {
				mergedObject[key] = localStorageParams[key];
			}
		}

		return mergedObject;
	};

	const getInitialFilters = (target = "query") => {
		const localStorageFilters = JSON.parse(localStorage.getItem(localStorageKey) || "{}") || {};
		const urlFilters = getParamsFromUrl() || {};
		const mergedFilters = mergeObjects(urlFilters, localStorageFilters);

		const filtersResult = getLocalFilters({ ...matchingFormDefaultValues, ...mergedFilters }, target);

		return filtersResult;
	};

	/**
	 *
	 * @param {*} newFilters modal filter keys
	 * @param {*} otherFilters Filter keys other than modal filters
	 */
	const updateFilters = (newFilters, currentFilters, config) => {
		const { needConversion = true, wideLoadingMessage } = config;
		const newFiltersValue = needConversion ? getLocalFilters(newFilters) : newFilters;

		dispatch({
			type: "SET_WIDE_LOADING",
			payload: {
				isDisplayed: true,
				message: wideLoadingMessage,
			},
		});

		if (!_.isEqual(newFiltersValue, currentFilters) && newFiltersValue?.qualifications?.length > 0) {
			dispatch({
				type: "SET_FILTERS",
				payload: newFiltersValue,
			});
			updateLocalStorage(newFilters);
			setFiltersInURL(newFilters);
		}
	};

	/**
	 *
	 * @param {*} agency agency object { id, agencyId }
	 */
	const updateAgencyFilter = (agency) => {
		dispatch({
			type: "SET_WIDE_LOADING",
			payload: {
				isDisplayed: true,
				message: "Changement d'agence en cours...",
			},
		});
		dispatch({
			type: "SET_SINGLE_FILTER",
			payload: {
				key: "agency",
				value: agency.id,
			},
		});
		dispatch({
			type: "SET_UNIFIED_TEMP_WORKER_ID",
			payload: null,
		});
		dispatch({
			type: "SET_ORDER_AGENCY_ID",
			payload: agency.agencyId,
		});

		const currentLocalStorage = JSON.parse(localStorage.getItem(localStorageKey));
		const newFilters = {
			...currentLocalStorage,
			agency: agency.id,
		};
		updateLocalStorage(newFilters);
		setFiltersInURL(newFilters);
	};

	return { updateFilters, updateAgencyFilter, getInitialFilters };
};

export default useFilterState;
