// @ts-nocheck
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Formik, Form, useFormikContext } from "formik";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams, useHistory } from "react-router-dom";
import { Button, SelectV2, ToggleSwitch, InputControlled, Tooltip } from "@zolteam/react-ras-library";

// Services
import { getWorkerRelationClients } from "../../../../../../services/clientRelationService";

// Utils
import { getAgencyListing } from "../../../../../../utils/matching/formatAgencyListing";
import { capitalizeWords } from "../../../../../../utils/string";

import {
	createClientRelationship,
	updateClientRelationship,
	getClientRelationship,
	getWorkerAgencies,
} from "../../../../../../services/unifiedTempWorkerService";

// Hooks
import { useToastContext } from "../../../../../../store/ToastContext";

// Constants
import { CREATE_MODE, EDIT_MODE } from "../../../../../../constants";
import clientRelationshipFormDefaultValues from "./clientRelationshipFormDefaultValues";
import clientRelationshipFormValidation from "./clientRelationshipFormValidation";

// Utils
import cn from "../../../../../../utils/cn";

import style from "./ProfileMissionsClientRelationshipForm.module.css";

const FormSkeleton = () => (
	<div className={style.wrap}>
		<Skeleton width={150} height={20} />
		<br />
		<Skeleton width={346} height={56} borderRadius={25} />
		<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
		<br />
		<br />
		<Skeleton width={150} height={20} />
		<br />
		<Skeleton width={346} height={56} borderRadius={25} />
		<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
		<br />
		<br />
		<Skeleton width={150} height={20} />
		<br />
		<Skeleton width={346} height={56} borderRadius={25} />
		<br />
		<br />
		<Skeleton width={150} height={20} />
		<br />
		<Skeleton width={200} height={60} borderRadius={25} />
		<br />
		<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
	</div>
);

const FormSection = ({ title, children }) => (
	<div className={style.formSection}>
		<div className={style.formSectionTitle}>{title}</div>
		{children}
	</div>
);
FormSection.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

const renderClientOption = (option) => (
	<div className={cn([option.hasClientRelation ? style.clientLabelDisabled : null])}>
		<span className={style.clientLabelCode}>{option.clientCode}</span>
		<span> - {option.name}</span>
	</div>
);

const FollowUpFormContent = ({ mode }) => {
	const { t } = useTranslation();
	const { profileId, relationshipId } = useParams();
	const history = useHistory();
	const [, toastDispatch] = useToastContext();
	const { values, errors, setFieldValue, setValues, isValid, dirty, touched, handleBlur } = useFormikContext();

	const isEditionMode = mode === EDIT_MODE;

	// Fetch list of agencies
	const { data: agencies } = useQuery(["follow-up-agencies", profileId], () => getWorkerAgencies(profileId));

	// Fetch list of clients from an agency
	const { data: clients, isFetched: clientsFetched } = useQuery(
		["follow-up-clients", values.agencyId, profileId],
		async () => {
			const data = await getWorkerRelationClients(values.agencyId, profileId, "");
			return data;
		},
		{
			enabled: !!values.agencyId && !!profileId,
		}
	);

	const agenciesOptions = useMemo(() => {
		if (!agencies?.data) return [];

		return getAgencyListing(agencies.data, "name").map((agency) => ({
			label: agency.name,
			value: agency.id,
		}));
	}, [agencies]);

	const clientsOptions = useMemo(() => {
		if (!clients?.data) return [];

		return clients.data.map((client) => ({
			label: `${client.clientCode} - ${client.name}`,
			value: client.id,
			...client,
		}));
	}, [clients]);

	// In case of create mode, if only one agency is available, select it by default
	const isOnlyOneAgencyAvailable = agenciesOptions.length === 1 && mode !== EDIT_MODE;
	useEffect(() => {
		if (isOnlyOneAgencyAvailable) {
			setFieldValue("agencyId", agenciesOptions[0].value);
		}
	}, [isOnlyOneAgencyAvailable]);

	const handleFieldBlur = (event) => {
		handleBlur(event.target.name);
	};

	const createClientRelationshipMutation = useMutation((v) => {
		if (isEditionMode) {
			return updateClientRelationship(profileId, relationshipId, v);
		}
		return createClientRelationship(profileId, v);
	});
	const clientLabelTemplate = (option) =>
		option.hasClientRelation ? (
			<Tooltip
				animation={false}
				placement="top-start"
				appendTo="parent"
				arrow={false}
				content={t("profile.clientRelationship.form.clientId.clientHasRelation")}
				tooltipBoxStyle={{ maxWidth: "300px" }}
			>
				{renderClientOption(option)}
			</Tooltip>
		) : (
			renderClientOption(option)
		);

	const handleSubmitForm = () => {
		createClientRelationshipMutation.mutate(values, {
			onSuccess: () => {
				toastDispatch({
					type: "SUCCESS",
					payload: {
						icon: "checkCircle",
						msg: t("profile.clientRelationship.form.creationSuccess"),
						params: {
							autoClose: true,
							closeOnClick: true,
						},
					},
				});
				history.push(`/profile/${profileId}/missions/client-relationship`);
			},
			onError: (e) => {
				// display error status and message
				switch (e.response.status) {
					case 400: {
						toastDispatch({
							type: "ERROR",
							payload: {
								msg: t(`profile.clientRelationship.form.errorCodes.${e.response.data.message}`),
								params: {
									autoClose: true,
									closeOnClick: true,
								},
							},
						});
						break;
					}
					default:
						break;
				}
			},
		});
	};

	const isSubmitDisabled = !isValid || !dirty;

	const isFormReady = useMemo(() => {
		if (!isEditionMode) return true;
		if (agenciesOptions?.length < 1 || clientsOptions?.length < 1) return false;
		return true;
	}, [agenciesOptions, clientsOptions, isEditionMode]);

	if (!isFormReady) {
		return <FormSkeleton />;
	}

	return (
		<div className={style.wrap}>
			{/* Agency */}
			<FormSection title={t("profile.clientRelationship.form.agencyId.label")}>
				{agenciesOptions.length > 0 ? (
					<>
						{isOnlyOneAgencyAvailable ? (
							<div className={style.readOnlyFieldBox}>
								<div className={cn([style.readOnlyField, style.readOnlyFieldAgency])}>
									{agenciesOptions.find((agency) => agency.value === values.agencyId)?.label}
								</div>
								<span className={style.readOnlyFieldInfo}>
									{t("profile.clientRelationship.form.agencyId.info")}
								</span>
							</div>
						) : (
							<SelectV2
								options={agenciesOptions}
								onChange={(selected) => {
									setValues({
										...values,
										agencyId: selected?.value,
										clientId: null,
									});
								}}
								disabled={isOnlyOneAgencyAvailable}
								name="agencyId"
								label={t("profile.clientRelationship.form.agencyId.placeholder")}
								placeholder={t("profile.clientRelationship.form.agencyId.placeholder")}
								value={values.agencyId || null}
								isClearable={false}
								info={t("profile.clientRelationship.form.agencyId.info")}
								error={!!errors.agencyId}
								noOptionsMessage={() => t("global.noDataText")}
								errorMessage={t("profile.clientRelationship.form.agencyId.error")}
								className={style.agencySelect}
							/>
						)}
					</>
				) : (
					<div>
						<Skeleton width={346} height={56} borderRadius={25} />
						<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
					</div>
				)}
			</FormSection>

			{values.agencyId && (
				<>
					{/* Client & Code Client */}
					<FormSection title={t("profile.clientRelationship.form.clientId.label")}>
						{clientsOptions.length > 0 || clientsFetched ? (
							<SelectV2
								options={clientsOptions}
								onChange={(selected) => {
									setValues({
										...values,
										clientId: selected?.value,
									});
								}}
								name="clientId"
								label={t("profile.clientRelationship.form.clientId.placeholder")}
								formatOptionLabel={(option) => clientLabelTemplate(option)}
								placeholder={t("profile.clientRelationship.form.clientId.placeholder")}
								value={values.clientId || null}
								isClearable={false}
								info={t("profile.clientRelationship.form.clientId.info")}
								error={!!errors.clientId && touched.clientId}
								errorMessage={t("profile.clientRelationship.form.clientId.error")}
								noOptionsMessage={() => t("global.noDataText")}
								isOptionDisabled={(option) => option.hasClientRelation}
								className={style.clientSelect}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						) : (
							<div>
								<Skeleton width={346} height={56} borderRadius={25} />
								<Skeleton className={style.fieldInfoSkeleton} width={110} height={17} />
							</div>
						)}
					</FormSection>
				</>
			)}

			{/* Once Client is selected */}
			{values.clientId ? (
				<>
					{/* Nom de l'intervenant */}
					<FormSection title={t("profile.clientRelationship.form.contact.label")}>
						<InputControlled
							type="text"
							name="contact"
							id="contact"
							className={style.authorField}
							label={t("profile.clientRelationship.form.contact.label")}
							value={values.contact}
							onChange={(e) => setFieldValue("contact", capitalizeWords(e.target.value))}
							onBlur={handleFieldBlur}
						/>
					</FormSection>

					{/* Status de la relation client */}
					<FormSection title={t("profile.clientRelationship.form.isAdequate.label")}>
						<div>
							<div className={style.isAdequate}>
								<ToggleSwitch
									id="isAdequate-adequate"
									name="isAdequate"
									onChange={() => {
										if (values.isAdequate === true) {
											return;
										}
										setFieldValue("isAdequate", true);
									}}
									toggled={values.isAdequate === true}
									className={style.adequacyField}
								>
									<label htmlFor="isAdequate-adequate" className={style["toggle-label"]}>
										{t("profile.clientRelationship.form.isAdequate.adequate")}
									</label>
								</ToggleSwitch>
							</div>
							<div className={style.isAdequate}>
								<ToggleSwitch
									id="isAdequate-inadequate"
									name="isAdequate"
									onChange={() => {
										if (values.isAdequate === false) {
											return;
										}
										setFieldValue("isAdequate", false);
									}}
									toggled={values.isAdequate === false}
									className={style.adequacyField}
								>
									<label htmlFor="isAdequate-inadequate" className={style["toggle-label"]}>
										{t("profile.clientRelationship.form.isAdequate.inadequate")}
									</label>
								</ToggleSwitch>
							</div>
							<span className={style.readOnlyFieldInfo}>
								{t("profile.clientRelationship.form.agencyId.info")}
							</span>
						</div>
					</FormSection>
				</>
			) : null}
			{/* SAVE */}
			<Button
				data-testid="command-save"
				disabled={isSubmitDisabled}
				color={isSubmitDisabled ? "grey" : "primary"}
				type="button"
				onClick={handleSubmitForm}
				isLoading={createClientRelationshipMutation.isLoading}
				style={{ position: "fixed", bottom: "2.5rem", right: "3.5rem" }}
			>
				{isEditionMode
					? t("profile.clientRelationship.form.submitEdit")
					: t("profile.clientRelationship.form.submit")}
			</Button>
		</div>
	);
};
FollowUpFormContent.propTypes = {
	mode: PropTypes.oneOf([CREATE_MODE, EDIT_MODE]).isRequired,
};

const ProfileMissionsClientRelationshipForm = ({ mode }) => {
	const { t } = useTranslation();
	const { profileId, relationshipId } = useParams();
	const isEditionMode = mode === EDIT_MODE;

	const {
		data: clientRelationshipData,
		isFetching: clientRelationshipDataFetching,
		error: clientRelationshipDataError,
	} = useQuery(["client-relationship", relationshipId], () => getClientRelationship(profileId, relationshipId), {
		enabled: isEditionMode,
		placeholderData: clientRelationshipFormDefaultValues,
	});

	const initialValues = useMemo(() => {
		if (!isEditionMode) return clientRelationshipFormDefaultValues;
		if (!clientRelationshipData?.data) return clientRelationshipFormDefaultValues;
		return {
			...clientRelationshipFormDefaultValues,
			...clientRelationshipData.data,
		};
	}, [clientRelationshipData]);

	if (isEditionMode && clientRelationshipDataFetching) {
		return <FormSkeleton />;
	}

	const shouldDisplayFormSkeleton = isEditionMode && clientRelationshipDataFetching;

	if (clientRelationshipDataError?.response?.status === 403) {
		return <div className={style.forbidden}>{t("profile.clientRelationship.forbidden")}</div>;
	}

	return shouldDisplayFormSkeleton ? (
		<FormSkeleton />
	) : (
		<Formik initialValues={initialValues} validationSchema={clientRelationshipFormValidation} enableReinitialize>
			{() => (
				<Form>
					<FollowUpFormContent mode={mode} />
				</Form>
			)}
		</Formik>
	);
};

ProfileMissionsClientRelationshipForm.propTypes = {
	mode: PropTypes.oneOf([CREATE_MODE, EDIT_MODE]).isRequired,
};

export default ProfileMissionsClientRelationshipForm;
