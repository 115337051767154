// @ts-nocheck
/* eslint-disable import/no-anonymous-default-export */
import client from "./client";

const postUsers = (agencyId, search) =>
	client.post(`/api-client-v2/agencies/${agencyId}/users`, {
		params: {
			searchText: search,
		},
	});

export default {
	postUsers,
};
