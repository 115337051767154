// @ts-nocheck
import { ModalV2 } from "@zolteam/react-ras-library";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import FormCreateAdvancePayments from "../forms/FormCreateAdvancePayments";
import FormEditAdvancePayments from "../forms/FormEditAdvancePayments";

const ModalContentAdvancePayments = ({ isModalOpen, setIsModalOpen, isNew, advancePaymentId, handleRefetch }) => {
	const { t } = useTranslation();

	const getTitle = () => {
		switch (true) {
			case isNew:
				return t("advancePayments.add");
			case !isNew:
				return t("advancePayments.edit");
			default:
				return "";
		}
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const renderContentModal = () => {
		switch (true) {
			case isNew: {
				return (
					<FormCreateAdvancePayments
						onSubmitSuccess={() => {
							handleRefetch();
							setIsModalOpen(false);
						}}
						onCancel={() => setIsModalOpen(false)}
					/>
				);
			}
			case !isNew:
				return (
					<FormEditAdvancePayments
						advancePaymentId={advancePaymentId}
						onSubmitSuccess={() => {
							handleRefetch();
							setIsModalOpen(false);
						}}
						onCancel={() => setIsModalOpen(false)}
					/>
				);
			default:
				return "";
		}
	};

	return (
		<ModalV2
			isDisplayed={isModalOpen}
			onClose={handleCloseModal}
			size="s"
			title={getTitle()}
			closeOnClickOutside={false}
		>
			{renderContentModal()}
		</ModalV2>
	);
};

ModalContentAdvancePayments.propTypes = {
	isModalOpen: PropTypes.bool,
	setIsModalOpen: PropTypes.func,
	isNew: PropTypes.bool,
	advancePaymentId: PropTypes.number,
	handleRefetch: PropTypes.func,
};

ModalContentAdvancePayments.defaultProps = {
	isModalOpen: false,
	setIsModalOpen: null,
	isNew: false,
	advancePaymentId: null,
	handleRefetch: () => {},
};

export default ModalContentAdvancePayments;
