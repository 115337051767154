// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Button, Title } from "@zolteam/react-ras-library";
import { useTranslation, Trans } from "react-i18next";
// Style
import style from "./Modals.module.css";
import cn from "../../../../utils/cn";

const ModalContentCommandFilled = ({ onConfirmClick, onCancelClick }) => {
	// Hooks
	const { t } = useTranslation();

	return (
		<div className={style.content}>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.profiles.modalsContent.commandFilled.title")}
			</Title>
			<div className={style.textContent}>
				<p className={style.message}>
					{t("commands.profiles.modalsContent.commandFilled.infoMessage")} <br />
					<Trans i18nKey="commands.profiles.modalsContent.commandFilled.infoMessageBold">
						<strong>Les profils en attente (“Of. acceptée” et “Profil proposé”)</strong>
						recevront un message d’annulation.
					</Trans>
				</p>
				<span className="mt-s">
					<p className={style.message}>{t("commands.profiles.modalsContent.commandFilled.infoConfirm")}</p>
				</span>
				<p className={cn([style.message, style["message-annotation"]])}>
					{t("commands.profiles.modalsContent.commandFilled.informationComp")}
				</p>
			</div>
			<hr className={cn([style.separator, "mt-l mb-m"])} />
			<div className={cn([style.buttonsBlock])}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.profiles.modalsContent.commandFilled.cancelAction")}
				</Button>
				<Button className={style.validationButtonRight} type="button" color="primary" onClick={onConfirmClick}>
					{t("commands.profiles.modalsContent.commandFilled.confirmAction")}
				</Button>
			</div>
		</div>
	);
};

ModalContentCommandFilled.propTypes = {
	onConfirmClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
};

export default ModalContentCommandFilled;
