// @ts-nocheck
import { useFormikContext } from "formik";
import { useState } from "react";
import { useQuery } from "react-query";

// Services
import { getOrderProfileNotes } from "../../../../../../services/commandService";

export const useProfileDetailNotes = ({ orderProfileId, activeTab, setNotesInitialValues }) => {
	const { setValues: setNotesValues } = useFormikContext();

	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

	const isNotesTabActive = activeTab === 1;

	const { isFetched: notesAreFetched, isFetching: notesAreFetching } = useQuery(
		["getOrderProfileNotes", orderProfileId],
		async () => {
			const notesData = await getOrderProfileNotes(orderProfileId);

			const formData = {
				notes: notesData?.data?.notes,
				notesVersion: notesData?.data?.notesVersion,
			};

			setNotesValues(formData);
			setNotesInitialValues(formData);
			return notesData;
		},
		{
			enabled: !!orderProfileId && isNotesTabActive,
		}
	);

	return {
		notesAreFetched,
		notesAreFetching,
		isNotesTabActive,
		isCancelModalOpen,
		setIsCancelModalOpen,
	};
};

export default useProfileDetailNotes;
