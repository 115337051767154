// @ts-nocheck
import React, { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";

// Style
import cn from "../../../utils/cn";
import style from "./CommandSideMenuItem.module.css";

interface ICommandSideMenuItemProps extends PropsWithChildren {
	path: string;
}

const CommandSideMenuItem: React.FC<ICommandSideMenuItemProps> = ({ path, children }) => (
	<button type="button" className={style.btn}>
		<NavLink
			className={(isActive: boolean) => {
				return cn([style.sidemenuItem, isActive && style.active]);
			}}
			to={path}
		>
			{children}
		</NavLink>
	</button>
);

export default CommandSideMenuItem;
