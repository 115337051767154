// @ts-nocheck
import { BackLink, Button, Text, useModal } from "@zolteam/react-ras-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useWindowSize } from "react-use";

// Store
import { useToastContext } from "../../store/ToastContext";
import useAppContext from "../../store/useAppContext";
// Hooks
import useMeasure from "../../hooks/useMeasure";
// Templates
import WithSideMenu from "../../components/templates/WithSideMenu";
import WithTopBarre from "../../components/templates/WithTopBarre";
// Organisms
import SuspiciousTable from "../../components/organisms/SuspiciousTable/SuspiciousTable";
// Molecules
import SuspiciousSheetFooter from "../../components/molecules/SuspiciousSheetFooter";
import SuspiciousSheetHeader from "../../components/molecules/SuspiciousSheetHeader";
// Services and utils
import unifiedTempWorkerSuggestion from "../../services/unifiedTemporaryWorkerSuggestion";
import { ShareErrorModal, ShareSheetModal } from "./Modals/SuspiciousSheetModal";

const SuspiciousSheet = () => {
	const [hasPropertyError, setPropertyError] = useState(false);
	const [openMenuWidth, setOpenMenuWidth] = useState(0);

	const [, toastDispatch] = useToastContext();

	const history = useHistory();
	const { t } = useTranslation();
	const [modalShareSheet, toggleModalShareSheet] = useModal();
	const [modalShareError, toggleModalShareError] = useModal();

	const [whiteContainerRef, { left }] = useMeasure();
	const [suspiciousHeader, { bottom }] = useMeasure();
	const [suspiciousFooter, { height: heightFooter }] = useMeasure();
	const { width, height } = useWindowSize();
	const { id } = useParams();
	const [{ suspiciousSheetForm, suspiciousFormTotalFields, suspiciousFormUnfilledFields }, appDispatch] =
		useAppContext();

	// Queries
	const queryClient = useQueryClient();

	/**
	 * Remove from react query cache the validate suspicious
	 */
	const optimisticDeleteValidateSuspiciousInSearch = () => {
		const tempWorkersSearchCache = queryClient.getQueriesData("postUnifiedTempWorkerSuggestionsSearch");
		if (tempWorkersSearchCache.length > 0) {
			const activeCache = tempWorkersSearchCache[0][0];
			queryClient.setQueryData([activeCache[0], activeCache[1]], (old) => {
				const newItems = old.data.items.filter((item) => `${item.id}` !== id);
				return {
					...old,
					data: {
						total: old.data.total - 1,
						items: newItems,
					},
				};
			});
		}
	};

	const getUnifiedTempWorkerSuggestionsQuery = useQuery(
		["getUnifiedTempWorkerSuggestions", id],
		() => unifiedTempWorkerSuggestion.getUnifiedTempWorkerSuggestions(id),
		{
			onSuccess: (response) => {
				const propertyErrors = response?.data?.propertyErrors;
				if (propertyErrors?.length) {
					const mainLocationError = propertyErrors.some(
						(propertyError) => propertyError.property === "mainLocation"
					);
					setPropertyError(true);
					if (mainLocationError) {
						toastDispatch({
							type: "ERROR",
							payload: {
								icon: "users",
								msg: t("global.apiErrors.ERR_BR_SUG_0011_MAIN_LOCATION"),
								params: {
									autoClose: false,
									closeOnClick: true,
								},
							},
						});
					}
				}
			},
		}
	);

	// Mutation
	const postUnifiedTempWorkerSuggestionsMutation = useMutation(
		(data) => unifiedTempWorkerSuggestion.postUnifiedTempWorkerSuggestionsSave(id, data),
		{
			onSuccess: () => {
				optimisticDeleteValidateSuspiciousInSearch();
				toastDispatch({
					type: "SUCCESS",
					payload: {
						icon: "users",
						msg: t("suspiciousSheet.sharedModifications"),
					},
				});
				history.push("/suspicions");
			},
			onError: (error) => {
				const msgError = error.response?.data?.message;
				const propertyError = error.response?.data?.property;
				toggleModalShareSheet();
				if (msgError === "ERR_BR_SUG_0007_DESYNCHRONIZED") {
					toggleModalShareError();
				} else if (propertyError === "mainLocation") {
					setPropertyError(true);
					toastDispatch({
						type: "ERROR",
						payload: {
							icon: "users",
							msg: t("global.apiErrors.ERR_BR_SUG_0012_MAIN_LOCATION"),
							params: {
								autoClose: false,
								closeOnClick: true,
							},
						},
					});
				}
			},
		}
	);

	const handleOpenMenu = () => {
		const paddingValue = getComputedStyle(document.body).getPropertyValue("--menu-width-unfold");
		setOpenMenuWidth((paddingValue?.split("rem")?.[0] || 0) * 16);
	};

	const getWidth = () => {
		if (openMenuWidth) {
			return width - openMenuWidth;
		}
		return width - left;
	};

	const handleShare = () => {
		const temporaryWorker = {
			...getUnifiedTempWorkerSuggestionsQuery?.data?.data.temporaryWorker,
			...suspiciousSheetForm.temporaryWorker,
		};
		postUnifiedTempWorkerSuggestionsMutation.mutate({
			...suspiciousSheetForm,
			temporaryWorker,
		});
	};

	return (
		<>
			<WithSideMenu onOpen={handleOpenMenu} onClose={() => setOpenMenuWidth(0)}>
				<WithTopBarre
					quickEntryContent={<div>test</div>}
					leftContent={
						<div className="d-flex">
							<BackLink onClick={() => history.push("/suspicions")}>
								<Text fontWeight="normal" tag="span">
									{t("suspiciousSheet.allSuspiciousLink")}
								</Text>
							</BackLink>
						</div>
					}
					containerClassname="container"
				>
					<div ref={whiteContainerRef} className="white-bg full-width container-radius full-height-grid">
						{getUnifiedTempWorkerSuggestionsQuery?.isSuccess ? (
							<>
								<div ref={suspiciousHeader}>
									<SuspiciousSheetHeader
										temporaryWorker={
											getUnifiedTempWorkerSuggestionsQuery?.data?.data?.temporaryWorker
										}
									/>
								</div>
								<SuspiciousTable
									width={getWidth()}
									height={height - bottom - heightFooter}
									data={getUnifiedTempWorkerSuggestionsQuery?.data?.data}
								/>
								<div ref={suspiciousFooter}>
									<SuspiciousSheetFooter
										agencies={getUnifiedTempWorkerSuggestionsQuery?.data?.data?.agencies}
									>
										<Button
											data-testid="shareSheet"
											type="button"
											color="primary"
											onClick={toggleModalShareSheet}
											disabled={
												(suspiciousFormTotalFields?.length !== 0 &&
													(suspiciousFormUnfilledFields !==
														suspiciousFormTotalFields?.length ||
														suspiciousFormUnfilledFields === 0)) ||
												hasPropertyError
											}
										>
											{t("suspiciousSheet.shareModifications")}
										</Button>
									</SuspiciousSheetFooter>
								</div>
							</>
						) : (
							<Text className="ml-l" tag="p" size="paragraph03" color="grey">
								{t("suspiciousSheet.loadingTempWorkerSuggestions")}
							</Text>
						)}
					</div>
				</WithTopBarre>
			</WithSideMenu>
			<ShareSheetModal
				isDisplayed={modalShareSheet}
				toggleModal={toggleModalShareSheet}
				handleModalAction={handleShare}
				loadingAction={postUnifiedTempWorkerSuggestionsMutation.isLoading}
			/>
			<ShareErrorModal
				isDisplayed={modalShareError}
				toggleModal={toggleModalShareError}
				handleConfirm={() => {
					appDispatch({
						type: "CLEAR_SUSPICIOUS_FORM",
					});
					history.go(0);
					toggleModalShareError();
				}}
				handleCancel={() => {
					history.push("/suspicions");
				}}
			/>
		</>
	);
};

export default SuspiciousSheet;
