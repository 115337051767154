import LocationCalculationType from "src/types/LocationCalculationType";

const isLocationBeingCalculated = (
	latitude: string | null,
	longitude: string | null,
	gpsCalculationRequestedAt: string | null
): boolean => {
	return gpsCalculationRequestedAt !== null && latitude === null && longitude === null;
};

const locationCouldNotBeCalculated = (
	latitude: string | null,
	longitude: string | null,
	gpsCalculationRequestedAt: string | null
): boolean => {
	return gpsCalculationRequestedAt === null && latitude === null && longitude === null;
};

// TODO : orderData must be typed
const useBusinessInformation = (orderData: any): LocationCalculationType => {
	const { latitude, longitude, gpsCalculationRequestedAt } = orderData?.data?.workAddress || {};

	return {
		isLocationBeingCalculated: isLocationBeingCalculated(latitude, longitude, gpsCalculationRequestedAt),
		locationCouldNotBeCalculated: locationCouldNotBeCalculated(latitude, longitude, gpsCalculationRequestedAt),
	};
};

export default useBusinessInformation;
