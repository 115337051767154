// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Picto, Text } from "@zolteam/react-ras-library";

// Style
import style from "./Toast.module.css";

const Toast = ({ icon = "", msg }) => (
	<div className={style.container}>
		{icon ? (
			<div className={style.icon}>
				<Picto icon={icon} />
			</div>
		) : null}
		<Text tag="span" size="paragraph02" className="ml-10">
			{msg}
		</Text>
	</div>
);

Toast.propTypes = {
	icon: PropTypes.string,
	msg: PropTypes.string.isRequired,
};

export default Toast;
