// @ts-nocheck
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import { Button, InfoMessage, ModalV2, Picto, PopoverCursor, Tag, Text, Textarea } from "@zolteam/react-ras-library";
import { Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";

// Style
import style from "./ModalContentProfileCardDetails.module.css";

// Store
import { useOrderProfilesContext } from "../../../../../store/OrderProfilesContext";

// Hooks
import { useProfileDetailNotes } from "./hooks/useProfileDetailNotes";

// Service
import {
	getCommunicationContent,
	postProfileDetails,
	putOrderProfileNotes,
} from "../../../../../services/commandService";
import { getUnifiedTempWorker } from "../../../../../services/missionFollowUpService";

// Components
import NavigationTabs from "../../../../../components/molecules/NavigationTabs/NavigationTabs";

// Utils
import cn from "../../../../../utils/cn";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";

// Constants
import { ACTIVITY_PROFILES_EVENTS, COMMUNICATION_SENT, ORDER_PROFILES_ERRORS } from "../../../../../constants";

const ModalContentProfileCardDetails = ({ profile, areNotesEditingLoading, setNotesInitialValues }) => {
	const { resetForm: resetNotesForm, dirty: dirtyNotes } = useFormikContext();

	const { unifiedTempWorkerId, orderProfileId, isVip, isCdiInterim } = profile;

	const [{ notesAreConflicting, isEditingNote, isNoteSuccessDisplayed }, dispatchOrderProfiles] =
		useOrderProfilesContext();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const [activeTab, setActiveTab] = useState(0);
	const tabs = [
		{ content: t("commands.profiles.modalsContent.profileDetail.activity.title"), id: 0 },
		{ content: t("commands.profiles.modalsContent.profileDetail.notes.title"), id: 1 },
	];

	const isActivityTabActive = activeTab === 0;

	const { notesAreFetched, notesAreFetching, isNotesTabActive, isCancelModalOpen, setIsCancelModalOpen } =
		useProfileDetailNotes({
			orderProfileId,
			activeTab,
			setNotesInitialValues,
		});

	const { data: unifiedTempWorker, isFetching } = useQuery(["unified-temp-worker"], () =>
		getUnifiedTempWorker(unifiedTempWorkerId)
	);

	const { firstName, lastName, avatarUrl, channelPreference, phone, email, isUnifiedTempWorkerValidated } =
		unifiedTempWorker?.data ?? {};

	const fetchProfileDetails = useCallback(async ({ pageParam = 1 }) => {
		const results = await postProfileDetails(orderProfileId, {
			limit: 15,
			page: pageParam,
		});

		return results;
	});

	const {
		data,
		isFetched: activitesAreFetched,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ["postProfileDetails", orderProfileId],
		queryFn: fetchProfileDetails,
		getNextPageParam: (_, allPages) => {
			// if last page is detected, return undefined;
			if (allPages.length * 15 > allPages[0]?.data?.total) {
				return undefined;
			}
			// if list of pages exist, return the next page index
			if (allPages?.length > 0) {
				return allPages.length + 1;
			}
			// if no array exists, return the first page index
			return 1;
		},
		enabled: !!orderProfileId,
	});

	const handleScroll = (e) => {
		const element = e.target;
		const atBottom = element.scrollHeight - element.scrollTop - 5 <= element.clientHeight;
		if (atBottom && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	};

	const handleCommunicationMedium = (communicationMediums) => {
		if (!communicationMediums?.length) {
			return null;
		}
		if (communicationMediums.length === 1) {
			return `, ${communicationMediums[0]}`;
		}
		if (communicationMediums.length > 1) {
			return `, ${communicationMediums.slice(0, -1).join(", ")} et ${communicationMediums.slice(-1)}`;
		}
		return "null";
	};

	const processCommunicationMediums = (communicationMediums) => {
		if (!communicationMediums) {
			return [];
		}
		let sortedArray = communicationMediums.sort((a, b) => {
			if (a === "push-notification") return 1;
			if (b === "push-notification") return -1;
			return a.localeCompare(b);
		});
		sortedArray = sortedArray.map((item) =>
			t(`commands.profiles.modalsContent.profileDetail.communicationMediumShort.${[item]}`)
		);

		return sortedArray;
	};

	const concatenatedActivities = useMemo(() => {
		if (!data) return [];
		if (activitesAreFetched) {
			// Concatenate all pages of result into one array
			return data.pages.reduce((acc, current) => [...acc, ...current.data.items], []);
		}
		return [];
	}, [data, activitesAreFetched]);

	useEffect(() => {
		dispatchOrderProfiles({ type: "SET_NOTES_ARE_CONFLICTING", payload: false });
	}, [activeTab]);

	useEffect(
		() => () => {
			queryClient.resetQueries({ queryKey: ["postProfileDetails", orderProfileId], exact: true });
			dispatchOrderProfiles({ type: "SET_NOTES_ARE_CONFLICTING", payload: false });
			dispatchOrderProfiles({ type: "SET_NOTE_SUCCESS_DISPLAYED", payload: false });
		},
		[]
	);

	const setCommunicationContent = (id, type, content) =>
		queryClient.setQueryData(["postProfileDetails", orderProfileId], (queryData) => {
			if (queryData && Array.isArray(queryData?.pages)) {
				// Create a new copy of the query data
				const updatedQueryData = {
					...queryData,
					pages: queryData?.pages.map((page) => {
						if (Array.isArray(page.data.items)) {
							// Create a new copy of the page data
							const updatedPage = {
								...page,
								data: {
									...page.data,
									// Update orderProfileStatus for the specific profile
									items: page.data.items.map((activity) => {
										const newActivity =
											type === "SET_OPEN"
												? { ...activity, isOpen: !activity?.isOpen }
												: { ...activity, content };
										return activity.groupUuid === id ? newActivity : activity;
									}),
								},
							};
							return updatedPage;
						}
						return page;
					}),
				};
				return updatedQueryData;
			}
			return queryData;
		});

	const handleComunicationClick = async (id) => {
		const targetedActivity = concatenatedActivities.find(({ groupUuid }) => groupUuid === id);
		let htmlContent = "";

		setCommunicationContent(id, "SET_OPEN", htmlContent);

		if (targetedActivity && targetedActivity.content) {
			htmlContent = targetedActivity?.content;
		} else {
			try {
				const response = await getCommunicationContent(orderProfileId, id);
				htmlContent = response?.data.content;
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}

		if (htmlContent === "") {
			// Stop loading if there is no content
			setCommunicationContent(id, "SET_CONTENT", " ");
			return;
		}

		setCommunicationContent(id, "SET_CONTENT", htmlContent);
	};

	const activityDetailTemplate = (index, content, groupUuid) => (
		<div className={style.activity} key={groupUuid}>
			<div className={style.dot} />
			{index !== concatenatedActivities.length - 1 && <div className={style.verticalBar} />}
			<div className={style.activityDetail}>{content}</div>
		</div>
	);

	const renderResponseReceivedActivity = (activity, index) => {
		const content = (
			<>
				<span className={style.activityText}>
					{activity.isDelegatedBy247 && (
						<PopoverCursor
							placement="top"
							content={<div className={style.popoverBox}>{t("commands.profiles.isDelegatedBy247")}</div>}
						>
							<div className={style.pictoTwentyFour}>
								<Picto icon="timeTwentyFour" />
							</div>
						</PopoverCursor>
					)}
					<Trans
						i18nKey="commands.profiles.modalsContent.profileDetail.activity.templates.statusChange"
						values={{
							status: t(
								`commands.profiles.modalsContent.profileDetail.statuses.${[activity.profileStatus]}`
							),
							agentName: `${activity.authorFirstName} ${activity.authorLastName.charAt(0).toUpperCase()}.`,
							orderProfileName: `${activity.tempWorkerFirstName} ${activity.tempWorkerLastName}`,
						}}
					/>
				</span>
				<span className={style.activityTime}>
					{t("commands.profiles.modalsContent.profileDetail.activity.when", {
						date: moment(activity.createdAt).format("DD MMM YYYY"),
						time: moment(activity.createdAt).format("HH[h]mm"),
						communicationMethods: "",
					})}
				</span>
			</>
		);
		return activityDetailTemplate(index, content, activity.groupUuid);
	};

	const renderProfileResponseActivity = (activity, index) => {
		const content = (
			<>
				<span className={style.activityText}>
					<Trans
						i18nKey="commands.profiles.modalsContent.profileDetail.activity.templates.offerResponse"
						values={{
							response: t(
								`commands.profiles.modalsContent.profileDetail.profileResponse.${[activity.profileStatus]}`
							),
							orderProfileName: `${activity.tempWorkerFirstName} ${activity.tempWorkerLastName}`,
							communicationMedium: t(
								`commands.profiles.modalsContent.profileDetail.communicationMedium.${[activity.communicationMedium][0]}`
							),
						}}
					/>
				</span>
				<span className={style.activityTime}>
					{t("commands.profiles.modalsContent.profileDetail.activity.when", {
						date: moment(activity.createdAt).format("DD MMM YYYY"),
						time: moment(activity.createdAt).format("HH[h]mm"),
						communicationMethods: handleCommunicationMedium(
							processCommunicationMediums(activity.communicationMedium)
						),
					})}
				</span>
			</>
		);

		return activityDetailTemplate(index, content, activity.groupUuid);
	};

	const renderCommunicationContent = (activity, index) => {
		const communicationMedium = activity?.communicationMedium?.[0] || "";

		if (activity.isOpen && !activity.content) {
			return (
				<div className={style.loaderBox}>
					<img className={style.loaderIcon} src="/circle-loader.png" alt="circle-loader" />
				</div>
			);
		}
		if (communicationMedium === "sms") {
			return (
				<div
					className={activity.content && activity.isOpen ? style.sms : style.hidden}
				>{`${activity.content}`}</div>
			);
		}
		return (
			<iframe
				id={index}
				title={`content${index}`}
				className={activity.content && activity.isOpen ? style.iframe : style.hidden}
				srcDoc={activity.content}
			/>
		);
	};

	const renderSendMissionStatusActivity = (activity, index) => {
		const content = (
			<>
				<span className={style.activityText}>
					<Trans
						i18nKey="commands.profiles.modalsContent.profileDetail.activity.templates.sendMissionStatus"
						values={{
							messageType: t(
								`commands.profiles.modalsContent.profileDetail.sendMissionStatus.${[activity.communicationType]}`
							),
							orderProfileName: `${activity.tempWorkerFirstName} ${activity.tempWorkerLastName}`,
						}}
					/>
				</span>
				<span className={style.activityTime}>
					{t("commands.profiles.modalsContent.profileDetail.activity.when", {
						date: moment(activity.createdAt).format("DD MMM YYYY"),
						time: moment(activity.createdAt).format("HH[h]mm"),
						communicationMethods: handleCommunicationMedium(
							processCommunicationMediums(activity.communicationMedium)
						),
					})}
				</span>
				<div
					className={style.contentAction}
					onClick={() => handleComunicationClick(activity.groupUuid)}
					onKeyDown={() => handleComunicationClick(activity.groupUuid)}
					role="button"
					tabIndex={index}
				>
					<span>
						{t("commands.profiles.modalsContent.profileDetail.contentActionButton", {
							sendMissionType: t(
								`commands.profiles.modalsContent.profileDetail.sendMissionType.${[activity.communicationType]}`
							),
						})}
					</span>
					<Picto icon={activity?.isOpen ? "chevronUp" : "chevronDown"} />
				</div>
				{renderCommunicationContent(activity, index)}
			</>
		);
		return activityDetailTemplate(index, content, activity.groupUuid);
	};

	const renderSendMissionCommunicationActivity = (activity, index) => {
		const content = (
			<>
				<span className={style.activityText}>
					<Trans
						i18nKey="commands.profiles.modalsContent.profileDetail.activity.templates.offerShared"
						values={{
							sendMissionCommunication: t(
								`commands.profiles.modalsContent.profileDetail.sendMissionCommunication.${[activity.communicationType]}`
							),
							agentName: `${activity.authorFirstName} ${activity.authorLastName.charAt(0).toUpperCase()}.`,
							orderProfileName: `${activity.tempWorkerFirstName} ${activity.tempWorkerLastName}`,
						}}
					/>
				</span>
				<span className={style.activityTime}>
					{t("commands.profiles.modalsContent.profileDetail.activity.when", {
						date: moment(activity.createdAt).format("DD MMM YYYY"),
						time: moment(activity.createdAt).format("HH[h]mm"),
						communicationMethods: handleCommunicationMedium(
							processCommunicationMediums(activity.communicationMedium)
						),
					})}
				</span>
				<div
					className={style.contentAction}
					onClick={() => handleComunicationClick(activity.groupUuid)}
					onKeyDown={() => handleComunicationClick(activity.groupUuid)}
					role="button"
					tabIndex={index}
				>
					<span>
						{t("commands.profiles.modalsContent.profileDetail.contentCommunicationButton", {
							communicationType: t(
								`commands.profiles.modalsContent.profileDetail.communicationType.${[activity.communicationType]}`
							),
						})}
					</span>
					<Picto icon={activity?.isOpen ? "chevronUp" : "chevronDown"} />
				</div>
				{renderCommunicationContent(activity, index)}
			</>
		);
		return activityDetailTemplate(index, content, activity.groupUuid);
	};

	const dispatchActivityBasedOnType = (activity, index) => {
		if (ACTIVITY_PROFILES_EVENTS.statusUpdate === activity.activityType) {
			return renderResponseReceivedActivity(activity, index);
		}
		if (ACTIVITY_PROFILES_EVENTS.receivedResponse === activity.activityType) {
			return renderProfileResponseActivity(activity, index);
		}
		if (
			ACTIVITY_PROFILES_EVENTS.communicationSent === activity.activityType &&
			COMMUNICATION_SENT.send.includes(activity.communicationType)
		) {
			return renderSendMissionStatusActivity(activity, index);
		}
		if (
			ACTIVITY_PROFILES_EVENTS.communicationSent === activity.activityType &&
			COMMUNICATION_SENT.share.includes(activity.communicationType)
		) {
			return renderSendMissionCommunicationActivity(activity, index);
		}
		return null;
	};

	return (
		<div className={style.content}>
			<div className={style.header}>
				{isFetching ? (
					<Skeleton containerClassName={style.skeletonCase} height={60} width={60} borderRadius={50} />
				) : (
					<div className={style.avatarBox}>
						{avatarUrl ? (
							<img className={style.avatar} src={avatarUrl} alt="user profil" />
						) : (
							<div className={style.avatarPlaceholder}>
								<Picto icon="userDelete" />
							</div>
						)}
						{isVip && (
							<span>
								<Picto className={style.vipPicto} icon="vip" />
							</span>
						)}
					</div>
				)}
				{isFetching ? (
					<Skeleton containerClassName={style.skeletonModel} height={50} borderRadius={25} width={250} />
				) : (
					<>
						<p className={style.name}>{firstName}</p>
						<p className={style.name}>{lastName}</p>
						{isCdiInterim && (
							<Tag className={style.profilAttribute} color="primaryLight">
								CDII
							</Tag>
						)}
					</>
				)}
			</div>
			<div className={style.body}>
				{isFetching ? (
					<Skeleton containerClassName={style.skeletonLeftPart} borderRadius={25} />
				) : (
					<div className={style.leftPart}>
						<div>
							<div className={style.channelPreferenceBox}>
								<span className={style.channelPreference}>
									{t(
										"commands.profiles.modalsContent.profileDetail.activity.preferredCommunicationChannel",
										{
											communicationMedium: channelPreference || "non défini",
										}
									)}
								</span>
							</div>
						</div>
						{channelPreference === "sms" ? (
							<span className={style.phone}>{formatPhoneNumber(`+33${phone.slice(1)}` || "")}</span>
						) : null}
						{channelPreference === "email" ? <span className={style.phone}>{email || ""}</span> : null}
						{isUnifiedTempWorkerValidated ? (
							<div className={style.validatedRas}>
								<div className={style.logoMyRas}>
									<Picto icon="logoMyRasAlt" className={style.myRasLogo} />
								</div>
								<span>{t("commands.profiles.modalsContent.profileDetail.activity.verified")} </span>
							</div>
						) : null}
					</div>
				)}

				{isFetching ? (
					<Skeleton containerClassName={style.skeletonRightPart} borderRadius={25} />
				) : (
					<div className={style.rightPart}>
						<div className={style.navigationTab}>
							<NavigationTabs tabs={tabs} onTabChange={setActiveTab} activeTab={activeTab} />
						</div>
						{isNoteSuccessDisplayed && (
							<div className={style.successMessage}>
								<InfoMessage withIcon color="success">
									<span className={style.successMessageContent}>
										{t("commands.profiles.modalsContent.profileDetail.notes.success")}
									</span>
								</InfoMessage>
							</div>
						)}
						{notesAreConflicting && (
							<div className={style.notesConflict}>
								<InfoMessage withIcon color="error">
									<Trans
										i18nKey="commands.profiles.modalsContent.profileDetail.notes.versionMismatch"
										components={{
											button: (
												<button
													className={style.infoLink}
													aria-label="rafraîchir"
													type="button"
													onClick={() => {
														dispatchOrderProfiles({
															type: "SET_NOTES_ARE_CONFLICTING",
															payload: false,
														});
														queryClient.invalidateQueries([
															"getOrderProfileNotes",
															orderProfileId,
														]);
													}}
												/>
											),
										}}
									/>
								</InfoMessage>
							</div>
						)}
						{isActivityTabActive && (
							<div className={style.activitiesBox} onScroll={handleScroll}>
								{activitesAreFetched ? (
									<div>
										{concatenatedActivities.map((activity, index) =>
											dispatchActivityBasedOnType(activity, index)
										)}
										{isFetchingNextPage && (
											<div className={style.loadingBox}>
												<span className={style.loader} />
											</div>
										)}
									</div>
								) : (
									<div>
										<div className={style.skeletonActivityItem}>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={50}
												borderRadius={25}
											/>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={15}
												borderRadius={10}
												width={100}
											/>
										</div>
										<div className={style.skeletonActivityItem}>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={50}
												borderRadius={25}
											/>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={15}
												borderRadius={10}
												width={100}
											/>
										</div>
										<div className={style.skeletonActivityItem}>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={50}
												borderRadius={25}
											/>
											<Skeleton
												containerClassName={style.skeletonFullWidth}
												height={15}
												borderRadius={10}
												width={100}
											/>
										</div>
									</div>
								)}
							</div>
						)}
						{isNotesTabActive && (
							<>
								{!notesAreFetched || notesAreFetching ? (
									<Skeleton
										containerClassName={cn([style.skeletonFullWidth, style.skeletonFullHeight])}
										borderRadius={25}
									/>
								) : (
									<>
										<Textarea
											className={cn([
												style.notesTextarea,
												isEditingNote ? style.notesTextareaEditing : null,
											])}
											disabled={!isEditingNote}
											name="notes"
											placeholder={t(
												"commands.profiles.modalsContent.profileDetail.notes.placeholder"
											)}
											shouldDisplayErrorMessage={false}
										/>
										<div className={style.notesActions}>
											{areNotesEditingLoading ? (
												<img
													className={style.noteLoaderIcon}
													src="/circle-loader.png"
													alt="circle-loader"
												/>
											) : (
												<>
													{isEditingNote ? (
														<span className={style.submitGroup}>
															<Button
																onClick={(e) => {
																	setIsCancelModalOpen(true);
																	e.preventDefault();
																}}
																type="button"
																color="primary"
																outline
															>
																{t(
																	"commands.profiles.modalsContent.profileDetail.notes.cancel"
																)}
															</Button>
															<Button
																disabled={!dirtyNotes}
																type="submit"
																color="primary"
															>
																{t(
																	"commands.profiles.modalsContent.profileDetail.notes.save"
																)}
															</Button>
														</span>
													) : (
														<Button
															color="primary"
															onClick={() => {
																dispatchOrderProfiles({
																	type: "SET_IS_EDITING_NOTE",
																	payload: true,
																});
																dispatchOrderProfiles({
																	type: "SET_NOTE_SUCCESS_DISPLAYED",
																	payload: false,
																});

																setTimeout(() => {
																	const textarea =
																		document.querySelector(
																			'textarea[name="notes"]'
																		);
																	if (textarea) {
																		textarea.focus();
																	}
																}, 50);
															}}
														>
															{t(
																"commands.profiles.modalsContent.profileDetail.notes.edit"
															)}
														</Button>
													)}
												</>
											)}
										</div>
									</>
								)}
							</>
						)}
					</div>
				)}
			</div>
			<ModalV2
				isDisplayed={isCancelModalOpen}
				onClose={() => setIsCancelModalOpen(false)}
				size="s"
				title={t("commands.profiles.modalsContent.profileDetail.notes.cancelModal.title")}
			>
				<div>
					<Text tag="p" size="paragraph01" lineHeight="l" className={style.modalText}>
						{t("commands.profiles.modalsContent.profileDetail.notes.cancelModal.message")}
					</Text>
					<hr className={style.separator} />
					<div className={style.modalButton}>
						<Button onClick={() => setIsCancelModalOpen(false)} color="primary" outline>
							{t("commands.profiles.modalsContent.profileDetail.notes.cancelModal.cancel")}
						</Button>
						<Button
							onClick={() => {
								dispatchOrderProfiles({ type: "SET_IS_EDITING_NOTE", payload: false });
								setIsCancelModalOpen(false);
								resetNotesForm();
								queryClient.invalidateQueries(["getOrderProfileNotes", orderProfileId]);
							}}
							color="primary"
						>
							{t("commands.profiles.modalsContent.profileDetail.notes.cancelModal.confirm")}
						</Button>
					</div>
				</div>
			</ModalV2>
		</div>
	);
};

ModalContentProfileCardDetails.propTypes = {
	profile: PropTypes.shape({
		unifiedTempWorkerId: PropTypes.number.isRequired,
		orderProfileId: PropTypes.number.isRequired,
		isVip: PropTypes.bool.isRequired,
		isCdiInterim: PropTypes.bool.isRequired,
	}).isRequired,
	areNotesEditingLoading: PropTypes.bool.isRequired,
	setNotesInitialValues: PropTypes.func.isRequired,
};

const ModalContentProfileCardDetailsForm = ({ profile }) => {
	const [, dispatchOrderProfiles] = useOrderProfilesContext();

	const [notesInitialValues, setNotesInitialValues] = useState({ notes: "", notesVersion: "" });

	const { mutate: putOrderProfileNotesMutate, isLoading: areNotesEditingLoading } = useMutation(
		(payload) => {
			const transformedData = {
				notes: payload.data.notes || "",
				notesVersion: payload.data.notesVersion,
			};

			return putOrderProfileNotes(payload.orderProfileId, transformedData);
		},
		{
			onSuccess: (success) => {
				dispatchOrderProfiles({ type: "SET_NOTES_ARE_CONFLICTING", payload: false });
				dispatchOrderProfiles({ type: "SET_NOTE_SUCCESS_DISPLAYED", payload: true });
				setTimeout(() => {
					dispatchOrderProfiles({ type: "SET_NOTE_SUCCESS_DISPLAYED", payload: false });
				}, 3000);
				if (success.data) setNotesInitialValues(success.data);
			},
			onError: (error) => {
				if (
					error.response.status === 409 &&
					error.response.data?.message === ORDER_PROFILES_ERRORS.ERR_CO_ORDER_PROFILE_NOTES_CONFLICT
				) {
					dispatchOrderProfiles({ type: "SET_NOTES_ARE_CONFLICTING", payload: true });
				}
			},
		}
	);

	return (
		<Formik
			initialValues={notesInitialValues}
			enableReinitialize
			onSubmit={(values) => {
				dispatchOrderProfiles({ type: "SET_IS_EDITING_NOTE", payload: false });
				putOrderProfileNotesMutate({
					orderProfileId: profile.orderProfileId,
					data: {
						notes: values.notes,
						notesVersion: values.notesVersion,
					},
				});
			}}
		>
			{() => (
				<Form>
					<ModalContentProfileCardDetails
						profile={profile}
						areNotesEditingLoading={areNotesEditingLoading}
						setNotesInitialValues={setNotesInitialValues}
					/>
				</Form>
			)}
		</Formik>
	);
};

ModalContentProfileCardDetailsForm.propTypes = {
	profile: PropTypes.shape({
		unifiedTempWorkerId: PropTypes.number.isRequired,
		orderProfileId: PropTypes.number.isRequired,
	}),
};

ModalContentProfileCardDetailsForm.defaultProps = {
	profile: {},
};

export default ModalContentProfileCardDetailsForm;
