// @ts-nocheck
import React, { useState } from "react";
import { Button, Title } from "@zolteam/react-ras-library";
import { t } from "i18next";
import { useTitle } from "react-use";
import WithTopBarre from "../../components/templates/WithTopBarre";
import WithSideMenu from "../../components/templates/WithSideMenu/WithSideMenu";
import FileUpload from "../../components/atoms/FileUpload";
import style from "./PurgeRGPD.module.css";
import { useToastContext } from "../../store/ToastContext";
import purgeRGPDService from "../../services/purgeRGPD";

const PurgeRGPD = () => {
	const [file, setFile] = useState(null);
	const [, toastDispatch] = useToastContext();

	useTitle(t("purgeRGPD.title"));

	const handleFileChange = (e) => {
		if (e.length === 0) return;

		const fileToUpload = e[0];

		if (fileToUpload.type === "text/csv") {
			setFile(fileToUpload);
		} else {
			setFile(null);
			toastDispatch({
				type: "WARN",
				payload: {
					msg: t("purgeRGPD.fileWrongFormat"),
				},
			});
		}
	};

	const uploadFile = async () => {
		if (!file) {
			toastDispatch({
				type: "WARN",
				payload: {
					msg: t("purgeRGPD.fileMissing"),
				},
			});
			return;
		}

		const formData = new FormData();
		formData.append("purgeFile", file);

		try {
			purgeRGPDService.postUploadPurgeFile(formData).then(
				(res) => {
					if (res.status === 201) {
						toastDispatch({
							type: "SUCCESS",
							payload: {
								msg: t("purgeRGPD.uploadFileSuccess"),
							},
						});

						setFile(null);
					}
				},
				(error) => {
					const errorMessage = error.response?.data?.message ?? "uploadFileFailed";

					toastDispatch({
						type: "ERROR",
						payload: {
							icon: "alertCircle",
							msg: t(`purgeRGPD.${errorMessage}`),
						},
					});
				}
			);
		} catch (error) {
			toastDispatch({
				type: "ERROR",
				payload: {
					msg: t("purgeRGPD.uploadFileFailed"),
				},
			});
		}
	};

	return (
		<WithSideMenu>
			<WithTopBarre
				quickEntryContent={<div>test</div>}
				title={
					<Title tag="h1" size="heading01" lineHeight="s">
						{t("purgeRGPD.title")}
					</Title>
				}
				containerClassname="container"
			>
				<div>
					<div className={style.divInfo}>
						<p>{t("purgeRGPD.description")}</p>
						<p>{t("purgeRGPD.additionalInformation")}</p>
					</div>
					<FileUpload
						accept=".csv"
						disabled
						files={file ? [file] : []}
						handleFileDrop={handleFileChange}
						handleFileChange={handleFileChange}
						handleDeleteClick={() => {
							setFile(null);
						}}
						info={
							<span className={style.fileInputInfo}>
								{t("purgeRGPD.uploadFile")}
								<br />
								{t("purgeRGPD.dragFile")}
							</span>
						}
					/>
					{file && (
						<Button size="l" type="button" color="primary" disabled={!file} onClick={() => uploadFile()}>
							{t("purgeRGPD.uploadFile")}
						</Button>
					)}
				</div>
			</WithTopBarre>
		</WithSideMenu>
	);
};

export default PurgeRGPD;
