// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Button, ModalV2, Picto } from "@zolteam/react-ras-library";
import { Form, Formik, useFormikContext } from "formik";
import i18n from "i18next";
import { isEmpty, omit } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";

// Store
import { useMedia } from "react-use";
import { ConfigurationFormProvider } from "../../../store/ConfigurationContext";
import { useToastContext } from "../../../store/ToastContext";

/// Forms
import OrderFormBlock from "../../../components/organisms/OrderFormBlock";
import BillingFormBlock from "./forms/BillingFormBlock";
import CommandAppealCaseFormBlock from "./forms/CommandAppealCaseFormBlock";
import CommandClientFormBlock from "./forms/CommandClientFormBlock";
import CommandInterlocutorFormBlock from "./forms/CommandInterlocutorFormBlock";
import CommandLocationFormBlock from "./forms/CommandLocationFormBlock";
import CommandNotesFormBlock from "./forms/CommandNotesFormBlock";
import CommandRulesFormBlock from "./forms/CommandRulesFormBlock";
import CommandStartFormBlock from "./forms/CommandStartFormBlock";
import CommandTimeFormBlock from "./forms/CommandTimeFormBlock";
import CommandTypeFormBlock from "./forms/CommandTypeFormBlock";

// Components
import TooltipMessage from "../../../components/atoms/TooltipMessage";
import ModalContentNotifyUser from "./modals/ModalContentNotifyUser";

// Hooks
import useEnabledFeatures from "../../../hooks/useEnabledFeatures";
import useOrderData from "../../../hooks/useOrderData";

// Services
import { getOrderProfiles, getOrderTempoStatus, postOrder, putOrder } from "../../../services/commandService";

// Utils
import compareTimesSlots from "../../../utils/compareTimesSlots";
import { capitalizeWords } from "../../../utils/string";
import commandFormDefaultValues from "./forms/commandFormDefaultValues";
import commandFormValidation from "./forms/commandFormValidation";

// Style
import style from "./Configuration.module.css";

// Constants
import {
	CREATE_MODE,
	EDIT_MODE,
	ORDER_CREATION_INVALID_PARAMETERS_ERROR_CODE,
	ORDER_STATUS,
	PROFILES_STATUS,
} from "src/constants";
import ModalContentTempoConflict from "./modals/ModalContentTempoConflict";
import ModalContentTempoError from "./modals/ModalContentTempoError";
import useStepDisplaySection from "src/views/Command/Configuration/stepDisplaySection/useStepDisplaySection";

const ConfigurationForm = ({ isOrderFetched }) => {
	const { t } = useTranslation();
	const {
		enabledFeatures: { hasMissionListAccess, hasNotifyUserOrderEditionAccess },
		query: { isLoading },
	} = useEnabledFeatures();
	const { orderId } = useParams();
	const { values, isValid, dirty, validateForm, setErrors, setValues, touched } = useFormikContext();
	const { agencyId, clientId, qualification, agencyOption, commercialCode, isUpdatedOnTempo } = values;
	const history = useHistory();
	const [, toastDispatch] = useToastContext();
	const isSubmitDisabled = !(isValid && dirty);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isTempoConflictModalOpen, setIsTempoConflictModalOpen] = useState(false);
	const [isTempoErrorModalOpen, setIsTempoModalErrorOpen] = useState(false);
	const [initialFormValues, setInitialFormValues] = useState({});
	const [isAtLeastOneProfileValidated, setIsAtLeastOneProfileValidated] = useState(false);

	const isNewCommand = orderId === CREATE_MODE; // INFO: in URL Params, the command ID is replaced by the string CREATE_MODE
	const commandFormMode = isNewCommand ? CREATE_MODE : EDIT_MODE;

	// REFACTOR: Remove when feature flipping is no longer needed
	useEffect(() => {
		if (!isLoading) {
			if (!hasMissionListAccess) {
				history.push("/commandes");
			}
		}
	}, [isLoading, hasMissionListAccess]);

	/**
	 * Prepare order values before use create/edit endpoint
	 */
	const getPreparedOrderValues = (value) => {
		const notifyUsers = value?.notifyUsers || false;
		const cleanedValues = omit(
			{
				...values,
				period: {
					// Don't have choice here to transform the date for the API, they don't accept standard format,
					// but they send ISO Format
					startDate: values.period.startDate.replace(/T|[.||+][A-Z|0-9:]*$/g, " ").trim(),
					endDate: values.period.endDate.replace(/T|[.||+][A-Z|0-9:]*$/g, " ").trim(),
				},
				contact: {
					...values.contact,
					name: capitalizeWords(values.contact.name),
				},
				// Cast to float
				packageAmount: parseFloat(values.packageAmount),
				notifyUsers,
			},
			[
				"agencyOption",
				"commercialCodeOption",
				"clientOption",
				"appealCaseOption",
				"qualificationOption",
				"workAddressCountryOption",
				"workAddressMeetingCountryOption",
			]
		);

		// We need to reset coordinates if in edition mode, country is different than France and if the address has
		// changed
		const shouldResetCoordinates = !isNewCommand && values.workAddress.country !== "France" && touched.workAddress;
		if (shouldResetCoordinates) {
			return {
				...cleanedValues,
				workAddress: {
					...cleanedValues.workAddress,
					latitude: null,
					longitude: null,
				},
			};
		}
		return cleanedValues;
	};

	const handleProfilesValidated = (profiles) => {
		setIsAtLeastOneProfileValidated(profiles?.data?.some((profil) => profil.status === PROFILES_STATUS.VALIDATED));
	};

	/**
	 * Manage errors display on post/put order
	 * @param {Object} error
	 * @param {string} codeErrorMessage
	 */
	const manageErrorDisplay = (error, codeErrorMessage) => {
		const message = error.response?.data?.message;

		if (message === ORDER_CREATION_INVALID_PARAMETERS_ERROR_CODE) {
			// Remaps errors to match formik errors
			const newErrors = {};
			Object.keys(error.response?.data?.errors).forEach((key) => {
				const splittedKeys = key.split(".");
				const lastKey = splittedKeys.pop();
				const lastKeyObject = splittedKeys.reduce((acc, currentKey) => {
					if (!acc[currentKey]) acc[currentKey] = {};
					return acc[currentKey];
				}, newErrors);
				lastKeyObject[lastKey] = error.response?.data?.errors[key];
			});
			// Set errors for formik
			setErrors(newErrors);
		}
		if (message === codeErrorMessage) {
			Object.values(error.response?.data?.errors).forEach((msg) => {
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: i18n.exists(`commands.apiCommandErrors.${msg}`)
							? i18n.t(`commands.apiCommandErrors.${msg}`)
							: msg,
						params: {
							autoClose: true,
							closeOnClick: true,
						},
					},
				});
			});
			return;
		}

		toastDispatch({
			type: "ERROR",
			payload: {
				icon: "alertCircle",
				msg: i18n.exists(`commands.apiCommandErrors.${message}`)
					? i18n.t(`commands.apiCommandErrors.${message}`)
					: message,
				params: {
					autoClose: true,
					closeOnClick: true,
				},
			},
		});
	};

	// API Calls
	useQuery("getOrderProfiles", () => getOrderProfiles(orderId), {
		enabled: !!orderId && orderId !== CREATE_MODE,
		onSuccess: (profiles) => handleProfilesValidated(profiles),
	});

	const postOrderMutation = useMutation(() => postOrder(getPreparedOrderValues()), {
		onSuccess: (res) => {
			toastDispatch({
				type: "SUCCESS",
				payload: {
					icon: "checkCircle",
					msg: t("commands.apiCommandSucces.created"),
					params: {
						autoClose: true,
						closeOnClick: true,
					},
				},
			});
			history.push(`/commandes/${res?.data?.id}/configuration`);
		},
		onError: (err) => {
			manageErrorDisplay(err, ORDER_CREATION_INVALID_PARAMETERS_ERROR_CODE);
		},
	});

	const putOrderMutation = useMutation(
		async ({ notifyUsers, tempoConflictAware = false }) => {
			if (!tempoConflictAware && isUpdatedOnTempo) {
				setIsTempoConflictModalOpen(true);
				return { data: { confirmationNeeded: true } };
			}
			if (!tempoConflictAware) {
				try {
					const orderTempoStatus = await getOrderTempoStatus(orderId);

					if (orderTempoStatus?.data?.isUpdatedOnTempo) {
						setIsTempoConflictModalOpen(true);
						return { data: { confirmationNeeded: true } };
					}
				} catch (error) {
					if (error?.response?.data?.message === "ERR_GT_OTS_0001_TEMPO_CALL_IS_ON_ERROR") {
						setIsTempoModalErrorOpen(true);
						return { data: { confirmationNeeded: true } };
					}
				}
			}
			const orderEditRes = await putOrder(orderId, getPreparedOrderValues({ notifyUsers }));
			return orderEditRes;
		},
		{
			onSuccess: (res) => {
				if (res?.data?.confirmationNeeded) return;

				toastDispatch({
					type: "SUCCESS",
					payload: {
						icon: "checkCircle",
						msg: t("commands.apiCommandSucces.updated"),
						params: {
							autoClose: true,
							closeOnClick: true,
						},
					},
				});
				history.go(0);
			},
			onError: (err) => {
				if (err?.response?.data?.message === "ERR_CO_ORDER_0057_ORDER_CHANGED_OUTSIDE_OF_APIV2") {
					setIsTempoConflictModalOpen(true);
				} else manageErrorDisplay(err, "ERROR_MESSAGE_ON_EDIT");
			},
		}
	);

	const isFormSubmitting = useMemo(
		() => postOrderMutation.isLoading || putOrderMutation.isLoading,
		[postOrderMutation, putOrderMutation]
	);

	const submitForm = ({ shouldNotifyUsers = false, tempoConflictAware = false } = {}) => {
		setIsModalOpen(false);
		validateForm().then((validation) => {
			if (isEmpty(validation)) {
				if (commandFormMode === CREATE_MODE) {
					postOrderMutation.mutate({ tempoConflictAware });
				} else {
					putOrderMutation.mutate({ notifyUsers: shouldNotifyUsers, tempoConflictAware });
				}
			}
		});
	};

	const handleSubmitForm = () => {
		const { period, workingTime, workAddress } = initialFormValues;
		const { address, addressComplement, postalCode, city } = workAddress;
		const { slots } = workingTime;

		const { period: currentPeriod, workingTime: currentWorkingTime, workAddress: currentWorkAddress } = values;

		const isPeriodChanged = period !== currentPeriod;
		const isSlotsChanged = compareTimesSlots(slots, currentWorkingTime?.slots);
		const isAddressChanged = address !== currentWorkAddress?.address;
		const isAddressComplementChanged = addressComplement !== currentWorkAddress?.addressComplement;
		const isPostalCodeChanged = postalCode !== currentWorkAddress?.postalCode;
		const isCityChanged = city !== currentWorkAddress?.city;

		// Remove "hasNotifyUserOrderEditionAccess" when feature is ready
		const shouldOpenModal =
			values.status !== ORDER_STATUS.NOT_PROVIDED &&
			isAtLeastOneProfileValidated &&
			commandFormMode === EDIT_MODE &&
			hasNotifyUserOrderEditionAccess &&
			(isPeriodChanged ||
				isSlotsChanged ||
				isAddressChanged ||
				isAddressComplementChanged ||
				isPostalCodeChanged ||
				isCityChanged);

		if (shouldOpenModal) {
			setIsModalOpen(true);
		} else {
			submitForm();
		}
	};

	// prefill certain fields depending on, qualification, client and workAddress
	useEffect(() => {
		const { qualificationOption, clientOption, workAddress } = values;
		const {
			startAM: clientStartAm,
			startPM: clientStartPm,
			endAM: clientEndAm,
			endPM: clientEndPm,
		} = clientOption || {};
		const { startAm: workStartAm, startPm: workStartPm, endAm: workEndAm, endPm: workEndPm } = workAddress || {};
		const { startTimeAm, startTimePm, endTimeAm, endTimePm } = qualificationOption || {};

		const hasQualificationTimeSlot = !!startTimeAm || !!startTimePm;
		const hasWorkAddressTimeSlot = !!workStartAm || !!workStartPm;
		const hasClientTimeSlot = !!clientStartAm || !!clientStartPm;

		if (commandFormMode === CREATE_MODE) {
			let timeSlots = {};

			if (!hasQualificationTimeSlot && !hasWorkAddressTimeSlot && !hasClientTimeSlot) {
				setValues({
					...values,
					workingTime: {
						...values.workingTime,
						slots: [{ startHour: null, endHour: null }],
					},
				});
				return;
			}

			if (hasWorkAddressTimeSlot) {
				timeSlots = {
					startTimeAm: workStartAm || null,
					endTimeAm: workEndAm || null,
					startTimePm: workStartPm || null,
					endTimePm: workEndPm || null,
				};
			} else if (hasQualificationTimeSlot) {
				timeSlots = {
					startTimeAm,
					endTimeAm,
					startTimePm,
					endTimePm,
				};
			} else if (hasClientTimeSlot) {
				timeSlots = {
					startTimeAm: clientStartAm || null,
					endTimeAm: clientEndAm || null,
					startTimePm: clientStartPm || null,
					endTimePm: clientEndPm || null,
				};
			}

			const slots = [];
			if (timeSlots.startTimeAm || timeSlots.endTimeAm) {
				slots.push({
					startHour: timeSlots.startTimeAm,
					endHour: timeSlots.endTimeAm,
				});
			}
			if (timeSlots.startTimePm || timeSlots.endTimePm) {
				slots.push({
					startHour: timeSlots.startTimePm,
					endHour: timeSlots.endTimePm,
				});
			}

			setValues({
				...values,
				workingTime: {
					...values.workingTime,
					slots,
				},
			});
		}
	}, [
		values?.workAddress?.startAm,
		values?.workAddress?.startPm,
		values?.workAddress?.endAm,
		values?.workAddress?.endPm,
		values?.qualificationOption,
		values?.clientOption?.startAM,
		values?.clientOption?.endAM,
		values?.clientOption?.startPM,
		values?.clientOption?.endPM,
	]);

	useEffect(() => {
		setInitialFormValues(values);
	}, [values.updatedAt]);

	const { shouldDisplaySecondStep, shouldDisplayThirdStep } = useStepDisplaySection(
		agencyId,
		clientId,
		qualification,
		agencyOption,
		commercialCode
	);

	const showBlockOneColumn = useMedia("(max-width: 1439px)");

	const formBlocks = useMemo(
		() => ({
			TYPE: {
				id: 1,
				component: (
					<OrderFormBlock>
						<CommandTypeFormBlock isOrderFetched={isOrderFetched} />
					</OrderFormBlock>
				),
				isVisible: true,
			},
			NOTES: {
				id: 2,
				component: (
					<OrderFormBlock>
						<CommandNotesFormBlock isOrderFetched={isOrderFetched} />
					</OrderFormBlock>
				),
				isVisible: true,
			},
			CLIENT: {
				id: 3,
				component: (
					<OrderFormBlock title={t("commands.customerRequirement")}>
						<CommandClientFormBlock isOrderFetched={isOrderFetched} />
					</OrderFormBlock>
				),
				isVisible: !isNewCommand || shouldDisplaySecondStep,
			},
			TIME: {
				id: 4,
				component: (
					<OrderFormBlock title={t("commands.durationAndWorkingTime")}>
						<CommandTimeFormBlock isOrderFetched={isOrderFetched} />
					</OrderFormBlock>
				),
				isVisible: !isNewCommand || shouldDisplayThirdStep,
			},
			INTERLOCUTOR: {
				id: 5,
				component: (
					<OrderFormBlock title={t("commands.clientInterlocutor")}>
						<CommandInterlocutorFormBlock isOrderFetched={isOrderFetched} />
					</OrderFormBlock>
				),
				isVisible: !isNewCommand || shouldDisplayThirdStep,
			},
			LOCATION: {
				id: 6,
				component: (
					<OrderFormBlock title={t("commands.missionPlace")}>
						<CommandLocationFormBlock isOrderFetched={isOrderFetched} />
					</OrderFormBlock>
				),
				isVisible: !isNewCommand || shouldDisplayThirdStep,
			},
			RULES: {
				id: 7,
				component: (
					<OrderFormBlock title={t("commands.instructions")}>
						<CommandRulesFormBlock isOrderFetched={isOrderFetched} />
					</OrderFormBlock>
				),
				isVisible: !isNewCommand || shouldDisplayThirdStep,
			},
			START: {
				id: 8,
				component: (
					<OrderFormBlock title={t("commands.missionStart")}>
						<CommandStartFormBlock isOrderFetched={isOrderFetched} />
					</OrderFormBlock>
				),
				isVisible: !isNewCommand || shouldDisplayThirdStep,
			},
			BILLING: {
				id: 9,
				component: (
					<OrderFormBlock title={t("commands.billing")}>
						<BillingFormBlock isOrderFetched={isOrderFetched} />
					</OrderFormBlock>
				),
				isVisible: !isNewCommand || shouldDisplayThirdStep,
			},
			APPEAL_CASE: {
				id: 10,
				component: (
					<OrderFormBlock title={t("commands.appealCase")}>
						<CommandAppealCaseFormBlock isOrderFetched={isOrderFetched} />
					</OrderFormBlock>
				),
				isVisible: !isNewCommand || shouldDisplayThirdStep,
			},
		}),
		[shouldDisplaySecondStep, shouldDisplayThirdStep, isOrderFetched]
	);

	const blockList = useMemo(() => {
		if (showBlockOneColumn) {
			return [
				formBlocks.TYPE,
				formBlocks.NOTES,
				formBlocks.CLIENT,
				formBlocks.TIME,
				formBlocks.LOCATION,
				formBlocks.START,
				formBlocks.INTERLOCUTOR,
				formBlocks.RULES,
				formBlocks.APPEAL_CASE,
				formBlocks.BILLING,
			].filter((block) => block.isVisible);
		}
		return {
			left: [
				formBlocks.TYPE,
				formBlocks.CLIENT,
				formBlocks.INTERLOCUTOR,
				formBlocks.RULES,
				formBlocks.APPEAL_CASE,
			].filter((block) => block.isVisible),
			right: [
				formBlocks.NOTES,
				formBlocks.TIME,
				formBlocks.LOCATION,
				formBlocks.START,
				formBlocks.BILLING,
			].filter((block) => block.isVisible),
		};
	}, [showBlockOneColumn, formBlocks]);

	return (
		<ConfigurationFormProvider>
			{showBlockOneColumn ? (
				<div className={style.singleColumn}>
					{blockList.map((block) => (
						<Fragment key={block.id}>{block.component}</Fragment>
					))}
				</div>
			) : (
				<div className={style.multiColumn}>
					<div className={style.leftColumn}>
						{blockList.left.map((block) => (
							<Fragment key={block.id}>{block.component}</Fragment>
						))}
					</div>
					<div className={style.rightColumn}>
						{blockList.right.map((block) => (
							<Fragment key={block.id}>{block.component}</Fragment>
						))}
					</div>
				</div>
			)}
			<div className={style.buttonWrapper}>
				<Button
					data-testid="command-save"
					disabled={isSubmitDisabled || isFormSubmitting}
					color={isSubmitDisabled ? "grey" : "primary"}
					type="button"
					onClick={handleSubmitForm}
					isLoading={postOrderMutation.isLoading}
					className={style.submitButton}
				>
					{commandFormMode === EDIT_MODE ? t("commands.save") : t("commands.saveAndCreate")}
					{isFormSubmitting && <Picto icon="loader" className={style.pictoLoader} />}
				</Button>
				{isUpdatedOnTempo && (
					<TooltipMessage color="warning" arrow={false}>
						{t("commands.isUpdatedOnTempo")}
					</TooltipMessage>
				)}
			</div>
			<ModalV2 isDisplayed={isModalOpen} onClose={() => setIsModalOpen(false)} size="s">
				<ModalContentNotifyUser
					onCancelClick={() => setIsModalOpen(false)}
					onConfirmClick={(shouldNotifyUsers) => {
						submitForm({ shouldNotifyUsers });
					}}
				/>
			</ModalV2>
			<ModalV2 isDisplayed={isTempoConflictModalOpen} onClose={() => setIsTempoConflictModalOpen(false)} size="s">
				<ModalContentTempoConflict
					onCancelClick={() => setIsTempoConflictModalOpen(false)}
					onConfirmClick={() => {
						setIsTempoConflictModalOpen(false);
						submitForm({ tempoConflictAware: true });
					}}
				/>
			</ModalV2>
			<ModalV2 isDisplayed={isTempoErrorModalOpen} onClose={() => setIsTempoModalErrorOpen(false)} size="s">
				<ModalContentTempoError
					onCancelClick={() => setIsTempoModalErrorOpen(false)}
					onConfirmClick={() => {
						setIsTempoModalErrorOpen(false);
						submitForm({ tempoConflictAware: true });
					}}
				/>
			</ModalV2>
		</ConfigurationFormProvider>
	);
};

ConfigurationForm.propTypes = {
	isOrderFetched: PropTypes.bool.isRequired,
};

const Configuration = () => {
	const { orderId } = useParams();
	const { orderData, isOrderFetched } = useOrderData(orderId);

	return (
		<Formik
			enableReinitialize
			initialValues={orderData?.data || commandFormDefaultValues}
			validationSchema={commandFormValidation}
			onSubmit={() => {}}
		>
			<Form className={style.form}>
				<ConfigurationForm isOrderFetched={isOrderFetched} />
			</Form>
		</Formik>
	);
};

export default Configuration;
