// @ts-nocheck
/* eslint-disable max-len */
import React, { useMemo } from "react";
// import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { Route, Switch /* , useHistory */ } from "react-router-dom";
// import { BackLink, Text } from '@zolteam/react-ras-library';

// Side Menu Views
import ProfileMissions from "./ProfileMissions/ProfileMissions";

// Views
import ProfileMissionsFollowUpForm from "./ProfileMissions/ProfileMissionsFollowUp/forms/ProfileMissionsFollowUpForm";
import ProfileMissionsClientRelationshipForm from "./ProfileMissions/ProfileMissionsClientRelationships/forms/ProfileMissionsClientRelationshipForm";

// Templates
import WithSideMenu from "../../components/templates/WithSideMenu";
import WithTopBarre from "../../components/templates/WithTopBarre";
import ProfileSideMenu from "../../components/templates/ProfileSideMenu";

// Utils
import { CREATE_MODE, EDIT_MODE } from "../../constants";

// import style from './Profile.module.css';

const Profile = () => {
	// Hooks
	// const { t } = useTranslation();
	// const { profileId } = useParams();

	// const history = useHistory();

	// Side Menu Routing
	const routes = useMemo(
		() => [
			{
				path: "/profile/:profileId/missions/follow-up/create",
				routeName: "profile-missions-follow-up-create",
				label: null,
				exact: true,
				component: <ProfileMissionsFollowUpForm mode={CREATE_MODE} />,
				showInMenu: false,
			},
			{
				path: "/profile/:profileId/missions/follow-up/:followUpId",
				routeName: "profile-missions-follow-up-edit",
				label: null,
				exact: true,
				component: <ProfileMissionsFollowUpForm mode={EDIT_MODE} />,
				showInMenu: false,
			},
			{
				path: "/profile/:profileId/missions/client-relationship/create",
				routeName: "profile-missions-client-relationship-create",
				label: null,
				exact: true,
				component: <ProfileMissionsClientRelationshipForm mode={CREATE_MODE} />,
				showInMenu: false,
			},
			{
				path: "/profile/:profileId/missions/client-relationship/:relationshipId",
				routeName: "profile-missions-client-relationship-edit",
				label: null,
				exact: true,
				component: <ProfileMissionsClientRelationshipForm mode={EDIT_MODE} />,
				showInMenu: false,
			},
			{
				path: "/profile/:profileId/missions",
				routeName: "profile-missions",
				label: "Missions",
				exact: false,
				component: <ProfileMissions />,
				showInMenu: true,
			},
		],
		[]
	);

	return (
		<WithSideMenu>
			<WithTopBarre
				quickEntryContent={null}
				// TODO: Uncomment once profile list view is ready
				// leftContent={(
				//   <div style={{ display: 'flex' }}>
				//     <BackLink onClick={() => history.push('/profiles')}>
				//       <Text fontWeight="normal" tag="span">
				//         {t('profile.backLinkText')}
				//       </Text>
				//     </BackLink>
				//   </div>
				// )}
				leftContent={null}
				title={null}
				containerClassname="container-with-sidemenu"
			>
				<ProfileSideMenu routes={routes.filter((route) => route.showInMenu)}>
					<Switch>
						{routes.map((route) => (
							<Route key={route.path} exact={route.exact} path={`${route.path}`}>
								{route.component}
							</Route>
						))}
					</Switch>
				</ProfileSideMenu>
			</WithTopBarre>
		</WithSideMenu>
	);
};

Profile.propTypes = {};

export default Profile;
