// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Components
import { Picto, Popover, PopoverCursor } from "@zolteam/react-ras-library";
import Tag from "../../../../components/atoms/Tag";

// Hooks
import useOrderData from "../../../../hooks/useOrderData";

// Style
import style from "./DragCard.module.css";

// Utils
import { ORDER_STATUS } from "../../../../constants";
import cn from "../../../../utils/cn";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: "none",
	// this spread is mandatory for draggables (cf doc)
	...draggableStyle,
	// opacity: isDragging ? '0.6' : '1',
	boxShadow: isDragging ? "0px 2px 5px rgba(51, 51, 51, 0.1)" : "none",
});

const DragCard = ({ profile, availableActions, index, handleDoubleClick, className }) => {
	const { t } = useTranslation();
	const { orderId } = useParams();
	const { orderData } = useOrderData(orderId);

	// Actions are removed if the user is not allowed to use them
	const actions = useMemo(() => {
		let allowedActions = availableActions;
		if (!profile.userId) {
			allowedActions = allowedActions.filter((action) => action.type !== "goToFi");
		}
		if (
			(profile.status !== "SELECTED" && profile.status !== "CONTACTED") ||
			profile.hasBeenShared ||
			orderData?.data?.status === ORDER_STATUS.IS_FILLED
		) {
			allowedActions = allowedActions.filter((action) => action.type !== "shareAction");
		}
		if (profile.status !== "VALIDATED") {
			allowedActions = allowedActions.filter((action) => action.type !== "shareMissionOrder");
		}
		return allowedActions;
	}, [availableActions, profile, orderData]);

	return (
		<Draggable key={profile.id} draggableId={profile.id.toString()} index={index}>
			{(provided, snapshot) => (
				<div
					onDoubleClick={() => handleDoubleClick(profile.id)}
					className={cn([style.card, className])}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
				>
					<div className={style.cardHead}>
						<div className={style.profilHeader}>
							{profile.avatarUrl ? (
								<div className={style.avatarBox}>
									<img className={style.avatar} src={profile.avatarUrl} alt="user profil" />
									{profile.isVip && <Picto className={style.vipPicto} icon="vip" />}
								</div>
							) : (
								<div className={style.avatarPlaceholder}>
									<Picto icon="userDelete" />
									{profile.isVip && <Picto className={style.vipPicto} icon="vip" />}
								</div>
							)}

							{profile.isUnifiedTempWorkerValidated ? (
								<PopoverCursor
									content={<div className={style.popoverBox}>Compte initialisé sur my R.A.S</div>}
								>
									<div className={style.logoMyRas}>
										<Picto icon="logoMyRasAlt" className={style.myRasLogo} />
									</div>
								</PopoverCursor>
							) : null}

							{profile.hasBeenShared && (
								<PopoverCursor content={<div className={style.popoverBox}>Offre partagée</div>}>
									<div className={style.offerShared}>
										<Picto icon="paperPlaneSolid" />
									</div>
								</PopoverCursor>
							)}
							{profile.missionOrderHasBeenShared && (
								<PopoverCursor
									content={<div className={style.popoverBox}>Ordre de mission partagé</div>}
								>
									<div className={style.offerShared}>
										<Picto icon="clipboardActive" />
									</div>
								</PopoverCursor>
							)}
							{profile.isDelegatedBy247 && (
								<PopoverCursor
									content={
										<div className={style.popoverBox}>
											{t("commands.profiles.isDelegatedBy247")}
										</div>
									}
								>
									<div className={style.offerShared}>
										<Picto icon="timeTwentyFour" />
									</div>
								</PopoverCursor>
							)}
						</div>
						{actions.length > 0 ? (
							<Popover
								placement="bottom-end"
								clickInside
								content={
									<ul className={style.actionsList}>
										{actions.map((action) => (
											<li key={action.label} className={style.actionsListItem}>
												<button
													className={style.actionsListItemButton}
													type="button"
													onClick={() => {
														action.onClick(profile);
													}}
												>
													{action.label}
												</button>
											</li>
										))}
									</ul>
								}
							>
								<Picto icon="more" className={style.more} />
							</Popover>
						) : null}
					</div>
					<div className={style.cardBody}>
						<div className={style.profileName}>
							<span className={style.fullName}>{`${profile.firstName} ${profile.lastName}`}</span>
							{profile?.isCdiInterim && (
								<span className={style.profilAttribute} color="primaryLight">
									CDII
								</span>
							)}
						</div>

						{profile?.phone && (
							<Tag className={style.profilePhone} size="s" radius="m" color="grey">
								{/* TODO: dynamic country code */}
								{formatPhoneNumber(`+33${profile?.phone?.slice(1)}` || "")}
							</Tag>
						)}
					</div>
				</div>
			)}
		</Draggable>
	);
};
DragCard.propTypes = {
	profile: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		phone: PropTypes.string,
		avatarUrl: PropTypes.string,
		status: PropTypes.string,
		hasBeenShared: PropTypes.bool,
		missionOrderHasBeenShared: PropTypes.bool,
		isDelegatedBy247: PropTypes.bool,
		isUnifiedTempWorkerValidated: PropTypes.bool,
		userId: PropTypes.number,
		isVip: PropTypes.bool,
		isCdiInterim: PropTypes.bool,
	}).isRequired,
	index: PropTypes.number.isRequired,
	availableActions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			onClick: PropTypes.func,
		})
	),
	handleDoubleClick: PropTypes.func.isRequired,
	className: PropTypes.string,
};

DragCard.defaultProps = {
	availableActions: [],
	className: null,
};

export default DragCard;
