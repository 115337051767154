// @ts-nocheck
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Picto, Popover } from "@zolteam/react-ras-library";

// Style
import style from "./IndividualFileAccessLink.module.css";

const IndividualFileAccessLink = ({ options }) => {
	const { t } = useTranslation();

	const processUsers = (users) => {
		const processedUsers = users.map((user) => ({
			userId: user.userId,
			agencyName: user.agencyName.includes("RAS ") ? user.agencyName.replace("RAS ", "") : user.agencyName,
		}));

		const sortedUsers = [...processedUsers].sort((a, b) => a.agencyName.localeCompare(b.agencyName));

		return sortedUsers;
	};

	return (
		<Popover
			placement="bottom-end"
			clickInside
			appendTo={document.body}
			minWidth={100}
			content={
				<div className={style.popoverBox}>
					{processUsers(options).map((option) => (
						<div key={option.userId} className={style.option}>
							<a
								href={`${process.env.REACT_APP_BASE_OLD_URL}/#/fr/contacts/${option.userId}`}
								target="_blank"
								rel="noreferrer"
							>
								{option.agencyName.replace("RAS ", "")}
							</a>
						</div>
					))}
				</div>
			}
		>
			<div className={style.select}>
				<div className={style.popoverTriggerLarge}>
					<p className={style.text}>
						{t("matching.matchingProfileSections.matchingProfileSectionDetails.individualFileLink")}
					</p>
					<Picto icon="arrowDown" className={style.arrow} />
				</div>
				<Picto icon="profile" className={style.popoverTriggerSmall} />
			</div>
		</Popover>
	);
};

IndividualFileAccessLink.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			agencyName: PropTypes.string.isRequired,
			userId: PropTypes.string.isRequired,
		})
	).isRequired,
};

export default IndividualFileAccessLink;
