// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import cn from "../../../utils/cn";

import styles from "./ProfileNav.module.css";

const ProfileNav = ({ children, isActive = false, path }) => (
	<button type="button" className={cn([styles.btn, isActive ? styles.active : null])}>
		<NavLink to={path}>{children}</NavLink>
	</button>
);

ProfileNav.propTypes = {
	children: PropTypes.string.isRequired,
	isActive: PropTypes.bool,
	path: PropTypes.string.isRequired,
};

export default ProfileNav;
