// @ts-nocheck
/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Picto } from "@zolteam/react-ras-library";
// Style
import style from "./DateRangeInput.module.css";
// Misc
import cn from "../../../utils/cn";
// Component

const DateRangeInput = ({
	label,
	value = null,
	onClick,
	onClear,
	className = null,
	isActive = false,
	error = null,
	info = null,
	disabled = false,
}) => {
	const hasValue = !!value;

	return (
		<div className={cn([className, disabled ? style.disabled : null])}>
			<button
				className={cn([
					style.button,
					isActive ? style.active : null,
					error ? style.error : null,
					disabled ? style.disabledBtn : null,
				])}
				type="button"
				onClick={onClick}
			>
				<span className={cn([style.label, hasValue ? style.withValue : null])}>{label}</span>
				{hasValue ? <p className={style.value}>{value}</p> : null}
				{!disabled && (
					<div className={style.pictobtn} type="button" onClick={hasValue ? onClear : () => undefined}>
						<Picto className={style.picto} icon={hasValue && !disabled ? "close" : "calendar"} />
					</div>
				)}
			</button>
			{info && !error ? <p className={style.info}>{info}</p> : null}
		</div>
	);
};

DateRangeInput.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired,
	className: PropTypes.string,
	value: PropTypes.string,
	isActive: PropTypes.bool,
	error: PropTypes.string,
	info: PropTypes.string,
	disabled: PropTypes.bool,
};

export default DateRangeInput;
