import { FC } from "react";
import Logo from "../../../assets/picto/myRAS.svg";
import { cntm } from "../../../utils/cntm";
import SVG from "../SVG/SVG";

interface MyRasPictoProps {
	className?: string;
}

const MyRasPicto: FC<MyRasPictoProps> = ({ className }) => {
	return <SVG src={Logo} className={cntm(["w-14 text-primary-500 ", className || ""])} />;
};

export default MyRasPicto;
