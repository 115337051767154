// @ts-nocheck
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";

// Style
import style from "./ProgressBar.module.css";
import cn from "../../../utils/cn";

const ProgressBar = ({ completed = 0, color = "success", colorAuto = false }) => {
	const progressColorAuto = (percentage) => {
		if (percentage <= 10) return "error";
		if (percentage <= 60) return "warning";
		return "success";
	};

	return (
		<div
			className={cn([style.containerStyles, colorAuto ? style[progressColorAuto(completed)] : style[color]])}
			data-testid={`progressBar-${colorAuto ? progressColorAuto(completed) : color}`}
		>
			<div className={style.fillerStyles} style={{ width: `${completed}%` }} />
		</div>
	);
};

ProgressBar.propTypes = {
	completed: PropTypes.number,
	color: PropTypes.oneOf(["primary", "success", "warning", "error"]),
	colorAuto: PropTypes.bool,
};

export default ProgressBar;
