// @ts-nocheck
import { useMemo, useRef } from "react";
// import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Route, Switch, useParams, useLocation, useHistory } from "react-router-dom";
import { BackLink, Text } from "@zolteam/react-ras-library";
import { isEmpty } from "lodash";

// Nested Views
import Configuration from "./Configuration/Configuration";
import Profiles from "./Profiles/Profiles";
import Matching from "./Matching/Matching";

// Templates
import WithSideMenu from "../../components/templates/WithSideMenu";
import WithTopBarre from "../../components/templates/WithTopBarre";
import CommandSideMenu from "../../components/templates/CommandSideMenu";

// Components
import OrderMessage from "../../components/atoms/OrderMessage";

// Hooks

import useOrderData from "../../hooks/useOrderData";

// Constants
import { CREATE_MODE, EDIT_MODE, ORDER_CONTENT_BACKGROUND } from "../../constants";

const Command = () => {
	// Hooks
	const { t } = useTranslation();
	const { orderId } = useParams();
	const { pathname } = useLocation();
	const history = useHistory();
	const scrollRef = useRef(null);
	const { agencyData, clientData, qualificationData, orderData } = useOrderData(orderId);

	const allRoutes = useMemo(
		() => [
			{
				path: "/commandes/:orderId/configuration",
				routeName: "configuration",
				label: "Infos commande",
				component: <Configuration scrollRef={scrollRef} />,
				steps: [CREATE_MODE, EDIT_MODE],
			},
			{
				path: "/commandes/:orderId/matching",
				routeName: "matching",
				label: "Matching",
				component: <Matching agency={agencyData} client={clientData} />,
				steps: [EDIT_MODE],
			},
			{
				path: "/commandes/:orderId/profils",
				routeName: "profils",
				label: "Gestion des profils",
				component: <Profiles agency={agencyData} client={clientData} />,
				steps: [EDIT_MODE],
			},
		],
		[scrollRef, agencyData, clientData]
	);

	const isPathConfiguration = useMemo(() => pathname.includes("configuration"), [pathname]);

	const routes = useMemo(
		() => allRoutes.filter((route) => (orderId === CREATE_MODE ? route.steps.includes(CREATE_MODE) : true)),
		[allRoutes, orderId]
	);

	return (
		<WithSideMenu>
			<WithTopBarre
				leftContent={
					<div style={{ display: "flex" }}>
						<a href="/commandes" onClick={(e) => e.preventDefault()}>
							<BackLink onClick={() => history.push("/commandes")}>
								<Text fontWeight="normal" tag="span">
									{t("commands.allCommandsLink")}
								</Text>
							</BackLink>
						</a>
					</div>
				}
				rightContent={<OrderMessage />}
				containerClassname="container-with-sidemenu"
			>
				<CommandSideMenu
					agency={agencyData}
					client={clientData}
					qualification={qualificationData}
					backgroundType={
						isPathConfiguration ? ORDER_CONTENT_BACKGROUND.TRANSPARENT : ORDER_CONTENT_BACKGROUND.WHITE
					}
					contact={
						orderId !== CREATE_MODE && !isEmpty(orderData)
							? {
									orderStatus: orderData?.data?.orderStatus,
									...orderData?.data?.contact,
								}
							: {}
					}
					routes={routes}
					ref={scrollRef}
				>
					<Switch>
						{routes.map((route) => (
							<Route key={route.path} exact path={`${route.path}`}>
								{route.component}
							</Route>
						))}
					</Switch>
				</CommandSideMenu>
			</WithTopBarre>
		</WithSideMenu>
	);
};

export default Command;
