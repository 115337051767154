// @ts-nocheck
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import cn from "../../../utils/cn";
import style from "./TextArea.module.css";

const TextAreaInput = ({
	label = "",
	placeholder = "",
	className = "",
	disabled = false,
	maxLength = null,
	autoScale = false,
	value,
	onChange,
}) => {
	const elementRef = useRef(null);

	const handleChange = (event) => {
		const newValue = event.target.value;
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<div className={cn([style.container, className])}>
			<label className={cn([style.label])}>{label}</label>
			<div className={cn([style.wrapper, style.field])}>
				<textarea
					ref={elementRef}
					data-testid="textarea"
					value={value}
					placeholder={placeholder}
					disabled={disabled}
					onChange={handleChange}
					style={
						autoScale && elementRef.current
							? {
									height: elementRef.current.scrollHeight,
									overflow: "hidden",
									resize: "none",
								}
							: null
					}
				/>
			</div>
			{maxLength ? (
				<span className={cn([style.lengthHint, value.length > maxLength && style.errorLengthHint])}>
					{value.length}/{maxLength}
				</span>
			) : null}
		</div>
	);
};

TextAreaInput.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	autoScale: PropTypes.bool,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	maxLength: PropTypes.number,
};

export default TextAreaInput;
