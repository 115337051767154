// @ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Title, ToggleSwitch, Text, InfoMessage } from "@zolteam/react-ras-library";
import { format } from "date-fns";
import localeFr from "date-fns/locale/fr";
import { Trans, useTranslation } from "react-i18next";

// Hooks
import useOrderOffer from "../../../../hooks/useOrderOffer";

// Components
import JobOffer from "../../../../components/molecules/JobOffer";

// Utils
import cn from "../../../../utils/cn";

// Style
import style from "./Modals.module.css";

// TODO: dynamic agency and client data
const ModalContentSharingOffers = ({ profile = {}, onAddClick, onCancelClick, currentOrder, context }) => {
	// Hooks
	const { t } = useTranslation();
	const [showClientName, setShowClientName] = useState(false);
	const [showCityAndPostalCode, setShowCityAndPostalCode] = useState(false);
	const { agencyName, qualificationName, period, clientName, missionTime, clientLocationSMS, workAddress } =
		useOrderOffer(currentOrder);

	const shownClient = () =>
		t("commands.profiles.modalsContent.sharingOffers.shownClient", {
			clientName,
		});

	const shownLocation = () => {
		if (workAddress?.city && workAddress?.postalCode) {
			return t("commands.profiles.modalsContent.sharingOffers.shownLocation", {
				clientLocationSMS,
			});
		}
		return `, ${clientLocationSMS}`;
	};

	const renderSectorOnly = () => (workAddress?.geographicSector ? `, ${workAddress?.geographicSector}` : "");

	const renderTimeSpan = () => {
		const { startHour, endHour, startHour2, endHour2 } = missionTime ?? {};
		const hasFirstSlotValid = !!startHour && !!endHour;
		const hasLastSlotValid = !!startHour2 && !!endHour2;
		const hasExactlyOneEmptyStringValue = (obj) => {
			const values = Object.values(obj);
			const emptyStringCount = values.filter((value) => value === "").length;
			return emptyStringCount === 1;
		};

		if (hasExactlyOneEmptyStringValue(missionTime)) {
			return "";
		}

		if (hasFirstSlotValid && hasLastSlotValid) {
			return t("commands.profiles.modalsContent.sharingOffers.timeSpanFull", {
				timeSpan: t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
					startHour,
					endHour,
				}),
				timeSpan2: startHour2
					? t("commands.profiles.modalsContent.sharingOffers.timeSPan2WithSlash", {
							startHour: startHour2,
							endHour: endHour2,
						})
					: "",
			});
		}
		if (hasFirstSlotValid && !hasLastSlotValid) {
			return ` (${t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
				startHour,
				endHour,
			})})`;
		}
		if (!hasFirstSlotValid && hasLastSlotValid) {
			return ` (${t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
				startHour: startHour2,
				endHour: endHour2,
			})})`;
		}

		return "";
	};

	return (
		<div className={style.content}>
			<Title tag="h3" size="heading01" lineHeight="l" className={style.title}>
				{t("commands.profiles.modalsContent.sharingOffers.title")}
			</Title>
			<div className={style.header}>
				{!profile && (
					<div className={style.infoMessage}>
						<InfoMessage color="primary" withIcon>
							{t("commands.profiles.modalsContent.sharingOffers.informationMessageBulk")}
						</InfoMessage>
					</div>
				)}
				<div className={style.infoMessage}>
					<InfoMessage color="primary" withIcon>
						{t("commands.profiles.modalsContent.sharingOffers.informationMessage")}
					</InfoMessage>
				</div>

				<div className={style.infoMessageBox}>
					<div className={style.switch}>
						<ToggleSwitch
							id="clientNameToggle"
							name="clientNameToggle"
							onChange={() => {
								setShowClientName(!showClientName);
							}}
							toggled={showClientName}
						>
							<label htmlFor="clientNameToggle" className={style["toggle-label"]}>
								{t("commands.profiles.modalsContent.sharingOffers.toggleClientName")}
							</label>
						</ToggleSwitch>
					</div>

					<div className={style.switch}>
						<ToggleSwitch
							id="locationToggle"
							name="locationToggle"
							onChange={() => {
								setShowCityAndPostalCode(!showCityAndPostalCode);
							}}
							toggled={showCityAndPostalCode}
						>
							<label htmlFor="locationToggle" className={style["toggle-label"]}>
								{t("commands.profiles.modalsContent.sharingOffers.toggleCityAndZipCode")}
							</label>
						</ToggleSwitch>
					</div>
				</div>

				<div className={style.subTitle}>
					<Text tag="p" size="paragraph01" fontWeight="bold" lineHeight="m">
						{t("commands.profiles.modalsContent.sharingOffers.overviewOffer")}
					</Text>
				</div>
			</div>

			<JobOffer
				currentOrder={currentOrder}
				showClientName={showClientName}
				showCityAndPostalCode={showCityAndPostalCode}
				context={context}
			/>

			<Text
				tag="p"
				size="paragraph01"
				fontWeight="bold"
				className={cn([style.subTitle, style.smsHeader])}
				lineHeight="m"
			>
				{t("commands.profiles.modalsContent.sharingOffers.textOffer")}
			</Text>

			<div className={style.textBox}>
				<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l">
					{t("commands.profiles.modalsContent.sharingOffers.missionIntro", {
						profileName:
							profile?.firstName || t("commands.profiles.modalsContent.sharingOffers.noFirstName"),
						agencyName,
					})}
					<br />
					{t("commands.profiles.modalsContent.sharingOffers.missionDetails", {
						qualificationName,
						periodSpan: t("commands.profiles.modalsContent.sharingOffers.periodSpanLowerCase", {
							startDate: period?.startDate
								? format(new Date(period?.startDate), "dd MMM yyyy", {
										locale: localeFr,
									})
								: "",
							endDate: period?.endDate
								? format(new Date(period?.endDate), "dd MMM yyyy", {
										locale: localeFr,
									})
								: "",
						}),
						timeSpanFull: renderTimeSpan(),
						clientName: showClientName ? shownClient() : "",
						clientLocation: showCityAndPostalCode ? shownLocation() : renderSectorOnly(),
					})}
				</Text>
				<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l">
					<Trans i18nKey="commands.profiles.modalsContent.sharingOffers.missionAction" />
				</Text>
			</div>

			<div className={style.footerMessage}>
				<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l">
					{t("commands.profiles.modalsContent.sharingOffers.furtherInformation")}
				</Text>
			</div>

			<hr className={style.separator} />
			<div className={style.buttonsBlock}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.profiles.modalsContent.sharingOffers.cancelAction")}
				</Button>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={() => onAddClick({ showClientName, showCityAndPostalCode })}
				>
					{t("commands.profiles.modalsContent.sharingOffers.addAction")}
				</Button>
			</div>
		</div>
	);
};

ModalContentSharingOffers.propTypes = {
	profile: PropTypes.shape(),
	context: PropTypes.string.isRequired,
	onAddClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
	currentOrder: PropTypes.shape().isRequired,
};

export default ModalContentSharingOffers;
