// @ts-nocheck
import { useMemo } from "react";

const useOrderOffer = (order) => {
	const { agency, qualificationName, period, workingTime, client, workAddress, missionDescription } = order || {
		agencyId: "",
		qualificationName: "",
		period: "",
		workingTime: {},
		clientId: "",
		workAddress: {},
		missionDescription: "",
	};

	const missionTime = useMemo(() => {
		const convertHour = (hour) => hour?.replace(":", "h") || "";
		const { slots } = workingTime || {};

		if (slots?.length > 0) {
			if (slots.length === 1) {
				return {
					startHour: convertHour(slots[0]?.startHour),
					endHour: convertHour(slots[0]?.endHour),
					startHour2: null,
					endHour2: null,
				};
			}
			if (slots.length === 2) {
				return {
					startHour: convertHour(slots[0]?.startHour),
					endHour: convertHour(slots[0]?.endHour),
					startHour2: convertHour(slots[1]?.startHour),
					endHour2: convertHour(slots[1]?.endHour),
				};
			}
		}
		return {
			startHour: "",
			endHour: "",
			startHour2: "",
			endHour2: "",
		};
	}, [workingTime]);

	const clientLocation = useMemo(() => {
		const { city, postalCode, country, geographicSector } = workAddress || {};

		if (city && postalCode) {
			let locationString = `${city} (${postalCode})`;

			if (country) {
				locationString += ` ${country}`;
			}

			return locationString;
		}

		return geographicSector || "";
	}, [workAddress]);

	const clientLocationSMS = useMemo(() => {
		const { city, postalCode, country, geographicSector } = workAddress || {};

		if (city && postalCode) {
			let locationString = `${city} (${postalCode})`;

			if (country && country.toLowerCase() !== "france") {
				locationString += ` ${country}`;
			}

			if (geographicSector) {
				locationString += `, ${geographicSector}`;
			}

			return locationString;
		}

		return geographicSector || "";
	}, [workAddress]);

	const clientLocationSidebar = useMemo(() => {
		if (Object.keys(workAddress).length === 0) {
			return null;
		}
		if (workAddress?.city && workAddress?.postalCode) {
			const { city, postalCode } = workAddress;
			return `${city} (${postalCode})`;
		}
		if (workAddress?.postalCode && !workAddress?.city) {
			const { postalCode } = workAddress;
			return `(${postalCode})`;
		}
		if (workAddress?.city && !workAddress?.postalCode) {
			const { city } = workAddress;
			return `${city}`;
		}
		return "";
	});

	return {
		agencyName: agency?.name || "",
		qualificationName,
		period,
		clientName: client?.name || "",
		missionTime,
		clientLocation,
		workAddress,
		missionDescription,
		clientLocationSidebar,
		clientLocationSMS,
	};
};

export default useOrderOffer;
