// @ts-nocheck
import React from "react";
import { Checkbox, Input, Picto, Select, Textarea } from "@zolteam/react-ras-library";
import style from "./FormCreateAdvancePayments.module.css";
import { t } from "i18next";
import DateSinglePickerField from "../../../../components/molecules/DateSinglePickerField/DateSinglePickerField";
import { getAtomFromDate } from "../../../../utils/formatDate";
import isDayBlocked from "../../../../views/AdvancePayments/utils/isDayBlocked";
import { useFormikContext } from "formik";
import { useQueries } from "react-query";
import user from "../../../../services/user";
import users from "../../../../services/users";

const FormCreateAdvancePaymentsInputs = () => {
	const { values, setValues, setFieldValue, setFieldTouched } = useFormikContext();

	const mapConvertUserOptions = (options) =>
		options.map((option) => ({
			value: option.id,
			label: `${option.firstName} ${option.lastName} - ${option.tempoId}`,
		}));

	const [getUserAgenciesQuery, getUsersByAgencyQuery] = useQueries([
		{
			queryKey: ["getUserAgencies"],
			queryFn: () => user.getUserAgencies(),
		},
		{
			queryKey: ["getUsersByAgency", values?.agencyId],
			queryFn: () => users.postUsers(values?.agencyId, ""),
			enabled: values?.agencyId !== null,
		},
	]);
	const { data: userAgenciesData } = getUserAgenciesQuery;
	const { data: usersByAgencyData, isLoading: isLoadingUsers } = getUsersByAgencyQuery;

	return (
		<>
			<Select
				className={style.selectAgency}
				size="m"
				value={values.agencyId ? { id: values?.agencyId, name: values?.agencyName } : null}
				name="agencyId"
				label={t("advancePayments.labelSearchAgency")}
				placeholder={t("advancePayments.labelSearchAgency")}
				options={userAgenciesData?.data || []}
				onChange={(agency) =>
					setValues({
						...values,
						agencyId: agency.id,
						userId: undefined,
						agencyName: agency.name,
					})
				}
				getOptionLabel={(option) => option.name}
				getOptionValue={(option) => option.id}
				noOptionsMessage={() => t("global.noDataText")}
			/>

			<Select
				className={style.selectUser}
				size="m"
				name="userId"
				label={t("advancePayments.labelSearchUser")}
				placeholder={t("advancePayments.labelSearchUser")}
				value={values?.userId || null}
				options={mapConvertUserOptions(usersByAgencyData?.data?.users || [])}
				onChange={(selectedUser) => setFieldValue("userId", selectedUser)}
				disabled={!values?.agencyId || isLoadingUsers}
				noOptionsMessage={() => t("global.noDataText")}
			/>

			<div className={style.containerTwoCols}>
				<div className={style.inputWithPicto}>
					<Input
						type="string"
						name="requestedAmount"
						id="requestedAmount"
						className={style.requestedAmount}
						label={t("advancePayments.requestedAmount")}
						onChange={(e) => setFieldValue("requestedAmount", e.target.value)}
						disabled={values?.isMaximumAdvanceRequested}
						value={values?.requestedAmount || ""}
					/>
					<Picto icon="euro" className={style.infoPicto} />
				</div>
				<div className={style.checkbox}>
					<Checkbox
						className={style.checkboxComponent}
						onChange={() =>
							setValues({
								...values,
								isMaximumAdvanceRequested: !values.isMaximumAdvanceRequested,
								requestedAmount: values.isMaximumAdvanceRequested ? values.requestedAmount : null,
							})
						}
						theme="primary"
						name="isMaximumAdvanceRequested"
						id="isMaximumAdvanceRequested"
						value={values?.isMaximumAdvanceRequested}
						label={t("advancePayments.isMaximumAdvanceRequested")}
					/>
				</div>
			</div>

			<div className={`${style.desiredPaymentDate} ${style.containerTwoCols}`}>
				<DateSinglePickerField
					id="desiredPaymentDate"
					name="desiredPaymentDate"
					label={t("advancePayments.desiredPaymentDate")}
					value={values.desiredPaymentDate}
					onClickOnInput={() => setFieldTouched("desiredPaymentDate", true)}
					onClear={() => {
						setFieldTouched("desiredPaymentDate", true);
						setFieldValue("desiredPaymentDate", null);
					}}
					onDateChange={(date) => setFieldValue("desiredPaymentDate", date ? getAtomFromDate(date) : null)}
					isDayBlocked={isDayBlocked}
					error={null}
					keepOpenOnDateSelect={false}
					inputClassName={style.desiredPaymentDateInput}
					numberOfMonths={1}
				/>
				<div className={style.flex1} />
			</div>

			<Textarea
				className="mt-l"
				label={t("advancePayments.comment")}
				placeholder={t("advancePayments.comment")}
				name="comment"
				value={values?.comment}
				autoScale
				onChange={(event) => setFieldValue("comment", event.target.value)}
				shouldDisplayErrorMessage
			/>
		</>
	);
};

export default FormCreateAdvancePaymentsInputs;
