// @ts-nocheck
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { useFormikContext } from "formik";
import set from "lodash/set";

import DateRangePicker from "../DateRangePicker";
import DateRangeInput from "../../atoms/DateRangeInput/DateRangeInput";

// Utils
import { getAtomFromDate } from "../../../utils/formatDate";

import cn from "../../../utils/cn";

import style from "./ProfileMissionFollowUpPeriod.module.css";

const DATE_FORMAT_STRING = "DD/MM/yyyy";

const ProfileMissionFollowUpPeriod = ({
	startDateInputName,
	endDateInputName,
	startDateLabel,
	endDateLabel,
	isLocked = false,
	needDatesEnteredInTempo,
}) => {
	const { setValues, values, touched, errors, setFieldTouched } = useFormikContext();

	const { t } = useTranslation();

	const [focusedInputName, setFocusedInputName] = useState("");

	const startDateValue = values[startDateInputName];
	const endDateValue = values[endDateInputName];

	const handleClearPeriodDate = (periodSlot, event) => {
		event.stopPropagation();
		const newValues = {
			...values,
			[periodSlot]: null,
		};
		setFieldTouched(periodSlot, true);
		set(newValues, periodSlot);
		setValues(newValues);
	};

	const handleDateRangeUpdate = (startDate, endDate) => {
		const newPeriod = {
			[startDateInputName]: startDate ? getAtomFromDate(startDate) : null,
			[endDateInputName]: endDate ? getAtomFromDate(endDate) : null,
		};

		if (startDate) {
			setFieldTouched(startDateInputName, true);
		}
		if (endDate) {
			setFieldTouched(endDateInputName, true);
		}

		return setValues({
			...values,
			...newPeriod,
		});
	};

	return (
		<div className={style.dateRangeWrapper}>
			<DateRangeInput
				label={t(startDateLabel)}
				disabled={isLocked}
				value={startDateValue ? moment(startDateValue).format(DATE_FORMAT_STRING) : ""}
				onClick={() => {
					setFieldTouched(startDateInputName, true);
					setFocusedInputName(startDateInputName);
				}}
				onClear={(e) => handleClearPeriodDate(startDateInputName, e)}
				className={style.fieldStartDate}
				isActive={focusedInputName === startDateInputName}
				error={
					touched?.startDate && errors?.startDate && !needDatesEnteredInTempo
						? t("commands.error.mandatoryStartDateField")
						: null
				}
				info={t("commands.requiredField")}
			/>
			<DateRangeInput
				label={t(endDateLabel)}
				disabled={isLocked}
				value={endDateValue ? moment(endDateValue).format(DATE_FORMAT_STRING) : ""}
				onClick={() => {
					setFieldTouched(endDateInputName, true);
					setFocusedInputName(endDateInputName);
				}}
				onClear={(e) => handleClearPeriodDate(endDateInputName, e)}
				className={cn([style.dateRangeField, style.fieldEndDate])}
				isActive={focusedInputName === endDateInputName}
				error={
					touched?.endDate && errors?.endDate && !needDatesEnteredInTempo
						? t("commands.error.mandatoryEndDateField")
						: null
				}
				info={t("commands.requiredField")}
			/>
			<DateRangePicker
				className={style.datePickerWrapper}
				dateFrom={moment(startDateValue || new Date())
					.startOf("day")
					.add(12, "hours")}
				dateTo={endDateValue ? moment(endDateValue) : null}
				focusedInput={focusedInputName.replace("period.", "")}
				setFocusedInput={(reactDatesInputName) =>
					setFocusedInputName(reactDatesInputName ? `period.${reactDatesInputName}` : "")
				}
				onDatesChange={handleDateRangeUpdate}
				keepOpenOnDateSelect
				isVisible={focusedInputName !== ""}
				isDayBlocked={() => false}
				setIsVisible={() => setFocusedInputName("")}
			/>
		</div>
	);
};

ProfileMissionFollowUpPeriod.propTypes = {
	startDateInputName: PropTypes.string.isRequired,
	endDateInputName: PropTypes.string.isRequired,
	startDateLabel: PropTypes.string.isRequired,
	endDateLabel: PropTypes.string.isRequired,
	isLocked: PropTypes.bool,
	needDatesEnteredInTempo: PropTypes.bool.isRequired,
};

export default ProfileMissionFollowUpPeriod;
