// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Title, Text, SelectAsync } from "@zolteam/react-ras-library";
import { useTranslation, Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import { Formik } from "formik";

// Style
import style from "./Modals.module.css";
import cn from "../../../../utils/cn";

// Services
import { getOrderNotProvidedReasons } from "../../../../services/commandService";

const ModalContentOrderNotProvided = ({ onAddClick, onCancelClick }) => {
	// States
	const [orderNotProvidedSelected, setOrderNotProvidedSelected] = useState(null);
	// Hooks
	const { t } = useTranslation();
	const { orderId } = useParams();

	// API Call
	const orderNotProvidedReasonsAsync = () => getOrderNotProvidedReasons(orderId).then((res) => res.data);

	const handleSelectOrderNotProvidedReasons = (selectedReason) => {
		setOrderNotProvidedSelected(selectedReason);
	};

	return (
		<div className={cn([style.content, style.limitWidth])}>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.profiles.modalsContent.orderNotProvided.title")}
			</Title>

			<div>
				<div className={style.infoMessage}>
					<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l">
						{t("commands.profiles.modalsContent.orderNotProvided.infoMessage")}
					</Text>
				</div>
				<div className={style.selectInput}>
					<Formik enableReinitialize initialValues={{ status: null, profile: null }}>
						{() => (
							<SelectAsync
								isSearchable={false}
								className={style.inputs}
								cacheOptions
								defaultOptions
								promiseOptions={orderNotProvidedReasonsAsync}
								onChange={handleSelectOrderNotProvidedReasons}
								name="orderNotProvidedReason"
								label={t("commands.orderNotProvidedReason")}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.id}
								value={orderNotProvidedSelected}
								noOptionsMessage={() => t("commands.selectNoAppealCaseFound")}
								loadingMessage={() => t("commands.selectLoadingorderNotProvidedReason")}
							/>
						)}
					</Formik>
				</div>
				<div className={style.warningMessage}>
					<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l">
						<Trans i18nKey="commands.profiles.modalsContent.orderNotProvided.warningMessage" />
					</Text>
				</div>
				<div className={style.confirmationMessage}>
					<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l">
						{t("commands.profiles.modalsContent.orderNotProvided.confirmationMessage")}
					</Text>
				</div>
			</div>

			<hr className={style.separator} />
			<div className={style.buttonsBlock}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.profiles.modalsContent.orderNotProvided.cancelAction")}
				</Button>
				<Button
					disabled={!orderNotProvidedSelected}
					className={style.validationButtonRight}
					type="button"
					color={!orderNotProvidedSelected ? "grey" : "primary"}
					onClick={() => onAddClick(orderNotProvidedSelected)}
				>
					{t("commands.profiles.modalsContent.orderNotProvided.confirmAction")}
				</Button>
			</div>
		</div>
	);
};

ModalContentOrderNotProvided.propTypes = {
	onAddClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
};

export default ModalContentOrderNotProvided;
