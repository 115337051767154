// @ts-nocheck
import { NOTIFICATION_STATUS_UNREAD } from "../constants";

/**
 * Get commercial codes list without null value from agencies list
 * @param agencies
 * @returns {unknown[]}
 */
export const prepareCommercialCodeList = (agencies = []) => {
	const commercialCodes = agencies?.map((agency) => agency.commercialCode);

	return commercialCodes?.filter((code, index) => code && commercialCodes.indexOf(code) === index);
};

/**
 * Get tempo folder name list from agencies list
 * @param agencies
 * @returns {unknown[] | undefined}
 */
export const prepareAgencyTempoFolderNameList = (agencies = []) =>
	agencies?.map((agency) => agency.tempoFolderName).filter((tempoFolderName) => tempoFolderName !== null);

/**
 * @param filters
 * @returns {*&{agencies: unknown[]}}
 */
export const prepareSearchFilters = (filters) => ({
	...filters,
	agencies: prepareAgencyTempoFolderNameList(filters.agencies),
	commercialCodes: prepareCommercialCodeList(filters.agencies),
});

export const prepareFiltersForUnreadCount = (filters) => ({
	...filters,
	agencies: prepareAgencyTempoFolderNameList(filters.agencies),
	commercialCodes: prepareCommercialCodeList(filters.agencies),
	status: NOTIFICATION_STATUS_UNREAD,
});
