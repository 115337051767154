// @ts-nocheck
import PropTypes from "prop-types";
import SpinnerIcon from "../../icons/Spinner";
import styles from "./Spinner.module.css";

const Spinner = ({ size, style, text }) => (
	<div
		style={{
			width: "100%",
			textAlign: "center",
			...style,
		}}
	>
		<SpinnerIcon
			className={styles.loadingIcon}
			style={{
				width: size,
				height: size,
			}}
		/>
		{!!text.length && <div>{text}</div>}
	</div>
);

Spinner.propTypes = {
	size: PropTypes.number,
	style: PropTypes.shape({}),
	text: PropTypes.string,
};

Spinner.defaultProps = {
	size: 30,
	style: {},
	text: "",
};

export default Spinner;
