// @ts-nocheck
/* eslint-disable no-debugger */
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, isValid } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { fr } from "date-fns/locale";

const locales = { fr };

export function createDateAsUTC(date) {
	return new Date(
		Date.UTC(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds()
		)
	);
}

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export const formatDate = (date, formatStr = "PP") => {
	const localesLanguage = typeof navigator !== "undefined" ? navigator.language.split("-")[0] : "fr";
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || "Europe/Paris";
	const dateUTC = createDateAsUTC(new Date(date));

	if (isValid(dateUTC) && date)
		return formatInTimeZone(dateUTC, timezone, formatStr, {
			locale: locales[localesLanguage],
		});
	return "";
};

export function dateDifference(updated, now) {
	let result = "";

	const diffSeconds = differenceInSeconds(now, updated);
	const diffMinutes = differenceInMinutes(now, updated);
	const diffHours = differenceInHours(now, updated);
	const diffDays = differenceInDays(now, updated);

	if (diffSeconds >= 1) result = "1min";
	if (diffMinutes >= 2 && diffMinutes < 60) result = `${diffMinutes}min`;
	if (diffMinutes >= 60 && diffHours < 24) result = `${diffHours}h`;
	if (diffDays >= 1) result = `${diffDays}j`;

	return result;
}

// Should be used when date is already in ISO format
export function getAtomFromISOString(isoDate) {
	return `${isoDate.replace(/[.||+][A-Z|0-9:]*$/g, " ").trim()}+0000`;
}

// Should be used when using a Date object
export function getAtomFromDate(date) {
	return getAtomFromISOString(date.toISOString());
}
