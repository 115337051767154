// @ts-nocheck
import { Button, Picto, Tag, Tooltip } from "@zolteam/react-ras-library";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Utils
import cn from "../../../utils/cn";

// style
import style from "./MatchingProfilItem.module.css";

// store
import { useMatchingContext } from "../../../store/MatchingContext";

// Utils
import formatAgencyListing from "../../../utils/matching/formatAgencyListing";
import VipTag from "../../../components/atoms/VipTag";

const MatchingProfilItem = ({
	cdiInterimAgencies,
	vipAgencies,
	title,
	firstName,
	lastName,
	orderProfileStatus,
	hasUserOnReferenceAgencyOrder,
	handleProfileClick,
	outOfBusinessReasons,
	id,
	hasNationality,
	isLoading,
	hasResidentPermit,
	hasValidMedicalCheckup,
}) => {
	const [{ filters, unifiedTempWorkerId, orderAgencyId }, dispatchMatching] = useMatchingContext();
	const { t } = useTranslation();
	const isGlobalSearch = filters.agency === "all";

	const isOutOfBusinessOnCurrentAgency = (outOfBusinessReason) => {
		if (isGlobalSearch) {
			return false;
		}
		return outOfBusinessReason?.some(({ agencyId: agency }) => agency === orderAgencyId);
	};

	const isOutOfBusinessOnOtherAgencies = (outOfBusinessReason) => {
		if (isGlobalSearch) {
			return false;
		}
		return outOfBusinessReason?.some(({ agencyId: agency }) => agency !== orderAgencyId);
	};

	const cleanAndSortOutOfBusinessReasons = (reasons) => {
		const cleanedData = reasons.map((item) => ({
			...item,
			agencyName: item.agencyName.replace("RAS ", ""),
		}));
		return cleanedData.sort((a, b) => a.agencyName.localeCompare(b.agencyName));
	};

	const tooltipDissociateStyle = {
		maxWidth: "inherit",
		padding: ".625rem 1.25rem",
	};

	const renderTooltipForOutOfBusinessReason = (content, children) => (
		<Tooltip arrow animation={false} placement="top" content={content} tooltipBoxStyle={tooltipDissociateStyle}>
			{children}
		</Tooltip>
	);

	const getOutOfBusinessTooltipForOtherAgencies = () => {
		if (isGlobalSearch && !hasResidentPermit) return null;

		const sortedData = cleanAndSortOutOfBusinessReasons(outOfBusinessReasons);

		if (isGlobalSearch && hasResidentPermit) {
			return <span>{t("matching.matchingProfileItem.noResidencePermit")}</span>;
		}

		if (isOutOfBusinessOnOtherAgencies(outOfBusinessReasons) || hasResidentPermit) {
			const content = (
				<>
					<div className={style.tooltipText}>
						{hasResidentPermit && (
							<div>
								<span>{t("matching.matchingProfileItem.noResidencePermit")}</span>
								<br />
							</div>
						)}
					</div>
					<div className={style.tooltipText}>
						{outOfBusinessReasons.filter(
							({ agencyId: currentAgencyId }) => currentAgencyId !== orderAgencyId
						).length ? (
							<>
								<span>{t("matching.matchingProfileItem.outOfBusinessOnOtherAgencies")}</span>
								<br />
							</>
						) : null}
						{sortedData
							.filter(({ agencyId: currentAgencyId }) => currentAgencyId !== orderAgencyId)
							.map((agency) => (
								<span key={agency.agencyId}>
									<span>{`${agency.agencyName} - `}</span>
									{agency.outOfBusinessReason.map((reason, index) => (
										<span key={reason}>
											{`${t(`outOfBusinessReasons.${reason}`)}
												${index !== agency.outOfBusinessReason.length - 1 ? ", " : ""} `}
										</span>
									))}
								</span>
							))}
					</div>
				</>
			);
			return content;
		}
		return null;
	};

	const getMedicalVisitTooltipContent = () => {
		if (hasValidMedicalCheckup || hasValidMedicalCheckup === undefined) {
			return null;
		}
		return (
			<div className={style.tooltipText}>
				<span>{t("matching.matchingProfileItem.hasValidMedicalCheckup")}</span>
			</div>
		);
	};

	const renderOutOfBusinessTooltipForCurrentAgency = () => {
		if (isGlobalSearch) return null;
		if (!outOfBusinessReasons.length === 0) return null;
		const sortedData = cleanAndSortOutOfBusinessReasons(outOfBusinessReasons);
		if (sortedData.length === 0) {
			return null;
		}

		if (!isGlobalSearch) {
			if (isOutOfBusinessOnCurrentAgency(outOfBusinessReasons)) {
				const content = sortedData
					.filter(({ agencyId: currentAgencyId }) => currentAgencyId === orderAgencyId)[0]
					.outOfBusinessReason.map((reason) => <div>{t(`outOfBusinessReasons.${reason}`)}</div>);
				const children = (
					<Tag className={style.profilAttribute} color="greyLight">
						<span className={style.outOfBusiness}>Sorti</span>
					</Tag>
				);
				return renderTooltipForOutOfBusinessReason(content, children);
			}

			return null;
		}
		return null;
	};

	const renderInfoTooltip = () => {
		const children = (
			<div className={style.picto}>
				<Picto
					icon="info"
					className={style.starPicto}
					style={{
						marginTop: "0.25px",
						width: "1.25rem",
						height: "1.25rem",
						color: "var(--color-primary-500)",
					}}
				/>
			</div>
		);
		const medicalVisitContent = getMedicalVisitTooltipContent();
		const outOfBusinessForOtherAgenciesContent = getOutOfBusinessTooltipForOtherAgencies();
		const shouldShowInfoTooltip = outOfBusinessForOtherAgenciesContent !== null || medicalVisitContent !== null;

		if (!shouldShowInfoTooltip) return null;

		const content = (
			<div className={cn([style.tooltipContentList, style.tooltipText])}>
				{medicalVisitContent}
				{outOfBusinessForOtherAgenciesContent}
			</div>
		);

		return renderTooltipForOutOfBusinessReason(content, children);
	};

	const renderOutOfBusinessForAllAgenciesSearch = () => {
		if (!outOfBusinessReasons.length === 0) return null;
		const sortedData = cleanAndSortOutOfBusinessReasons(outOfBusinessReasons);
		if (sortedData.length === 0) {
			return null;
		}
		if (isGlobalSearch) {
			const content = (
				<span>
					<span>{t("matching.matchingProfileItem.outOfBusinessOnAgencies")}</span>
					<br />
					{sortedData.map((agency) => (
						<span key={agency.agencyId} className={style.label}>
							<span>{`${agency.agencyName} - `}</span>
							{agency.outOfBusinessReason.map((reason, index) => (
								<span key={reason}>
									{`${t(`outOfBusinessReasons.${reason}`)}
                      ${index !== agency.outOfBusinessReason.length - 1 ? ", " : ""}`}
								</span>
							))}
						</span>
					))}
				</span>
			);
			const children = (
				<div>
					<Tag className={style.profilAttribute} color="greyLight">
						<span className={style.outOfBusiness}>Sorti</span>
					</Tag>
				</div>
			);
			return renderTooltipForOutOfBusinessReason(content, children);
		}
		return null;
	};

	const profilPictoStatus = useCallback(
		(status) => {
			if (isLoading) {
				return {
					picto: "loader",
					color: "primary",
					pictoSize: "loader",
				};
			}
			if (!hasNationality && orderProfileStatus === null) {
				return {
					picto: "plus",
					color: "grey",
					pictoSize: "medium",
					outline: false,
				};
			}
			if (!hasUserOnReferenceAgencyOrder) {
				return {
					picto: "addUser",
					color: "primary",
					pictoSize: "large",
					outline: true,
				};
			}
			if (status === null) {
				return {
					picto: "plus",
					color: "primary",
					pictoSize: "medium",
					outline: true,
				};
			}
			if (status === "SELECTED") {
				return {
					picto: "check",
					color: "success",
					pictoSize: "small",
				};
			}
			return {
				picto: "check",
				color: "grey",
				pictoSize: "small",
			};
		},
		[isLoading, orderProfileStatus, hasUserOnReferenceAgencyOrder, hasNationality]
	);

	const handleTempWorkerClick = () => {
		dispatchMatching({
			type: "SET_UNIFIED_TEMP_WORKER_ID",
			payload: id,
		});
	};

	const isStatusButtonDisabled = useMemo(() => {
		if (!hasNationality) {
			return true;
		}
		return false;
	}, [hasNationality]);

	return (
		<div
			tabIndex={0}
			onKeyDown={handleTempWorkerClick}
			role="button"
			onClick={handleTempWorkerClick}
			className={cn([style.wrapper, id === unifiedTempWorkerId ? style.selected : null])}
		>
			{!hasUserOnReferenceAgencyOrder && hasNationality ? (
				<Tooltip
					arrow
					animation={false}
					placement="top"
					content={<span>{t("matching.matchingProfileItem.cloningFunctionTooltipMessage")}</span>}
				>
					<Button
						type="button"
						className={style.selectionButton}
						color={profilPictoStatus(orderProfileStatus).color}
						iconOnly
						disabled={isLoading}
						outline={profilPictoStatus(orderProfileStatus).outline}
						size="xs"
						key={id}
						onClick={(e) => {
							e.stopPropagation();
							if (isLoading) {
								return;
							}
							handleProfileClick(
								id,
								orderProfileStatus,
								hasUserOnReferenceAgencyOrder,
								hasResidentPermit
							);
						}}
					>
						<div className={cn([style.picto, style[profilPictoStatus(orderProfileStatus).pictoSize]])}>
							<Picto
								icon={profilPictoStatus(orderProfileStatus).picto}
								style={{
									marginRight: 0,
								}}
							/>
						</div>
					</Button>
				</Tooltip>
			) : (
				<Button
					type="button"
					className={style.selectionButton}
					color={profilPictoStatus(orderProfileStatus).color}
					iconOnly
					outline={profilPictoStatus(orderProfileStatus).outline}
					size="xs"
					key={id}
					disabled={isLoading}
					onClick={(e) => {
						e.stopPropagation();
						if (isLoading || isStatusButtonDisabled) {
							return;
						}
						handleProfileClick(id, orderProfileStatus, hasUserOnReferenceAgencyOrder, hasResidentPermit);
					}}
				>
					<div className={cn([style.picto, style[profilPictoStatus(orderProfileStatus).pictoSize]])}>
						<Picto
							icon={profilPictoStatus(orderProfileStatus).picto}
							style={{
								marginRight: 0,
							}}
						/>
					</div>
				</Button>
			)}
			<p className={style.profilDetails}>{`${title} ${firstName} ${lastName}`}</p>
			<div className={style.profilAttributes}>
				{cdiInterimAgencies.length === 1 && !isGlobalSearch && (
					<span className={style.tag}>
						<Tag className={style.profilAttribute} color="primaryLight">
							CDII
						</Tag>
					</span>
				)}
				{cdiInterimAgencies.length > 0 && isGlobalSearch && (
					<Tooltip
						arrow
						animation={false}
						placement="top"
						content={formatAgencyListing(cdiInterimAgencies).map((agency) => (
							<span>{agency}</span>
						))}
					>
						<span className={style.tag}>
							<Tag className={style.profilAttribute} color="primaryLight">
								CDII
							</Tag>
						</span>
					</Tooltip>
				)}
				{vipAgencies.length === 1 && !isGlobalSearch && <VipTag className={style.profilAttribute} />}
				{vipAgencies.length > 0 && isGlobalSearch && (
					<Tooltip
						arrow
						animation={false}
						placement="top"
						content={formatAgencyListing(vipAgencies).map((agency) => (
							<span>{agency}</span>
						))}
					>
						<div>
							<Tag className={style.profilAttribute} color="primaryLight">
								<Picto
									icon="star"
									className={style.starPicto}
									style={{
										height: "10px",
										width: "10px",
										marginRight: 0,
									}}
								/>
							</Tag>
						</div>
					</Tooltip>
				)}
				{renderOutOfBusinessForAllAgenciesSearch()}
				{renderInfoTooltip()}
				{renderOutOfBusinessTooltipForCurrentAgency()}
				{!hasNationality && (
					<Tooltip
						arrow
						placement="top"
						content={<div>{t("matching.matchingProfileItem.noNationality")}</div>}
					>
						<span>
							<Picto icon="warning" className={style.warnPicto} />
						</span>
					</Tooltip>
				)}
			</div>
		</div>
	);
};

MatchingProfilItem.propTypes = {
	cdiInterimAgencies: PropTypes.arrayOf(PropTypes.string).isRequired,
	vipAgencies: PropTypes.arrayOf(PropTypes.string).isRequired,
	title: PropTypes.string,
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	orderProfileStatus: PropTypes.string,
	handleProfileClick: PropTypes.func.isRequired,
	id: PropTypes.number.isRequired,
	outOfBusinessReasons: PropTypes.arrayOf(
		PropTypes.shape({
			agencyId: PropTypes.number.isRequired,
			agencyName: PropTypes.string.isRequired,
			outOfBusinessReason: PropTypes.arrayOf(PropTypes.string).isRequired,
		})
	),
	hasNationality: PropTypes.bool.isRequired,
	hasUserOnReferenceAgencyOrder: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool,
	hasResidentPermit: PropTypes.bool.isRequired,
	hasValidMedicalCheckup: PropTypes.bool.isRequired,
};

MatchingProfilItem.defaultProps = {
	title: "",
	orderProfileStatus: null,
	isLoading: false,
	outOfBusinessReasons: [],
};

export default MatchingProfilItem;
