// @ts-nocheck
import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

// eslint-disable-next-line import/no-cycle
import useFilterState from "../hooks/useFilterState";

// Utils
import { defaultFilters } from "../views/Command/Matching/matchingFiltersEnum";

const initMatchingStore = {
	searchResult: null,
	filterMatchingModalOpen: false,
	emptySection: {
		isDisplayed: false,
		section: null,
	},
	filters: defaultFilters,
	wideLoading: {
		isDisplayed: true,
		message: "Chargement en cours...",
	},
	profilesQuery: {
		fetching: false,
		initialFetch: false,
		hasCommonQualification: null, // null | true | false
	},
	orderAgencyId: null,
	unifiedTempWorkerId: null,
};

const matchingReducer = (state, action) => {
	switch (action.type) {
		case "SET_FILTERS": {
			return { ...state, filters: action.payload };
		}
		case "SET_SINGLE_FILTER": {
			return {
				...state,
				filters: {
					...state.filters,
					[action.payload.key]: action.payload.value,
				},
			};
		}
		case "SET_FILTER_MATCHING_MODAL_OPEN": {
			return { ...state, filterMatchingModalOpen: action.payload };
		}
		case "UPDATE_SEARCH_RESULT_NUMBER": {
			return { ...state, searchResult: action.payload };
		}
		case "SET_QUALIFICATION_VALUE": {
			if (state.filters.qualifications.includes(action.payload)) {
				return state;
			}
			return {
				...state,
				filters: {
					...state.filters,
					qualifications: [...state.filters.qualifications, action.payload],
				},
			};
		}
		case "SET_EMPTY_SECTION": {
			return {
				...state,
				emptySection: action.payload,
			};
		}
		case "SET_WIDE_LOADING": {
			return {
				...state,
				wideLoading: action.payload,
			};
		}
		case "SET_INITIAL_FETCH": {
			return {
				...state,
				profilesQuery: action.payload,
			};
		}
		case "SET_UNIFIED_TEMP_WORKER_ID": {
			return {
				...state,
				unifiedTempWorkerId: action.payload,
			};
		}
		case "SET_ORDER_AGENCY_ID": {
			return {
				...state,
				orderAgencyId: action.payload,
			};
		}
		default: {
			throw new Error(`[MATCHING] - Unhandled action type: ${action.type}`);
		}
	}
};

export const MatchingStateContext = createContext();
const MatchingDispatchContext = createContext();

export const MatchingFormProvider = ({ children }) => {
	const { orderId } = useParams();
	const { getInitialFilters } = useFilterState(orderId);

	const initialFilters = getInitialFilters("query");

	const [state, dispatch] = useReducer(matchingReducer, {
		...initMatchingStore,
		filters: {
			...initMatchingStore.filters,
			...initialFilters,
		},
	});

	return (
		<MatchingStateContext.Provider value={state}>
			<MatchingDispatchContext.Provider value={dispatch}>{children}</MatchingDispatchContext.Provider>
		</MatchingStateContext.Provider>
	);
};

export const useMatchingContext = () => [useContext(MatchingStateContext), useContext(MatchingDispatchContext)];

MatchingFormProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
