// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

// style
import style from "./Point.module.css";

const Point = ({ classname = "", bgColor = "var(--color-accent-500)" }) => (
	<span className={`${style.point} ${classname}`} style={{ backgroundColor: bgColor }} />
);

Point.propTypes = {
	classname: PropTypes.string,
	bgColor: PropTypes.string,
};

export default Point;
