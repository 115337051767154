// @ts-nocheck
import { DaysSelector, Textarea, WorkHoursTimeSlots } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import set from "lodash/set";
import "moment/locale/fr";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useMedia } from "react-use";

// Components
import DateRangeInput from "../../../../../components/atoms/DateRangeInput/DateRangeInput";
import TooltipMessage from "../../../../../components/atoms/TooltipMessage";
import DateRangePicker from "../../../../../components/molecules/DateRangePicker";

// Hooks

// Style
import cn from "../../../../../utils/cn";
import style from "./CommandTimeFormBlock.module.css";

// Constants
import { COMMAND_TYPE_INTERIM, CREATE_MODE, INDEX_DAYS } from "../../../../../constants";

// Misc
import { commandPeriodFormDefaultValues } from "../commandFormDefaultValues";

const DATE_FORMAT_STRING = "DD/MM/yyyy";
const START_DATE_INPUT_NAME = "period.startDate";
const END_DATE_INPUT_NAME = "period.endDate";
const DAYS_INDEXES_TABLE = {
	1: "monday",
	2: "tuesday",
	3: "wednesday",
	4: "thursday",
	5: "friday",
	6: "saturday",
	7: "sunday",
};

const selectDefaultWorkingDays = (momentStartDate, momentEndDate) => {
	const startDate = momentStartDate.clone().utcOffset(0);
	const endDate = momentEndDate.clone().utcOffset(0);

	const startDateJs = startDate.toDate();
	const endDateJs = endDate.toDate();
	const timeDifference = endDateJs - startDateJs;
	const daysDiff = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

	// if more than 6 days, select all days
	if (daysDiff > 6) {
		return {
			monday: true,
			tuesday: true,
			wednesday: true,
			thursday: true,
			friday: true,
			saturday: true,
			sunday: true,
		};
	}

	// if less than 6 days, select only the days between start and end
	const selectedDays = {};
	for (let i = 0; i < daysDiff + 1; i += 1) {
		const dayIndex = startDate.day() === 0 ? 7 : startDate.day();
		selectedDays[DAYS_INDEXES_TABLE[dayIndex]] = true;
		startDate.add(1, "days");
	}

	const newDaysSelected = {
		...commandPeriodFormDefaultValues?.workingTime?.workingDays,
		...selectedDays,
	};

	return newDaysSelected;
};

const CommandTimeFormBlock = ({ isOrderFetched }) => {
	// Hooks
	const { values, setValues, setFieldValue, setFieldTouched, touched, errors } = useFormikContext();
	const { type, period, workingTime } = values;

	const { t } = useTranslation();

	const { orderId } = useParams();
	const isNewCommand = orderId === CREATE_MODE;

	const showOneColumn = useMedia("(max-width: 1439px)");
	const showFullSize = useMedia("(min-width: 1870px)");

	const [focusedInputName, setFocusedInputName] = useState("");
	const [startDateDayIndex, setStartDateDayIndex] = useState(null);

	const TRAD_DAYS = useMemo(() => INDEX_DAYS.map((index) => t(`global.days.${index}`)), [INDEX_DAYS]);

	const workingDaysToIndexesArray = useMemo(
		() =>
			Object.values(workingTime?.workingDays || {})
				.map((isSelected, index) => (isSelected ? index + 1 : null))
				.filter(Boolean),
		[workingTime.workingDays]
	);

	const handleClearPeriodDate = (periodSlot, event) => {
		event.stopPropagation();
		const newValues = {
			...values,
			...workingTime,
			workingDays: commandPeriodFormDefaultValues.workingTime.workingDays,
		};

		set(newValues, periodSlot, null);
		setValues(newValues);
		setFieldTouched(periodSlot, true);
	};

	const areAllDaysSelected = useMemo(() => {
		const arrayOfValues = Object.values(values.workingTime.workingDays);
		const allDayAreSelected = arrayOfValues.every((day) => day === true);
		return allDayAreSelected;
	}, [values.workingTime.workingDays]);

	const handleDateRangeUpdate = (startDate, endDate) => {
		const newPeriod = {
			// WARNING: those dates are transformed when sent to the API (because they don't accept ISO format on POST/PUT)
			// If you rewrite those lines, watch out for the POST/PUT API call in Configuration.jsx
			startDate: startDate?.toISOString() || null,
			endDate: endDate?.toISOString() || null,
		};
		const newWorkingDays =
			startDate && endDate
				? selectDefaultWorkingDays(startDate, endDate)
				: commandPeriodFormDefaultValues.workingTime.workingDays;

		if (startDate) {
			setFieldTouched("period.startDate", true);
		}
		if (endDate) {
			setFieldTouched("period.endDate", true);
		}

		return setValues({
			...values,
			period: newPeriod,
			workingTime: {
				...values.workingTime,
				workingDays: newWorkingDays,
			},
		});
	};

	const handleDayClick = (selectedDayIndex) => {
		if (selectedDayIndex === startDateDayIndex) {
			return;
		}
		// The API use an object of days set with bool, but the component uses indexes and array. So we need
		// some dumb logic to match both of their behaviour.
		const weekDaySelected = DAYS_INDEXES_TABLE[selectedDayIndex];

		setFieldValue("workingTime.workingDays", {
			...values.workingTime?.workingDays,
			[weekDaySelected]: !values.workingTime?.workingDays[weekDaySelected],
		});
	};

	const handleChangeWorkHours = (e) => {
		const [slotLabel, slotIndex] = e.name.split("-");
		const newWorkingTimeSlots = workingTime.slots.map((slotValue, index) => {
			if (index === Number(slotIndex)) {
				return { ...slotValue, [slotLabel]: e.value || null };
			}
			return slotValue;
		});

		setFieldValue("workingTime.slots", newWorkingTimeSlots);
	};

	useEffect(() => {
		let firtDayIndex = moment(period?.startDate).day();
		if (firtDayIndex === 0) {
			firtDayIndex = 7;
		}
		setStartDateDayIndex(firtDayIndex);
		const weekDaySelected = DAYS_INDEXES_TABLE[firtDayIndex];

		requestAnimationFrame(() => {
			setFieldValue("workingTime.workingDays", {
				...values.workingTime?.workingDays,
				[weekDaySelected]: true,
			});
		});
	}, [period?.startDate]);

	if (!isOrderFetched && !isNewCommand) {
		return (
			<div className={style.skeletonColumn}>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonPeriod} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonDaysSelected} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonFullWidth} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonFullWidth} height={100} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonFullWidth} height={100} borderRadius={25} />
				</div>
			</div>
		);
	}

	const isPeriodError =
		(touched?.period?.startDate && errors?.period?.startDate) ||
		(touched?.period?.endDate && errors?.period?.endDate);

	return (
		<div>
			{/* Début - Fin */}
			<div
				className={cn([
					style.dateRangeWrapper,
					showFullSize || showOneColumn ? style.horizontalDateRange : style.verticalDateRange,
				])}
			>
				<div className={style.dateRangeContent}>
					<DateRangeInput
						label={t("commands.periodForm.start")}
						value={period.startDate ? moment(period.startDate).format(DATE_FORMAT_STRING) : ""}
						onClick={() => {
							setFieldTouched("period.startDate", true);
							setFocusedInputName(START_DATE_INPUT_NAME);
						}}
						onClear={(e) => handleClearPeriodDate(START_DATE_INPUT_NAME, e)}
						className={cn([style.fieldStartDate, style.dateRangeField])}
						isActive={focusedInputName === START_DATE_INPUT_NAME}
						error={
							touched?.period?.startDate && errors?.period?.startDate
								? t("commands.error.mandatoryStartDateField")
								: null
						}
					/>
					<DateRangeInput
						label={t("commands.periodForm.end")}
						value={period.endDate ? moment(period.endDate).format(DATE_FORMAT_STRING) : ""}
						onClick={() => {
							setFieldTouched("period.endDate", true);
							setFocusedInputName(END_DATE_INPUT_NAME);
						}}
						onClear={(e) => handleClearPeriodDate(END_DATE_INPUT_NAME, e)}
						className={cn([style.dateRangeField, style.fieldEndDate])}
						isActive={focusedInputName === END_DATE_INPUT_NAME}
						error={
							touched?.period?.endDate && errors?.period?.endDate
								? t("commands.error.mandatoryEndDateField")
								: null
						}
					/>
					{isPeriodError && (
						<TooltipMessage className={cn([style.centerTooltip, style.dateTooltip])} color="error">
							{t("commands.error.mandatoryDateField")}
						</TooltipMessage>
					)}
					<DateRangePicker
						className={style.datePickerWrapper}
						dateFrom={moment(period.startDate || new Date())
							.startOf("day")
							.add(12, "hours")}
						dateTo={period.endDate ? moment(period.endDate) : null}
						focusedInput={focusedInputName.replace("period.", "")}
						setFocusedInput={(reactDatesInputName) =>
							setFocusedInputName(reactDatesInputName ? `period.${reactDatesInputName}` : "")
						}
						onDatesChange={handleDateRangeUpdate}
						keepOpenOnDateSelect
						isVisible={focusedInputName !== ""}
						isDayBlocked={() => false}
						setIsVisible={() => setFocusedInputName("")}
					/>
				</div>
				{/* Jours travaillés */}
				<DaysSelector
					className={cn([
						style.daysSelector,
						showFullSize || showOneColumn ? null : style.verticalDaysSelector,
					])}
					displayDays={TRAD_DAYS}
					lockedDays={[startDateDayIndex]}
					selectedIndexDays={workingDaysToIndexesArray || []}
					selectAllLabel={
						areAllDaysSelected ? t("commands.unSelectAllDaysAction") : t("commands.selectAllDaysAction")
					}
					onDayClick={handleDayClick}
					onSelectAllClick={null}
				/>
			</div>
			{/* Working hours */}
			<div
				className={cn([
					style.periodWrapper,
					showFullSize ? style.showFullSizePeriod : style.periodVerticalWrapper,
				])}
			>
				<WorkHoursTimeSlots
					slotsConfig={{
						popoverLabels: {
							delete: t("commands.periodForm.delete"),
							add: t("commands.periodForm.add"),
							clear: t("commands.periodForm.clear"),
						},
						names: {
							start: "startHour",
							end: "endHour",
						},
						labels: {
							start: null,
							end: null,
						},
						menuStartTime: {
							start: "05:00",
							end: "16:00",
						},
						placeholders: {
							start: "--:--",
							end: "--:--",
						},
					}}
					showSeparators={showOneColumn || !showFullSize}
					direction={showFullSize || showOneColumn ? "horizontal" : "vertical"}
					onChange={(e) => handleChangeWorkHours(e)}
					onNewSlotsLine={(defaultSlotValues) =>
						setFieldValue("workingTime.slots", workingTime.slots.concat(defaultSlotValues))
					}
					onDeleteSlotsLine={(indexToDelete, params) => {
						const { softDelete } = params || {};

						if (softDelete) {
							setFieldValue(
								"workingTime.slots",
								workingTime.slots.map((slotValue, index) =>
									index === indexToDelete ? { startHour: null, endHour: null } : slotValue
								)
							);
							return;
						}
						setFieldValue(
							"workingTime.slots",
							workingTime.slots.filter((slotValue, index) => index !== indexToDelete)
						);
					}}
					values={
						workingTime.slots.length
							? workingTime.slots
							: commandPeriodFormDefaultValues?.workingTime?.slots
					}
					className={style.workHoursTime}
				/>
				{errors.workingTime?.slots && (
					<TooltipMessage
						className={cn([
							style.centerTooltip,
							style.dateTooltip,
							!showOneColumn && style.verticalPeriodTooltip,
						])}
						color="error"
					>
						{t("commands.error.wrongTimeFormat")}
					</TooltipMessage>
				)}
				{/* Add this field when time is right - (Heures hebdo) */}
				{/* <Field
          type="text"
          name="weeklyHours"
          id="weeklyHours"
          label="Heures hebdo"
          classNameContainer={style.field}
          className={cn([style.capitalize])}
          info={t('commands.requiredField')}
          onBlur={(e) => {
            handleBlur(e);
            setFieldValue('weeklyHours.name', e.target.value);
          }}
        /> */}
			</div>

			{/* Horaires particuliers - Période non travaillée */}
			{type === COMMAND_TYPE_INTERIM ? (
				<div className={style.comments}>
					<Textarea
						className={style.textarea}
						name="workingTime.particularWorkTime"
						label={t("commands.periodForm.particularWorkTimeTextAreaLabel")}
						maxLength={120}
						shouldDisplayErrorMessage={false}
					/>
					<Textarea
						className={style.textarea}
						name="workingTime.periodNotWorking"
						label={t("commands.periodForm.periodNotWorkingTextAreaLabel")}
						maxLength={40}
						shouldDisplayErrorMessage={false}
					/>
				</div>
			) : null}
		</div>
	);
};

CommandTimeFormBlock.propTypes = {
	isOrderFetched: PropTypes.bool.isRequired,
};

export default CommandTimeFormBlock;
