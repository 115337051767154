// @ts-nocheck
import PropTypes from "prop-types";

// Style
import style from "./ActiveFiltersCount.module.css";

// eslint-disable-next-line no-unused-vars
const ActiveFiltersCount = ({ count = 0 }) => (
	<div className={style.wrapper} type="button">
		<span className={style.text}>Filtrer</span>
		<div className={style.badge}>{count}</div>
	</div>
);

ActiveFiltersCount.propTypes = {
	count: PropTypes.number,
};

export default ActiveFiltersCount;
