// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Picto } from "@zolteam/react-ras-library";

// Style
import styles from "./DropdownButton.module.css";
import cn from "../../../utils/cn";

const DropdownButton = ({ children: status, theme = "primary", type }) => (
	<>
		<div className={styles.desktop}>
			<div className={cn([styles.container, styles[theme]])}>{status}</div>
		</div>
		<div className={styles.mobile}>
			{type === "NOT_PROVIDED" && <Picto className={styles.closePicto} icon="close" />}
			<span className={cn([styles.round, styles[theme], styles[type]])} />
		</div>
	</>
);

DropdownButton.propTypes = {
	children: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	theme: PropTypes.oneOf(["primary", "grey"]),
};

export default DropdownButton;
