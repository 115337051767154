// @ts-nocheck
// target is one of 'query' or 'form', depending on the context
// in case of form values, it expects the array to contain objects with an id and label property
const getLocalFilters = (filters, target = "query") =>
	Object.keys(filters).reduce((acc, key) => {
		switch (key) {
			case "qualifications": {
				if (target === "form") {
					acc[key] = filters[key];
				} else {
					acc[key] = filters[key].map((qualification) => {
						if (typeof qualification === "object") {
							return qualification.id;
						}
						return qualification;
					});
				}
				break;
			}
			case "distance": {
				if (filters[key] === null || filters[key] === "null") {
					acc[key] = null;
					break;
				}
				try {
					acc[key] = parseInt(filters[key], 10);
				} catch (error) {
					acc[key] = filters[key];
				}
				break;
			}
			default: {
				acc[key] = filters[key];
			}
		}
		return acc;
	}, {});

export default getLocalFilters;
