// @ts-nocheck
/**
 * Defines if a variable is considered as not specified in the context of a list filter
 * @param value
 * @returns {boolean}
 */
function isFilterSet(value) {
	return value && value !== null && value !== undefined && value.length !== 0 && value !== false && value !== "all";
}

/**
 * Counts the number of active list filters
 * @param {object} filters filters to scan
 * @param {array} excludedKeys do not watch these keys
 * @returns {int} active filter count
 */
function countActiveFilters(filters, excludedKeys = []) {
	let counter = 0;

	Object.entries(filters).map(([key, value]) => {
		if (!excludedKeys.includes(key)) {
			if (value && typeof value === "object" && value !== null) {
				Object.entries(value).every(([key2, value2]) => {
					if (!excludedKeys.includes(key2) && isFilterSet(value2)) {
						counter += 1;
						return false;
					}

					return true;
				});
			} else {
				counter += isFilterSet(value) ? 1 : 0;
			}
		}

		return value;
	});

	return counter;
}

export default countActiveFilters;
