// @ts-nocheck
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { components } from "react-select";
import { StatusBox, Text } from "@zolteam/react-ras-library";

// Style
import style from "./OptionMultiSelect.module.css";

const OptionMultiSelect = (props) => (
	<div className={style.option}>
		<components.Option {...props}>
			<StatusBox
				id={`${props.label}`}
				name={`${props.label}`}
				value={props.isSelected ? "true" : "false"}
				theme="primary"
				onClick={() => null}
			/>
			<label htmlFor={`${props.label}`}>
				<Text tag="span" style={{ marginLeft: props.isDisabled ? "var(--margin-m)" : null }}>
					{props.label}
				</Text>
			</label>
		</components.Option>
	</div>
);

OptionMultiSelect.propTypes = {};

export default OptionMultiSelect;
