// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import "@zolteam/react-ras-library/dist/index.css";
import i18n from "i18next";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./i18n";

// Store
import { AppProvider, AppStateContext } from "./store/AppContext";
import { ToastProvider, useToastContext } from "./store/ToastContext";

// Routes
import routes from "./routes";

// Components
import UserLoadingScreen from "./components/atoms/UserLoadingScreen";

function App() {
	const [, toastDispatch] = useToastContext();
	const { t } = useTranslation();

	const handleClientErrors = (error) => {
		if (error?.request?.status === 401) {
			window.location.href = `${process.env.REACT_APP_BASE_OLD_URL}/#/fr/signin`;
		}
		const msgError = error.response?.data?.message;
		if (i18n.exists(`global.apiErrors.${msgError}`)) {
			toastDispatch({
				type: "ERROR",
				payload: {
					icon: "users",
					msg: t(`global.apiErrors.${msgError}`),
				},
			});
		}
	};

	const queryClient = useRef(
		new QueryClient({
			defaultOptions: {
				queries: {
					retry: 1,
					refetchOnWindowFocus: false,
				},
			},
			queryCache: new QueryCache({
				onError: (error) => {
					handleClientErrors(error);
				},
			}),
			mutationCache: new MutationCache({
				onError: (error) => {
					handleClientErrors(error);
				},
			}),
		})
	);

	return (
		<QueryClientProvider client={queryClient.current}>
			<AppProvider>
				<SkeletonTheme baseColor="var(--color-neutral-100)">
					<AppStateContext.Consumer>
						{({ user }) => (
							<UserLoadingScreen loading={<span />}>
								<Router>
									<Switch>
										{user?.roles &&
											routes
												.filter(({ permissions }) => {
													if (permissions === undefined) return true;
													return !permissions || user?.roles[permissions];
												})
												.map((route) => <Route key={route.label} {...route} />)}
									</Switch>
								</Router>
							</UserLoadingScreen>
						)}
					</AppStateContext.Consumer>
				</SkeletonTheme>
				<ReactQueryDevtools />
			</AppProvider>
		</QueryClientProvider>
	);
}

function AppWrapper() {
	return (
		<ToastProvider>
			<App />
		</ToastProvider>
	);
}

export default AppWrapper;
