// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Picto, PictoAction, SelectMulti, Title } from "@zolteam/react-ras-library";
import { useTranslation } from "react-i18next";

// Atoms
import Tag from "../../atoms/Tag";
// Molecules
import OptionMultiSelect from "../../molecules/OptionMultiSelect";
// Organisms
import ListMaxWidth from "../../organisms/ListMaxWidth";

// Style
import style from "./AgenciesFilter.module.css";

// Utils
import cn from "../../../utils/cn";

const AgenciesFilter = ({
	options,
	selectedOptions,
	handleFilter,
	maxWidth = "300",
	inputSize,
	widthSelect,
	titleOnTop = false,
	activeSelectAllOption = false,
	disableOtherOptionsOnSelectAll = false,
	maxSelectionCount = null,
}) => {
	// Hook
	const { t } = useTranslation();
	const [isFilterMinimized, setIsFilterMinimized] = useState(false);
	const [selectedAgencies, setSelectedAgencies] = useState([]);
	const [initialValues, setInitialValues] = useState([]);
	const [isFirstTime, setIsFirstTime] = useState(true);

	const isLoaded = options?.length > 0 && selectedOptions?.length > 0 && isFirstTime;

	// Constant
	const PREFIX_AGENCY_NAME = "RAS ";

	// Handler
	const handleUpdate = (choosenOptions) => {
		handleFilter(choosenOptions);
	};

	const handleReset = () => {
		handleFilter(initialValues);
	};

	const handleDelete = (id) => {
		const filteredOptions = selectedAgencies.filter((agency) => agency.id !== id);
		handleFilter(filteredOptions);
	};

	const handleExpendOrFold = () => setIsFilterMinimized(!isFilterMinimized);

	const getSelectedAgencies = () =>
		options.reduce((acc, item) => {
			if (selectedOptions?.includes(item.id)) {
				acc.push(item);
			}
			return acc;
		}, []);

	useEffect(() => {
		setInitialValues(getSelectedAgencies());
		setIsFirstTime(false);
	}, [isLoaded]);

	useEffect(() => {
		setSelectedAgencies(getSelectedAgencies());
	}, [selectedOptions]);

	return (
		<>
			<div className={style.containerTitleAndClearBtn} style={{ marginBottom: isFilterMinimized ? "" : "10px" }}>
				{titleOnTop ? (
					<Title tag="h3" fontWeight="normal" lineHeight="s">
						{t("clientsMyTemp.notifications.agency")}
					</Title>
				) : null}
				{titleOnTop ? (
					<div className={style.containerWithClearBtn}>
						{initialValues.length !== selectedAgencies.length ? (
							<Button
								size="s"
								type="button"
								color="transparentPrimary"
								className={style.clearBtn}
								onClick={() => handleReset()}
							>
								{t("global.filters.reinit")}
							</Button>
						) : null}
						<Button color="transparent" type="button" onClick={() => handleExpendOrFold()}>
							<Picto
								icon={isFilterMinimized ? "chevronUp" : "chevronDown"}
								style={{ width: "20px", height: "20px", marginRight: 0 }}
							/>
						</Button>
					</div>
				) : null}
			</div>
			<div className={style.filterContainer} style={{ display: isFilterMinimized ? "none" : "block" }}>
				{selectedAgencies.length ? (
					<div className={cn([style.agenciesFilter, "mb-s"])}>
						<ListMaxWidth
							array={selectedAgencies}
							component={({ data: { name, id } }) => (
								<Tag color="primary" size="xs" className="ml-10">
									<>
										<span>{name}</span>
										<button
											type="button"
											className="noButton"
											aria-label="Delete"
											onClick={() => handleDelete(id)}
										>
											<Picto icon="close" />
										</button>
									</>
								</Tag>
							)}
							keyListElem="id"
							margeError={36}
							maxWidth={maxWidth}
							maxWidthMarge={16}
							addMaxWidthMarge={!titleOnTop && options.length !== selectedAgencies.length}
						/>
						{!titleOnTop && initialValues.length !== selectedAgencies.length ? (
							<PictoAction
								className={style.iconPink}
								picto="trash"
								pictoSize={{ width: "16px", height: "16px" }}
								onClick={handleReset}
							/>
						) : null}
					</div>
				) : null}
				<div className={style.agenciesInput}>
					<SelectMulti
						isMulti
						size={inputSize}
						value={selectedAgencies}
						name="agencies"
						label={t(titleOnTop ? "global.filters.selectOptionsText" : "global.filters.agenciesLabel")}
						options={options}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						selectAllLabel={t("global.filters.allAgencies")}
						activeSelectAllOption={activeSelectAllOption}
						disableOtherOptionsOnSelectAll={disableOtherOptionsOnSelectAll}
						maxSelectionCount={maxSelectionCount}
						components={{
							Option: OptionMultiSelect,
						}}
						onChange={(choosenOptions) => handleUpdate(choosenOptions)}
						getOptionLabel={(option) => option.name.replace(PREFIX_AGENCY_NAME, "")}
						getOptionValue={(option) => option.id}
						isClearable={false}
						useFormikStyle={false}
					/>
				</div>
			</div>
		</>
	);
};

AgenciesFilter.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	selectedOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
	handleFilter: PropTypes.func.isRequired,
	maxWidth: PropTypes.string,
	inputSize: PropTypes.string,
	widthSelect: PropTypes.string,
	titleOnTop: PropTypes.bool,
	activeSelectAllOption: PropTypes.bool,
	disableOtherOptionsOnSelectAll: PropTypes.bool,
	maxSelectionCount: PropTypes.number,
};

AgenciesFilter.defaultProps = {
	maxWidth: "300",
	inputSize: "s",
	widthSelect: "",
	titleOnTop: false,
	activeSelectAllOption: false,
	disableOtherOptionsOnSelectAll: false,
	maxSelectionCount: null,
};

export default AgenciesFilter;
