// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import moment from "moment";
import "moment/locale/fr";
import PropTypes from "prop-types";

// Components
import { Picto } from "@zolteam/react-ras-library";
import { DayPickerSingleDateController } from "react-dates";

// Utils
import cn from "../../../utils/cn";

// Style
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import style from "./DateSinglePicker.module.css";

const DateSinglePicker = ({
	id,
	dateString = "",
	focusedInput,
	setFocusedInput,
	onDateChange,
	className = "",
	isVisible = true,
	setIsVisible = undefined,
	numberOfMonths = 2,
	...props
}) => {
	/**
	 * Capitalize month text
	 * @param {*} month month text
	 * @returns {string} capitalized month text
	 */
	const formatMonthText = (month) => {
		let text = moment(month).locale("fr").format("MMMM Y");
		text = text.charAt(0).toUpperCase() + text.slice(1);

		return text;
	};

	const handleOnClickOutside = () => {
		setIsVisible("");
		setFocusedInput("");
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const widthCalendar = numberOfMonths * 270;

	return isVisible ? (
		<div data-testid="period-date-range-picker" className={className}>
			<DayPickerSingleDateController
				date={dateString ? moment(dateString).startOf("day").add(12, "hours") : null}
				isDayHighlighted={() => false}
				onDateChange={(date) => onDateChange(date)}
				focused={!!focusedInput}
				onFocusChange={(focusStatus) => {
					setFocusedInput(focusStatus?.focused ? focusedInput : "");
				}}
				id={id}
				dayPickerNavigationInlineStyles={{}}
				numberOfMonths={numberOfMonths}
				monthFormat="MMMM Y"
				onOutsideClick={handleOnClickOutside}
				renderMonthElement={({ month }) => formatMonthText(month)}
				hideKeyboardShortcutsPanel
				renderDayContents={(dayMoment) => <div className={style.dayWrapper}>{dayMoment.format("D")}</div>}
				navPrev={
					<div className={cn([style.chevronWrapper, style.chevronLeftWrapper])}>
						<Picto icon="chevronLeft" className={style.picto} />
					</div>
				}
				navNext={
					<div className={cn([style.chevronWrapper, style.chevronRightWrapper])}>
						<Picto icon="chevronRight" className={style.picto} />
					</div>
				}
				{...props}
			/>
		</div>
	) : null;
};

DateSinglePicker.propTypes = {
	id: PropTypes.string.isRequired,
	dateString: PropTypes.string,
	focusedInput: PropTypes.string.isRequired,
	setFocusedInput: PropTypes.func.isRequired,
	onDateChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	isVisible: PropTypes.bool,
	setIsVisible: PropTypes.func,
	numberOfMonths: PropTypes.func,
};

export default DateSinglePicker;
