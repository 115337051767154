// @ts-nocheck
import { useTranslation } from "react-i18next";

// Components
import { Button } from "@zolteam/react-ras-library";

// Hooks
import { useMatchingContext } from "../../../store/MatchingContext";

// Style
import style from "./EmptySection.module.css";

// Constants

import { MATCHING_ERRORS } from "../../../constants/index";

const EmptySection = () => {
	const { t } = useTranslation();
	const [{ emptySection }, dispatchMatching] = useMatchingContext();

	const toggleFilterMatchingModal = () => {
		dispatchMatching({
			type: "SET_FILTER_MATCHING_MODAL_OPEN",
			payload: true,
		});
	};

	// TODO: inmplement the default empty section
	if (
		emptySection.sectionType === MATCHING_ERRORS.NO_COMMON_QUALIFICATION ||
		emptySection.sectionType === MATCHING_ERRORS.DEFAULT ||
		emptySection.sectionType === MATCHING_ERRORS.ERR_BR_MAT_0015_INVALID_WORKING_DAYS_ORDER_PERIOD
	) {
		return (
			<div className={style.emptySection}>
				<div className={style.emptySection}>
					<h3 className={style.emptySectionTitle}>{t("matching.noResultsTitle")}</h3>
					{emptySection.sectionType === MATCHING_ERRORS.ERR_BR_MAT_0015_INVALID_WORKING_DAYS_ORDER_PERIOD && (
						<p className={style.emptySectionText}>
							{t("matching.errors.ERR_BR_MAT_0015_INVALID_WORKING_DAYS_ORDER_PERIOD")}
						</p>
					)}
					{emptySection.sectionType === MATCHING_ERRORS.NO_COMMON_QUALIFICATION && (
						<p className={style.emptySectionText}>{t("matching.noResultsText")}</p>
					)}
					<Button
						className={style.validationButtonRight}
						color="primary"
						type="button"
						onClick={toggleFilterMatchingModal}
					>
						{t("matching.noResultsButton")}
					</Button>
				</div>
			</div>
		);
	}
	return null;
};

export default EmptySection;
