// @ts-nocheck
/* eslint-disable import/no-anonymous-default-export */
import client from "./client";

/**
 * @description Get advance payments
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const postAdvancePaymentsSearch = (params) => client.post("/api-client-v2/advance-payments/search", params);

const postAdvancePaymentsUsers = (agencyId, search) => {
	client.post(`/api-client-v2/advance-payments/agencies/${agencyId}/users`, {
		params: {
			searchText: search,
		},
	});
};

const postAdvancePayments = (params) => client.post("/api-client-v2/advance-payments", params);

const getAdvancePaymentById = (id) => client.get(`/api-client-v2/advance-payments/${id}`);

const patchAdvancePayment = (params, id) =>
	client.patch(`/api-client-v2/advance-payments/${id}`, {
		comment: params.comment,
		requestedAmount: params.requestedAmount,
	});

export default {
	postAdvancePaymentsSearch,
	postAdvancePaymentsUsers,
	postAdvancePayments,
	getAdvancePaymentById,
	patchAdvancePayment,
};
