// @ts-nocheck
import React from "react";
import { t } from "i18next";
import PropTypes from "prop-types";

// Component
import { Button, Picto, PictoAction } from "@zolteam/react-ras-library";

// Style
import style from "./TableFiltersPanel.module.css";
import cn from "../../../utils/cn";

const TableFiltersPanel = ({ title = t("global.filtersPanel.title"), content = null, onClear, onClose }) => (
	<div className={style.container}>
		{title ? <div className={style.title}>{title}</div> : null}

		<PictoAction
			className={style.pictoClose}
			picto="close"
			pictoSize={{ width: "9.5px", height: "9.5px" }}
			onClick={onClose}
		/>

		<Button className={cn([style.trashBtn])} color="transparent" type="button" onClick={onClear}>
			<Picto icon="trash" className={style.trashPicto} />
			<div className={style.clearMessage}>{t("global.filtersPanel.clearAll")}</div>
		</Button>

		<div className={style.filtersWrapper}>{content}</div>
	</div>
);

TableFiltersPanel.propTypes = {
	onClear: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	content: PropTypes.node,
};

export default TableFiltersPanel;
