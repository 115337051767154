// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

import style from "./Tag.module.css";
import cn from "../../../utils/cn";

const Tag = ({ size = "m", color = "primary", radius = "m", className = "", children }) => (
	<span className={cn([style.tag, style[size], style[color], style[`radius-${radius}`], style[children], className])}>
		{children}
	</span>
);

Tag.propTypes = {
	size: PropTypes.oneOf(["xs", "s", "m", "l"]),
	color: PropTypes.oneOf(["primary", "grey", "success", "error"]),
	radius: PropTypes.oneOf(["s", "m", "l"]),
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

export default Tag;
