// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

import { Picto } from "@zolteam/react-ras-library";
import style from "./LogTreeStructure.module.css";

const LogTreeStructure = ({ children: logs }) => (
	<div className={style.logTree}>
		{logs.map((log, index) =>
			log ? (
				// eslint-disable-next-line react/no-array-index-key
				<div key={index} className={style.logContainer}>
					{index !== 0 ? <Picto icon="chevronRight" style={{ height: "16px" }} /> : null}
					<div className={style.log}>{log}</div>
				</div>
			) : null
		)}
	</div>
);

LogTreeStructure.propTypes = {
	children: PropTypes.node.isRequired,
};

export default LogTreeStructure;
