// @ts-nocheck
/* eslint-disable import/no-extraneous-dependencies */
import { Picto, PopoverCursor } from "@zolteam/react-ras-library";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// Style
import cn from "../../../utils/cn";
import style from "./MenuItem.module.css";

const MenuItem = ({
	children,
	popoverLabel,
	handleMenuOpen,
	menuOpen,
	picto,
	path,
	isExternal,
	open,
	hasChilds,
	childs,
	handleActiveChilds,
	activeChilds,
}) => (
	<PopoverCursor content={menuOpen ? <span /> : <div className="popoverBox">{popoverLabel}</div>}>
		<li className={style.menuItem}>
			{isExternal ? (
				<a href={`${process.env.REACT_APP_BASE_OLD_URL}${path}`} className={style.menuItemLink}>
					<Picto
						className={cn([style.picto, picto === "power" && style.logout])}
						icon={picto}
						style={{ width: "1.375rem" }}
					/>
					{children}
				</a>
			) : null}
			{!isExternal && !hasChilds ? (
				<NavLink className={style.menuItemLink} to={path} activeClassName={style.active}>
					<Picto className={style.picto} icon={picto} style={{ width: "1.375rem" }} />
					{children}
				</NavLink>
			) : null}
			{!isExternal && hasChilds ? (
				<>
					<button
						type="button"
						className={cn([style.menuItemLink, style.menuItemChilds, open && style.active])}
						onClick={() => {
							handleMenuOpen(true);
							if (menuOpen || activeChilds !== path) {
								handleActiveChilds(path);
							}
						}}
					>
						<span className={style.menuItemChildsName}>
							<Picto className={style.picto} icon={picto} style={{ width: "1.375rem" }} />
							{children}
						</span>
						<span className={cn([style.menuItemChildsIcon, activeChilds === path && style.activeChild])}>
							<Picto className={style.menuItemPicto} icon="chevronRight" />
						</span>
					</button>
					{hasChilds ? (
						<div
							className={cn([
								style.menuItemChildsWrapper,
								menuOpen && activeChilds === path && style.activeMenuItemChilds,
							])}
						>
							{childs || null}
						</div>
					) : null}
				</>
			) : null}
		</li>
	</PopoverCursor>
);

MenuItem.propTypes = {
	picto: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	popoverLabel: PropTypes.string.isRequired,
	handleMenuOpen: PropTypes.func.isRequired,
	menuOpen: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
	isExternal: PropTypes.bool.isRequired,
	open: PropTypes.bool.isRequired,
	hasChilds: PropTypes.bool.isRequired,
	childs: PropTypes.node,
	handleActiveChilds: PropTypes.func.isRequired,
	activeChilds: PropTypes.string.isRequired,
};

MenuItem.defaultProps = {
	childs: null,
};

export default MenuItem;
