// @ts-nocheck
/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useRef, useMemo, useState } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { Picto, ModalV2 } from "@zolteam/react-ras-library";
import { useTranslation, Trans } from "react-i18next";
import MatchingProfilItem from "../../molecules/MatchingProfilItem/MatchingProfilItem";

// services
// TODO: remove once we merge the agency filter commit
import {
	postMatchingOrderSearch,
	getAgencies,
	postSelectProfile,
	postDeslectProfile,
	postAddMatchedProfiles,
	postCreateUser,
} from "../../../services/commandService";

// TODO: remove this and get this data from context
import useOrderData from "../../../hooks/useOrderData";
import useFilterState from "../../../hooks/useFilterState";

// store
import { useAppContext } from "../../../store/AppContext";
import { useMatchingContext } from "../../../store/MatchingContext";
import { useToastContext } from "../../../store/ToastContext";

// components
import ModalContentAddAllProfilesModal from "../../../views/Command/Matching/modals/modalContentAddAllProfiles/ModalContentAddAllProfilesModal";
import ModalContentcreateUserModal from "../../../views/Command/Matching/modals/modalContentCreateUser/ModalContentCreateUser";

// utils
import { MATCHING_ERRORS, PROFILES_STATUS } from "../../../constants/index";

// style
import style from "./MatchingResultList.module.css";

const MatchingResultList = () => {
	const { orderId } = useParams();
	const { pathname } = useLocation();
	const [, dispatchApp] = useAppContext();
	const [, toastDispatch] = useToastContext();
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [isAddAllProfilesModalOpen, setIsAddAllProfilesModalOpen] = useState(false);
	const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
	const [isBlockCreateUserModalOpen, setIsBlockCreateUserModalOpen] = useState(false);
	const [selectableProfilesNumber, setSelectableProfilesNumber] = useState(null);
	const [clickedProfile, setClickedProfile] = useState(null);

	const [{ filters, emptySection, unifiedTempWorkerId, orderAgencyId }, dispatchMatching] = useMatchingContext();
	// TODO remove this and get this data from context
	const { agencyData, hasCommonQualificationId, commonQualificationDetails, isLoading, orderData } =
		useOrderData(orderId);
	const { getInitialFilters } = useFilterState(orderId);
	const initialFilters = getInitialFilters("query");

	useEffect(() => {
		if (hasCommonQualificationId === undefined || hasCommonQualificationId === false) return;
		dispatchMatching({
			type: "SET_WIDE_LOADING",
			payload: {
				isDisplayed: true,
				message: "Chargement en cours...",
			},
		});
	}, [pathname, hasCommonQualificationId]);

	// Initialiser les filtres avec l'agence
	useEffect(() => {
		if (initialFilters.agency) {
			dispatchMatching({
				type: "SET_FILTERS",
				payload: { ...filters, agency: initialFilters.agency },
			});
		}
	}, [initialFilters.agency]);

	const commonQualification = useMemo(() => {
		if (
			initialFilters?.qualifications?.length > 0 &&
			initialFilters?.qualifications?.find((q) => q === commonQualificationDetails?.id) === undefined
		) {
			// At least one qualification is in the params but not is not the order qualification
			return [];
		}
		return commonQualificationDetails?.id ? [commonQualificationDetails?.id] || [] : [];
	}, [commonQualificationDetails]);

	const commonDocumentsIds = useMemo(
		() => initialFilters.commonDocuments.map((item) => item.id),
		[initialFilters.commonDocuments]
	);

	const initialAgencyFetched = useRef(false);

	useEffect(() => {
		const fetchInitialData = async () => {
			const agencies = await getAgencies("");
			const foundAgency = agencies?.data?.find((agency) => agency.id === agencyData?.id);

			const initialAgency = agencies?.data?.find((a) => {
				return a.tempoFolderName === initialFilters.agency;
			});

			if (initialAgency || foundAgency) {
				dispatchMatching({
					type: "SET_ORDER_AGENCY_ID",
					payload: initialAgency?.id || foundAgency?.id,
				});
			}

			const mergedQualifications = [...(filters?.qualifications || []), ...(commonQualification || [])];
			const uniqueQualifications = [...new Set(mergedQualifications)];

			const newFilters = {
				...filters,
				qualifications: uniqueQualifications,
				agency: initialFilters.agency || foundAgency?.tempoFolderName || null,
				orderAgencyId: foundAgency?.id || null,
			};

			if (orderData && !filters?.period?.startDate && !filters?.period?.endDate) {
				newFilters.period = {
					startDate: orderData?.data.period?.startDate,
					endDate: orderData?.data.period?.endDate,
				};
			}

			dispatchMatching({
				type: "SET_FILTERS",
				payload: newFilters,
			});
		};

		if (!isLoading && agencyData?.id && !initialAgencyFetched.current) {
			initialAgencyFetched.current = true;
			fetchInitialData();
		}
	}, [
		isLoading,
		agencyData?.id,
		filters,
		hasCommonQualificationId,
		commonQualification,
		orderData?.data.period?.startDate,
		orderData?.data.period?.endDate,
	]);

	const fetchSearchResults = useCallback(
		async ({ pageParam = 1 }) => {
			const results = await postMatchingOrderSearch(orderId, {
				limit: 100,
				page: pageParam,
				query: {
					...filters,
					// Handling "Toute" agencies
					agency: filters.agency === "all" ? null : filters.agency,
					commonDocuments: commonDocumentsIds,
				},
			});
			dispatchMatching({
				type: "SET_WIDE_LOADING",
				payload: {
					isDisplayed: false,
					message: null,
				},
			});
			return results;
		},
		[filters]
	);

	const handleTitleCorrespondence = (title) => {
		if (title === "MR") return "M.";
		if (title === "MRS") return "Mme";
		return "";
	};

	const {
		data,
		error,
		isFetched: profilesAreFetched,
		isFetchingNextPage,
		hasNextPage,
		refetch: refetchProfiles,
		fetchNextPage,
		dataUpdatedAt: profilesUpdatedAt,
	} = useInfiniteQuery({
		queryKey: ["postMatchingOrderSearch", filters, orderId],
		queryFn: fetchSearchResults,
		getNextPageParam: (_, allPages) => {
			// if last page is detected, return undefined;
			if (allPages.length * 100 > allPages[0]?.data?.total) {
				return undefined;
			}
			// if list of pages exist, return the next page index
			if (allPages?.length > 0) {
				return allPages.length + 1;
			}
			// if no array exists, return the first page index
			return 1;
		},
		enabled:
			!!filters.agency &&
			filters?.qualifications?.length > 0 &&
			!!filters.period?.startDate &&
			!!filters?.period?.endDate,
	});

	const [isDataStale, setIsDataStale] = useState(false);
	const POLLING_INTERVAL = 30000;
	const STALE_DELAY = 5 * 60 * 1000;

	useEffect(
		() => () => {
			dispatchApp({ type: "CLEAR_ORDER_MESSAGE" });
		},
		[pathname]
	);

	useEffect(() => {
		setIsDataStale(false);
	}, [profilesUpdatedAt, setIsDataStale]);

	useEffect(() => {
		const checkDataStaleness = () => {
			const now = new Date().getTime();
			if (now - profilesUpdatedAt > STALE_DELAY && !isDataStale) {
				setIsDataStale(true);
				dispatchApp({
					type: "SET_ORDER_MESSAGE",
					payload: {
						displayed: true,
						content: (
							<div>
								<Trans
									i18nKey="matching.staleDataWarning"
									components={{
										button: (
											<button
												className={style.infoLink}
												aria-label="rafraîchir"
												type="button"
												onClick={() => {
													dispatchApp({ type: "CLEAR_ORDER_MESSAGE" });
													dispatchMatching({
														type: "SET_WIDE_LOADING",
														payload: {
															isDisplayed: true,
															message: "Chargement en cours...",
														},
													});
													refetchProfiles();
												}}
											/>
										),
									}}
								/>
								<Picto
									onClick={() => {
										dispatchApp({ type: "CLEAR_ORDER_MESSAGE" });
									}}
									icon="close"
									className={style.pictoClose}
								/>
							</div>
						),
						color: "warning",
						withIcon: true,
					},
				});
			}
		};

		if (!profilesUpdatedAt) return () => null;
		const interval = setInterval(checkDataStaleness, POLLING_INTERVAL);
		return () => clearInterval(interval);
	}, [profilesUpdatedAt, isDataStale]);

	const updateProfilesStatus = (unifiedTempWorkerIds) => {
		const profilsLength = unifiedTempWorkerIds.length;
		for (const id of unifiedTempWorkerIds) {
			updateOrderProfileStatus(id, PROFILES_STATUS.SELECTED);
		}
		setSelectableProfilesNumber(selectableProfilesNumber - profilsLength);
	};

	const concatenatedProfiles = useMemo(() => {
		if (!data) return [];
		if (profilesAreFetched) {
			// Concatenate all pages of result into one array
			return data.pages.reduce((acc, current) => [...acc, ...current.data.items], []);
		}
		return [];
	}, [data, profilesAreFetched]);

	useEffect(() => {
		setSelectableProfilesNumber(data?.pages?.[0]?.data?.selectableProfilesNumber);
	}, [agencyData, data?.pages?.[0]?.data?.selectableProfilesNumber]);

	const { mutate: addMatchedProfilesMutate, isLoading: addMatchedProfilesLoading } = useMutation(
		(selectedProfiles) =>
			postAddMatchedProfiles(orderId, {
				limit: selectedProfiles,
				query: {
					...filters,
					// Handling "Toute" agencies
					agency: filters.agency === "all" ? null : filters.agency,
					commonDocuments: commonDocumentsIds,
				},
			}),
		{
			onSuccess: (response) => {
				const { unifiedTempWorkerIds } = response?.data ?? {};
				updateProfilesStatus(unifiedTempWorkerIds);
				dispatchMatching({
					type: "SET_WIDE_LOADING",
					payload: {
						isDisplayed: false,
						message: "",
					},
				});
			},
			onError: () => {
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: t("global.apiCommandErrors.INTERNAL_SERVER_ERROR"),
					},
				});
				dispatchMatching({
					type: "SET_WIDE_LOADING",
					payload: {
						isDisplayed: false,
						message: "",
					},
				});
			},
		}
	);

	const handleAddAllProfilesModalClick = (profilesCount) => {
		setIsAddAllProfilesModalOpen(false);
		addMatchedProfilesMutate(profilesCount);
	};

	const handleCreateUserModalClick = () => {
		setIsCreateUserModalOpen(false);
		createUserMutationMutate(clickedProfile);
	};

	useEffect(() => {
		if (addMatchedProfilesLoading) {
			dispatchMatching({
				type: "SET_WIDE_LOADING",
				payload: {
					isDisplayed: true,
					message: "Ajout de profils en cours...",
				},
			});
		}
	}, [addMatchedProfilesLoading]);

	const updateOrderProfileStatus = (id, newStatus, isProfileLoading, hasUserOnReferenceAgencyOrder = true) => {
		// Update query data in the cache: setQueryData
		queryClient.setQueryData(["postMatchingOrderSearch", filters, orderId], (queryData) => {
			if (queryData && Array.isArray(queryData?.pages)) {
				// Create a new copy of the query data
				const updatedQueryData = {
					...queryData,
					pages: queryData?.pages.map((page) => {
						if (Array.isArray(page.data.items)) {
							// Create a new copy of the page data
							const updatedPage = {
								...page,
								data: {
									...page.data,
									// Update orderProfileStatus for the specific profile
									items: page.data.items.map((profile) =>
										profile.id === id
											? {
													...profile,
													orderProfileStatus: newStatus,
													isProfileLoading,
													hasUserOnReferenceAgencyOrder,
												}
											: profile
									),
								},
							};
							return updatedPage;
						}
						return page;
					}),
				};
				return updatedQueryData;
			}
			return queryData;
		});
	};

	const { mutate: profileSelectMutationMutate, isLoading: profileSelectMutationLoading } = useMutation(
		(id) => postSelectProfile(orderId, id),
		{
			onMutate: (id) => {
				updateOrderProfileStatus(id, null, true);
			},
			onSuccess: (response, id) => {
				setSelectableProfilesNumber(selectableProfilesNumber - 1);
				queryClient.invalidateQueries(["getOrderProfiles"]);
				if (response?.data?.status === 204) {
					toastDispatch({
						type: "ERROR",
						payload: {
							icon: "alertCircle",
							msg: t(`commands.apiCommandErrors.${response?.data?.message}`),
						},
					});
					return;
				}
				updateOrderProfileStatus(id, "SELECTED", false);
			},
			onError: (e, id) => {
				const { response } = e;
				updateOrderProfileStatus(id, null, false);
				refetchProfiles();
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: t(`commands.apiCommandErrors.${response?.data?.message}`),
					},
				});
			},
		}
	);

	const { mutate: profileDeselectMutationMutate, isLoading: profileDeselectMutationLoading } = useMutation(
		(id) => postDeslectProfile(orderId, id),
		{
			onMutate: (id) => {
				updateOrderProfileStatus(id, null, true);
			},
			onSuccess: (response, id) => {
				setSelectableProfilesNumber(selectableProfilesNumber + 1);
				queryClient.invalidateQueries(["getOrderProfiles"]);
				updateOrderProfileStatus(id, null, false);
			},
			onError: (e, id) => {
				updateOrderProfileStatus(id, null, false, false);
				refetchProfiles();
				const { response } = e;
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: t(`commands.apiCommandErrors.${response?.data?.message}`),
					},
				});
			},
		}
	);

	const { mutate: createUserMutationMutate, isLoading: createUserMutationLoading } = useMutation(
		(id) => postCreateUser(id, orderData?.data?.agencyId),
		{
			onMutate: (id) => {
				updateOrderProfileStatus(id, null, true, false);
			},
			onSuccess: (response, id) => {
				profileSelectMutationMutate(id);
				queryClient.refetchQueries({
					queryKey: [
						"getMatchingTempWorkerInformations",
						orderId,
						orderAgencyId,
						unifiedTempWorkerId,
						filters?.agency === "all",
					],
				});
			},
			onError: (e, id) => {
				const { response } = e;
				refetchProfiles();
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: t(`commands.apiCommandErrors.${response?.data?.message}`),
					},
				});
				updateOrderProfileStatus(id, null, false, false);
			},
		}
	);

	const handleProfileClick = (id, orderProfileStatus, hasUserOnReferenceAgencyOrder, hasResidentPermit) => {
		if (profileSelectMutationLoading || profileDeselectMutationLoading || createUserMutationLoading) return;
		if (hasResidentPermit && !hasUserOnReferenceAgencyOrder && orderProfileStatus === null) {
			setIsBlockCreateUserModalOpen(true);
			return;
		}
		if (!hasUserOnReferenceAgencyOrder) {
			setClickedProfile(id);
			setIsCreateUserModalOpen(true);
			return;
		}
		if (orderProfileStatus === null) {
			profileSelectMutationMutate(id);
			return;
		}
		if (orderProfileStatus === "SELECTED") {
			profileDeselectMutationMutate(id);
		}
	};

	useEffect(() => {
		dispatchMatching({
			type: "SET_INITIAL_FETCH",
			payload: {
				initialFetch: profilesAreFetched,
				hasCommonQualification: !!hasCommonQualificationId,
			},
		});
	}, [profilesAreFetched, hasCommonQualificationId]);

	useEffect(() => {
		if (error) {
			const message = MATCHING_ERRORS[error?.response?.data?.message] || MATCHING_ERRORS.DEFAULT;

			dispatchMatching({
				type: "SET_EMPTY_SECTION",
				payload: {
					isDisplayed: true,
					sectionType: message,
				},
			});
			return;
		}
		if (profilesAreFetched) {
			dispatchMatching({
				type: "SET_EMPTY_SECTION",
				payload: {
					isDisplayed: concatenatedProfiles?.length < 1,
					sectionType: MATCHING_ERRORS.DEFAULT,
				},
			});
		}
	}, [concatenatedProfiles, profilesAreFetched, error]);

	useEffect(() => {
		dispatchMatching({
			type: "UPDATE_SEARCH_RESULT_NUMBER",
			payload: data?.pages?.[0]?.data?.total || null,
		});
		if (!unifiedTempWorkerId) {
			dispatchMatching({
				type: "SET_UNIFIED_TEMP_WORKER_ID",
				payload: data?.pages?.[0]?.data?.items[0]?.id,
			});
		}
	}, [data]);

	const handleScroll = (e) => {
		const element = e.target;
		const atBottom = element.scrollHeight - element.scrollTop - 5 <= element.clientHeight;
		if (atBottom && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	};

	if (emptySection.isDisplayed) return null;

	return (
		<div className={style.boxwrapper}>
			{selectableProfilesNumber >= 10 ? (
				<>
					<div
						className={style.addAllButton}
						onClick={() => setIsAddAllProfilesModalOpen(true)}
						onKeyDown={() => setIsAddAllProfilesModalOpen(true)}
						role="button"
						tabIndex={0}
					>
						<Picto className={style.addAllButtonPicto} icon="plus" />
						<span>{t("matching.addAll")}</span>
					</div>
					<hr className={style.separator} />
				</>
			) : null}
			<div
				className={selectableProfilesNumber >= 10 ? style.wrapperWithButton : style.wrapper}
				onScroll={handleScroll}
			>
				{concatenatedProfiles.map(
					({
						id,
						title,
						firstName,
						lastName,
						vipAgencies,
						cdiInterimAgencies,
						orderProfileStatus,
						outOfBusinessReasons,
						hasNationality,
						isProfileLoading,
						hasUserOnReferenceAgencyOrder,
						hasResidentPermit,
						hasValidMedicalCheckup,
					}) => (
						<MatchingProfilItem
							key={id}
							title={handleTitleCorrespondence(title)}
							firstName={firstName}
							lastName={lastName}
							vipAgencies={vipAgencies}
							cdiInterimAgencies={cdiInterimAgencies}
							orderProfileStatus={orderProfileStatus}
							handleProfileClick={handleProfileClick}
							id={id}
							outOfBusinessReasons={outOfBusinessReasons}
							hasNationality={hasNationality}
							hasResidentPermit={hasResidentPermit}
							isLoading={isProfileLoading}
							hasUserOnReferenceAgencyOrder={hasUserOnReferenceAgencyOrder}
							hasValidMedicalCheckup={hasValidMedicalCheckup}
						/>
					)
				)}
				{isFetchingNextPage && (
					<div className={style.loadingBox}>
						<span className={style.loader} />
					</div>
				)}
			</div>
			<ModalV2
				size="s"
				isDisplayed={isAddAllProfilesModalOpen}
				onClose={() => setIsAddAllProfilesModalOpen(false)}
			>
				<ModalContentAddAllProfilesModal
					selectableProfilesNumber={selectableProfilesNumber}
					onCancelClick={() => setIsAddAllProfilesModalOpen(false)}
					onConfirmClick={(profilesCount) => handleAddAllProfilesModalClick(profilesCount)}
				/>
			</ModalV2>
			<ModalV2 size="s" isDisplayed={isCreateUserModalOpen} onClose={() => setIsCreateUserModalOpen(false)}>
				<ModalContentcreateUserModal
					agencyName={agencyData?.name ?? null}
					onCancelClick={() => setIsCreateUserModalOpen(false)}
					onConfirmClick={() => handleCreateUserModalClick()}
				/>
			</ModalV2>
			<ModalV2
				size="s"
				isDisplayed={isBlockCreateUserModalOpen}
				onClose={() => setIsBlockCreateUserModalOpen(false)}
			>
				<div className={style.content}>
					<p className={style.title}>{t("matching.blockCreateUserModal.title")}</p>
					<div className={style.textContent}>
						<p className={style.modalMessage}>{t("matching.blockCreateUserModal.infoMessage")}</p>
					</div>
					<p className={style.modalMessage}>{t("matching.blockCreateUserModal.confirmMessage")}</p>
				</div>
			</ModalV2>
		</div>
	);
};

export default MatchingResultList;
