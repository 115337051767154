// @ts-nocheck
import {
	Badge,
	Button,
	PaginationV2,
	Picto,
	PictoAction,
	SelectPopover,
	Text,
	Title,
	ToggleSwitch,
} from "@zolteam/react-ras-library";
import { has } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// Hook
import { useMutation, useQueries, useQueryClient } from "react-query";
import { useTitle } from "react-use";
// Store
import useAppContext from "../../store/useAppContext";
// Utils
import cn from "../../utils/cn";
import countActiveFilters from "../../utils/filterUtils";
import { formatDate } from "../../utils/formatDate";
import { prepareAgencyTempoFolderNameList, prepareCommercialCodeList } from "../../utils/notificationsListUtils";
import { definePageOffset } from "../../utils/paginationUtils";
// Templates
import AgenciesFilter from "../../components/templates/AgenciesFilter/AgenciesFilter";
import TableFilters from "../../components/templates/TableFilters";
import WithSideMenu from "../../components/templates/WithSideMenu/WithSideMenu";
import WithSideTableFiltersPanel from "../../components/templates/WithSideTableFiltersPanel";
import WithTopBarre from "../../components/templates/WithTopBarre";
// Organisms
import TableListing from "../../components/organisms/TableListing";
// Molecules
import ColumnListing from "../../components/molecules/ColumnListing/ColumnListing";
import PaginationSearch from "../../components/molecules/PaginationSearch/PaginationSearch";
import TableInfo from "../../components/molecules/TableInfo";
import TitleCount from "../../components/molecules/TitleCount";
// Atoms
import Tag from "../../components/atoms/Tag";
// Services
import myTempNotifications from "../../services/myTempNotifications";
import user from "../../services/user";
// Constants
import {
	DEFAULT_NOTIFICATIONS_SEARCH_FILTERS,
	DEFAULT_NOTIFICATIONS_SEARCH_PARAMS,
	NOTIFICATION_STATUS_ALL,
	NOTIFICATION_STATUS_READ,
	NOTIFICATION_STATUS_UNREAD,
	PAGINATION_OFFSET_OPTIONS,
} from "../../constants";
// Style
import style from "./Notifications.module.css";

const Notifications = () => {
	const [{ notificationsFilters }, appDispatch] = useAppContext();

	// Hooks
	const { t } = useTranslation();

	useTitle(t("clientsMyTemp.notifications.title"));

	// Ref
	const searchTimeOutRef = useRef(null);

	// States
	const [disableSearch, setDisableSearch] = useState(false);
	const [data, setData] = useState();
	const [options, setOptions] = useState(DEFAULT_NOTIFICATIONS_SEARCH_PARAMS);
	const [isUnreadToogleActive, setIsUnreadToogleActive] = useState(
		notificationsFilters?.status === NOTIFICATION_STATUS_UNREAD
	);
	const [isFirstLoading, setIsFirstLoading] = useState(true);
	const [showFiltersPanel, setShowFiltersPanel] = useState(false);

	const queryClient = useQueryClient();

	// API Calls
	const [getUserAgenciesQuery, postNotificationsSearchQuery, postFilteredNotificationsCountQuery] = useQueries([
		{
			queryKey: ["getUserAgencies"],
			queryFn: () => user.getUserAgencies(),
			onSuccess: (result) => {
				appDispatch({
					type: "SET_NOTIFICATIONS_TABLE_FILTERS",
					payload: {
						agencies: result?.data,
						// Les codes commerciaux ne sont pas réellement un filtre au sens propre.
						// ils ne doivent pas être mis à jour en fonction du choix des agences via le filtre agence.
						// ils sont juste la liste des codes commerciaux de toutes les agences auxquelles est rattaché l'utilisateur
						// ils sont mis à jour une seule fois à cet endroit et ne doivent plus changer
						commercialCodes: prepareCommercialCodeList(result?.data),
					},
				});
			},
		},
		{
			queryKey: ["postNotificationsSearch", notificationsFilters, options],
			queryFn: () =>
				myTempNotifications.postNotificationsSearch({
					query: {
						...notificationsFilters,
						agencies: prepareAgencyTempoFolderNameList(notificationsFilters.agencies),
					},
					...options,
				}),
			onSuccess: (result) => {
				setData(result?.data);
				setIsFirstLoading(false);
			},
			enabled: notificationsFilters?.agencies?.length > 0 && !disableSearch,
		},
		{
			queryKey: ["postFilteredNotificationsCount", notificationsFilters],
			queryFn: () =>
				myTempNotifications.postUnreadNotificationsCount({
					query: {
						...notificationsFilters,
						agencies: prepareAgencyTempoFolderNameList(notificationsFilters.agencies),
						status: NOTIFICATION_STATUS_UNREAD,
					},
				}),
			enabled: notificationsFilters?.agencies?.length > 0 && !disableSearch,
		},
	]);

	// Destructuring useQuery and useMutation
	const { data: userAgenciesData, isLoading: isLoadingUserAgencies } = getUserAgenciesQuery;

	const {
		isError: isErrorNotificationsSearch,
		isLoading: isLoadingNotificationsSearch,
		isFetching: isFetchingNotificationsSearch,
	} = postNotificationsSearchQuery;

	const { data: notificationsCount, isLoading: isLoadingNotificationsCount } = postFilteredNotificationsCountQuery;

	const patchMyTempNotificationStatusMutation = useMutation(myTempNotifications.patchMyTempNotificationStatus);

	// Memo
	const userAgenciesMemo = useMemo(() => userAgenciesData?.data, [userAgenciesData]);

	// Handlers
	const handlePaginationChange = (params) =>
		setOptions({
			...options,
			...params,
		});

	const handleRefresh = () => {
		postNotificationsSearchQuery.refetch();
		postFilteredNotificationsCountQuery.refetch();
		handlePaginationChange({ page: 1 });
	};

	const handleSearch = (params) => {
		switch (true) {
			case has(params, "agencies") && params.agencies.length === 0: {
				// search without agencies does not make sense, reset with user agencies in this case
				appDispatch({
					type: "SET_NOTIFICATIONS_TABLE_FILTERS",
					payload: {
						...notificationsFilters,
						...params,
						agencies: [],
					},
				});

				break;
			}
			case has(params, "agencies") && params.agencies.length > 0: {
				appDispatch({
					type: "SET_NOTIFICATIONS_TABLE_FILTERS",
					payload: {
						...notificationsFilters,
						...params,
						agencies: params.agencies,
					},
				});

				break;
			}
			default: {
				appDispatch({
					type: "SET_NOTIFICATIONS_TABLE_FILTERS",
					payload: {
						...notificationsFilters,
						...params,
					},
				});
			}
		}

		handlePaginationChange({ page: 1 });
	};

	const handleSearchDelayed = (params) => {
		setDisableSearch(true);
		clearTimeout(searchTimeOutRef.current);

		searchTimeOutRef.current = setTimeout(() => {
			handleSearch(params);
			setDisableSearch(false);
		}, 150);
	};

	const handleOnlyUnreadOrAll = () => {
		setIsUnreadToogleActive(!isUnreadToogleActive);
		appDispatch({
			type: "SET_NOTIFICATIONS_TABLE_FILTERS",
			payload: {
				...notificationsFilters,
				status:
					notificationsFilters.status === NOTIFICATION_STATUS_UNREAD
						? NOTIFICATION_STATUS_ALL
						: NOTIFICATION_STATUS_UNREAD,
			},
		});

		handlePaginationChange({ page: 1 });
	};

	const handleUpdateNotificationStatus = async ({ id, status }) => {
		const newStatus = status === NOTIFICATION_STATUS_UNREAD ? NOTIFICATION_STATUS_READ : NOTIFICATION_STATUS_UNREAD;

		patchMyTempNotificationStatusMutation.mutate({
			notificationId: id,
			newStatus,
		});

		const index = data?.items.findIndex((notification) => notification.id === id);

		// Update status in data list
		const notificationToUpdate = data?.items.find((notification) => notification.id === id);
		data?.items.splice(index, 1, {
			...notificationToUpdate,
			status: newStatus,
		});

		//  Remove notification from list if unread notification toogle is active
		if (isUnreadToogleActive) {
			setTimeout(() => {
				data?.items.splice(index, 1);
				setData({
					...data,
					total: (data?.total ?? 0) - 1,
				});
			}, 1500);
		}

		// Refetch count displays (here on list + left menu)
		await queryClient.invalidateQueries("postUnreadNotificationsCount");
		await postFilteredNotificationsCountQuery.refetch();
	};

	const handleShowFiltersPanel = () => setShowFiltersPanel(!showFiltersPanel);

	const handleClearAllFilters = () => {
		handleSearch({
			...DEFAULT_NOTIFICATIONS_SEARCH_FILTERS,
			agencies: userAgenciesMemo,
			commercialCodes: notificationsFilters.commercialCodes,
		});
	};

	const isListLoading = () =>
		(isFirstLoading && !isErrorNotificationsSearch) ||
		isFetchingNotificationsSearch ||
		isLoadingNotificationsSearch ||
		isLoadingUserAgencies;

	const showCount = () =>
		notificationsCount?.data?.count !== null &&
		notificationsCount?.data?.count !== undefined &&
		!isLoadingNotificationsCount &&
		!isLoadingUserAgencies;

	const getPageMaxNumber = () => Math.round((data?.total ?? 0) / options.limit);

	const getStartPagination = options.limit * options.page - options.limit + 1;

	const getEndPagination = options.limit * options.page <= data?.total ? options.limit * options.page : data?.total;

	useEffect(() => {
		appDispatch({
			type: "SET_NOTIFICATIONS_TABLE_FILTERS",
			payload: {
				agencies: userAgenciesMemo,
				commercialCodes: prepareCommercialCodeList(userAgenciesMemo),
			},
		});
	}, [userAgenciesMemo]);

	return (
		<WithSideMenu>
			<WithSideTableFiltersPanel
				showPanel={showFiltersPanel}
				onClose={handleShowFiltersPanel}
				onClear={handleClearAllFilters}
				filtersContent={
					<div>
						{userAgenciesMemo !== undefined && userAgenciesMemo?.length > 0 ? (
							<AgenciesFilter
								titleOnTop
								activeSelectAllOption
								options={userAgenciesMemo || []}
								selectedOptions={notificationsFilters?.agencies?.map((agency) => agency.id)}
								handleFilter={(agenciesSelected) => {
									handleSearchDelayed({ agencies: agenciesSelected });
								}}
							/>
						) : null}
					</div>
				}
			>
				<WithTopBarre
					quickEntryContent={<div>test</div>}
					title={
						<TitleCount count={notificationsCount?.data?.count}>
							<Title tag="h1" size="heading01" lineHeight="s">
								{t("clientsMyTemp.notifications.title")}
							</Title>
							{showCount() ? (
								<Tag size="m" radius="s" color="primary" className="ml-10">
									{`${notificationsCount?.data?.count} ${t("clientsMyTemp.notifications.unread")}`}
								</Tag>
							) : null}
						</TitleCount>
					}
					containerClassname="container"
				>
					<TableFilters
						rightContent={
							<div className={cn([style.container, "mt-m"])}>
								<div className={style.filterField}>
									<ToggleSwitch
										name="unreadToggle"
										id="unreadToggle"
										onChange={handleOnlyUnreadOrAll}
										toggled={isUnreadToogleActive}
									>
										<label className={style.toggleLabel}>
											{t("clientsMyTemp.notifications.filters.onlyUnreadToggle")}
										</label>
									</ToggleSwitch>
								</div>
								<div className={cn([style.filterField, "ml-l"])} onClick={handleRefresh}>
									<PictoAction
										className={style.iconPink}
										picto="refresh"
										pictoSize={{ width: "20px", height: "20px" }}
									/>
									<Text tag="span" className="ml-s">
										{t("global.filters.refresh")}
									</Text>
								</div>
								<div className={cn([style.filterField, "ml-l"])} onClick={handleShowFiltersPanel}>
									<PictoAction
										className={style.iconPink}
										picto="sliders"
										pictoSize={{ width: "20px", height: "20px" }}
									/>
									<Text tag="span" className="ml-s">
										{t("global.filtersPanel.openPanel")}
									</Text>
									<Badge
										color="primary"
										content={countActiveFilters(notificationsFilters, [
											"commercialCodes",
											"all",
											"status",
										])}
										className="ml-s"
									/>
								</div>
							</div>
						}
					/>

					<TableListing
						loading={isListLoading()}
						error={isErrorNotificationsSearch}
						data={data?.items}
						handleSearch={handlePaginationChange}
						initialColumnSort={{
							field: "createdAt",
							orderBy: "DESC",
						}}
						canBeChecked={false}
						showCustomMessage={notificationsFilters?.agencies?.length === 0}
						customMessage={t("global.filters.selectOneAgency")}
						footer={
							data?.total ? (
								<>
									<PaginationV2
										previousLabel={<Picto icon="chevronLeft" />}
										nextLabel={<Picto icon="chevronRight" />}
										breakLine={
											<PaginationSearch
												onInputChange={handlePaginationChange}
												max={getPageMaxNumber()}
											/>
										}
										currentPage={options.page}
										totalCount={data.total}
										pageSize={options.limit}
										siblingCount={2}
										onPageChange={(activePage) => handlePaginationChange({ page: activePage })}
									/>
									<Text tag="span" size="paragraph02" color="grey">
										{t("global.pagination.resultsPagination", {
											resultStart: getStartPagination,
											resultEnd: getEndPagination,
											resultTotal: data?.total,
										})}
									</Text>
									<SelectPopover
										name="limit"
										options={PAGINATION_OFFSET_OPTIONS}
										onChange={(value) =>
											handlePaginationChange({
												limit: value,
												page: definePageOffset(value, options.limit, options.page),
											})
										}
										value={
											options.limit
												? PAGINATION_OFFSET_OPTIONS.find(
														(option) => option.value === options.limit
													)
												: null
										}
									/>
								</>
							) : null
						}
					>
						<ColumnListing
							id="status"
							field={(elem) => ({ id: elem.id, status: elem.status })}
							component={({ data: { id, status } }) => (
								<span>
									<Button
										color="transparent"
										type="button"
										style={{ display: "flex" }}
										onClick={() => handleUpdateNotificationStatus({ id, status })}
									>
										<Picto
											icon={
												status === NOTIFICATION_STATUS_UNREAD
													? "ringingBellActive"
													: "ringingBell"
											}
											className={style.pictoRingingBell}
											style={{
												color:
													status === NOTIFICATION_STATUS_UNREAD
														? "var(--color-primary-500)"
														: "var(--color-black)",
											}}
										/>
									</Button>
								</span>
							)}
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="clientName"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("clientsMyTemp.notifications.client")}
								</Text>
							}
							field={(elem) => ({
								clientName: elem.client,
								isHighlighted: elem.isHighlighted,
							})}
							component={({ data: { clientName, isHighlighted } }) => (
								<Text
									tag="span"
									size="paragraph02"
									style={{
										color: isHighlighted ? "var(--color-warning-500)" : "var(--color-black)",
									}}
								>
									{clientName}
								</Text>
							)}
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="clientCode"
							name={
								<Text tag="span" size="paragraph02" color="grey" className={style.customHeaderWidth}>
									{t("clientsMyTemp.notifications.clientCode")}
								</Text>
							}
							field={(elem) => ({
								clientCode: elem.clientCode,
								isHighlighted: elem.isHighlighted,
							})}
							component={({ data: { clientCode, isHighlighted } }) => (
								<Text
									tag="span"
									size="paragraph02"
									className={style.customHeaderWidth}
									style={{
										color: isHighlighted ? "var(--color-warning-500)" : "var(--color-black)",
									}}
								>
									{clientCode}
								</Text>
							)}
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="content"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("clientsMyTemp.notifications.content")}
								</Text>
							}
							field={(elem) => ({
								content: elem.content,
								isHighlighted: elem.isHighlighted,
							})}
							component={({ data: { content, isHighlighted } }) => (
								<Text
									tag="span"
									size="paragraph02"
									style={{
										color: isHighlighted ? "var(--color-warning-500)" : "var(--color-black)",
									}}
								>
									{content || ""}
								</Text>
							)}
						/>
						<ColumnListing
							id="docPdf"
							name={
								<Text tag="span" size="paragraph02" color="grey" className={style.customHeaderWidth}>
									{t("clientsMyTemp.notifications.document")}
								</Text>
							}
							field={(elem) => ({ details: elem.details })}
							component={({ data: { details } }) =>
								details?.url ? (
									<a href={details?.url} target="__blank" aria-label="pdf">
										<Picto icon="pdf" className={style.iconPdf} />
									</a>
								) : null
							}
							tdClassName="min-column-width"
							containsLink
						/>
						<ColumnListing
							id="agencyName"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("clientsMyTemp.notifications.agency")}
								</Text>
							}
							field={(elem) => ({ agencyName: elem.agency })}
							component={({ data: { agencyName } }) => (
								<Text tag="span" size="paragraph02">
									{agencyName || ""}
								</Text>
							)}
						/>
						<ColumnListing
							id="createdAt"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("clientsMyTemp.notifications.createdAt")}
								</Text>
							}
							field={(elem) => ({ createdAt: elem.createdAt })}
							component={({ data: { createdAt } }) => (
								<TableInfo
									info={formatDate(createdAt, "dd MMM yyyy")}
									subInfo={createdAt?.length > 10 ? formatDate(createdAt, "HH'h'mm") : ""}
								/>
							)}
							isSortable
							tdClassName="min-column-width"
						/>
					</TableListing>
				</WithTopBarre>
			</WithSideTableFiltersPanel>
		</WithSideMenu>
	);
};

export default Notifications;
