// @ts-nocheck
// import style from '../MaintenanceHeader/MaintenanceHeader.module.css';
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SuspiciousSheetFooter from "../SuspiciousSheetFooter";

// eslint-disable-next-line react/prop-types
const MaintenanceHeader = ({ title, description }) => {
	const { t } = useTranslation();
	return (
		<div>
			<h1>{t(`${title ?? ""}`)}</h1>
			<p>{t(`${description ?? ""}`)}</p>
		</div>
	);
};

SuspiciousSheetFooter.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
};

export default MaintenanceHeader;
