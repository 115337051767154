// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";
import { InfoMessage, Input } from "@zolteam/react-ras-library";

// Components
import TooltipMessage from "../../atoms/TooltipMessage";

// Style
import style from "./Field.module.css";
import cn from "../../../utils/cn";

const Field = ({
	type,
	name,
	label = "",
	readOnly = false,
	className = "",
	classNameContainer = "",
	children = null,
	pictoName = null,
	onBlur = () => {},
	onFocus = () => {},
	onPictoClick = undefined,
	isFocused = false,
	disabled = false,
	info = null,
	step = null,
	showErrorMessage = true,
	customErrorDisplay = false,
}) => {
	const [, { error, touched }] = useField(name);

	const isErrorDisplayed = error && touched;
	return (
		<div className={cn([style.inputContainer, classNameContainer])}>
			<Input
				type={type}
				name={name}
				label={label}
				className={cn([style.input, className])}
				pictoName={pictoName}
				readOnly={readOnly}
				onBlur={onBlur}
				onFocus={onFocus}
				onPictoClick={onPictoClick}
				isFocused={isFocused}
				disabled={disabled}
				locked={disabled}
				step={step || null}
			>
				{!isErrorDisplayed && info && !customErrorDisplay && <p className={style.infoMessage}>{info}</p>}
				{showErrorMessage && !customErrorDisplay && (
					<ErrorMessage name={name}>
						{(msg) => (
							<InfoMessage color="error" withIcon>
								{msg}
							</InfoMessage>
						)}
					</ErrorMessage>
				)}
				{!isErrorDisplayed && info && customErrorDisplay && <p className={style.infoMessage}>{info}</p>}
				{showErrorMessage && customErrorDisplay && (
					<ErrorMessage name={name}>
						{(msg) => <TooltipMessage color="error">{msg}</TooltipMessage>}
					</ErrorMessage>
				)}
			</Input>
			{children}
		</div>
	);
};

Field.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	readOnly: PropTypes.bool,
	label: PropTypes.string,
	className: PropTypes.string,
	classNameContainer: PropTypes.string,
	pictoName: PropTypes.string,
	children: PropTypes.node,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onPictoClick: PropTypes.func,
	isFocused: PropTypes.bool,
	disabled: PropTypes.bool,
	info: PropTypes.string,
	step: PropTypes.number,
	showErrorMessage: PropTypes.bool,
	customErrorDisplay: PropTypes.bool,
};

export default Field;
