// @ts-nocheck
import { InputTime, Textarea, ToggleSwitch } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import "moment/locale/fr";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

// Services
import { getCountries } from "../../../../../services/commandService";

// Components
import FullAddressFormTemplate from "./FullAddressFormTemplate";

// Utils
import cn from "../../../../../utils/cn";

// Constants
import { CREATE_MODE } from "../../../../../constants";

// Style
import style from "./CommandStartFormBlock.module.css";

const CommandStartFormBlock = ({ isOrderFetched }) => {
	const { t } = useTranslation();

	const { values, setFieldValue, setValues } = useFormikContext();
	const { workingTime, workAddress } = values;
	const { orderId } = useParams();
	const isNewCommand = orderId === CREATE_MODE;

	const getCountriesQuery = useQuery("getCountries", () => getCountries());

	const [isStartHourDifferent, setIsStartHourDifferent] = useState(!!workingTime.firstDayStartHour);
	const [hasDifferentMeetingAddress, setHasDifferentMeetingAddress] = useState(!!workAddress?.meetingAddress);

	useEffect(() => {
		if (workAddress?.meetingAddress) {
			setHasDifferentMeetingAddress(true);
		}
		if (workingTime.firstDayStartHour) {
			setIsStartHourDifferent(true);
		}
	}, [workAddress.meetingAddress, workingTime.firstDayStartHour]);

	const handleDifferentStartHourToogle = () =>
		setIsStartHourDifferent((prevState) => {
			// We need to reset the value of the attribute if set to false, to avoid sending bad
			// data to the back-end
			const newValue = !prevState;

			if (!newValue) {
				setFieldValue("workingTime.firstDayStartHour", null);
			}

			return newValue;
		});

	const handleMeetingToogle = () =>
		setHasDifferentMeetingAddress((prevState) => {
			const newValue = !prevState;
			// When toggled off, we need to reset the values of meeting address form to null to avoid
			// sending "cached" values to the API
			if (!newValue) {
				setValues({
					...values,
					workAddress: {
						...values.workAddress,
						meetingAddress: null,
						meetingAddressComplement: null,
						meetingPostalCode: null,
						meetingCity: null,
						meetingCountry: null,
					},
					workAddressMeetingCountryOption: null,
				});
			} else {
				setValues({
					...values,
					workAddress: {
						...values.workAddress,
						meetingCountry: "France",
					},
					workAddressMeetingCountryOption: getCountriesQuery.data.data.find(
						(country) => country.name === "France"
					),
				});
			}

			return newValue;
		});

	if (!isOrderFetched && !isNewCommand) {
		return (
			<div className={style.skeletonColumn}>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonFullWidth} height={80} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton width={360} height={24} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton width={340} height={24} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonAddress} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonComplementaryInfo} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonZip} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonCity} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonCountry} height={50} borderRadius={25} />
				</div>
			</div>
		);
	}

	return (
		<div>
			<Textarea
				className={style.textarea}
				name="workAddress.comment"
				label={t("commands.locationForm.commentPlaceholder")}
				maxLength={800}
				shouldDisplayErrorMessage={false}
			/>
			<div className={cn([style.toggle])}>
				<ToggleSwitch
					name="differentStartHourToogle"
					id="differentStartHourToogle"
					onChange={handleDifferentStartHourToogle}
					toggled={isStartHourDifferent}
				>
					<label htmlFor="differentStartHourToogle" className={style.toggleLabel}>
						{t("commands.periodForm.differentStartHourToogleLabel")}
					</label>
				</ToggleSwitch>
			</div>
			{isStartHourDifferent ? (
				<div>
					<InputTime
						className={style.input}
						startTime="05:00"
						placeholder="--:--"
						name="workingTime.firstDayStartHour"
						onChange={(e) => setFieldValue("workingTime.firstDayStartHour", e)}
						value={workingTime.firstDayStartHour}
					/>
				</div>
			) : null}
			<div className={cn([style.field, style.toggle])}>
				<ToggleSwitch
					name="meetingToggle"
					id="meetingToggle"
					onChange={handleMeetingToogle}
					toggled={hasDifferentMeetingAddress}
				>
					<label htmlFor="meetingToggle" className={style.toggleLabel}>
						{t("commands.locationForm.meetingToggleLabel")}
					</label>
				</ToggleSwitch>
			</div>
			{hasDifferentMeetingAddress ? (
				<FullAddressFormTemplate
					addressName="workAddress.meetingAddress"
					addressComplementName="workAddress.meetingAddressComplement"
					postalCodeName="workAddress.meetingPostalCode"
					cityName="workAddress.meetingCity"
					countryName="workAddress.meetingCountry"
					countriesList={getCountriesQuery?.data?.data || []}
				/>
			) : null}
		</div>
	);
};

CommandStartFormBlock.propTypes = {
	isOrderFetched: PropTypes.bool.isRequired,
};

export default CommandStartFormBlock;
