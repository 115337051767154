// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Button, ModalV2, Text, Title } from "@zolteam/react-ras-library";
import { Trans, useTranslation } from "react-i18next";

// Style
import style from "./SuspiciousSheetModal.module.css";

// Store
import { useAppContext } from "../../../store/AppContext";
import { formatSuspiciousFormFields } from "../../../utils/suspiciousSheetUtils";

export const ShareDetachedModal = ({ isDisplayed, toggleModal, handleModalAction }) => {
	const { t } = useTranslation();
	return (
		<ModalV2 isDisplayed={isDisplayed} onClose={toggleModal} size="m">
			<Title tag="h1" lineHeight="l" size="heading01">
				{t("suspiciousSheet.shareDetachedTitle")}
			</Title>
			<Text tag="p" size="paragraph01" lineHeight="l" className={style.modalText}>
				{t("suspiciousSheet.shareDetachedText")}
			</Text>
			<div className={style.modalButton}>
				<Button type="button" color="primary" outline onClick={toggleModal}>
					{t("suspiciousSheet.cancel")}
				</Button>
				<Button type="button" color="primary" onClick={handleModalAction}>
					{t("suspiciousSheet.shareDetached")}
				</Button>
			</div>
		</ModalV2>
	);
};

export const ShareSheetModal = ({ isDisplayed, toggleModal, handleModalAction, loadingAction }) => {
	const { t } = useTranslation();
	const [{ suspiciousFormTotalFields, suspiciousSheetForm }] = useAppContext();

	const getTextValue = (property) =>
		formatSuspiciousFormFields(
			property,
			suspiciousSheetForm?.temporaryWorker?.[property] ?? "",
			suspiciousSheetForm?.temporaryWorker
		);

	return (
		<ModalV2 isDisplayed={isDisplayed} onClose={toggleModal} size="auto">
			<Title tag="h1" lineHeight="l" size="heading01">
				{t("suspiciousSheet.shareSheetTitle")}
			</Title>
			{suspiciousFormTotalFields?.length > 0 ? (
				<>
					<Text tag="p" size="paragraph01" lineHeight="l" className={style.modalText}>
						{t("suspiciousSheet.shareSheetText")}
					</Text>
					<ul className={style.modalList} data-testid="shareSheetModalFieldsList">
						{suspiciousFormTotalFields.map((field) => (
							<li key={field} className={style.modalListItem}>
								<span
									className={style.modalListLabel}
								>{`${t(`suspiciousSheet.workerInfo.${field}`)} :`}</span>
								<span className={style.modalListValue}>{`${getTextValue(field)}`}</span>
							</li>
						))}
					</ul>
				</>
			) : null}

			{suspiciousSheetForm?.dissociatedUsers?.length > 0 ? (
				<div className={style.modalSubInfos}>
					<Text tag="p" size="paragraph01" lineHeight="l">
						<Trans
							i18nKey="suspiciousSheet.shareSheetInfo"
							count={suspiciousSheetForm?.dissociatedUsers?.length}
							values={{
								id: suspiciousSheetForm?.dissociatedUsers.join(", "),
							}}
							components={[<strong>strong</strong>]}
						/>
					</Text>
				</div>
			) : null}

			<div className={style.modalButton}>
				<Button type="button" color="primary" outline onClick={toggleModal}>
					{t("suspiciousSheet.cancel")}
				</Button>
				<Button type="button" color="primary" onClick={handleModalAction} isLoading={loadingAction}>
					{t("suspiciousSheet.shareSheetButton")}
				</Button>
			</div>
		</ModalV2>
	);
};

export const IsLockedModal = ({ isDisplayed, handleCancel, handleConfirm, data }) => {
	const { t } = useTranslation();
	return (
		<ModalV2 isDisplayed={isDisplayed} onClose={handleCancel} size="auto">
			<Title tag="h1" lineHeight="l" size="heading01">
				{t("suspiciousSheet.lockedSheetModalTitle")}
			</Title>
			<Text tag="p" size="paragraph01" lineHeight="l" className={style.modalText}>
				<Trans
					i18nKey="suspiciousSheet.lockedSheetModalText"
					values={{
						firstName: data.lockedByFirstName,
						lastName: data.lockedByLastName,
					}}
					components={[<br />]}
				/>
			</Text>
			<div className={style.modalButton}>
				<Button type="button" color="primary" onClick={handleConfirm}>
					{t("suspiciousSheet.lockedSheetModalConfirm")}
				</Button>
				<Button type="button" color="primary" outline onClick={handleCancel}>
					{t("suspiciousSheet.lockedSheetModalCancel")}
				</Button>
			</div>
		</ModalV2>
	);
};

export const ShareErrorModal = ({ isDisplayed, toggleModal, handleConfirm, handleCancel }) => {
	const { t } = useTranslation();
	return (
		<ModalV2 isDisplayed={isDisplayed} onClose={toggleModal} size="m">
			<Title tag="h1" lineHeight="l" size="heading01">
				{t("suspiciousSheet.shareErrorTitle")}
			</Title>
			<Text tag="p" size="paragraph01" lineHeight="l" className={style.modalText}>
				{t("suspiciousSheet.shareErrorDescription")}
			</Text>
			<Text tag="p" size="paragraph01" lineHeight="l" className={style.modalText}>
				{t("suspiciousSheet.shareErrorDescription2")}
			</Text>
			<div className={style.modalButton}>
				<Button type="button" color="primary" outline onClick={handleCancel}>
					{t("suspiciousSheet.shareErrorCancel")}
				</Button>
				<Button type="button" color="primary" onClick={handleConfirm}>
					{t("suspiciousSheet.shareErrorConfirm")}
				</Button>
			</div>
		</ModalV2>
	);
};
