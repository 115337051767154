// @ts-nocheck
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { useFormikContext } from "formik";

import DateRangePicker from "../DateRangePicker";
import DateRangeInput from "../../atoms/DateRangeInput/DateRangeInput";

// Utils
import { getAtomFromDate } from "../../../utils/formatDate";

import cn from "../../../utils/cn";

import style from "./OrderFilterPeriod.module.css";

const DATE_FORMAT_STRING = "DD/MM/yyyy";

const OrderFilterPeriod = ({ startDateInputName, endDateInputName, startDateLabel, endDateLabel }) => {
	const { setValues, values, touched, errors, setFieldTouched } = useFormikContext();

	const { t } = useTranslation();

	const [focusedInputName, setFocusedInputName] = useState("");

	const { minCreationDate, maxCreationDate } = values;

	const handleClearPeriodDate = (periodSlot, event) => {
		event.stopPropagation();
		const newValues = {
			...values,
			[periodSlot]: null,
		};
		setFieldTouched(periodSlot, true);
		setValues(newValues);
	};

	const handleDateRangeUpdate = (startDate, endDate) => {
		const newPeriod = {
			minCreationDate: startDate ? getAtomFromDate(startDate) : null,
			maxCreationDate: endDate ? getAtomFromDate(endDate) : null,
		};

		if (startDate) {
			setFieldTouched(startDateInputName, true);
		}
		if (endDate) {
			setFieldTouched(endDateInputName, true);
		}

		return setValues({
			...values,
			...newPeriod,
		});
	};

	return (
		<div className={style.dateRangeWrapper}>
			<DateRangeInput
				label={t(startDateLabel)}
				value={minCreationDate ? moment(minCreationDate).format(DATE_FORMAT_STRING) : ""}
				onClick={() => {
					setFieldTouched(startDateInputName, true);
					setFocusedInputName(startDateInputName);
				}}
				onClear={(e) => handleClearPeriodDate("minCreationDate", e)}
				className={style.fieldStartDate}
				isActive={focusedInputName === startDateInputName}
				error={touched?.startDate && errors?.startDate ? t("commands.error.mandatoryStartDateField") : null}
			/>
			<DateRangeInput
				label={t(endDateLabel)}
				value={maxCreationDate ? moment(maxCreationDate).format(DATE_FORMAT_STRING) : ""}
				onClick={() => {
					setFieldTouched(endDateInputName, true);
					setFocusedInputName(endDateInputName);
				}}
				onClear={(e) => handleClearPeriodDate("maxCreationDate", e)}
				className={cn([style.dateRangeField, style.fieldEndDate])}
				isActive={focusedInputName === endDateInputName}
				error={touched?.endDate && errors?.endDate ? t("commands.error.mandatoryEndDateField") : null}
			/>
			<DateRangePicker
				className={style.datePickerWrapper}
				dateFrom={moment(minCreationDate || new Date())
					.startOf("day")
					.add(12, "hours")}
				dateTo={maxCreationDate ? moment(maxCreationDate) : null}
				focusedInput={focusedInputName.replace("period.", "")}
				setFocusedInput={(reactDatesInputName) =>
					setFocusedInputName(reactDatesInputName ? `period.${reactDatesInputName}` : "")
				}
				onDatesChange={handleDateRangeUpdate}
				keepOpenOnDateSelect
				isVisible={focusedInputName !== ""}
				isDayBlocked={() => false}
				setIsVisible={() => setFocusedInputName("")}
			/>
		</div>
	);
};

OrderFilterPeriod.propTypes = {
	startDateInputName: PropTypes.string.isRequired,
	endDateInputName: PropTypes.string.isRequired,
	startDateLabel: PropTypes.string.isRequired,
	endDateLabel: PropTypes.string.isRequired,
};

export default OrderFilterPeriod;
