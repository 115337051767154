// @ts-nocheck
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Template
import TableFiltersPanel from "../TableFiltersPanel";

// Style
import style from "./WithSideTableFiltersPanel.module.css";
import cn from "../../../utils/cn";

const WithSideTableFiltersPanel = ({
	children,
	filtersContent = null,
	title = undefined,
	showPanel = false,
	onClear = () => {},
	onClose = () => {},
}) => {
	const [isOpen, setIsOpen] = useState(showPanel);

	useEffect(() => {
		setIsOpen(showPanel);
	}, [showPanel]);

	return (
		<>
			<div className={cn([style.sidePanel, isOpen ? style.open : null])}>
				<TableFiltersPanel title={title} content={filtersContent} onClear={onClear} onClose={onClose} />
			</div>

			<div className={style.wrapperWithSidePanel}>{children}</div>
		</>
	);
};

WithSideTableFiltersPanel.propTypes = {
	children: PropTypes.node.isRequired,
	filtersContent: PropTypes.node,
	title: PropTypes.string,
	showPanel: PropTypes.bool,
	onClear: PropTypes.func,
	onClose: PropTypes.func,
};

export default WithSideTableFiltersPanel;
