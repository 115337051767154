// @ts-nocheck
/**
 * @param {string} value
 * @returns {string|null} formatted number
 */
const formatPhoneNumber = (value) => {
	if (!value) {
		return null;
	}

	let phone = value.replaceAll(" ", "");
	const regexFrancePhone = /^\s*(?:(\+)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}\s*$/g;

	if (!regexFrancePhone.test(phone)) return phone;

	if (phone.startsWith("+")) {
		phone = phone.replace(/(\+)33/, "0"); // clean indicatif france
	}

	return phone.replace(/\d{2}(?!$)/g, "$& "); // ajout d'espace tous les 2 chiffres
};

export default formatPhoneNumber;
