// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

// Style
import style from "./IdBox.module.css";

const IdBox = ({ children = null }) => <div className={style.container}>{children}</div>;

IdBox.propTypes = {
	children: PropTypes.node,
};

export default IdBox;
