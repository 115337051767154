// @ts-nocheck
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";

// Services
import { getAvailableFeatures } from "../services/user";

// Utils
import { FEATURE_SCOPES } from "../constants";

const useEnabledFeatures = () => {
	const { data, ...enabledFeaturesQuery } = useQuery(["enabledFeatures"], getAvailableFeatures, {
		staleTime: Infinity,
	});

	const isAllowed = useCallback(
		(featureList) => {
			if (!data) {
				return false;
			}
			// If at least one feature is allowed, return true
			return featureList.some((feature) => data?.data[feature]);
		},
		[data]
	);

	const enabledFeatures = useMemo(
		() => ({
			hasNotifyUserOrderEditionAccess: isAllowed([FEATURE_SCOPES.bdcMVP, FEATURE_SCOPES.bdcPiloteV2] || false),
			hasMissionListAccess:
				isAllowed([FEATURE_SCOPES.bdcMVP, FEATURE_SCOPES.bdcPiloteV2, FEATURE_SCOPES.bdcPiloteV1]) || false,
			hasOrderProfilesAccess: isAllowed([FEATURE_SCOPES.bdcMVP, FEATURE_SCOPES.bdcPiloteV2]) || false,
			hasMatchingAccess: isAllowed([FEATURE_SCOPES.bdcMVP]) || false,
			hasProfileAccess: isAllowed([FEATURE_SCOPES.unifiedTempWorkerMission]) || false,
			hasMissionFollowUpAccess: isAllowed([FEATURE_SCOPES.unifiedTempWorkerMission]) || false,
		}),
		[data, isAllowed]
	);

	return { enabledFeatures, query: { ...data, ...enabledFeaturesQuery } };
};

export default useEnabledFeatures;
