// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Select, SelectAsync, Title, Checkbox, Tag, InfoMessage, Tooltip } from "@zolteam/react-ras-library";
import { useTranslation } from "react-i18next";
import { find } from "lodash";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
// Services
import { getAvailableProfiles } from "../../../../services/commandService";
// Style
import style from "./Modals.module.css";
import cn from "../../../../utils/cn";
// Misc
import { PROFILES_STATUS, STATUS_OPTIONS } from "../../../../constants";

// Store
import { useToastContext } from "../../../../store/ToastContext";

const ModalContentAddProfile = ({ onAddClick, onCancelClick, defaultSelectedStatus = null }) => {
	// States
	const [selectedStatus, setSelectedStatus] = useState(defaultSelectedStatus || "");
	const [selectedProfiles, setSelectedProfiles] = useState([]);
	const [profileInputValue, setProfileInputValue] = useState("");
	const [profilesLength, setProfilesLength] = useState(null);

	// Hooks
	const { t } = useTranslation();
	const { orderId } = useParams();
	const [, toastDispatch] = useToastContext();

	const handleDeleteProfilClick = (profilId) => {
		const filterProfils = selectedProfiles.filter(({ id }) => id !== profilId);
		setSelectedProfiles(filterProfils);
	};

	const handleAddClick = () => {
		if (!selectedStatus && !selectedProfiles.length) {
			toastDispatch({
				type: "WARN",
				payload: {
					icon: "users",
					msg: "Merci de sélectionner au moins un profil et une étape de validation.",
				},
			});
			return;
		}
		if (!selectedStatus) {
			toastDispatch({
				type: "WARN",
				payload: {
					icon: "users",
					msg: "Merci de sélectionner l'étape de validation pour ce ou ces profils.",
				},
			});
			return;
		}
		if (!selectedProfiles.length) {
			toastDispatch({
				type: "WARN",
				payload: {
					icon: "users",
					msg: "Merci de sélectionner au moins un profil",
				},
			});
			return;
		}
		onAddClick({ selectedStatus, selectedProfiles });
	};

	const renderOptionLabel = useCallback(
		(option) => {
			const isSelected =
				selectedProfiles.find(
					({ unifiedTempWorkerUniqueId }) => unifiedTempWorkerUniqueId === option.unifiedTempWorkerUniqueId
				) != null;

			return (
				<div
					className={cn([
						style.optionsLabel,
						!option.hasValidIdentityDocument ? style.labelSelectDisabled : null,
					])}
				>
					<div className={style.optionCheckbox}>
						<Checkbox
							className={style.checkbox}
							onChange={() => null}
							theme="primary"
							id={option.unifiedTempWorkerUniqueId}
							value={isSelected}
						/>
					</div>
					<p className={cn([style.optionLabel, isSelected ? style.active : ""])}>
						{`${option.firstName} ${option.lastName} ${option.unifiedTempWorkerUniqueId}`}
					</p>
				</div>
			);
		},
		[selectedProfiles]
	);

	const renderSelectedProfile = ({ firstName, lastName, id, unifiedTempWorkerUniqueId }) => (
		<div className={style.addedProfilesItem} key={id}>
			<Tag key={id} color="primaryLight" size="s" showCloseButton onClose={() => handleDeleteProfilClick(id)}>
				<p className={style.profilSelected}>
					<strong>{`${firstName} ${lastName}`}</strong>
					{` ${unifiedTempWorkerUniqueId}`}
				</p>
			</Tag>
		</div>
	);

	const handleProfileSelect = useCallback((item) => {
		setSelectedProfiles((prevSelection) => {
			const selectedIndex = prevSelection.findIndex((profile) => profile.id === item.id);
			if (selectedIndex !== -1) return prevSelection.filter((profile) => profile.id !== item.id);
			return prevSelection.concat([item]);
		});
	}, []);

	// API Calls
	let timeoutId;

	const orderProfilesOptions = async (inputValue) => {
		clearTimeout(timeoutId);
		return new Promise((resolve) => {
			timeoutId = setTimeout(async () => {
				if (inputValue.length < 2) {
					resolve([]);
				} else {
					try {
						const res = await getAvailableProfiles(orderId, {
							search: inputValue,
							limit: 20,
						});
						const result = res.data?.sort((a, b) => a.lastName.localeCompare(b.lastName));
						setProfilesLength(res.data?.length);
						resolve(result);
					} catch (error) {
						resolve([]);
					}
				}
			}, 200);
		});
	};

	const profileOptionLabel = useCallback(
		(option) =>
			!option.hasValidIdentityDocument ? (
				<Tooltip
					animation={false}
					appendTo={profilesLength > 5 ? "parent" : document.body}
					arrow={false}
					placement="top-start"
					content={t("commands.profiles.modalsContent.addProfile.hasInvalidIdentityDocument")}
					tooltipBoxStyle={{ maxWidth: "300px" }}
				>
					{renderOptionLabel(option)}
				</Tooltip>
			) : (
				renderOptionLabel(option)
			),
		[profilesLength, selectedProfiles]
	);

	return (
		<div className={cn([style.content, style.fixedWidth])}>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.profiles.modalsContent.addProfile.title")}
			</Title>
			<div className={style.addedProfilesBox}>
				{selectedProfiles.map((profil) => renderSelectedProfile(profil))}
			</div>
			<Formik enableReinitialize initialValues={{ status: defaultSelectedStatus || null, profile: null }}>
				{({ values, setFieldValue }) => (
					<Form className={style.addProfileForm}>
						<SelectAsync
							className={cn([style.inputs])}
							isSearchable
							searchIcon
							promiseOptions={orderProfilesOptions}
							onChange={(item) => {
								handleProfileSelect(item);
							}}
							inputPersistOnOptionSelect
							name="profile"
							label={t("commands.profiles.modalsContent.addProfile.searchLabel")}
							getOptionLabel={(option) => profileOptionLabel(option)}
							getOptionValue={(option) => option.unifiedTempWorkerUniqueId}
							isOptionDisabled={(option) => !option.hasValidIdentityDocument}
							value=""
							handleInputChange={(value, { action, prevInputValue }) => {
								if (action === "set-value") {
									setProfileInputValue(prevInputValue);
									return prevInputValue;
								}
								if (action !== "menu-close") setProfileInputValue(value);
								return value;
							}}
							inputValue={profileInputValue}
							noOptionsMessage={() => t("commands.profiles.modalsContent.addProfile.emptySearch")}
							loadingMessage={() => t("commands.profiles.modalsContent.addProfile.loadingSearch")}
						/>
						<Select
							className={style.inputs}
							options={STATUS_OPTIONS}
							onChange={(item) => {
								setSelectedStatus(item.value);
								setFieldValue("status", item.value);
							}}
							getOptionLabel={(item) => t(item.label)}
							getOptionValue={(item) => item.value}
							name="status"
							value={find(STATUS_OPTIONS, { value: values.status })}
							label={t("commands.profiles.modalsContent.addProfile.selectLabel")}
						/>
						<div className={style.messageBox}>
							{selectedStatus === PROFILES_STATUS.VALIDATED ? (
								<InfoMessage className={style.message} withIcon>
									{t("commands.profiles.modalsContent.addProfile.validatedStatusMessage")}
								</InfoMessage>
							) : null}
							{selectedStatus === PROFILES_STATUS.OFFER_ACCEPTED ||
							selectedStatus === PROFILES_STATUS.PROPOSED ? (
								<InfoMessage className={style.message} withIcon>
									{t("commands.profiles.modalsContent.addProfile.acceptedStatusMessage")}
								</InfoMessage>
							) : null}
						</div>
					</Form>
				)}
			</Formik>

			<hr className={style.separator} />
			<div className={style.buttonsBlock}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.profiles.modalsContent.addProfile.cancelAction")}
				</Button>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={() => handleAddClick()}
				>
					{t("commands.profiles.modalsContent.addProfile.addAction")}
				</Button>
			</div>
		</div>
	);
};

ModalContentAddProfile.propTypes = {
	onAddClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
	defaultSelectedStatus: PropTypes.string,
};

export default ModalContentAddProfile;
