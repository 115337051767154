// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

// Style
import style from "./WideLoader.module.css";

const WideLoader = ({ message }) => (
	<div className={style.wideLoader}>
		<div className={style.wideLoaderContent}>
			<img
				className={style.wideLoaderIcon}
				src="/circle-loader.png"
				width="75"
				height="auto"
				alt="circle-loader"
			/>
			<div className={style.wideLoaderMessage}>{message}</div>
		</div>
	</div>
);

WideLoader.propTypes = {
	message: PropTypes.node.isRequired,
};

export default WideLoader;
