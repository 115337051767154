// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Picto, Text, Tooltip } from "@zolteam/react-ras-library";

// Style
import style from "./TableLockedSheet.module.css";

const TableLockedSheet = ({ icon, isLocked = false, msg, children }) =>
	isLocked ? (
		<Tooltip
			animation={false}
			arrow={false}
			placement="right"
			content={
				<Text tag="span" size="paragraph02" color="grey">
					{msg}
				</Text>
			}
		>
			<div className={style.container}>
				<Picto icon={icon} />
				{children}
			</div>
		</Tooltip>
	) : (
		<div className={style.container}>{children}</div>
	);

TableLockedSheet.propTypes = {
	icon: PropTypes.string.isRequired,
	msg: PropTypes.string.isRequired,
	isLocked: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export default TableLockedSheet;
